import React from "react";
import { getImage } from "./../../../utils/helperMethods";

const ReportCard = ({ user, report }) => {
  const { name, username, image } = user;
  let img = getImage(username);
  return (
    <div className="report">
      <div className="info">
        <div
          className="img"
          style={{
            background: `url(${
              image ? image.url : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        <div className="naming">
          <p className="name">{name}</p>
          {username ? <p className="username">@{username}</p> : null}
        </div>
      </div>
      {report ? <p className="rep">{report}</p> : null}
    </div>
  );
};

export default ReportCard;
