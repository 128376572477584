import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import lang from "../../assets/lang";
import history from "../../utils/history";
import { Modal } from "antd";
import { Loader } from "../common/loader";
import BasicInput from "../common/ant-design/input";
import { authService } from "../../services/authService";
import { success } from "../../utils/helperMethods";

const ConfirmationSent = () => {
  const authId = sessionStorage.getItem("authId");
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [emailMod, setEmailMod] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [emailText, setEmailText] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!email && !authId) {
      history.replace("/");
    }
    return () => {
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("authId");
    };
  }, [email, authId]);
  const validateEmail = (e) => {
    setEmailText(e.target.value);
    if (e.target.value.length >= 5) {
      setEmailCheck(true);
      authService.validateEmail(e.target.value).then((res) => {
        setEmailValid(res.data.valid);
        setEmailCheck(false);
      });
    } else {
      setEmailValid(false);
      setEmailCheck(false);
    }
  };
  const submitEmail = (resend) => {
    setLoading(true);
    authService
      .changeEmail(
        authId,
        resend ? email : emailText,
        sessionStorage.getItem("name"),
        lang.ls()
      )
      .then((res) => {
        if (!resend) {
          setEmail(emailText);
          sessionStorage.setItem("email", emailText);
        }
        setEmailMod(false);
        setLoading(false);
        setEmailText("");
        setEmailValid(null);
        if (resend) {
          success("Re-Sending Email done Successfully !!");
        } else {
          success(
            "Your Email has changes successfully !!\n Check your new email for the new Verification Email..."
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const cancelEmail = () => {
    setEmailMod(false);
  };
  const editEmail = () => {
    setEmailMod(true);
  };
  return (
    <div className="showcase">
      <div className="faded">
        <div className="container single-grid">
          <div className="issue">
            <h1 className="head-white issue-head">
              {lang.oneLastStep[lang.ls()]}
            </h1>
            <p className="issue-body">{lang.emailConfDesc[lang.ls()]}</p>
            <Link to="/" className="btn-issue">
              {lang.returnHome[lang.ls()]}
            </Link>
            <div className="issue-action">
              <Link onClick={editEmail} className="head">
                {email}
              </Link>
              <p className="desc">{lang.wrongEmail[lang.ls()]}</p>
            </div>
            <div className="issue-action">
              <Link onClick={() => submitEmail(true)} className="head">
                {loading ? <Loader tiny margin /> : lang.resendConf[lang.ls()]}
              </Link>
              <p className="desc">{lang.didnotEmail[lang.ls()]}</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={lang.changeEmail[lang.ls()]}
        visible={emailMod}
        onOk={() => submitEmail(false)}
        onCancel={cancelEmail}
        okButtonProps={{ disabled: !emailValid }}
      >
        <div className="validate">
          <BasicInput
            placeholder={lang.email[lang.ls()]}
            tooltip={lang.emailUnique[lang.ls()]}
            value={emailText}
            onChange={validateEmail}
            classes="sufix-input"
          />
          <div className="indicator">
            {emailCheck ? (
              <Loader tinycolor />
            ) : emailValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : emailValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

export default ConfirmationSent;
