var lang = {
  ls: function () {
    return localStorage.getItem("lang");
  },
  rtl: function () {
    if (localStorage.getItem("lang") === "ar") {
      return true;
    } else {
      return false;
    }
  },
  //placeholders
  addToWish: {
    en: "Add to Wishlist",
    ar: "إضافة لقائمة رغباتي",
  },
  feedSubm: {
    en: "Feedback submitted successfully",
    ar: "تم إرسال تقييمك بنجاح",
  },
  chat: {
    en: "Chat",
    ar: "دردشة",
  },
  noResults: {
    en: "No Results",
    ar: "ليس هناك نتائج",
  },
  removeWish: {
    en: "Remove from Wishlist",
    ar: "حذف من قائمة رغباتي",
  },
  topMoments: {
    en: "Top Momants",
    ar: "لحظات بارزة",
  },
  removeNotif: {
    en: "Remove Notification",
    ar: "حذف الإشعار",
  },
  copyLink: {
    en: "Copy Link",
    ar: "نسخ الرابط",
  },
  like: {
    en: "Like",
    ar: "إعجاب",
  },
  unlike: {
    en: "Unlike",
    ar: "إلغاء الإعجاب",
  },
  views: {
    en: "Views",
    ar: "المشاهدات",
  },
  getinTouch: {
    en: "Get In Touch",
    ar: "ابقى على تواصل",
  },
  pending: {
    en: "Pending",
    ar: "قيد الإنتظار",
  },
  remove: {
    en: "Remove",
    ar: "مسح",
  },
  emailPlace: {
    en: "E-mail or Username",
    ar: "البريد الإلكتروني او إسم المستخدم",
  },
  passPlace: {
    en: "Password",
    ar: "كلمة المرور",
  },
  firstName: {
    en: "First Name",
    ar: "الإسم الأول",
  },
  lastName: {
    en: "Last Name",
    ar: "إسم العائلة",
  },
  compName: {
    en: "Firm Name",
    ar: "إسم المنشأة",
  },
  enterText: {
    en: "Enter Text Here",
    ar: "اكتب رسالتك هنا",
  },
  searchChats: {
    en: "Search Chats",
    ar: "البحث عن محادثة",
  },
  username: {
    en: "Username",
    ar: "إسم المستخدم",
  },
  email: {
    en: "E-mail",
    ar: "البريد الإلكتروني",
  },
  rePass: {
    en: "Re-enter Password",
    ar: "أعد إدخال كلمة المرور",
  },
  city: {
    en: "City",
    ar: "المدينة",
  },
  fullName: {
    en: "Full Name",
    ar: "الإسم كاملاً",
  },
  enterMess: {
    en: "Enter your Message here",
    ar: "أدخل رسالتك هنا",
  },
  eventName: {
    en: "Momant Name",
    ar: "إسم اللحظة",
  },
  startTiming: {
    en: "Start Date & Time",
    ar: "تاريخ البدء والوقت",
  },
  endTiming: {
    en: "End Date & Time",
    ar: "تاريخ الانتهاء والوقت",
  },
  detailedAddress: {
    en: "Detailed Address",
    ar: "العنوان بالتفصيل",
  },
  eventDetails: {
    en: "Momant Details",
    ar: "تفاصيل اللحظة",
  },
  newKeyword: {
    en: "New Keyword",
    ar: "كلمة داله جديدة",
  },
  limited: {
    en: "Limited",
    ar: "محدودة العدد",
  },
  privacy: {
    en: "Privacy",
    ar: "الخصوصية",
  },
  upload: {
    en: "Upload",
    ar: "صورة جديدة",
  },
  reviewPlace: {
    en: "Add your Review here",
    ar: "اضف تعليقك هنا",
  },
  reportPlace: {
    en: "Add your Report here",
    ar: "اضف بلاغك هنا",
  },
  chatDeleteModal: {
    en: "Are you sure that you want to delete this chat?",
    ar: "هل انت متاكد من مسح هذه المحادثة؟",
  },

  //labels
  adminDash: {
    en: "Admin Dashboard",
    ar: "لوحة تحكم المشرف",
  },
  signIn: {
    en: "Sign-In",
    ar: "الدخول",
  },
  signUp: {
    en: "Sign-Up",
    ar: "إنشاء حساب",
  },
  emailLbl: {
    en: "E-mail Address",
    ar: "البريد الإلكتروني",
  },
  hi: {
    en: "Hi",
    ar: "أهلاً",
  },
  newPass: {
    en: "New Password",
    ar: "كلمة المرور الجديدة",
  },
  confNewPass: {
    en: "ReEnter New Password",
    ar: "اعد إدخال كلمة المرور الجديدة",
  },
  currPass: {
    en: "Current Password",
    ar: "كلمة المرور الحالية",
  },
  hostedby: {
    en: "Hosted By",
    ar: "المستضيف",
  },
  joined: {
    en: "Joined",
    ar: "مُنضم",
  },
  country: {
    en: "Country",
    ar: "الدولة",
  },
  gender: {
    en: "Gender",
    ar: "الجنس",
  },
  dateOfBirth: {
    en: "Date of Birth",
    ar: "تاريخ الميلاد",
  },
  age: {
    en: "Age",
    ar: "العمر",
  },
  education: {
    en: "Education",
    ar: "التعليم",
  },
  about: {
    en: "About",
    ar: "نبذه عني",
  },
  public: {
    en: "Public",
    ar: "عام",
  },
  private: {
    en: "Private",
    ar: "خاص",
  },
  created: {
    en: "Created",
    ar: "انشأت",
  },
  category: {
    en: "Category",
    ar: "الفئة",
  },
  sortBy: {
    en: "Sort By",
    ar: "الترتيب",
  },
  nearbyEv: {
    en: "Nearby Momants",
    ar: "اللحظات القريبة منك",
  },
  latestEvents: {
    en: "Latest Momants",
    ar: "أحدث اللحظات",
  },
  address: {
    en: "Address",
    ar: "العنوان",
  },
  date: {
    en: "Date",
    ar: "التاريخ",
  },
  time: {
    en: "Time",
    ar: "الوقت",
  },
  details: {
    en: "Details",
    ar: "التفاصيل",
  },
  keywords: {
    en: "Keywords",
    ar: "الكلمات الدالة",
  },
  gofriends: {
    en: "Go With your Friends",
    ar: "إذهب مع اصدقائك",
  },
  invitefriends: {
    en: "Invite your Friends",
    ar: "إدعو أصدقائك",
  },
  location: {
    en: "Location",
    ar: "الموقع",
  },
  editEvent: {
    en: "Edit Momant",
    ar: "تعديل لحظة",
  },
  publicSee: {
    en: "Everyone will see this Momant",
    ar: "سوف يرى الجميع هذا الحدث",
  },
  privateSee: {
    en: "Only your Friends will see the Momant",
    ar: "فقط أصدقائك سيشاهدون الحدث",
  },
  images: {
    en: "Images",
    ar: "الصور",
  },
  welcome: {
    en: "Welcome to MOMANTS",
    ar: "مرحبا بك في مومانتز",
  },
  blockedInfo: {
    en: "You're Blocked",
    ar: "أنت محظور",
  },
  block: {
    en: "Block",
    ar: "حظر",
  },
  rateAndReview: {
    en: "Rate & Review",
    ar: "تعليق و تقييم",
  },
  report: {
    en: "Report",
    ar: "بلاغ",
  },
  loadMore: {
    en: "Load More",
    ar: "إظهار المزيد",
  },
  to: {
    en: "To",
    ar: "إلى",
  },
  mutualFriends: {
    en: "Mutual Friends",
    ar: "الأصدقاء المشتركين",
  },
  all: {
    en: "All",
    ar: "الكل",
  },
  atService: {
    en: "At Your Service",
    ar: "في خدمتكم",
  },
  exploreEvents: {
    en: "Explore Momants",
    ar: "إستكشف اللحظات",
  },
  connectFriends: {
    en: "Connect",
    ar: "تواصل",
  },
  secure: {
    en: "Secure",
    ar: "اّمن",
  },
  chatting: {
    en: "Chatting",
    ar: "المراسلة",
  },
  exploreTalk: {
    en: "Explore Momants nearby and all over the world",
    ar: "استكشاف اللحظات القريبة وفي جميع أنحاء العالم",
  },
  connectTalk: {
    en: "Connect with all of your friends",
    ar: "تواصل مع جميع أصدقائك من حولك",
  },
  secureTalk: {
    en: "All your activities are secured",
    ar: "جميع أنشطتك آمنة",
  },
  chattingTalk: {
    en: "Secured chatting platform",
    ar: "منصة الدردشة الآمنة",
  },
  sports: {
    en: "Sports",
    ar: "رياضية",
  },
  learing: {
    en: "Learning",
    ar: "تعليمية",
  },
  exhib: {
    en: "Exhibitions",
    ar: "معارض",
  },
  festival: {
    en: "Festival",
    ar: "إحتفالات",
  },
  social: {
    en: "Social",
    ar: "إجتماعية",
  },
  adventure: {
    en: "Adventures",
    ar: "مغامرات",
  },
  closed: {
    en: "Closed",
    ar: "مغلق",
  },
  running: {
    en: "Ongoing",
    ar: "جارية",
  },
  archived: {
    en: "Archived",
    ar: "مأرشف",
  },
  catched: {
    en: "Catched",
    ar: "ملتقط",
  },
  reported: {
    en: "Reported",
    ar: "مُبلغ عنه",
  },

  //Menus
  explore: {
    en: "Explore",
    ar: "إستكشف",
  },
  events: {
    en: "Momants",
    ar: "اللحظات",
  },
  connections: {
    en: "Connections",
    ar: "العلاقات",
  },
  profile: {
    en: "Profile",
    ar: "الملف الشخصي",
  },
  reviews: {
    en: "Reviews",
    ar: "التقييمات",
  },
  reports: {
    en: "Reports",
    ar: "البلاغات",
  },
  aboutUs: {
    en: "About Us",
    ar: "معلومات عنا",
  },
  contactUs: {
    en: "Contact Us",
    ar: "تواصل معنا",
  },
  contactTalk: {
    en:
      "You have a question? we would love to hear from you, and we will reply to you as soon as possible",
    ar: "هل لديك سؤال؟ نود أن نسمع منك ، وسوف نقوم بالرد عليك في أقرب وقت ممكن",
  },
  settings: {
    en: "Settings",
    ar: "الإعدادات",
  },
  logOut: {
    en: "Log-Out",
    ar: "تسجيل خروج",
  },
  friends: {
    en: "Friends",
    ar: "الأصدقاء",
  },
  followers: {
    en: "Followers",
    ar: "المتابعين",
  },
  following: {
    en: "Following",
    ar: "المتابَعون",
  },
  requests: {
    en: "Friend Requests",
    ar: "طلبات الصداقة",
  },
  users: {
    en: "Users",
    ar: "المستخدمين",
  },
  companies: {
    en: "Firms",
    ar: "المنشاّت",
  },
  blocked: {
    en: "Blocked",
    ar: "المحظورين",
  },
  blockedOne: {
    en: "Blocked",
    ar: "محظور",
  },
  myEvents: {
    en: "My Momants",
    ar: "لحظاتي",
  },
  wishlist: {
    en: "Wishlist",
    ar: "قائمة الرغبات",
  },
  shared: {
    en: "Shares",
    ar: "المشاركات",
  },
  share: {
    en: "Share",
    ar: "مشاركة",
  },
  unShare: {
    en: "unShare",
    ar: "إلغاء المشاركة",
  },
  joins: {
    en: "Joined",
    ar: "المنضم إليها",
  },
  joinRequests: {
    en: "Join Requests",
    ar: "طلبات الإنضمام",
  },
  map: {
    en: "Map",
    ar: "الخريطة",
  },
  list: {
    en: "List",
    ar: "القائمة",
  },
  usersJoined: {
    en: "Users Joined",
    ar: "الأشخاص المنضمين",
  },
  blockedBy: {
    en: "Blocked By",
    ar: "حظر من قبل",
  },

  //checkboxs
  rememberMe: {
    en: "Remember me",
    ar: "ابقني متصل",
  },
  terms: {
    en: "You agree to MOMANTS terms of use and privacy policy by Signing Up",
    ar:
      "أنت توافق على شروط الاستخدام وسياسة الخصوصية الخاصة MOMANTS عن طريق الاشتراك",
  },
  agreeTo: {
    en: "You agree to",
    ar: "انت توافق على",
  },
  use: {
    en: "Terms of Use",
    ar: "شروط الإستخدام",
  },
  privCond: {
    en: "Privacy Policy",
    ar: "سياسة الخصوصية",
  },
  bySign: {
    en: "by signing into MOMANTS",
    ar: "عن طريق التسجيل في مومانتز",
  },
  and: {
    en: "and",
    ar: "و",
  },

  //Btn's
  forgotPass: {
    en: "Forgot your Password?",
    ar: "نسيت كلمة المرور؟",
  },
  send: {
    en: "Send",
    ar: "إرسال",
  },
  readMore: {
    en: "Read More",
    ar: "اعرف أكثر",
  },
  user: {
    en: "User",
    ar: "مستخدم",
  },
  company: {
    en: "Firm",
    ar: "منشأة",
  },
  register: {
    en: "Register",
    ar: "تسجيل",
  },
  resetPass: {
    en: "Reset Password",
    ar: "تغيير كلمة المرور",
  },
  logIn: {
    en: "Log-In",
    ar: "تسجيل الدخول",
  },
  unblock: {
    en: "Unblock",
    ar: "فك الحظر",
  },
  accept: {
    en: "Accept",
    ar: "قبول",
  },
  reject: {
    en: "Reject",
    ar: "رفض",
  },
  delete: {
    en: "Delete",
    ar: "مسح",
  },
  message: {
    en: "Message",
    ar: "مراسلة",
  },
  addFriend: {
    en: "Add Friend",
    ar: "أضف صديق",
  },
  unFriend: {
    en: "Unfriend",
    ar: "الغاء الصداقه",
  },
  requestSent: {
    en: "Request Sent",
    ar: "تم الإرسال",
  },
  follow: {
    en: "Follow",
    ar: "تابع",
  },
  unFollow: {
    en: "Unfollow",
    ar: "الغاء المتابعة",
  },
  edit: {
    en: "Edit",
    ar: "تعديل",
  },
  seeall: {
    en: "See All",
    ar: "إظهار الكل",
  },
  join: {
    en: "Join",
    ar: "إنضم",
  },
  sent: {
    en: "Sent",
    ar: "أرسلت",
  },
  invite: {
    en: "Invite",
    ar: "قم بدعوته",
  },
  submitEvent: {
    en: "Submit Momant",
    ar: "حفظ اللحظة",
  },
  returnHome: {
    en: "Return Home",
    ar: "الصفحة الرئيسية",
  },
  getStarted: {
    en: "Get Started",
    ar: "البدء الآن",
  },
  submit: {
    en: "Submit",
    ar: "حفظ",
  },
  search: {
    en: "Search",
    ar: "بحث",
  },
  searchEvents: {
    en: "Search Momants",
    ar: "بحث فى اللحظات",
  },
  latest: {
    en: "Latest",
    ar: "الأحدث",
  },
  views: {
    en: "Views",
    ar: "المشاهدات",
  },
  likes: {
    en: "Likes",
    ar: "الإعجابات",
  },
  rate: {
    en: "Rate",
    ar: "التقييم",
  },
  changePass: {
    en: "Change Password",
    ar: "تغيير كلمة السر",
  },
  changeEmail: {
    en: "Change Email",
    ar: "تغيير البريد الالكتروني",
  },
  changeName: {
    en: "Change Name",
    ar: "تغيير الإسم",
  },
  changeUsername: {
    en: "Change Username",
    ar: "تغير إسم المستخدم",
  },
  changePassTalk: {
    en:
      "It's a good idea to use a strong password that you're not using elsewhere",
    ar: "إنها لفكرة جيدة أن تستخدم كلمة مرور قوية لا تستخدمها في أي مكان آخر",
  },
  editCatches: {
    en: "Edit Catching Words",
    ar: "تعديل الكلمات اللاقطة",
  },
  eventChachingWords: {
    en: "Momant Catching Words",
    ar: "الكلمات الملتقطة للحظات",
  },
  notif: {
    en: "Notifications",
    ar: "الإشعارات",
  },

  //links & Desc
  dontAcc: {
    en: "Don't have an account?",
    ar: "ليس لديك حساب؟",
  },
  haveAcc: {
    en: "Have an account?",
    ar: "لديك حساب؟",
  },
  slogan: {
    en: "Meet Your City",
    ar: "إلتقي بمدينتك",
  },
  giveFeed: {
    en: "Give us your Feedback",
    ar: "زودنا بتقييمك",
  },
  enterFeed: {
    en: "Enter your Feedback",
    ar: "أدخل ملاحظاتك",
  },
  privacyPol: {
    en: "Privacy Policy",
    ar: "سياسات الخصوصية",
  },
  termsUse: {
    en: "Terms of Use",
    ar: "تعليمات الاستخدام",
  },
  copyRights: {
    en: "Copyright © 2020 Momants, All Rights Reserved",
    ar: "حقوق النشر © 2020 Momants ، جميع الحقوق محفوظة",
  },
  aboutTalk: {
    en:
      "Momants is a platform for gathering users in a social network and allowing them to create, share, find and attend events nearby or around the globe. Our mission is to encourage users to meet new people based on shared interests and starting having unforgettable momants. We believe that the momants have voice and feelings.",
    ar:
      "مومانتز هو عبارة عن منصة تجمع المستخدمين خلال شبكة اجتماعية وتمكنيهم من إنشاء، ومشاركة وإيجاد وحضور اللحظات القريبة منهم أو في جميع أنحاء العالم.  مهمتنا هي تشجيع المستخدمين على التعرف على أشخاص جدد بناءً على الاهتمامات المشتركة بينهم والحصول على لحظات لا تنسى.  نحن نؤمن أن اللحظات لها صوت ومشاعر.",
  },
  intro: {
    en: "Share your events with your connected friends, and find events nearby",
    ar: "شارك لحظاتك مع اصدقائك المتصلين, وابحث عن اللحظات القريبة منك",
  },
  getApp: {
    en: "Get Our App Now",
    ar: "احصل على تطبيقاتنا الان",
  },
  forgotPassDesc: {
    en:
      "Please enter your E-mail address, you will recieve a link to create a new Password via E-mail",
    ar:
      "يرجى إدخال عنوان البريد الإلكتروني الخاص بك ، وسوف تتلقى رابط لإنشاء كلمة مرور جديدة عبر البريد الإلكتروني",
  },
  resetPassDesc: {
    en: "Please enter a new password to reset the old one",
    ar: "الرجاء إدخال كلمة مرور جديدة لإعادة تعيين كلمة المرور القديمة",
  },
  forgotSentDesc: {
    en:
      "Check your email and simply click the reset password link to create new password.",
    ar:
      "تحقق من بريدك الإلكتروني وانقر على رابط إعادة تعيين كلمة المرور لإنشاء كلمة مرور جديدة.",
  },
  emailConfDesc: {
    en:
      "Check your email and simply click the verification link to activate your account.",
    ar: "تحقق من بريدك الإلكتروني وانقر على رابط التحقق لتفعيل حسابك.",
  },
  wrongEmail: {
    en: "Wrong email address? Edit your email above",
    ar: "عنوان بريد إلكتروني خاطئ؟ تعديل البريد الإلكتروني الخاص بك",
  },
  oneLastStep: {
    en: "ONE LAST STEP",
    ar: "خطوة واحدة أخيرة",
  },
  didnotEmail: {
    en: "If you did not receive an email, please check your spam/junk folder",
    ar:
      "إذا لم تتلق بريدًا إلكترونيًا ، فالرجاء التحقق من مجلد البريد العشوائي او غير الهام لديك",
  },
  resendReset: {
    en: "Re-send Reset Password email",
    ar: "إعادة إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور",
  },
  resendConf: {
    en: "Re-send Verification email",
    ar: "إعادة ارسال بريد التحقق",
  },
  passChangedTalk: {
    en:
      "Your password is successfully changed, you can log-in with the new password now",
    ar:
      "تم تغيير كلمة المرور الخاصة بك بنجاح ، يمكنك تسجيل الدخول باستخدام كلمة المرور الجديدة الآن",
  },
  blockedTalk: {
    en:
      "Unfortunately you are blocked for some reason. You can contact us for checking if you can unblock your account",
    ar:
      "لسوء الحظ أنت محظور لسبب ما. يمكنك الاتصال بنا للتحقق مما إذا كان يمكنك إلغاء حظر حسابك",
  },
  welcomeTalk: {
    en:
      "Congratulations you're now a member of MOMANTS community. Now have the best experience in connecting with your community and share events",
    ar:
      "تهانينا ، أنت الآن عضو في مجتمع مومانتز. الآن لديك أفضل تجربة في التواصل مع مجتمعك ومشاركة الأحداث",
  },
  noEventsFound: {
    en: "No Momants Found",
    ar: "لا يوجد لحظات",
  },
  noUsersFound: {
    en: "No Users Found",
    ar: "لا يوجد مستخدمين",
  },
  noCompaniesFound: {
    en: "No Firms Found",
    ar: "لا يوجد منشاّت",
  },
  noBlockedFound: {
    en: "No Blocked Users Found",
    ar: "لا يوجد اشخاص محظورين",
  },
  noFriendsFound: {
    en: "No Friends Found",
    ar: "لا يوجد اصدقاء",
  },
  noFollowersFound: {
    en: "No Followers Found",
    ar: "لا يوجد متابعين",
  },
  noFollowingsFound: {
    en: "No Followings Found",
    ar: "لا يوجد متابعون",
  },
  noFriendRequestsFound: {
    en: "No Friend Requests Found",
    ar: "لا يوجد طلبات صداقة",
  },
  noContacts: {
    en: "No Contacts Found",
    ar: "لا يوجد رسائل",
  },
  noFeedbacks: {
    en: "No Feedbacks Found",
    ar: "لا يوجد تقييمات",
  },
  createEvent: {
    en: "Create Momant",
    ar: "إنشاء لحظة",
  },
  noReports: {
    en: "No Reports Found",
    ar: "لا يوجد تقريرات",
  },
  noReviews: {
    en: "No Reviews Found",
    ar: "لا يوجد تقييمات",
  },
  emailWarn: {
    en:
      "If you changed your email your account will be deactivated til you validate it through an email will be sent to your new emai",
    ar:
      "إذا قمت بتغيير بريدك الإلكتروني ، فسيتم إلغاء تنشيط حسابك حتى تتحقق من صحته عبر بريد إلكتروني سيتم إرساله إلى بريدك الإلكتروني الجديد",
  },
  cancel: {
    en: "Cancel",
    ar: "إلغاء",
  },
  register: {
    en: "Register",
    ar: "تسجيل",
  },
  aboutComp: {
    en: "About",
    ar: "نبذة عنا",
  },
  blog: {
    en: "Blog",
    ar: "المدونة",
  },
  help: {
    en: "Help",
    ar: "مساعدة",
  },
  faq: {
    en: "FAQ",
    ar: "الأسئلة المتكررة",
  },
  contact: {
    en: "Contact",
    ar: "تواصل معنا",
  },
  followus: {
    en: "Follow Us",
    ar: "تابعنا",
  },

  //validation
  passValid: {
    en: "Password Should be greater than 8 characters",
    ar: "كلمة المرور يجب أن تكون أكبر من 8 أحرف",
  },
  twoPassValid: {
    en: "The Two Passwords does not match",
    ar: "كلمتا المرور غير متطابقتين",
  },
  existEmail: {
    en: "The email does not exist",
    ar: "البريد الإلكتروني ليس موجود",
  },
  errTry: {
    en: "ُError.. Try again",
    ar: "هناك خطأ.. حاول مرة اخرى",
  },
  passChanged: {
    en: "Password Changed Successfully",
    ar: "تم تغيير الرقم السري بنجاح",
  },
  required: {
    en: "Required Field",
    ar: "حقل متطلب",
  },
  emailUnique: {
    en: "E-mail must be Unique",
    ar: "يجب أن يكون البريد الإلكتروني فريدًا",
  },
  usernameUnique: {
    en: "Username must be unique",
    ar: "يجب أن يكون اسم المستخدم فريدًا",
  },
  emailChanged: {
    en: "Email Changed Successfully",
    ar: "تم تغيير البريد الإلكتروني بنجاح",
  },
  usernameChanged: {
    en: "Username Changed Successfully",
    ar: "تم تغيير إسم المستخدم بنجاح",
  },
  contacts: {
    en: "Contacts",
    ar: "الرسائل",
  },
  feedbacks: {
    en: "Feedbacks",
    ar: "التقييمات",
  },
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  normal: {
    en: "Normal",
    ar: "عاديين",
  },
  reply: {
    en: "Reply",
    ar: "رد",
  },
  replied: {
    en: "Replied",
    ar: "تم الرد",
  },
  contactSent: {
    en: "Message Sent Successfully",
    ar: "تم إرسال الرسالة بنجاح",
  },
  blockModalTitle: {
    en: "Do you really want to Block this account?",
    ar: "هل تريد حقًا حظر هذا الحساب؟",
  },
  blockModalDesc: {
    en:
      "When you click OK, this account will be blocked and can not login until you unblock it.",
    ar:
      'عند النقر فوق "موافق" ، سيتم حظر هذا الحساب ولا يمكنه تسجيل الدخول حتى تقوم بإلغاء حظره.',
  },
  unBlockModalTitle: {
    en: "Do you really want to unBlock this account?",
    ar: "هل تريد فعلًا إلغاء حظر هذا الحساب؟",
  },
  unBlockModalDesc: {
    en: "When you click OK, this account will be unblocked.",
    ar: 'عند النقر فوق "موافق" ، سيتم إلغاء حظر هذا الحساب.',
  },
  eventDeleteModalTitle: {
    en: "Do you really want to Delete this Momant?",
    ar: "هل تريد حقًا حذف هذه اللحظة؟",
  },
  eventDeleteModalDesc: {
    en: "When you click Yes, this event will be deleted permanently.",
    ar: 'عند النقر فوق "موافق" ، سيتم حذف هذه اللحظة نهائيًا.',
  },
  accountInfo: {
    en: "Account Information",
    ar: "معلومات عن الحساب",
  },
  signUpRole: {
    en: "Create Admin Accounts",
    ar: "إنشاء حسابات المسؤولين",
  },
  adminsRole: {
    en: "Edit Admin Roles",
    ar: "تعديل صلاحيات المسؤولين",
  },
  catchingRole: {
    en: "Edit Catching Words",
    ar: "تعديل الكلمات الملتقطة",
  },
  editEventRole: {
    en: "Edit Momants",
    ar: "تعديل اللحظات",
  },
  deleteEventRole: {
    en: "Delete Momants",
    ar: "مسح اللحظات",
  },
  blockUsersRole: {
    en: "Block Users",
    ar: "حظر المستخدمين",
  },
  roles: {
    en: "Roles",
    ar: "الصلاحيات",
  },
  createAccount: {
    en: "Create Account",
    ar: "إنشاء حساب",
  },
  notifications: {
    en: "Notifications",
    ar: "الإشعارات",
  },
  noNotifications: {
    en: "No Notifications Yet",
    ar: "لا إشعارات بعد",
  },
  deleteAdminTitle: {
    en: "Are you sure that you want to delete this admin account?",
    ar: "هل أنت متأكد أنك تريد حذف حساب المشرف هذا؟",
  },
  adminDeleted: {
    en: "Admin account deleted successfully.",
    ar: "تم حذف حساب المسؤول بنجاح.",
  },
  notifDis: {
    en: "Notifications are Muted",
    ar: "تم كتم الإشعارات",
  },
  doubleClick: {
    en: "Double click on the marker to go to Google Maps",
    ar: "انقر نقرًا مزدوجًا فوق العلامة لرؤية الموقع على خرائط Google",
  },
  networkError: {
    en: "Network Error !! Try again later",
    ar: "خطأ في الشبكة !! حاول مرة أخرى في وقت لاحق",
  },
  notFound: {
    en: "Momant Not Foun This momant may be deleted or didn't exist",
    ar: "لم يتم العثور على اللحظة ربما تم حذف هذه اللحظة أو لم تكن موجودة",
  },
  copy: {
    en: "Copy",
    ar: "نسخ",
  },
  copied: {
    en: "Copied",
    ar: "تم النسخ",
  },

  //notifications
  followNotif: {
    en: "Started Follwing You",
    ar: "بدأ يتابعك",
  },
  inviteNotif: {
    en: "Invited you to Momant",
    ar: "قام بدعوتك للحظة",
  },
  joinNotif: {
    en: "Has joined your event",
    ar: "لقد إنضم لفعاليتك",
  },
  joinReqNotif: {
    en: "Sent a join request to your event",
    ar: "لقد أرسل طلب إنضمام لفعاليتك",
  },
  reviewNotif: {
    en: "Has reviewed your event",
    ar: "قام بتقييم فعاليتك",
  },
  createNotif: {
    en: "Has created new event",
    ar: "قام بإنشاء لحظة جديدة",
  },
  updateNotif: {
    en: "Has updated the event you joined",
    ar: "قام بتعديل اللحظة التي انضميت اليها",
  },
  shareNotif: {
    en: "Has shared event",
    ar: "قام بمشاركة لحظة",
  },
  deleteNotif: {
    en:
      "This event has been deleted as it’s against Momants’s terms of service",
    ar: "تم حذف هذه اللحظة لأنه يتعارض مع بنود خدمة مومانتز",
  },
  adminUpdateNotif: {
    en:
      "This event has been updated as it’s against Momants’s terms of service",
    ar: "تم تحديث هذه اللحظة لأنه مخالف لشروط خدمة مومانتز",
  },
};

export default lang;
