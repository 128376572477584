import React, { Component } from "react";
import Search from "../../common/search";
import { contactService } from "../../../services/contactService";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
import EmptyLabel from "../../common/ant-design/empty";
import NotRepliedContact from "../cards/notRepliedContact";
import Paginate from "../../common/ant-design/pagination";

class Contacts extends Component {
  state = {
    contacts: [],
    contactsCount: null,
    contactsCurrPage: 1,
    loading: true,
    search: "",
    pageSize: 6,
  };
  componentDidMount() {
    this.getData("", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getData = (sort, filter, page) => {
    const { pageSize } = this.state;
    filter = this.cleanFilter(filter);
    this.setState({ filter, loading: true, contactsCurrPage: page });
    contactService.getContacts(filter, page, pageSize).then((res) => {
      this.setState({ contacts: res.data, loading: false });
    });
    contactService.getCount(filter).then((res) => {
      this.setState({ contactsCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ eventsCurrPage: page });
    this.getData(this.state.sort, this.state.filter, page);
  };
  replyState = (id) => {
    let contacts = [...this.state.contacts];
    contacts.map((cont) => {
      if (cont._id === id) {
        cont.isReplied = true;
      }
      return null;
    });
    this.setState({ contacts });
  };
  updateState = (id) => {
    let contacts = [...this.state.contacts];
    let contactsCount = this.state.contactsCount;
    contacts = contacts.filter((obj) => {
      return obj._id !== id;
    });
    contactsCount = contactsCount - 1;
    this.setState({ contacts, contactsCount });
  };
  render() {
    const {
      contacts,
      search,
      pageSize,
      contactsCount,
      contactsCurrPage,
      loading,
    } = this.state;
    return (
      <div className="content">
        <Search
          value={search}
          onChange={this.getData}
          placeholder={lang.search[lang.ls()]}
          filterDis
        />
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {contactsCount === 0 ? (
              <EmptyLabel label={lang.noContacts[lang.ls()]} />
            ) : (
              <React.Fragment>
                {!loading && (
                  <p className="info-parag">
                    {contactsCount} {lang.contacts[lang.ls()]}
                  </p>
                )}
                <div className="triple-grid">
                  {contacts.map((cont) => {
                    return (
                      <NotRepliedContact
                        key={cont._id}
                        id={cont._id}
                        name={cont.name}
                        email={cont.email}
                        message={cont.message}
                        replied={cont.isReplied}
                        replyState={this.replyState}
                        updateState={this.updateState}
                      />
                    );
                  })}
                </div>
                {contactsCount > pageSize && !loading && (
                  <Paginate
                    def={contactsCurrPage}
                    total={contactsCount}
                    size={pageSize}
                    onChange={this.changePage}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Contacts;
