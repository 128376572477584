import React, { Component } from "react";
import { Tabs } from "antd";
import "./events.scss";
import lang from "../../../assets/lang";
import { eventService } from "../../../services/eventService";
import { connect } from "react-redux";
import ListEvents from "../../common/eventslist";
import Search from "../../common/search";
import { userService } from "../../../services/userService";
import _ from "lodash";
const { TabPane } = Tabs;

class EventsUser extends Component {
  state = {
    myEvents: [],
    wishlist: [],
    joins: [],
    joinRequests: [],
    temp: [],
    tempEn: false,
    joinCount: null,
    reqs: null,
    tab: "myevents",
    myeventsLoad: true,
    myEventsCount: null,
    myEventsCurrPage: 1,
    wishlistLoad: true,
    joinsLoad: true,
    reqLoad: true,
    pageSize: 12,
  };
  componentDidMount() {
    this.getEvents("created_at", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getEvents = (sort, filter, page) => {
    const { tab, pageSize } = this.state;
    const { token, type } = this.props;
    filter = this.cleanFilter(filter);
    this.setState({ sort: sort, filter: filter });
    if (tab === "myevents") {
      this.setState({ myeventsLoad: true });
      eventService
        .getMyEvents(token, sort, filter, page, pageSize)
        .then((res) => {
          this.setState({ myEvents: res.data, myeventsLoad: false });
        });
      eventService.getMyEventsCount(token, filter).then((res) => {
        this.setState({ myEventsCount: res.data.count });
      });
    } else if (tab === "wishlist") {
      this.setState({ wishlistLoad: true });
      userService.getWishlistCards(token, type).then((res) => {
        this.setState({ wishlist: res.data.wishlist, wishlistLoad: false });
      });
    } else if (tab === "joins") {
      this.setState({ joinsLoad: true });
      userService.getJoinedEvents(token, type).then((res) => {
        this.setState({
          joins: res.data.joined,
          joinsLoad: false,
          joinCount: res.data.joined.length,
        });
      });
    } else if (tab === "joinrequests") {
      this.setState({ reqLoad: true });
      userService.getJoinRequests(token, type).then((res) => {
        this.setState({
          joinRequests: res.data.joinRequests,
          reqLoad: false,
          reqs: res.data.joinRequests.length,
        });
      });
    }
  };
  searchOthers = (sort, filter, page) => {
    const { tab, wishlist, joins, joinRequests } = this.state;
    filter = this.cleanFilter(filter);
    let data = [];
    if (tab === "wishlist") {
      data = [...wishlist];
    } else if (tab === "joins") {
      data = [...joins];
    } else if (tab === "joinrequests") {
      data = [...joinRequests];
    }
    if (sort !== "") {
      data = _.orderBy(data, [sort], ["desc"]);
      if (tab === "wishlist") {
        this.setState({ wishlist: data, temp: data });
      } else if (tab === "joins") {
        this.setState({ joins: data, temp: data });
      } else if (tab === "joinrequests") {
        this.setState({ joinRequests: data, temp: data });
      }
    }
    if (_.isEmpty(filter)) {
      this.setState({ tempEn: false });
    } else {
      this.setState({ tempEn: true });
      if (filter.name) {
        data = data.filter((obj) => {
          return obj.name.toLowerCase().includes(filter.name);
        });
        delete filter.name;
      }
      if (filter.category) {
        data = data.filter((obj) => {
          return obj.category._id === filter.category;
        });
        delete filter.category;
      }
      if (filter.city) {
        data = data.filter((obj) => {
          return obj.city._id === filter.city;
        });
        delete filter.city;
      }
      if (filter.isPublic) {
        let x = filter.isPublic === "true" ? true : false;
        data = data.filter((obj) => {
          return obj.isPublic === x;
        });
        delete filter.isPublic;
      }
      this.setState({ temp: data });
    }
  };
  changePage = (page) => {
    this.setState({ myEventsCurrPage: page });
    this.getEvents(this.state.sort, this.state.filter, page);
  };
  changeTab = (e) => {
    this.setState({ tab: e, myEventsCurrPage: 1 }, () => {
      this.getEvents(this.state.sort, this.state.filter, 1);
    });
  };
  render() {
    const {
      myEvents,
      wishlist,
      joins,
      joinRequests,
      joinCount,
      reqs,
      tab,
      myeventsLoad,
      myEventsCount,
      myEventsCurrPage,
      wishlistLoad,
      joinsLoad,
      reqLoad,
      pageSize,
      temp,
      tempEn,
    } = this.state;
    return (
      <div className="content inner-tabs">
        <Tabs
          defaultActiveKey={tab}
          onChange={this.changeTab}
          style={{ direction: "ltr" }}
        >
          <TabPane tab={lang.myEvents[lang.ls()]} key="myevents">
            <Search
              onChange={this.getEvents}
              filter={this.state.filter}
              sort={this.state.sort}
              type="Event"
              placeholder={lang.searchEvents[lang.ls()]}
            />
            <ListEvents
              loading={myeventsLoad}
              events={myEvents}
              eventCount={myEventsCount}
              pageSize={pageSize}
              changePage={this.changePage}
              page={myEventsCurrPage}
            />
          </TabPane>
          <TabPane tab={lang.wishlist[lang.ls()]} key="wishlist">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              sort={this.state.sort}
              type="Event"
              placeholder={lang.searchEvents[lang.ls()]}
            />
            <ListEvents
              loading={wishlistLoad}
              events={!tempEn ? wishlist : temp}
              eventCount={!tempEn ? wishlist.length : temp.length}
            />
          </TabPane>
          <TabPane tab={lang.joins[lang.ls()]} key="joins">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              sort={this.state.sort}
              type="Event"
              placeholder={lang.searchEvents[lang.ls()]}
            />
            <ListEvents
              loading={joinsLoad}
              events={!tempEn ? joins : temp}
              eventCount={!tempEn ? joins.length : temp.length}
            />
          </TabPane>
          <TabPane tab={lang.joinRequests[lang.ls()]} key="joinrequests">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              sort={this.state.sort}
              type="Event"
              placeholder={lang.searchEvents[lang.ls()]}
            />
            <ListEvents
              loading={reqLoad}
              events={!tempEn ? joinRequests : temp}
              eventCount={!tempEn ? joinRequests.length : temp.length}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
    wishlistCount: state.wishlist.wishlist.length,
  };
};

export default connect(mapStateToProps, null)(EventsUser);
