import React, { Component } from "react";
import EventShowcase from "./eventshowcase";
import EventData from "./eventdata";
import HostLocation from "./hostloc";
import lang from "../../../assets/lang";
import MapContainer from "../../common/maps/map";
import { Loader } from "../../common/loader";
import { reviewService } from "../../../services/reviewService";
import { reportService } from "../../../services/reportService";
import { Tabs } from "antd";
import Normal from "../cards/normalCard";
import Report from "../cards/reportCard";
import Block from "../cards/blockCard";
import ReportCard from "../cards/report";
import ReviewCard from "../cards/review";
import EmptyLabel from "../../common/ant-design/empty";
import { adminService } from "../../../services/adminService";
const { TabPane } = Tabs;

class EventProfile extends Component {
  state = {
    event: {},
    reviews: [],
    reports: [],
    loading: true,
  };
  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    adminService.getEvent(id).then((res) => {
      const event = res.data;
      this.setState({ event, loading: false });
    });
    reportService.getAdminReports(id).then((res) => {
      this.setState({ reports: res.data });
    });
    reviewService.getEventReviews(id).then((res) => {
      this.setState({ reviews: res.data });
    });
  }
  loadUsers = (users, obj) => {
    return (
      <React.Fragment>
        {users.length === 0 ? (
          <EmptyLabel label={lang.noUsersFound[lang.ls()]} />
        ) : (
          <div className="triple-grid">
            {users.map((user) => {
              const { _id, name, username, image, isReported, isBlocked } = obj
                ? user.id
                : user;
              if (isBlocked) {
                return (
                  <Block
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              } else if (isReported) {
                return (
                  <Report
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              } else {
                return (
                  <Normal
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              }
            })}
          </div>
        )}
      </React.Fragment>
    );
  };
  tabHeader = (name, count) => {
    return (
      <div className="tab-head">
        <div className="name">{name}</div>
        <div className="count">{count}</div>
      </div>
    );
  };
  render() {
    const { event, reviews, reports, loading } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          {loading ? (
            <Loader />
          ) : (
            <div className="profile-event">
              <div className="main">
                <div className="event-showcase">
                  <EventShowcase event={event} />
                </div>
                <div className="data">
                  <EventData event={event} />
                </div>
                <HostLocation event={event} />
                <div className="reports">
                  <i className="far fa-file-alt"></i>
                  <div className="info">
                    <p className="rep">{lang.reports[lang.ls()]}</p>
                    <p className="reps">{reports ? reports.length : "None"}</p>
                  </div>
                </div>
                <div style={{ gridColumn: "1 / 3" }} className="location">
                  <div className="label">{lang.location[lang.ls()]}</div>
                  <div className="mapping" style={{ direction: "ltr" }}>
                    <MapContainer markers={[event]} />
                  </div>
                </div>
              </div>
              <Tabs
                style={{
                  direction: "ltr",
                  marginTop: "2rem",
                  padding: "0 0.5rem 0.5rem 0.5rem",
                }}
              >
                <TabPane
                  tab={this.tabHeader(lang.reviews[lang.ls()], reviews.length)}
                  key="reviews"
                >
                  {reviews.length === 0 ? (
                    <EmptyLabel label={lang.noReviews[lang.ls()]} />
                  ) : (
                    <div className="triple-grid">
                      {reviews.map((rev) => {
                        return <ReviewCard rev={rev} />;
                      })}
                    </div>
                  )}
                </TabPane>
                <TabPane
                  tab={this.tabHeader(
                    lang.joined[lang.ls()],
                    event.joined.length
                  )}
                  key="joined"
                >
                  {this.loadUsers(event.joined, false)}
                </TabPane>
                <TabPane
                  tab={this.tabHeader(lang.reports[lang.ls()], reports.length)}
                  key="reports"
                >
                  {reports.length === 0 ? (
                    <EmptyLabel label={lang.noReports[lang.ls()]} />
                  ) : (
                    <div className="repos">
                      {reports.map((rep) => {
                        const { _id, host, report, created_at } = rep;
                        return (
                          <ReportCard
                            key={_id}
                            user={host.id}
                            report={report}
                            created={created_at}
                          />
                        );
                      })}
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EventProfile;
