import React from "react";
import "./signup.scss";
import UserForm from "./user-form";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import lang from "../../assets/lang";
import InitLang from "../common/initLang";
import Recaptcha from "react-google-invisible-recaptcha";

const { TabPane } = Tabs;

const SignUp = () => {
  return (
    <div className="showcase">
      <div className="faded">
        <InitLang />
        <div className="container double-grid griding">
          <div className="gradient-card">
            <img
              style={{ width: "250px", marginBottom: "20px" }}
              src={
                "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
              }
              alt="logo"
            />
            <h2 className="head-main">{lang.signUp[lang.ls()]}</h2>
            <p className="parag-center-white">{lang.intro[lang.ls()]}</p>
            <p className="link-desc" style={{ color: "white" }}>
              {lang.haveAcc[lang.ls()]}{" "}
              <Link to="/sign-in" className="link">
                {lang.logIn[lang.ls()]}
              </Link>
            </p>
            <Link to="/" className="link">
              {lang.returnHome[lang.ls()]}
            </Link>
          </div>
          <div className="sign-up">
            <Recaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} />
            <UserForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
