import React from "react";
import { Loader } from "./loader";
import Paginate from "./ant-design/pagination";
import EmptyLabel from "./ant-design/empty";
import UserCard from "./cards/usercard";
import RequestCard from "./cards/requestcard";
import BlockedCard from "./cards/blockedcard";

const ListUsers = ({
  loading,
  users,
  userCount,
  pageSize,
  page,
  changePage,
  cdis,
  type,
  empty,
  count,
}) => {
  return (
    <React.Fragment>
      {!loading && !cdis && (
        <p className="info-parag">
          {userCount} {count}
        </p>
      )}
      {loading ? (
        <Loader />
      ) : userCount === 0 ? (
        <EmptyLabel label={empty} />
      ) : type === "request" ? (
        <div className="triple-grid">
          {users.map((user) => {
            return <RequestCard key={user._id} request={user} />;
          })}{" "}
        </div>
      ) : type === "block" ? (
        <div className="triple-grid">
          {users.map((user) => {
            return <BlockedCard key={user._id} user={user} />;
          })}
        </div>
      ) : (
        <div className="triple-grid">
          {users.map((user) => {
            return <UserCard key={user._id} user={user} />;
          })}
        </div>
      )}
      {userCount > pageSize && !loading && (
        <Paginate
          def={page}
          total={userCount}
          size={pageSize}
          onChange={changePage}
        />
      )}
    </React.Fragment>
  );
};

export default ListUsers;
