import React from "react";
import Outline from "./outline";
import AdminNavbar from "../common/adminNavbar";

const AdminHomePage = () => {
  return (
    <React.Fragment>
      <AdminNavbar />
      <Outline />
    </React.Fragment>
  );
};

export default AdminHomePage;
