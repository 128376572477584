import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const selectService = {
  getCountries,
  getCities,
  getCategories
};

function getCountries() {
  return axios.get("/country/");
}

function getCities() {
  return axios.get("/city/");
}

function getCategories() {
  return axios.get("/category/");
}
