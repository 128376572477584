import React, { useState } from "react";
import { Switch, Modal } from "antd";
import { Loader } from "../../common/loader";
import lang from "../../../assets/lang";
import BasicInput from "../../common/ant-design/input";
import { authService } from "../../../services/authService";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { success } from "../../../utils/helperMethods";
var pattern = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const MainSettings = ({
  email,
  setEmail,
  notif,
  changeNotif,
  chnotif,
  user,
  auth,
  logout,
}) => {
  const [emailMod, setEmailMod] = useState(false);
  const [passMod, setPassMod] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [emailText, setEmailText] = useState("");
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const validateEmail = (e) => {
    setEmailText(e.target.value);
    if (e.target.value.length >= 5 && pattern.test(e.target.value)) {
      setEmailCheck(true);
      authService.validateEmail(e.target.value).then((res) => {
        setEmailValid(res.data.valid);
        setEmailCheck(false);
      });
    } else {
      setEmailValid(false);
      setEmailCheck(false);
    }
  };
  const submitEmail = () => {
    setLoading(true);
    authService
      .changeEmail(auth._id, emailText, user.name, lang.ls())
      .then((res) => {
        logout();
        setEmail(emailText);
        setEmailMod(false);
        setLoading(false);
        setEmailText("");
        setEmailValid(null);
        success(lang.emailChanged[lang.ls()]);
      });
  };
  const cancelEmail = () => {
    setEmailMod(false);
  };
  const editEmail = () => {
    setEmailMod(true);
  };
  const submitPass = () => {
    setLoading(true);
    setError("");
    if (newPass.length < 8 || confPass < 8) {
      setLoading(false);
      setError(lang.passValid[lang.ls()]);
    } else if (newPass !== confPass) {
      setLoading(false);
      setError(lang.twoPassValid[lang.ls()]);
    } else {
      authService
        .changePassword(auth._id, currPass, newPass)
        .then((res) => {
          setLoading(false);
          setPassMod(false);
          setCurrPass("");
          setNewPass("");
          setConfPass("");
          success(lang.passChanged[lang.ls()]);
        })
        .catch((err) => {
          const { data } = err.response;
          setLoading(false);
          setError(data.message);
        });
    }
  };
  const cancelPass = () => {
    setPassMod(false);
  };
  const editPass = () => {
    setPassMod(true);
  };
  const inputCurrPass = (e) => {
    setCurrPass(e.target.value);
  };
  const inputNewPass = (e) => {
    setNewPass(e.target.value);
  };
  const inputConfPass = (e) => {
    setConfPass(e.target.value);
  };
  return (
    <div className="main-settings">
      <div className="sett-item">
        <div className="desc">
          <i className="fas fa-key"></i>
          <div className="info">
            <div className="head">{lang.changePass[lang.ls()]}</div>
            <div className="talk">{lang.changePassTalk[lang.ls()]}</div>
          </div>
        </div>
        <button onClick={editPass} className="btn-ok">
          {lang.edit[lang.ls()]}
        </button>
      </div>
      <div className="sep"></div>
      <div className="sett-item">
        <div className="desc">
          <i className="fas fa-envelope"></i>
          <div className="info">
            <div className="head">{lang.changeEmail[lang.ls()]}</div>
            <div className="talk">{email}</div>
          </div>
        </div>
        <button onClick={editEmail} className="btn-ok">
          {lang.edit[lang.ls()]}
        </button>
      </div>
      <div className="sep"></div>
      <div className="sett-item">
        <div className="head">{lang.notif[lang.ls()]}</div>
        <Switch disabled={chnotif} checked={notif} onChange={changeNotif} />
        {chnotif && <Loader tinycolor />}
      </div>
      <Modal
        title={lang.changeEmail[lang.ls()]}
        visible={emailMod}
        onOk={submitEmail}
        onCancel={cancelEmail}
        okButtonProps={{ disabled: !emailValid }}
      >
        <div className="email-warn">{lang.emailWarn[lang.ls()]}</div>
        <div className="validate">
          <BasicInput
            placeholder={lang.email[lang.ls()]}
            tooltip={lang.emailUnique[lang.ls()]}
            value={emailText}
            onChange={validateEmail}
            classes="sufix-input"
          />
          <div className="indicator">
            {emailCheck ? (
              <Loader tinycolor />
            ) : emailValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : emailValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
      <Modal
        title={lang.changePass[lang.ls()]}
        visible={passMod}
        onOk={submitPass}
        onCancel={cancelPass}
      >
        {error ? <div className="alert">{error}</div> : null}
        <BasicInput
          placeholder={lang.currPass[lang.ls()]}
          value={currPass}
          onChange={inputCurrPass}
          password={true}
          classes="sufix-input"
        />
        <BasicInput
          placeholder={lang.newPass[lang.ls()]}
          value={newPass}
          onChange={inputNewPass}
          password={true}
          classes="sufix-input"
        />
        <BasicInput
          placeholder={lang.confNewPass[lang.ls()]}
          value={confPass}
          onChange={inputConfPass}
          password={true}
          classes="sufix-input"
        />
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.login.auth,
    user: state.login.user,
  };
};

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSettings);
