import React from "react";
import "../common.scss";

const LabelInput = ({ name, type, label, onChange }) => {
  return (
    <div className="label-input">
      <p>{label}</p>
      <input name={name} type={type} onChange={onChange} />
    </div>
  );
};

export default LabelInput;
