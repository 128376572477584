import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RememberAdmin = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Redirect from={`${props.location}`} to="/admin/login/home" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = state => {
  return {
    loggedIn: state.admin.loggedIn
  };
};

export default connect(mapStateToProps, null)(RememberAdmin);
