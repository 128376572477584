import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/feedbacks";

export const feedbackService = {
  createFeedback,
  getFeedback,
  getFeedCards,
  getCount
};
function createFeedback(token, type, content) {
  return axios.post(endPoint + "/", content, {
    params: {
      token,
      type
    }
  });
}
function getFeedback(token) {
  return axios.get(endPoint + "/", {
    params: {
      token
    }
  });
}
function getFeedCards(page, limit) {
  return axios.get(endPoint + "/feedbacks", {
    params: {
      page,
      limit
    }
  });
}
function getCount() {
  return axios.get(endPoint + "/count");
}
