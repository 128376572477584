import React, { useState, useEffect } from "react";
import Intro from "./intro";
import InitLang from "../common/initLang";
import { eventService } from "../../services/eventService";

const Showcase = () => {
  const [moments, setMoments] = useState([]);
  useEffect(() => {
    eventService.getEventsMapLand().then((res) => {
      setMoments(res.data);
    });
  }, []);
  return (
    <div className="showcase">
      <div className="faded">
        <Intro moments={moments} />
      </div>
    </div>
  );
};

export default Showcase;
