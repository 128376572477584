import React from "react";
import lang from "../../../assets/lang";

const IllegalCard = ({ count }) => {
  return (
    <div style={{ textDecoration: "none", direction: "ltr" }}>
      <div className="alert-card">
        <i className="fas fa-times"></i>
        <div className="info">
          <p>{lang.catched[lang.ls()]}</p>
          <p className="count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default IllegalCard;
