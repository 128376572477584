import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import ConnectionsUser from "../login/connections/connectionsuser";
import ConnectionsCompany from "../login/connections/connectionscompany";

const ConnectionsRoute = ({ type, location, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      type === "User" ? (
        <ConnectionsUser location={location} />
      ) : (
        <ConnectionsCompany location={location} />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(ConnectionsRoute);
