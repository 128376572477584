export const userTypes = {
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",

  CHANGE_ADMIN_EMAIL: "CHANGE_ADMIN_EMAIL",
  CHANGE_ADMIN_NAME: "CHANGE_ADMIN_NAME",

  CHANGE_IMAGE: "CHANGE_IMAGE",
  CHANGE_DATA: "CHANGE_DATA",
  CHANGE_NOTIF: "CHANGE_NOTIF",

  LOGOUT: "LOGOUT",
};
