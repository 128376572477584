import React from "react";
import { Link } from "react-router-dom";
import { eventImage } from "../../../utils/helperMethods";
import "../common.scss";
import lang from "../../../assets/lang";
var moment = require("moment");

const EventMapCard = ({ event }) => {
  const { _id, name, host: acc, startTiming, images } = event;
  const host = acc.id;
  const img = eventImage();
  const width = window.innerWidth;
  console.log(width);
  return (
    <Link
      to={
        width < 1160 ? "" : host._id ? `/login/events/${_id}` : `/moment/${_id}`
      }
      className="event-map-card"
      style={{
        background: `url(${
          images.length > 0 ? images[0].url : img
        }) no-repeat center center/cover`,
      }}
    >
      <div className="info">
        <p className="name">{name}</p>
        {host._id && (
          <React.Fragment>
            <p className="by">{lang.hostedby[lang.ls()]}</p>
            <Link to={`/login/users/${host._id}/${acc.type}`} className="host">
              {host.name}
            </Link>
          </React.Fragment>
        )}
        <div className="timing">
          <p className="date">
            <i className="fa fa-calendar-alt"></i>{" "}
            {moment(startTiming).format("Do MMM")}
          </p>
          <p className="time">
            <i className="far fa-clock"></i>{" "}
            {moment(startTiming).format("hh:mm A")}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default EventMapCard;
