import { friendsTypes } from "../types/friendsTypes";

export function friendsManage(state = { friends: [] }, action) {
  switch (action.type) {
    case friendsTypes.SET_FRIEND_REQUEST:
      return {
        ...state,
        setting: true
      };
    case friendsTypes.SET_FRIEND_SUCCESS:
      return {
        friends: action.data
      };
    case friendsTypes.SET_FRIEND_FAILURE:
      return { ...state };
    case friendsTypes.ADD_FRIEND_REQUEST:
      return {
        ...state,
        adding: true
      };
    case friendsTypes.ADD_FRIEND_SUCCESS:
      return {
        friends: [action.data, ...state.friends]
      };
    case friendsTypes.ADD_FRIEND_FAILURE:
      return { ...state };
    case friendsTypes.REMOVE_FRIEND_REQUEST:
      return {
        ...state,
        removing: true
      };
    case friendsTypes.REMOVE_FRIEND_SUCCESS:
      return {
        friends: state.friends.filter(x => x !== action.data)
      };
    case friendsTypes.REMOVE_FRIEND_FAILURE:
      return { ...state };
    case friendsTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
