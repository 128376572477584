import React from "react";
import { connect } from "react-redux";
import { getImage } from "./../../../utils/helperMethods";
import lang from "../../../assets/lang";

const ProfileHeader = ({
  data,
  count,
  blockAccount,
  unblockAccount,
  roles,
}) => {
  const { name, username, image, isBlocked, code } = data;
  let btn;
  let img = getImage(username);
  if (!isBlocked) {
    btn = (
      <button onClick={blockAccount} className="btn-ok">
        <i className="fas fa-ban"></i> {lang.block[lang.ls()]}
      </button>
    );
  } else {
    btn = (
      <button onClick={unblockAccount} className="btn-ok">
        {lang.unblock[lang.ls()]}
      </button>
    );
  }
  return (
    <div className="profile-header">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <div className="name">{name}</div>
        {username ? <p className="username">@{username}</p> : null}
        {code ? <p className="username">@{code}</p> : null}
        {roles.blockUsers && btn}
      </div>
      <div className="reports">
        <i className="far fa-file-alt"></i>
        <p>{lang.reports[lang.ls()]}</p>
        <p className="reps">{count ? count : "None"}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.admin.roles,
  };
};

export default connect(mapStateToProps, null)(ProfileHeader);
