import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Loader } from "../../common/loader";
import lang from "../../../assets/lang";
import BasicInput from "../../common/ant-design/input";
import { adminService } from "../../../services/adminService";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { success } from "../../../utils/helperMethods";

const AccountInformation = ({
  token,
  name,
  email,
  roles,
  changeAdminEmail,
  changeAdminName,
}) => {
  const [rolesDoc, setRolesDoc] = useState([]);
  const [emailMod, setEmailMod] = useState(false);
  const [nameMod, setNameMod] = useState(false);
  const [passMod, setPassMod] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [emailText, setEmailText] = useState("");
  const [nameText, setNameText] = useState(name);
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let rolesDoc = [];
    if (roles.signUp) rolesDoc.push(lang.signUpRole[lang.ls()]);
    if (roles.admins) rolesDoc.push(lang.adminsRole[lang.ls()]);
    if (roles.catching) rolesDoc.push(lang.catchingRole[lang.ls()]);
    if (roles.editEvent) rolesDoc.push(lang.editEventRole[lang.ls()]);
    if (roles.deleteEvent) rolesDoc.push(lang.deleteEventRole[lang.ls()]);
    if (roles.blockUsers) rolesDoc.push(lang.blockUsersRole[lang.ls()]);
    setRolesDoc(rolesDoc);
  }, [roles]);
  const validateEmail = (e) => {
    setEmailText(e.target.value);
    if (e.target.value.length >= 5) {
      setEmailCheck(true);
      adminService.validateEmail(e.target.value).then((res) => {
        setEmailValid(res.data.valid);
        setEmailCheck(false);
      });
    } else {
      setEmailValid(false);
      setEmailCheck(false);
    }
  };
  const validateName = (e) => {
    setNameText(e.target.value);
  };
  const submitEmail = () => {
    setLoading(true);
    adminService.changeEmail(token, emailText).then((res) => {
      changeAdminEmail(emailText);
      setEmailMod(false);
      setLoading(false);
      setEmailText("");
      setEmailValid(null);
      success("Email Changed Successfully...");
    });
  };
  const submitName = () => {
    setLoading(true);
    adminService.changeName(token, nameText).then((res) => {
      changeAdminName(nameText);
      setNameMod(false);
      setLoading(false);
      success("Name Changed Successfully...");
    });
  };
  const cancelEmail = () => {
    setEmailMod(false);
  };
  const editEmail = () => {
    setEmailMod(true);
  };
  const cancelName = () => {
    setNameMod(false);
  };
  const editName = () => {
    setNameMod(true);
  };
  const submitPass = () => {
    setLoading(true);
    setError("");
    if (newPass.length < 8 || confPass < 8) {
      setLoading(false);
      setError(lang.passValid[lang.ls()]);
    } else if (newPass !== confPass) {
      setLoading(false);
      setError(lang.twoPassValid[lang.ls()]);
    } else {
      adminService
        .changePassword(token, currPass, newPass)
        .then((res) => {
          setLoading(false);
          setPassMod(false);
          setCurrPass("");
          setNewPass("");
          setConfPass("");
          success("Password Changed Successfully...");
        })
        .catch((err) => {
          const { data } = err.response;
          setLoading(false);
          setError(data.message);
        });
    }
  };
  const cancelPass = () => {
    setPassMod(false);
  };
  const editPass = () => {
    setPassMod(true);
  };
  const inputCurrPass = (e) => {
    setCurrPass(e.target.value);
  };
  const inputNewPass = (e) => {
    setNewPass(e.target.value);
  };
  const inputConfPass = (e) => {
    setConfPass(e.target.value);
  };
  return (
    <div className="account-info">
      <div className="info">
        <div className="head">{lang.accountInfo[lang.ls()]}</div>
        <div className="name">{name}</div>
        <div className="email">{email}</div>
      </div>
      <div className="actions">
        <div className="roles">
          {rolesDoc.map((role) => {
            return (
              <div className="role">
                <i className="fas fa-check"></i>
                {role}
              </div>
            );
          })}
        </div>
        <div className="btn-s">
          <div onClick={editName} className="btn-ok">
            {lang.changeName[lang.ls()]}
          </div>
          <div onClick={editEmail} className="btn-ok">
            {lang.changeEmail[lang.ls()]}
          </div>
          <div onClick={editPass} className="btn-ok">
            {lang.changePass[lang.ls()]}
          </div>
        </div>
      </div>
      <Modal
        title={lang.changeName[lang.ls()]}
        visible={nameMod}
        onOk={submitName}
        onCancel={cancelName}
        okButtonProps={{ disabled: !nameText }}
      >
        <div className="validate">
          <BasicInput
            placeholder={lang.fullName[lang.ls()]}
            value={nameText}
            onChange={validateName}
          />
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
      <Modal
        title={lang.changeEmail[lang.ls()]}
        visible={emailMod}
        onOk={submitEmail}
        onCancel={cancelEmail}
        okButtonProps={{ disabled: !emailValid }}
      >
        <div className="validate">
          <BasicInput
            placeholder={lang.email[lang.ls()]}
            tooltip={lang.emailUnique[lang.ls()]}
            value={emailText}
            onChange={validateEmail}
            classes="sufix-input"
          />
          <div className="indicator">
            {emailCheck ? (
              <Loader tinycolor />
            ) : emailValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : emailValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
      <Modal
        title={lang.changePass[lang.ls()]}
        visible={passMod}
        onOk={submitPass}
        onCancel={cancelPass}
      >
        {error ? <div className="alert">{error}</div> : null}
        <BasicInput
          placeholder={lang.currPass[lang.ls()]}
          value={currPass}
          onChange={inputCurrPass}
          password={true}
          classes="sufix-input"
        />
        <BasicInput
          placeholder={lang.newPass[lang.ls()]}
          value={newPass}
          onChange={inputNewPass}
          password={true}
          classes="sufix-input"
        />
        <BasicInput
          placeholder={lang.confNewPass[lang.ls()]}
          value={confPass}
          onChange={inputConfPass}
          password={true}
          classes="sufix-input"
        />
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.admin.token,
    name: state.admin.name,
    email: state.admin.email,
    roles: state.admin.roles,
  };
};

const mapDispatchToProps = {
  changeAdminEmail: userActions.changeAdminEmail,
  changeAdminName: userActions.changeAdminName,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
