import React from "react";
import EventCard from "../cards/eventcard";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";

const EventProfile = ({ events, count, name, type, target, id }) => {
  return (
    <div className="profile-item">
      <div className="indicator">
        <div className="head">{name}</div>
        <div className="count">{count}</div>
      </div>
      <div className="show-e">
        {events.map((ev) => {
          return <EventCard key={ev._id} myEvent={ev} />;
        })}
      </div>
      {events.length === 3 && (
        <Link to={`/login/users/${id}/${type}/${target}`} className="btn-ok">
          {lang.seeall[lang.ls()]}
        </Link>
      )}
    </div>
  );
};

export default EventProfile;
