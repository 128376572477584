import React from "react";
import Metrics from "./metrics";
import PicName from "./picname";
import Actions from "./actions";

const Header = ({ user, own, reported, open }) => {
  return (
    <div className="profile-head">
      <PicName
        id={user._id}
        image={user.image}
        username={user.username}
        code={user.code}
        name={user.name}
        own={own}
        reported={reported}
        open={open}
      />
      <div className="view">
        <Metrics
          id={user._id}
          events={user.myEvents.length}
          followers={user.followers.length}
          following={user.followings.length}
          type={user.username ? "User" : "Company"}
          own={own}
        />
        <Actions own={own} user={user} id={user._id} />
      </div>
    </div>
  );
};

export default Header;
