import React from "react";
import { getImage } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import { Rate } from "antd";

const ReviewCard = ({ rev }) => {
  const { host, rate, review } = rev;
  const { name, username, image, _id } = host.id;
  const img = getImage(username);
  return (
    <div className="review-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <Link
          to={`/admin/login/user/${_id}/${username ? "User" : "Company"}`}
          className="name"
        >
          {name}
        </Link>
        {username && <p className="username">@{username}</p>}
        <Rate className="rate" disabled value={rate} />
      </div>
      <p className="feed">{review}</p>
    </div>
  );
};

export default ReviewCard;
