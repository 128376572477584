import React from "react";

const Input = ({ icon, name, placeholder, value, error, onChange, type }) => {
  return (
    <div className="input-form m-2">
      <i className={icon}></i>
      <input
        value={value}
        name={name}
        type={type}
        onChange={onChange}
        id={name}
        placeholder={placeholder}
        className="input"
      />
      {error && <div className="alert alert-danger error">{error}</div>}
    </div>
  );
};

export default Input;
