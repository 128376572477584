import React, { useEffect, useState } from "react";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { friendsActions } from "../../../redux/actions/friendsActions";
import { requestActions } from "../../../redux/actions/requestActions";
import { requestService } from "../../../services/requestService";
import { Loader } from "../loader";
import { followActions } from "../../../redux/actions/followActions";
import { chatActions } from "../../../redux/actions/chatActions";
import history from "../../../utils/history";

const Actions = ({
  own,
  user,
  id,
  friends,
  requests,
  ownInfo,
  removeRequest,
  unfriend,
  sendRequest,
  token,
  type,
  removing,
  sending,
  canceling,
  followings,
  following,
  unfollowing,
  addFollow,
  removeFollow,
  linkChat,
  seeAllBtn,
}) => {
  const [request, setRequest] = useState();
  useEffect(() => {
    requestService.getProfRequest(id).then((res) => {
      setRequest(res.data);
    });
  }, [requests, id]);
  function dispatchChat(recv) {
    seeAllBtn();
    linkChat(recv);
    history.push("/login/chat");
  }
  function cancelRequest() {
    removeRequest(request._id);
  }
  function sendReq() {
    sendRequest(token, id);
  }
  function removeFriend() {
    unfriend(token, id);
  }
  function sendFoll() {
    console.log(user);
    addFollow(
      token,
      type,
      id,
      user.username ? "User" : "Company",
      user.fcmToken,
      ownInfo.name
    );
  }
  function removeFoll() {
    removeFollow(token, type, id, user.username ? "User" : "Company");
  }
  return (
    <div className="actions">
      {!own ? (
        <React.Fragment>
          {friends.includes(id) && (
            <button
              onClick={() =>
                dispatchChat({
                  id: user._id,
                  type: user.username ? "User" : "Company",
                })
              }
              className="btn-mess"
            >
              <i className="far fa-comments"></i>
              {lang.message[lang.ls()]}
            </button>
          )}
          {user.username && type === "User" && user.friendRequests ? (
            friends.includes(id) ? (
              <button onClick={removeFriend} className="btn-del">
                <i className="fas fa-user-minus"></i>
                {removing ? <Loader tiny /> : lang.unFriend[lang.ls()]}
              </button>
            ) : requests.includes(id) ? (
              <button onClick={cancelRequest} className="btn-mess">
                {canceling ? <Loader tiny /> : lang.requestSent[lang.ls()]}
              </button>
            ) : (
              <button onClick={sendReq} className="btn-ok">
                <i className="fas fa-user-plus"></i>
                {sending ? <Loader tiny /> : lang.addFriend[lang.ls()]}
              </button>
            )
          ) : null}
          {followings.includes(id) ? (
            <button onClick={removeFoll} className="btn-del">
              {unfollowing ? <Loader tiny /> : lang.unFollow[lang.ls()]}
            </button>
          ) : (
            <button onClick={sendFoll} className="btn-ok">
              <i className="fas fa-rss"></i>
              {following ? <Loader tiny /> : lang.follow[lang.ls()]}
            </button>
          )}
        </React.Fragment>
      ) : (
        <Link
          to="/login/profile/edit"
          style={{ padding: "5px 4rem" }}
          className="btn-gr"
        >
          <i className="edit fas fa-edit"></i>
          {lang.edit[lang.ls()]}
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requests: state.request.requests,
    friends: state.friend.friends,
    token: state.login.token,
    type: state.login.type,
    ownInfo: state.login.user,
    removing: state.friend.removing,
    sending: state.request.sending,
    canceling: state.request.canceling,
    followings: state.follow.followings,
    following: state.follow.following,
    unfollowing: state.follow.unfollowing,
  };
};

const mapDispatchToProps = {
  removeRequest: requestActions.removeRequest,
  unfriend: friendsActions.removeFriend,
  sendRequest: requestActions.addRequest,
  addFollow: followActions.addFollow,
  linkChat: chatActions.linkChat,
  removeFollow: followActions.removeFollow,
  seeAllBtn: chatActions.seeAllBtn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
