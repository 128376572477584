import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Loader } from "../../common/loader";
import { authService } from "../../../services/authService";
import { eventService } from "../../../services/eventService";
import OverallProfile from "../../common/profiles/overallProfile";
import Header from "../../common/profiles/header";
import OtherEvents from "./otherevents";
import OtherFollowers from "./otherfollowers";
import OtherFollowings from "./otherfollowings";
import OtherFriends from "./otherfriends";
import { userService } from "../../../services/userService";
import history from "../../../utils/history";
import { connect } from "react-redux";
import { reportService } from "../../../services/reportService";
import ReportModal from "../../common/modals/reportmodal";

class UserProfile extends Component {
  state = {
    user: {},
    friends: [],
    events: [],
    mutual: [],
    loading: true,
    attLoad: true,
    report: false,
    reported: false,
  };
  openModal = () => {
    this.setState({ report: true });
  };
  closeModal = () => {
    this.setState({ report: false });
  };
  updateReport = () => {
    const { match } = this.props;
    const { id } = match.params;
    reportService.checkReport(id, this.props.token).then((res) => {
      this.setState({ reported: res.data });
    });
  };
  componentDidMount() {
    const { match, ownid, token, typeS } = this.props;
    const { id, type } = match.params;
    if (ownid !== id) {
      authService.getOtherProfile(id, type).then((res) => {
        this.setState({ loading: false, user: res.data });
      });
      reportService.checkReport(id, token).then((res) => {
        this.setState({ reported: res.data });
      });
      eventService.getOtherProfEvents(id, "created_at").then((res) => {
        this.setState({ events: res.data });
        if (type === "User") {
          userService.mutualFriends(token, id).then((res) => {
            this.setState({ mutual: res.data });
            userService.getOtherProfFriends(token, typeS, id).then((res) => {
              this.setState({ friends: res.data.friends, attLoad: false });
            });
          });
        } else {
          this.setState({ attLoad: false });
        }
      });
    } else {
      history.push("/login/profile");
    }
  }
  render() {
    const {
      user,
      friends,
      events,
      loading,
      attLoad,
      reported,
      report,
      mutual,
    } = this.state;
    const { match } = this.props;
    const { id, type } = match.params;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Header
              reported={reported}
              open={this.openModal}
              user={user}
              own={false}
            />
            <Switch>
              <Route
                path="/login/users/:id/:type"
                exact
                component={() => (
                  <OverallProfile
                    user={user}
                    friends={friends}
                    events={events}
                    loading={attLoad}
                    mutual={mutual.length}
                    own={false}
                  />
                )}
              />
              <Route
                path="/login/users/:id/:type/friends"
                exact
                component={OtherFriends}
              />
              <Route
                path="/login/users/:id/:type/events"
                exact
                component={OtherEvents}
              />
              <Route
                path="/login/users/:id/:type/followers"
                exact
                component={OtherFollowers}
              />
              <Route
                path="/login/users/:id/:type/followings"
                exact
                component={OtherFollowings}
              />
            </Switch>
            {report ? (
              <ReportModal
                updateReport={this.updateReport}
                close={this.closeModal}
                id={id}
                rtype={type}
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ownid: state.login.user._id,
    token: state.login.token,
    typeS: state.login.type,
  };
};

export default connect(mapStateToProps, null)(UserProfile);
