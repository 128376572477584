import React, { useState, Component, useEffect } from "react";
import { compose, withStateHandlers } from "recompose";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import EventMapCard from "../cards/eventmapcard";
import { getMapCateg } from "../../../utils/helperMethods";
// import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
const get = require("lodash/get");

// Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_KEY}`);
// Geocode.enableDebug();

const Maps = withScriptjs(
  withGoogleMap(({ marks, show, land }) => {
    const [SelectedMark, setSelectedMark] = useState(null);
    const [ownPos, setOwnPos] = useState(null);
    const [center, setCenter] = useState(null);
    const [recenter, setRecenter] = useState(false);
    const [own, setOwn] = useState(false);
    const [drag, setDrag] = useState(false);
    useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setOwnPos(pos);
          setDrag(false);
          if (show) setOwn(true);
        });
      }
    }, [recenter]);
    const onPlaceSelected = (place) => {
      setDrag(false);
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setOwn(true);
    };
    return (
      <GoogleMap
        defaultZoom={land ? 9 : 7}
        center={
          marks.length === 1 && !show && !own && !land
            ? { lat: parseFloat(marks[0].lat), lng: parseFloat(marks[0].lng) }
            : drag && !land
            ? null
            : center && !land
            ? center
            : { lat: 24.678, lng: 46.689 }
        }
        onDrag={() => {
          setDrag(true);
          setOwn(true);
        }}
      >
        <button
          onClick={() => {
            setRecenter(!recenter);
            setOwn(true);
          }}
          className="recenter"
        >
          <i className="fas fa-crosshairs"></i>
        </button>
        {marks.length === 1 && !show && (
          <button
            style={{
              background: `url(${getMapCateg(
                "default"
              )}) no-repeat center center/contain`,
              backgroundColor: "white",
            }}
            onClick={() => setOwn(false)}
            className="remarker"
          ></button>
        )}
        {!land && (
          <Autocomplete
            className="map-search"
            onPlaceSelected={onPlaceSelected}
            types={["geocode"]}
            componentRestrictions={{ country: "sa" }}
          />
        )}
        {ownPos && (
          <Marker
            icon="https://www.robotwoods.com/dev/misc/bluecircle.png"
            position={ownPos}
          />
        )}
        {marks
          ? marks.map((mark) => {
              return (
                <Marker
                  position={{
                    lat: parseFloat(mark.lat),
                    lng: parseFloat(mark.lng),
                  }}
                  key={mark.lat}
                  onDblClick={() =>
                    window.open(
                      `https://maps.google.com/?q=${mark.lat},${mark.lng}&ll=${mark.lat},${mark.lng}&z=10`,
                      "_blank"
                    )
                  }
                  onClick={() => {
                    setSelectedMark(mark);
                  }}
                  icon={{
                    url: getMapCateg(mark.category.name["en"].toLowerCase()),
                    scaledSize: new window.google.maps.Size(45, 45),
                  }}
                />
              );
            })
          : null}
        {SelectedMark && show ? (
          <InfoWindow
            position={{
              lat: parseFloat(SelectedMark.lat),
              lng: parseFloat(SelectedMark.lng),
            }}
            onCloseClick={() => {
              setSelectedMark(null);
            }}
          >
            <React.Fragment>
              <EventMapCard event={SelectedMark} />
              <div
                onClick={() =>
                  window.open(
                    `https://maps.google.com/?q=${SelectedMark.lat},${SelectedMark.lng}&ll=${SelectedMark.lat},${SelectedMark.lng}&z=10`,
                    "_blank"
                  )
                }
                className="maps"
                style={{
                  background: `url(https://res.cloudinary.com/momants/image/upload/v1588566816/66964-google-platform-maps-suite-logo-cloud_se7mve.png) no-repeat center center/cover`,
                }}
              ></div>
            </React.Fragment>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    );
  })
);

const MapInput = withScriptjs(
  withGoogleMap((props) => {
    const [isMarkerShown, setIsMarkerShown] = useState(false);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [ownPos, setOwnPos] = useState(null);
    const [center, setCenter] = useState(null);
    const [recenter, setRecenter] = useState(false);
    useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setOwnPos(pos);
        });
      }
    }, [recenter]);
    const onMapClick = (e) => {
      setMarkerPosition(e.latLng);
      setIsMarkerShown(true);
    };
    const onPlaceSelected = (place) => {
      setIsMarkerShown(true);
      setMarkerPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    };
    return (
      <GoogleMap
        defaultZoom={7}
        center={center ? center : { lat: 24.678, lng: 46.689 }}
        onClick={(e) => {
          onMapClick(e);
          props.output(e);
        }}
      >
        <button
          onClick={() => {
            setRecenter(!recenter);
          }}
          className="recenter"
        >
          <i className="fas fa-crosshairs"></i>
        </button>
        <Autocomplete
          className="map-search"
          onPlaceSelected={(e) => {
            onPlaceSelected(e);
            props.placing(e);
          }}
          types={["geocode"]}
          componentRestrictions={{ country: "sa" }}
        />
        {ownPos && (
          <Marker
            icon="https://www.robotwoods.com/dev/misc/bluecircle.png"
            position={ownPos}
          />
        )}
        {isMarkerShown ? (
          <Marker
            position={markerPosition}
            icon={{
              url: getMapCateg("default"),
              scaledSize: new window.google.maps.Size(45, 45),
            }}
          />
        ) : get(props, "lat") ? (
          <Marker
            position={{ lat: props.lat, lng: props.lng }}
            icon={{
              url: getMapCateg("default"),
              scaledSize: new window.google.maps.Size(45, 45),
            }}
          />
        ) : null}
      </GoogleMap>
    );
  })
);

export default class MapContainer extends Component {
  render() {
    const { type, output, markers, lat, lng, show, land, placing } = this.props;

    if (type === "input") {
      return (
        <MapInput
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `100%`, position: "relative" }} />
          }
          mapElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "20px",
                boxShadow: "0px 0px 15px -7px #333",
              }}
            />
          }
          output={output}
          placing={placing}
          lat={lat}
          lng={lng}
        />
      );
    } else {
      return (
        <Maps
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `100%`, position: "relative" }} />
          }
          mapElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "20px",
                boxShadow: "0px 0px 15px -7px #333",
              }}
            />
          }
          marks={markers}
          show={show}
          land={land}
        />
      );
    }
  }
}
