import React, { useState } from "react";
import { getImage } from "../../../utils/helperMethods";
import "../common.scss";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { requestActions } from "../../../redux/actions/requestActions";
import { followActions } from "../../../redux/actions/followActions";
import { blockActions } from "../../../redux/actions/blockActions";
import Tooltip from "@material-ui/core/Tooltip";
const UserCard = ({
  user,
  token,
  type,
  friends,
  ownInfo,
  requests,
  sendRequest,
  chatting,
  addFollow,
  followings,
  ownid,
  addBlock,
  sending,
}) => {
  const [status, setStatus] = useState("");
  const {
    _id,
    name,
    username,
    image,
    followers,
    friendRequests,
    fcmToken,
  } = user;
  const img = getImage(username);
  const to = username
    ? `/login/users/${_id}/User`
    : `/login/users/${_id}/Company`;
  function sendReq() {
    sendRequest(token, _id);
  }
  function sendFoll() {
    addFollow(
      token,
      type,
      _id,
      username ? "User" : "Company",
      fcmToken,
      ownInfo.name
    );
  }
  function block() {
    setStatus(
      username ? "You're blocked this user" : "You're blocked this company"
    );
    let room = "";
    chatting.map((ch) => {
      if (ch.id === _id) room = ch.room;
      return null;
    });
    addBlock(
      token,
      type,
      { id: _id, type: username ? "User" : "Company" },
      room
    );
  }
  return (
    <div className="user-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <a href={to} className="name">
          {name}
        </a>
        {username ? (
          <p className="username">@{username}</p>
        ) : (
          <p className="username">
            <i className="fas fa-rss"></i> {followers.length}{" "}
            {lang.followers[lang.ls()]}
          </p>
        )}
        {_id !== ownid && (
          <div className="actions">
            {status ? (
              <div className="status">{status}</div>
            ) : (
              <React.Fragment>
                {friends && requests && username && friendRequests ? (
                  !friends.includes(_id) && !requests.includes(_id) ? (
                    <Tooltip title={lang.addFriend[lang.ls()]}>
                      <i
                        disabled={sending}
                        onClick={sendReq}
                        className="friend fas fa-user-plus"
                      ></i>
                    </Tooltip>
                  ) : null
                ) : null}
                {!followings.includes(_id) ? (
                  <Tooltip title={lang.follow[lang.ls()]}>
                    <i onClick={sendFoll} className="friend fas fa-rss"></i>
                  </Tooltip>
                ) : null}
                <Tooltip title={lang.block[lang.ls()]}>
                  <i onClick={block} className="block fas fa-ban"></i>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
    ownid: state.login.user._id,
    ownInfo: state.login.user,
    friends: state.friend.friends,
    requests: state.request.requests,
    fsetting: state.friend.setting,
    rsetting: state.request.setting,
    sending: state.request.sending,
    followings: state.follow.followings,
    chatting: state.chat.chatting,
  };
};

const mapDispatchToProps = {
  sendRequest: requestActions.addRequest,
  addFollow: followActions.addFollow,
  addBlock: blockActions.addBlock,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
