import React from "react";
import Head from "../../common/head";
import CounterCard from "../../common/cards/countcard";
import ReportCard from "./../../common/cards/reportcard";
import IllegalCard from "./../../common/cards/illegalcard";
import lang from "../../../assets/lang";

const EventCounters = ({ icon, label, counters }) => {
  return (
    <React.Fragment>
      <Head label={label} mid={true} />
      <div className="triple-grid">
        <CounterCard
          icon={icon}
          label={lang.normal[lang.ls()]}
          count={counters.normal}
        />
        <ReportCard count={counters.reported} />
        <IllegalCard count={counters.catched} />
      </div>
    </React.Fragment>
  );
};

export default EventCounters;
