import React from "react";
import "./select.scss";
import { Select } from "antd";
import lang from "../../../assets/lang";
const { Option } = Select;

const LabelSelect = ({ label, name, onChange, options }) => {
  return (
    <div className="label-select">
      <div className="label">{label}</div>
      <Select defaultValue="" onChange={e => onChange(e, name)}>
        <Option value="">{lang.all[lang.ls()]}</Option>
        {options.map(option => {
          return (
            <Option key={option._id} value={`${option._id}`}>
              {option.name ? option.name[lang.ls()] : option}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LabelSelect;
