import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/chatting";

export const chattingService = {
  getChatting,
  seeMessages,
  loadMore,
  deleteChat,
};
function getChatting(token) {
  return axios.get(endPoint + "/", {
    params: {
      token,
    },
  });
}
function seeMessages(token, id) {
  return axios.put(endPoint + "/seen", null, {
    params: {
      token,
      id,
    },
  });
}
function loadMore(id, loadNo) {
  return axios.get(endPoint + "/load", {
    params: {
      id,
      loadNo,
    },
  });
}
function deleteChat(room, token) {
  return axios.put(endPoint + "/delete", null, {
    params: {
      room,
      token,
    },
  });
}
