import React from "react";
import { getImage } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import lang from "../../../assets/lang";

const HostLocation = ({ event }) => {
  const { host: acc } = event;
  const { id: host } = acc;
  const img = getImage(host.username);
  const to = `/admin/login/user/${host._id}/${acc.type}`;
  return (
    <React.Fragment>
      <div className="host">
        <div
          className="img"
          style={{
            background: `url(${
              host.image ? host.image.url : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        <div className="info">
          <p className="by">{lang.hostedby[lang.ls()]}</p>
          <Link to={to} className="name">
            {host.name}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HostLocation;
