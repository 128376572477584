import React, { useState, useEffect } from "react";
import { getImage } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import lang from "../../../assets/lang";
import { Loader } from "../loader";
import { inviteService } from "../../../services/inviteService";
import { userService } from "../../../services/userService";
import { connect } from "react-redux";
import { Modal } from "antd";

const EventNotJoined = ({
  event,
  notJoined,
  inviteFriend,
  inviting,
  invitObj,
  user,
  type,
  friends,
}) => {
  const [allNot, setAllNot] = useState([]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (type === "User") {
      inviteService
        .getInvitations({ from: user._id, event: event._id })
        .then((res) => {
          const obj = [];
          let friendsNotJoined = [];
          res.data.map((inv) => {
            return obj.push(inv.to);
          });
          friends.map((friend) => {
            if (
              !event.joined.includes(friend) &&
              event.host.id._id !== friend &&
              !obj.includes(friend)
            ) {
              friendsNotJoined.push(friend);
            }
            return null;
          });
          userService.getUsersFromArray(friendsNotJoined).then((res) => {
            setAllNot(res.data);
          });
        });
    }
  }, [event, user, type, friends, notJoined]);
  const closeModal = () => {
    setModal(false);
  };
  return (
    <div className="friends">
      <div className="link-head">
        <div className="head">{lang.invitefriends[lang.ls()]}</div>
        <div onClick={() => setModal(true)} className="btn-ok">
          {lang.seeall[lang.ls()]}
        </div>
      </div>
      <div className="items">
        {notJoined.map((fr) => {
          return (
            <div key={fr._id} className="info">
              <div
                className="img"
                style={{
                  background: `url(${
                    fr.image ? fr.image.url : getImage(fr.username)
                  }) no-repeat center center/cover`,
                }}
              ></div>
              <Link to={`/login/users/${fr._id}/User`} className="name">
                {fr.name}
              </Link>
              <button
                disabled={inviting}
                onClick={() => inviteFriend(fr._id, fr.fcmToken)}
                className="btn-ok"
              >
                {inviting && invitObj === fr._id ? (
                  <Loader tiny />
                ) : (
                  lang.invite[lang.ls()]
                )}
              </button>
            </div>
          );
        })}
      </div>
      <Modal
        title={lang.invitefriends[lang.ls()]}
        visible={modal}
        onCancel={closeModal}
        footer={null}
        bodyStyle={{
          height: "400px",
          overflow: "auto",
        }}
      >
        <div className="friends">
          <div className="items">
            {allNot.map((fr) => {
              return (
                <div key={fr._id} className="info">
                  <div
                    className="img"
                    style={{
                      background: `url(${
                        fr.image ? fr.image.url : getImage(fr.username)
                      }) no-repeat center center/cover`,
                    }}
                  ></div>
                  <Link to={`/login/users/${fr._id}/User`} className="name">
                    {fr.name}
                  </Link>
                  <button
                    disabled={inviting}
                    onClick={() => inviteFriend(fr._id, fr.fcmToken)}
                    className="btn-ok"
                  >
                    {inviting && invitObj === fr._id ? (
                      <Loader tiny />
                    ) : (
                      lang.invite[lang.ls()]
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    type: state.login.type,
    friends: state.friend.friends,
  };
};

export default connect(mapStateToProps)(EventNotJoined);
