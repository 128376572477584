import React, { Component } from "react";
import LabelInput from "../../common/inputs/labelinput";
import InitLang from "../../common/initLang";
import lang from "../../../assets/lang";
import history from "../../../utils/history";
import { Loader } from "../../common/loader";
import { adminService } from "../../../services/adminService";

class SetPassword extends Component {
  state = {
    data: {
      newPass: "",
      confPass: "",
    },
    adminId: "",
    loading: false,
    resetLoad: false,
    error: "",
  };
  componentDidMount() {
    const { match } = this.props;
    this.setState({ loading: true });
    adminService
      .verTempToken(match.params.token)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ adminId: match.params.id });
      })
      .catch((err) => {
        this.setState({ loading: false });
        history.replace("/");
      });
  }
  handleInserting = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleReset = () => {
    this.setState({ resetLoad: true });
    const data = { ...this.state.data };
    const { adminId } = this.state;
    if (data.newPass === data.confPass) {
      if (data.newPass.length < 8) {
        this.setState({ resetLoad: false });
        this.setState({
          error: lang.passValid[lang.ls()],
        });
      } else {
        adminService.setPassword(adminId, data.newPass).then((res) => {
          this.setState({ resetLoad: false });
          history.replace("/issue/password-changed");
        });
        this.setState({ error: "" });
      }
    } else {
      this.setState({ resetLoad: false });
      this.setState({ error: lang.twoPassValid[lang.ls()] });
    }
  };

  render() {
    const { name } = this.props;
    const { error, loading, resetLoad } = this.state;
    return (
      <div className="showcase">
        <div className="faded">
          <InitLang />
          <div className="container single-grid">
            {loading ? (
              <Loader />
            ) : (
              <div className="issue">
                <h1 className="head-white issue-head">
                  {lang.hi[lang.ls()]} {name} ...
                </h1>
                <p className="issue-body">{lang.resetPassDesc[lang.ls()]}</p>
                {error && <div className="alert">{error}</div>}
                <LabelInput
                  type="password"
                  label={lang.newPass[lang.ls()]}
                  name="newPass"
                  onChange={this.handleInserting}
                />
                <LabelInput
                  type="password"
                  label={lang.rePass[lang.ls()]}
                  name="confPass"
                  onChange={this.handleInserting}
                />
                <button onClick={this.handleReset} className="btn-issue">
                  {resetLoad ? (
                    <Loader tiny margin />
                  ) : (
                    lang.resetPass[lang.ls()]
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SetPassword;
