import React, { useState, useEffect } from "react";
import lang from "../../assets/lang";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";
import { getImage } from "../../utils/helperMethods";
import { userActions } from "../../redux/actions";
import InitLang from "./initLang";
import history from "../../utils/history";

const LandingNavbar = ({ user, token, type, logout, remember }) => {
  const [opa, setOpa] = useState(false);
  useEffect(() => {
    handleRemember();
    document.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setOpa(true);
      } else {
        setOpa(false);
      }
    });
  }, []);
  const handleRemember = () => {
    if (!remember && user) {
      if (!sessionStorage.getItem("remember")) {
        logout();
      }
    }
  };
  const handleLogout = () => {
    logout();
  };
  const langs = localStorage.getItem("lang");
  const modalPlace = langs === "en" ? "bottomRight" : "bottomLeft";
  const img = getImage(type === "User" ? true : false);
  const sett = (
    <Menu>
      <Menu.Item>
        <Link to="/login/settings" className="btn-drop">
          <i className="fas fa-cogs"></i> {lang.settings[lang.ls()]}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="" onClick={handleLogout} className="btn-drop">
          <i className="fas fa-sign-out-alt"></i> {lang.logOut[lang.ls()]}
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={opa ? "land-nav-op" : "land-nav"}>
      <InitLang top />
      <div className="brand">
        <div
          style={{
            background: `url(https://res.cloudinary.com/momants/image/upload/v1587288038/momants_ry4bpu.png) no-repeat center center/contain`,
            cursor: "pointer",
          }}
          className="img"
          onClick={() => history.push("/")}
        ></div>
        <div
          style={{
            background: `url(https://res.cloudinary.com/momants/image/upload/v1594146534/BrandName_ycacxe.png) no-repeat center center/contain`,
            cursor: "pointer",
          }}
          className="name"
          onClick={() => history.push("/")}
        ></div>
      </div>
      <div className="elements">
        <Link
          onClick={() => sessionStorage.setItem("create", true)}
          to="/login/event/form/new"
          className="link"
        >
          {lang.createEvent[lang.ls()]}
        </Link>
        <Divider type="vertical" />
        {!token ? (
          <React.Fragment>
            <Link to="/sign-up" className="link">
              {lang.register[lang.ls()]}
            </Link>
            <Divider type="vertical" />
            <Link to="/sign-in" className="link">
              {lang.signIn[lang.ls()]}
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link to="/login/explore" className="link">
              {lang.explore[lang.ls()]}
            </Link>
            <Divider type="vertical" />
            <Link to="/login/profile">
              <div
                className="img"
                style={{
                  background: `url(${
                    user.image ? user.image.url : img
                  }) no-repeat center center/cover`,
                  margin: "0 1rem",
                }}
              ></div>
            </Link>
            <Dropdown overlay={sett} trigger={["click"]} placement={modalPlace}>
              <i className="fas fa-chevron-down"></i>
            </Dropdown>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    token: state.login.token,
    type: state.login.type,
    remember: state.login.remember,
  };
};

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingNavbar);
