var hel = [
  {
    type: "head",
    en: "Welcome to momants.com",
    ar: "مرحبا بك في موقعنا momants.com",
  },
  {
    type: "talk",
    en:
      "In this guide, all steps and information needed to access our website and services are clearly explained.",
    ar:
      "سنستعرض في هذا الدليل الخطوات اللازمة لتصفح سهل واستخدام فعال لخدمات موقعنا وبطريقة جذابة وممتعة...",
  },
  {
    type: "talk",
    en:
      "This platform aims at gathering all users at a social network where they can create, share, find and attend events nearby or all over the world.",
    ar:
      "تهدف منصة momants  إلى تجميع المستخدمين عبر شبكة اجتماعية وتمكينهم من إنشاء ومشاركة وإيجاد وحضور اللحظات المميزة التي تحدث قريباً منهم أو في أي مكان في العالم.",
  },
  {
    type: "subHead",
    en: "Create an account",
    ar: "التسجيل في الموقع",
  },
  {
    type: "talk",
    en:
      "If you want to access our platform and know more about our services, you need to sign up first. Every time you want to access our platform, you need to sign in using your username and password. In case of forgetting your password, you can simply ask for resetting the password.",
    ar:
      "كي تستطيع الاطلاع على الخدمات المقدمة من موقع momants.com عليك أولاً أن تنشئ حساب في الموقع وكلما رغبت باستخدام إحدى الخدمات، عليك تسجيل الدخول باستخدام الحساب وكلمة المرور الخاصين بك..وفي حال نسيت كلمة المرور بإمكانك ببساطة أن تطلب إعادة تعيين كلمة السر.",
  },
  {
    type: "talk",
    en:
      "To know more about the platform, you can press the tab ‘about’ at the bottom of the homepage. There, you can add your feedback about the platform.",
    ar:
      "لمعرفة معلومات عامة عن الموقع والهدف منه، يمكنك الضغط على تبويب 'نبذة عنا' في أسفل الصفحة ثم بإمكانك مباشرة أن تقوم بتقييم الصفحة.",
  },
  {
    type: "subHead",
    en: "Explore moments",
    ar: "استكشف اللحظات",
  },
  {
    type: "talk",
    en:
      "If you set your location automatically, then the browser will show you all moments created nearby, where you can ask for joining events you are interested in.",
    ar:
      "إذا قمت بتحديد موقعك الجغرافي آلياً، يقوم الموقع باستعراض كافة اللحظات المسجلة على الموقع والقريبة منك وهنا بإمكانك اختيار الانضمام إليها.",
  },
  {
    type: "talk",
    en:
      "If you are looking for events far from where you reside, then you can search for them manually by writing the name of the targeted city in search box. where all events created in this city will show up.",
    ar:
      "وفي حال كنت تبحث عن لحظات في مدينة معينة غير المدينة التي تقيم فيها بإمكانك إدخال اسم المدينة يدوياً باللغة العربية أو الانكليزية في مربع البحث الظاهر فوق الخريطة، فتظهر لك جميع اللحظات المسجلة على الموقع في هذه المدينة.",
  },
  {
    type: "subHead",
    en: "To create a moment",
    ar: "لإنشاء لحظة",
  },
  {
    type: "talk",
    en:
      "When you want to create a moment, you need to name it and classify it using category tab (adventure, art, conference, cultural, entertainment, exhibition, festival, hosting, learning, meeting, music, occasion, performing, religion, social, sports, volunteering), then you choose whether to make the moment available to the public or not. Then you need to choose a start date and end date for it. And you can add further details about the event.",
    ar:
      "عندما ترغب بإنشاء لحظة، عليك أن تختار اسماً للحظتك وفئة من ضمن القائمة المنسدلة (مغامرات، فنون، مؤتمرات، ثقافية، ترفيه، معارض، مهرجانات، ضيافة، تعليمية، لقاءات، موسيقى، مناسبات، عروض، دينية، اجتماعية، رياضية، تطوع)، ومن ثم تحدد ما إذا كانت اللحظة متاحة للمشاهدة من قبل العامة أم لا، ومن ثم قم بتحديد تاريخ بدء اللحظة وتوقيتها وتاريخ انتهاء اللحظة وتوقيتها، ضع عنواناً للحظة وأضف بعض التفاصيل الخاصة بها.",
  },
  {
    type: "subHead",
    en: "To browse all moments",
    ar: "لاستعراض اللحظات بكافة أنواعها",
  },
  {
    type: "talk",
    en:
      "You can browse all moments whether you joined it, asked for joining it, or you have created them. you can also browse the wish list and in each category, you can know more about a particular moment. ",
    ar:
      "قم  باستعراض جميع اللحظات سواء كانت لحظات طلبت الانضمام إليها أو اللحظات التي سبق وانضممت إليها أواللحظات التي قمت بإنشائها، وقائمة الرغبات وضمن كل قائمة يمكنك البحث في القائمة عن لحظة معينة تريد الاطلاع على تفاصيلها.",
  },
  {
    type: "subHead",
    en: "To browse all your contacts",
    ar: "لاستعراض قائمة علاقاتك ضمن المنصة",
  },
  {
    type: "talk",
    en:
      "You can browse all your relationships in the platform by choosing the tab ‘connections’ on the left top of the homepage. You can also browse all contacts available under each category (friends, followers, following, friend requests, users, firms and blocked).",
    ar:
      "يمكنك استعراض كافة أنواع علاقاتك على المنصة، قم باختيار العلاقات ثم باختيارك أية فئة من الفئات في رأس الصفحة واستعراض الحسابات الموجودة ضمنها. إذ يمكن استعراض كل الحسابات تحت كل فئة من الفئات التالية (الأصدقاء، المتابِعون، المتابَعون، طلبات الصداقة، المستخدمون، المنشآت، المحظورون",
  },
  {
    type: "subHead",
    en: "To edit you profile",
    ar: "لتعديل ملفك الشخصي",
  },
  {
    type: "talk",
    en:
      "choose the tab ‘profile’ on the left top of the home page, then add or edit all your personal data (DoB, profile photo, education, gender, city, country). You can also browse your connections and moments using this tab.",
    ar:
      "قم باختيار تبويب الملف الشخصي وقم بإضافة وتعديل بياناتك الشخصية (تاريخ ميلاد، صورة شخصية، تعليم، الجنس، المدينة، الدولة). وبإمكانك في هذه الصفحة أن تستعرض علاقاتك على المنصة واللحظات التي شاركت بها و اللحظات التي أنشأتها.",
  },
  {
    type: "subHead",
    en: "To browse reviews",
    ar: "لاستعراض التقييمات",
  },
  {
    type: "talk",
    en:
      "You can browse all reviews of the moments you created by choosing the tab ‘reviews’.",
    ar:
      "لاستعراض التقييمات الصادرة منك على لحظات المستخدمين ويمكنك اختيار تبويب 'تقييمات' واستعراض كافة التقييمات الصادرة.",
  },
  {
    type: "subHead",
    en: "To browse reports",
    ar: "لاستعراض الإبلاغات",
  },
  {
    type: "talk",
    en:
      "You can browse all reports on moments, users and firms by choosing the tab ‘reports’.",
    ar:
      "يمكنك استعراض كافة التقارير الصادرة منك على اللحظات أو المنشآت أو المستخدمين باختيار تبويب 'إبلاغات'.",
  },
  {
    type: "subHead",
    en: "Settings",
    ar: "إعدادات الحساب",
  },
  {
    type: "talk",
    en:
      "If you want to change the settings of your account (password, email, suspend friend requests), you can press the drop-down list on the right top of the home page and choose ‘settings’ and make the changes you need.",
    ar:
      "في حال كنت ترغب بضبط الإعدادات الخاصة بحسابك من تغيير كلمة السر أو البريد الالكتروني أو إيقاف استقبال طلبات الصداقة ما عليك إلا أن تضغط على سهم القائمة المنسدلة أعلى ويسار الصفحة الرئيسية وتختار تبويب 'إعدادات'وتقوم بالضبط الذي تريده.",
  },
  {
    type: "subHead",
    en: "Notifications",
    ar: "إشعارات",
  },
  {
    type: "talk",
    en:
      "You can browse all notifications by pressing the bell icon ‘ ‘ on the right top of the home page.",
    ar:
      "يتيح لك تبويب إشعارات والذي يظهر بشكل جرس في أعلى ويسار الصفحة الرئيسية الاطلاع على كافة الإشعارات التي تخصك.",
  },
  {
    type: "subHead",
    en: "Chat",
    ar: "دردشة",
  },
  {
    type: "talk",
    en:
      "You can chat with any contact on your contact list  by pressing on chat icon ‘ ’ on the right top of the home page.",
    ar:
      "يمكن القيام بمحادثات مع أي من جهات الاتصال لديك فقط باختيارك تبويب محادثات والذي يظهر بشكل مراسلات في أعلى ويسار الصفحة الرئيسية.",
  },
  {
    type: "subHead",
    en: "New Friend requests",
    ar: "أصدقاء جدد",
  },
  {
    type: "talk",
    en:
      "You can browse all friend requests by pressing on ‘+’ on the right top of the home page where you can add who you like.",
    ar:
      "يمكنك الاطلاع عل طلبات الصداقة المقدمة لك من خلال الضغط على إشارة + التي تظهر في أعلى ويسار الصفحة الرئيسية لتشاهد كافة طلبات الصداقة وإضافة من تراه مناسباً .",
  },
  {
    type: "subHead",
    en: "Browsing Language",
    ar: "تغيير اللغة",
  },
  {
    type: "talk",
    en:
      "you can convert the browsing language from English to Arabic by simply press on ‘AR’ ON the right top of the home page, then you can browse all sub-pages in Arabic.",
    ar:
      "إذا كنت ترغب بالتجول في صفحات الموقع باللغة الانكليزية يمكنك تغيير اللغة من أعلى ويمين الشاشة والانتقال بين اللغتين العربية والانكليزية كما ترغب.",
  },
  {
    type: "subHead",
    en: "Terms of use",
    ar: "شروط الاستخدام",
  },
  {
    type: "talk",
    en:
      "If you are interested in knowing more about the terms of use, you can choose the tab ‘terms of use’ on the bottom of the home page.",
    ar:
      "للاطلاع على شروط الاستخدام الخاصة بالموقع يمكنك اختيار تبويب 'شروط الاستخدام' في أسفل الصفحة الرئيسية.",
  },
  {
    type: "subHead",
    en: "Privacy policy",
    ar: "سياسات الخصوصية",
  },
  {
    type: "talk",
    en:
      "If you are interested in knowing more about the privacy policy, then you can choose the tab ‘privacy policy’ on the bottom of home page.",
    ar:
      "للاطلاع على سياسات الخصوصية الخاصة بالموقع يمكنك اختيار تبويب 'سياسات الخصوصية' في أسفل الصفحة الرئيسية.",
  },
  {
    type: "subHead",
    en: "Help",
    ar: "مساعدة",
  },
  {
    type: "talk",
    en:
      "To know all services offered by our platform and how to access them, you can simply press the tab ‘help’ on the bottom of the home page. There, you can read the user guide where all details are clearly explained.",
    ar:
      "للتعرف على كافة الخدمات وطريقة الوصول إليها، يمكنك اختيار تبويب 'مساعدة' في أسفل الصفحة الرئيسية فيظهر لك دليل المستخدم الذي يحتوي على كافة المعلومات والخطوات التي تحتاجها.",
  },
  {
    type: "subHead",
    en: "You have a question?",
    ar: "لديك سؤال؟",
  },
  {
    type: "talk",
    en:
      "If you have any further questions about the services, you can first check the frequently asked questions by choosing ‘FAQs’ tab on the bottom of the home page.",
    ar:
      "في حال كان لديك أي سؤال بخصوص الخدمات، يمكنك أولاً أن تطلع على الأسئلة المتكررة وأجوبتها الواردة تحت تبويب 'أسئلة متكررة' في أسفل الصفحة الرئيسية.",
  },
  {
    type: "subHead",
    en: "Contact us",
    ar: "تواصل معنا",
  },
  {
    type: "talk",
    en:
      "If you have not found the answer for your question in FAQs or if you want to suggest or comment on any issue related to our services, don’t hesitate to contact us. All what you need is to press on ‘contact us’ tab then you can add your message and your email, and we contact you back shortly.",
    ar:
      "في حال لم تجد الإجابة على سؤالك ضمن الأسئلة المتكررة أو في حال رغبتك بإرسال أي تعليق أو اقتراح يخص الموقع ما عليك إلا أن تختار تبويب 'تواصل معنا' في أسفل الصفحة الرئيسية وسيظهر لك مربع نص لإدخال رسالتك واسمك وبريدك الالكتروني ولدى استقبالنا لرسالتك سنقوم بإرسال الرد المناسب في أسرع وقت ممكن.",
  },
  {
    type: "subHead",
    en: "Momants on social media",
    ar: "مومانتس في كل مكان",
  },
  {
    type: "talk",
    en:
      "You can also follow our accounts on social media platforms (Facebook, Twitter, Instagram) by pressing on the chosen platform icon on the bottom of the home page.",
    ar:
      "بإمكانك أيضاً أن تتابع حساباتنا على صفحات التواصل الاجتماعي Facebook, Twitter and Instagram فقط بالضغط على الأيقونات الخاصة بكل تطبيق في يسار وأسفل الصفحة الرئيسية.",
  },
];

export default hel;
