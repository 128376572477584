import React, { Component } from "react";

class InitLang extends Component {
  enLang = () => {
    if (!(localStorage.getItem("lang") === "en")) {
      localStorage.setItem("lang", "en");
      window.location.reload();
    }
  };
  arLang = () => {
    if (!(localStorage.getItem("lang") === "ar")) {
      localStorage.setItem("lang", "ar");
      window.location.reload();
    }
  };
  componentDidMount() {
    var x = localStorage.getItem("lang");
    x === "en"
      ? (document.getElementById("en").style.color = "#ef7a30")
      : (document.getElementById("ar").style.color = "#ef7a30");
  }
  render() {
    let classes = "";
    if (this.props.normal) classes = "lang-normal";
    else if (this.props.top) classes = "lang-top";
    else classes = "lang";
    return (
      <div className={classes}>
        <i className="fas fa-globe"></i>
        <button
          onClick={() => {
            this.enLang();
          }}
          id="en"
        >
          EN
        </button>
        <button
          onClick={() => {
            this.arLang();
          }}
          id="ar"
        >
          AR
        </button>
      </div>
    );
  }
}

export default InitLang;
