import React, { Component } from "react";
import { Tabs } from "antd";
import "./explore.scss";
import lang from "../../../assets/lang";
import MapContainer from "../../common/maps/map";
import Search from "../../common/search";
import { eventService } from "../../../services/eventService";
import { Loader } from "../../common/loader";
import ListEvents from "../../common/eventslist";
import { connect } from "react-redux";
const { TabPane } = Tabs;

class Explore extends Component {
  state = {
    mapEvents: [],
    events: [],
    latest: [],
    nearby: [],
    tab: "map",
    mapload: true,
    listload: false,
    pageSize: 6,
    eventCount: 0,
    currentPage: 1,
  };
  changeTab = (e) => {
    this.setState({ tab: e, filter: { name: "" }, sort: "created_at" });
  };
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getEvents = (sort, filter, page) => {
    const { pageSize } = this.state;
    const { token, type } = this.props;
    filter = this.cleanFilter(filter);
    this.setState({ mapload: true });
    eventService.getMapCards(token, type, filter).then((res) => {
      this.setState({ mapEvents: res.data, mapload: false });
    });
    this.setState({ listload: true });
    eventService.getCount(token, type, filter).then((res) => {
      this.setState({ eventCount: res.data.count });
    });
    eventService
      .getCards(token, type, sort, filter, page, pageSize)
      .then((res) => {
        console.log(res.data);
        this.setState({ events: res.data, listload: false });
      });
  };
  changePage = (page) => {
    this.setState({ currentPage: page });
    this.getEvents(this.state.sort, this.state.filter, page);
  };
  componentDidMount() {
    const { token, type } = this.props;
    eventService.getMapCards(token, type, {}).then((res) => {
      this.setState({ mapEvents: res.data, mapload: false, search: false });
    });
    eventService.getCount(token, type, {}).then((res) => {
      this.setState({ eventCount: res.data.count });
    });
    eventService
      .getCards(token, type, "created_at", {}, 1, this.state.pageSize)
      .then((res) => {
        this.setState({ events: res.data });
      });
    {
      this.props.city &&
        eventService
          .getCards(
            token,
            type,
            "created_at",
            { city: this.props.city._id },
            1,
            this.state.pageSize
          )
          .then((res) => {
            this.setState({ nearby: res.data });
          });
    }
    eventService
      .getCards(token, type, "created_at", {}, 1, this.state.pageSize)
      .then((res) => {
        this.setState({ latest: res.data });
      });
  }

  render() {
    const {
      mapEvents,
      events,
      latest,
      nearby,
      tab,
      mapload,
      listload,
      pageSize,
      eventCount,
      currentPage,
    } = this.state;
    return (
      <div className="content inner-tabs">
        {/* <Search
          onChange={this.getEvents}
          filter={this.state.filter}
          sort={this.state.sort}
          type="Event"
          placeholder={lang.searchEvents[lang.ls()]}
        /> */}
        <Tabs
          defaultActiveKey={tab}
          onChange={this.changeTab}
          style={{ direction: "ltr" }}
        >
          <TabPane className="map" tab={lang.map[lang.ls()]} key="map">
            {mapload ? <Loader /> : <MapContainer show markers={mapEvents} />}
          </TabPane>
          <TabPane tab={lang.list[lang.ls()]} key="list">
            <Search
              onChange={this.getEvents}
              filter={this.state.filter}
              sort={this.state.sort}
              type="Event"
              placeholder={lang.searchEvents[lang.ls()]}
            />
            <ListEvents
              loading={listload}
              events={events}
              eventCount={eventCount}
              pageSize={pageSize}
              changePage={this.changePage}
              page={currentPage}
            />
            {nearby.length !== 0 && (
              <React.Fragment>
                {/* <div className="line"></div> */}
                <div className="head-main">{lang.nearbyEv[lang.ls()]}</div>
                <ListEvents
                  loading={false}
                  events={nearby}
                  eventCount={6}
                  cdis
                  pageSize={pageSize}
                />
              </React.Fragment>
            )}
            {latest.length !== 0 && (
              <React.Fragment>
                {/* <div className="line"></div> */}
                <div className="head-main">{lang.latestEvents[lang.ls()]}</div>
                <ListEvents
                  loading={false}
                  events={latest}
                  cdis
                  eventCount={6}
                  pageSize={pageSize}
                />
              </React.Fragment>
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    city: state.login.user.city,
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(Explore);
