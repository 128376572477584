import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/catch";

export const catchService = {
  setCatch,
  getCatch,
};
function setCatch(cach) {
  return axios.post(endPoint + "/", { cach });
}
function getCatch() {
  return axios.get(endPoint + "/");
}
