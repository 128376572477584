import React from "react";
import { Input, Tooltip, Icon } from "antd";
import "./input.scss";

const BasicInput = ({
  placeholder,
  classes,
  tooltip,
  icon,
  password,
  loading,
  disabled,
  name,
  value,
  onChange
}) => {
  const prefix = icon ? <Icon type={icon} /> : undefined;
  const dot = classes ? classes : null;
  const dots = disabled ? dot + " dis-input" : dot;
  const val = value ? value : undefined;
  if (!password) {
    return (
      <React.Fragment>
        <Input
          placeholder={placeholder}
          className={dots}
          value={val}
          prefix={prefix}
          name={name}
          suffix={
            tooltip ? (
              <Tooltip title={tooltip}>
                <Icon type="info-circle" />
              </Tooltip>
            ) : loading ? (
              <Icon type="loading" />
            ) : null
          }
          allowClear={false}
          onChange={onChange}
          disabled={disabled}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Input.Password
          placeholder={placeholder}
          className={dots}
          name={name}
          value={val}
          prefix={prefix}
          allowClear={false}
          onChange={onChange}
          disabled={disabled}
        />
      </React.Fragment>
    );
  }
};

export default BasicInput;
