import React, { Component } from "react";
import Search from "../../common/search";
import { adminService } from "../../../services/adminService";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
import Normal from "../cards/normalCard";
import Report from "../cards/reportCard";
import Paginate from "../../common/ant-design/pagination";
import Block from "../cards/blockCard";
import EmptyLabel from "../../common/ant-design/empty";

class Companies extends Component {
  state = {
    companies: [],
    companiesCount: null,
    companiesCurrPage: 1,
    loading: true,
    search: "",
    pageSize: 6,
  };
  componentDidMount() {
    this.getData("joined_at", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getData = (sort, filter, page) => {
    const { pageSize } = this.state;
    filter = this.cleanFilter(filter);
    this.setState({ filter, loading: true, companiesCurrPage: page });
    adminService.getCompaniesCards(filter, page, pageSize).then((res) => {
      this.setState({ companies: res.data, loading: false });
    });
    adminService.getCompaniesCount(filter).then((res) => {
      this.setState({ companiesCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ companiesCurrPage: page });
    this.getData(this.state.sort, this.state.filter, page);
  };
  render() {
    const {
      companies,
      search,
      pageSize,
      companiesCurrPage,
      loading,
      companiesCount,
    } = this.state;
    return (
      <div className="content">
        <Search
          value={search}
          onChange={this.getData}
          type="Company"
          placeholder={lang.search[lang.ls()]}
          admin="Company"
        />
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {companiesCount === 0 ? (
              <EmptyLabel label={lang.noCompaniesFound[lang.ls()]} />
            ) : (
              <React.Fragment>
                {!loading && (
                  <p className="info-parag">
                    {companiesCount} {lang.companies[lang.ls()]}
                  </p>
                )}
                <div className="triple-grid">
                  {companies.map((comp) => {
                    const {
                      _id,
                      name,
                      image,
                      isReported,
                      isBlocked,
                      followers,
                    } = comp;
                    if (isBlocked) {
                      return (
                        <Block
                          name={name}
                          image={image}
                          followers={followers.length}
                          id={_id}
                          key={_id}
                        />
                      );
                    } else if (isReported) {
                      return (
                        <Report
                          name={name}
                          image={image}
                          followers={followers.length}
                          id={_id}
                          key={_id}
                        />
                      );
                    } else {
                      return (
                        <Normal
                          name={name}
                          image={image}
                          followers={followers.length}
                          id={_id}
                          key={_id}
                        />
                      );
                    }
                  })}
                </div>
                {companiesCount > pageSize && !loading && (
                  <Paginate
                    def={companiesCurrPage}
                    total={companiesCount}
                    size={pageSize}
                    onChange={this.changePage}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Companies;
