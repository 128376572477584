import React, { useState, useEffect } from "react";
import "./chat.scss";
import BasicInput from "../../common/ant-design/input";
import io from "socket.io-client";
import { connect } from "react-redux";
import { Loader } from "../../common/loader";
import EmptyLabel from "../../common/ant-design/empty";
import { chattingService } from "../../../services/chattingService";
import { chatActions } from "../../../redux/actions/chatActions";
import { Menu } from "antd";
import { authService } from "../../../services/authService";
import ChatWindow from "./chatWindow";
import ChatsMenu from "./chatsMenu";
import lang from "../../../assets/lang";

const server = process.env.REACT_APP_API_URL;
let socket = io(server);
// let socket;

const Chatting = ({
  user,
  type,
  token,
  chatting,
  activeChat,
  getChattings,
  sendMessage,
  selectChat,
  linkChat,
  linkedChat,
  loadMore,
  seeAllBtn,
  loadList,
}) => {
  const [message, setMessage] = useState("");
  const [chatSearch, setChatSearch] = useState("");
  const [activate, setActivate] = useState(null);
  const [users, setUsers] = useState([]);
  const [comp, setComp] = useState([]);
  const [chatLoad, setChatLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchMod, setSearchMod] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  useEffect(() => {
    if (activeChat && !refresh) {
      const isUser1 = activeChat.user1.id._id === user._id;
      const recv = {
        _id: activeChat._id,
        id: isUser1 ? activeChat.user2.id._id : activeChat.user1.id._id,
        type: isUser1 ? activeChat.user2.type : activeChat.user1.type,
      };
      selectChatRoom(recv);
      setRefresh(true);
    }
    if (linkedChat) {
      selectChatRoom(linkedChat);
      linkChat(null);
    }
    if (activate) {
      setChatLoad(true);
      socket.emit("join", activate);
    }
    getChattings(token);
  }, [server, activate, linkedChat]);

  useEffect(() => {
    socket.on("joined", (chatting) => {
      setChatLoad(false);
      selectChat(chatting);
    });
    socket.on("Output Chat Message", (chatMsg) => {
      sendMessage(chatMsg);
      getChattings(token);
    });
    return () => {
      socket.emit("disconnect");
      socket.off();
    };
  }, [activate, message]);

  const loadMoreMess = (isVisible) => {
    if (isVisible) {
      loadMore(activeChat._id, activeChat.chat.length);
    }
  };
  const menu = (
    <Menu className="search-modal">
      {searchLoad && <Loader />}
      {users.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.users[lang.ls()]}>
          {users.map((us) => {
            return us._id !== user._id ? (
              <Menu.Item key={us._id}>
                <span
                  onClick={() =>
                    selectChatRoom({
                      id: us._id,
                      type: "User",
                    })
                  }
                  className="btn-drop"
                >
                  <i className="fas fa-user"></i> {us.name}
                </span>
              </Menu.Item>
            ) : null;
          })}
        </Menu.ItemGroup>
      ) : null}
      {comp.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.companies[lang.ls()]}>
          {comp.map((comp) => {
            return comp._id !== user._id ? (
              <Menu.Item key={comp._id}>
                <span
                  onClick={() =>
                    selectChatRoom({
                      id: comp._id,
                      type: "Company",
                    })
                  }
                  className="btn-drop"
                >
                  <i className="fas fa-building"></i> {comp.name}
                </span>
              </Menu.Item>
            ) : null;
          })}
        </Menu.ItemGroup>
      ) : null}
      {!users.length && !comp.length && !searchLoad && (
        <EmptyLabel label="No Result !" />
      )}
    </Menu>
  );
  const selectChatRoom = (recv) => {
    seeAllBtn();
    const item = {
      senderId: user._id,
      senderType: type,
      receiverId: recv.id,
      receiverType: recv.type,
    };
    setActivate(item);
    if (recv._id) {
      chattingService.seeMessages(token, recv._id).then((res) => {
        getChattings(token);
      });
    }
    setChatSearch("");
    setUsers([]);
    setComp([]);
  };
  const handleChatChange = (e) => {
    setMessage(e.target.value);
  };
  const searchUsers = (e) => {
    setChatSearch(e.target.value);
    setSearchLoad(true);
    setSearchMod(true);
    if (e.target.value) {
      authService.searchChats(token, type, e.target.value).then((res) => {
        setUsers(res.data.users);
        setComp(res.data.companies);
        setSearchLoad(false);
      });
    } else {
      setUsers([]);
      setComp([]);
      setSearchLoad(false);
      setSearchMod(false);
    }
  };
  const submitChat = (e, id) => {
    e.preventDefault();
    chattingService.seeMessages(token, id).then((res) => {
      getChattings(token);
    });
    let chatMessage = message;
    let senderId = user._id;
    let senderType = type;
    let receiverId =
      activeChat.user1.id._id === user._id
        ? activeChat.user2.id._id
        : activeChat.user1.id._id;
    let receiverType =
      activeChat.user1.id._id === user._id
        ? activeChat.user2.type
        : activeChat.user1.type;
    let chatRoom = activeChat.room;
    if (chatMessage) {
      socket.emit("Input Chat Message", {
        chatMessage,
        senderId,
        senderType,
        receiverId,
        receiverType,
        chatRoom,
      });
      setMessage("");
    }
  };
  return (
    <div className="content">
      <div className="chatting">
        <div className="chat">
          {chatLoad ? (
            <Loader />
          ) : activeChat ? (
            <ChatWindow
              activeChat={activeChat}
              message={message}
              handleChatChange={handleChatChange}
              submitChat={submitChat}
              loadMoreMess={loadMoreMess}
            />
          ) : (
            <div className="chat-now">
              <img
                alt="logo"
                src={
                  "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
                }
                className="logo"
              />
              <div className="chat-lbl">Chat Now| ...</div>
            </div>
          )}
        </div>
        <div className="chats">
          <div className="items">
            <div className="search-top">
              <BasicInput
                placeholder={lang.searchChats[lang.ls()]}
                icon="search"
                onChange={searchUsers}
                value={chatSearch}
              />
              {searchMod ? menu : null}
            </div>
            {chatting.length ? (
              <ChatsMenu
                chatting={chatting}
                activeChat={activeChat}
                selectChatRoom={selectChatRoom}
              />
            ) : (
              <EmptyLabel label="No Chats Yet ..." />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    type: state.login.type,
    token: state.login.token,
    chatting: state.chat.chatting,
    activeChat: state.chat.activeChat,
    loadList: state.chat.loadList,
    linkedChat: state.chat.linkedChat,
  };
};

const mapDispatchToProps = {
  getChattings: chatActions.getChattings,
  sendMessage: chatActions.sendMessage,
  selectChat: chatActions.selectChat,
  linkChat: chatActions.linkChat,
  loadMore: chatActions.loadMore,
  seeAllBtn: chatActions.seeAllBtn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatting);
