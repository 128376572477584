import React, { Component } from "react";
import UserCard from "../../common/cards/usercard";
import "./other.scss";
import lang from "../../../assets/lang";
import { userService } from "../../../services/userService";
import { companyService } from "../../../services/companyService";
import { Loader } from "../../common/loader";
import { connect } from "react-redux";

class OtherFollowers extends Component {
  state = {
    users: [],
    loading: true,
  };
  componentDidMount() {
    const { match, token, typeS } = this.props;
    const { id, type } = match.params;
    const followers = [];
    if (type === "User") {
      userService.getOtherFollowers(token, typeS, id).then((res) => {
        res.data.followers.map((foll) => {
          followers.push(foll.id);
          return null;
        });
        this.setState({ users: followers, loading: false });
      });
    } else {
      companyService.getOtherFollowers(token, typeS, id).then((res) => {
        res.data.followers.map((foll) => {
          followers.push(foll.id);
          return null;
        });
        this.setState({ users: followers, loading: false });
      });
    }
  }

  render() {
    const { users, loading } = this.state;
    return (
      <div className="other">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="head">{lang.followers[lang.ls()]}</div>
            <div className="line"></div>
            <div className="items">
              {users.map((user) => {
                return <UserCard user={user} />;
              })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    typeS: state.login.type,
  };
};

export default connect(mapStateToProps, null)(OtherFollowers);
