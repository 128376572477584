import { userTypes } from "../types";

export function adminManage(state = {}, action) {
  switch (action.type) {
    case userTypes.ADMIN_LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userTypes.ADMIN_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        token: action.data.token,
        name: action.data.admin.name,
        email: action.data.admin.email,
        remember: action.remember,
        roles: {
          signUp: action.data.admin.signUp,
          admins: action.data.admin.admins,
          catching: action.data.admin.catching,
          editEvent: action.data.admin.editEvent,
          deleteEvent: action.data.admin.deleteEvent,
          blockUsers: action.data.admin.blockUsers,
        },
      };
    case userTypes.CHANGE_ADMIN_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case userTypes.CHANGE_ADMIN_NAME:
      return {
        ...state,
        name: action.name,
      };
    case userTypes.ADMIN_LOGIN_FAILURE:
      return {};
    case userTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
