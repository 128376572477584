import React from "react";

export const Loader = ({ tiny, margin, tinycolor }) => {
  const classes = margin
    ? "tiny-loader loadingio-spinner-dual-ring-c8aqmf23tgd"
    : "loadingio-spinner-dual-ring-c8aqmf23tgd";
  const classesC = margin
    ? "tiny-loader loadingio-spinner-dual-ring-jj888ggjf9"
    : "loadingio-spinner-dual-ring-jj888ggjf9";
  return tiny ? (
    <div className={classes}>
      <div className="ldio-ysmpid058t">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  ) : tinycolor ? (
    <div className={classesC}>
      <div className="ldio-m58iv7a50dp">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="loader">
      <div className="loadingio-spinner-eclipse-25x4osfi4yb">
        <div className="ldio-ybix7bsblui">
          <div></div>
        </div>
      </div>
    </div>
  );
};
