import React from "react";
import Joi from "joi-browser";
import Form from "../form";
import lang from "../../../assets/lang";
import { reportService } from "../../../services/reportService";
import { connect } from "react-redux";

class ReportModal extends Form {
  state = {
    data: {
      report: "",
      to: {},
    },
    loading: false,
  };

  schema = {
    report: Joi.string().min(1).required().label("Report"),
    to: Joi.required(),
  };

  componentDidMount() {
    const data = { ...this.state.data };
    data.to = {
      id: this.props.id,
      type: this.props.rtype,
    };
    this.setState({ data });
  }

  doSubmit = () => {
    const { token, type } = this.props;
    this.setState({ loading: true });
    reportService.createReport(token, type, this.state.data).then((res) => {
      this.props.updateReport();
      this.setState({ loading: false });
      this.props.close("report");
    });
  };
  render() {
    const { loading } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="div-modal">
          <div className="modal-items">
            <div className="close" onClick={() => this.props.close("report")}>
              <i className="fas fa-times"></i>
            </div>
            <div className="head">
              <i className="far fa-file-alt"></i> {lang.report[lang.ls()]}
            </div>
            {this.renderTextArea("report", lang.reportPlace[lang.ls()])}
            {this.renderButton({
              label: lang.submit[lang.ls()],
              icon: loading ? "loading" : null,
            })}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(ReportModal);
