import React from "react";
import { getImage } from "../../../utils/helperMethods";
import "./cards.scss";
import lang from "../../../assets/lang";

const Block = ({ name, username, image, followers, id }) => {
  let to = `/admin/login/user/${id}/${username ? "User" : "Company"}`;
  let img = getImage(username);
  return (
    <div className="block-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <a href={to} className="name">
          {name}
        </a>
        {username ? (
          <div className="code">@{username}</div>
        ) : followers ? (
          <div className="code">
            <i className="fas fa-rss"></i> {followers}{" "}
            {lang.followers[lang.ls()]}
          </div>
        ) : null}
      </div>
      <p className="blocked">
        <i className="fas fa-ban"></i> {lang.blockedOne[lang.ls()]}
      </p>
    </div>
  );
};

export default Block;
