import { friendsTypes } from "../types/friendsTypes";
import { userService } from "../../services/userService";
import { requestService } from "../../services/requestService";
import io from "socket.io-client";
import history from "../../utils/history";

export const friendsActions = {
  setFriends,
  addFriend,
  removeFriend,
  logout,
};
const server = process.env.REACT_APP_API_URL;
let socket;
function setFriends(token) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getFriendsIds(token)
      .then((res) => {
        dispatch(success(res.data.friends));
        if (sessionStorage.getItem("acting")) {
          history.replace(`/login/events/${sessionStorage.getItem("acting")}`);
        }
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: friendsTypes.SET_FRIEND_REQUEST };
  }
  function success(data) {
    return { type: friendsTypes.SET_FRIEND_SUCCESS, data };
  }
  function failure(error) {
    return { type: friendsTypes.SET_FRIEND_FAILURE, error };
  }
}
function addFriend(req, friend) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    requestService
      .acceptRequest(req)
      .then((res) => {
        socket.emit("setGlobal");
        dispatch(success(friend));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: friendsTypes.ADD_FRIEND_REQUEST };
  }
  function success(data) {
    return { type: friendsTypes.ADD_FRIEND_SUCCESS, data };
  }
  function failure(error) {
    return { type: friendsTypes.ADD_FRIEND_FAILURE, error };
  }
}
function removeFriend(token, target) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    userService
      .unFriend(token, target)
      .then((res) => {
        socket.emit("setGlobal");
        dispatch(success(target));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: friendsTypes.REMOVE_FRIEND_REQUEST };
  }
  function success(data) {
    return { type: friendsTypes.REMOVE_FRIEND_SUCCESS, data };
  }
  function failure(error) {
    return { type: friendsTypes.REMOVE_FRIEND_FAILURE, error };
  }
}
function logout() {
  return { type: friendsTypes.LOGOUT };
}
