import React from "react";
import { Switch, Route } from "react-router-dom";
import "./admin.scss";
import AdminLogin from "./Login";
import AdminHomePage from "./homePage";
import RememberAdmin from "../routes/RememberAdmin";
import PrivateAdmin from "../routes/PrivateAdmin";
import AdminIssue from "./issues";

const Admin = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/admin/issue" component={AdminIssue} />
        <RememberAdmin path="/admin" exact component={AdminLogin} />
        <PrivateAdmin path="/admin/login" component={AdminHomePage} />
      </Switch>
    </React.Fragment>
  );
};

export default Admin;
