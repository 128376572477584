import { userTypes } from "../types";
import { authService } from "../../services/authService";
import { alertActions } from "./alertActions";
import history from "../../utils/history";
import { wishlistActions } from "./wishlistActions";
import { friendsActions } from "./friendsActions";
import { requestActions } from "./requestActions";
import { followActions } from "./followActions";
import { blockActions } from "./blockActions";
import { chatActions } from "./chatActions";
import { adminService } from "../../services/adminService";
import { notifActions } from "./notifActions";

export const userActions = {
  login,
  adminLogin,
  register,
  logout,
  changeImage,
  changeData,
  changeAdminEmail,
  changeAdminName,
  changeNotif,
};

function login({ email, password, rememberMe }) {
  return (dispatch) => {
    dispatch(request({ email }));
    sessionStorage.setItem("remember", rememberMe);
    authService
      .login(email, password)
      .then((res) => {
        dispatch(success(res.data, rememberMe));
        if (sessionStorage.getItem("create")) {
          history.replace("/login/event/form/new");
        } else {
          history.replace("/login/explore");
        }
        dispatch(alertActions.clear());
        const { token, response } = res.data;
        dispatch(chatActions.getChattings(token));
        dispatch(notifActions.setNotifs(token, response.user._id));
        dispatch(wishlistActions.setAll(response.user.wishlist));
        dispatch(friendsActions.setFriends(token));
        dispatch(requestActions.setRequests(token));
        dispatch(followActions.setFollows(token, response.auth.type));
        dispatch(blockActions.setBlocks(token, response.auth.type));
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.activate) {
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("authId", data.authId);
          history.push("/issue/confirmation-sent");
        } else if (data.blocked) {
          history.push("/issue/blocked");
        } else {
          setTimeout(() => {
            dispatch(alertActions.clear());
          }, 5000);
          dispatch(alertActions.error(data.message));
        }
        dispatch(failure(data.message));
      });
  };

  function request(user) {
    return { type: userTypes.LOGIN_REQUEST, user };
  }
  function success(data, remember) {
    return { type: userTypes.LOGIN_SUCCESS, data, remember };
  }
  function failure(error) {
    return { type: userTypes.LOGIN_FAILURE, error };
  }
}
function adminLogin({ email, password, rememberMe }) {
  return (dispatch) => {
    dispatch(request({ email }));
    sessionStorage.setItem("remember", rememberMe);
    adminService
      .login(email, password)
      .then((res) => {
        dispatch(success(res.data, rememberMe));
        dispatch(alertActions.clear());
        history.push("/admin/login/home");
      })
      .catch((error) => {
        const { data } = error.response;
        dispatch(alertActions.error(data.message));
        dispatch(failure(data.message));
      });
  };

  function request(user) {
    return { type: userTypes.ADMIN_LOGIN_REQUEST, user };
  }
  function success(data, remember) {
    return { type: userTypes.ADMIN_LOGIN_SUCCESS, data, remember };
  }
  function failure(error) {
    return { type: userTypes.ADMIN_LOGIN_FAILURE, error };
  }
}
function register(user, profile, lang) {
  return (dispatch) => {
    dispatch(request());
    authService
      .register(user, profile, lang)
      .then((res) => {
        dispatch(success(user.email));
        sessionStorage.setItem("email", user.email);
        sessionStorage.setItem("name", profile.name);
        sessionStorage.setItem("authId", res.data);
        history.push("/issue/confirmation-sent");
        dispatch(alertActions.clear());
      })
      .catch((error) => {
        const { data } = error.response;
        dispatch(failure(data.message));
        dispatch(alertActions.error(data.message));
      });
  };

  function request() {
    return { type: userTypes.REGISTER_REQUEST };
  }
  function success(email) {
    return { type: userTypes.REGISTER_SUCCESS, email };
  }
  function failure(error) {
    return { type: userTypes.REGISTER_FAILURE, error };
  }
}
function logout() {
  history.push("/");
  return { type: userTypes.LOGOUT };
}
function changeImage(image) {
  return { type: userTypes.CHANGE_IMAGE, image };
}
function changeData(name, city) {
  return { type: userTypes.CHANGE_DATA, name, city };
}
function changeAdminEmail(email) {
  return { type: userTypes.CHANGE_ADMIN_EMAIL, email };
}
function changeAdminName(name) {
  return { type: userTypes.CHANGE_ADMIN_NAME, name };
}
function changeNotif(value) {
  return { type: userTypes.CHANGE_NOTIF, value };
}
