import React from "react";
import CounterCard from "../../common/cards/countcard";
import lang from "../../../assets/lang";

const MainCounters = ({ counters }) => {
  const { users, companies, events, contacts, feedbacks, rate } = counters;
  return (
    <div className="triple-grid">
      <CounterCard
        icon="fas fa-users"
        label={lang.users[lang.ls()]}
        count={users}
      />
      <CounterCard
        icon="far fa-building"
        label={lang.companies[lang.ls()]}
        count={companies}
      />
      <CounterCard
        icon="fa fa-calendar-alt"
        label={lang.events[lang.ls()]}
        count={events}
      />
      <CounterCard
        icon="far fa-address-book"
        label={lang.contacts[lang.ls()]}
        count={contacts}
      />
      <CounterCard
        icon="far fa-comment-alt"
        label={lang.feedbacks[lang.ls()]}
        count={feedbacks}
      />
      <CounterCard
        icon="fas fa-star-half-alt"
        label={lang.rate[lang.ls()]}
        count={rate}
      />
    </div>
  );
};

export default MainCounters;
