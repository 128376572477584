import React from "react";
import ContactUs from "../common/contact";

const LandingContact = () => {
  return (
    <div className="contact-home">
      <div className="container">
        <ContactUs />
      </div>
    </div>
  );
};

export default LandingContact;
