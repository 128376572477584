import React from "react";
// import { reportService } from "../../../services/reportService";

const OwnReportCard = ({ report, updateState }) => {
  // const [closing, setClosing] = useState(false);
  const { name, username } = report.to.id;
  // const type = report.to.type;
  // const removing = () => {
  //   setClosing(true);
  //   reportService.removeReport(report._id).then((res) => {
  //     updateState(type, report._id);
  //     reportService.checkIsReported(type, _id);
  //   });
  // };
  return (
    <div className="report-card">
      <div className="name">{name}</div>
      {username ? <div className="username">@{username}</div> : null}
      <div className="rep">{report.report}</div>
    </div>
  );
};

export default OwnReportCard;
