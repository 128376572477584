import React, { Component } from "react";
import SignInForm from "./signIn-form";
import lang from "../../assets/lang";
class SignIn extends Component {
  render() {
    return (
      <div className="showcase">
        <div className="faded">
          <div className="single-grid gradient-card sign-in">
            <img
              style={{ width: "170px" }}
              src={
                "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
              }
              alt="logo"
            />
            <h2 className="head-main">{lang.signIn[lang.ls()]}</h2>
            <SignInForm />
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
