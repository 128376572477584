import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home";
import AdminMenu from "./adminMenu";
import Users from "./users/index";
import Events from "./events/index";
import Contacts from "./contacts/index";
import Feedbacks from "./feedbacks/index";
import Settings from "./settings/index";
import Companies from "./companies/index";
import Profile from "./profiles/profile";
import EventProfile from "./profiles/eventprofile";
import InitLang from "../common/initLang";
import EventForm from "../login/create";

const Outline = () => {
  return (
    <div className="container">
      <InitLang />
      <AdminMenu />
      <Switch>
        <Route path="/admin/login/user/:id/:type" component={Profile} />
        <Route path="/admin/login/event/form/:id" component={EventForm} />
        <Route path="/admin/login/event/:id" component={EventProfile} />
        <Route path="/admin/login/home" component={Home} />
        <Route path="/admin/login/users" component={Users} />
        <Route path="/admin/login/companies" component={Companies} />
        <Route path="/admin/login/events" component={Events} />
        <Route path="/admin/login/contacts" component={Contacts} />
        <Route path="/admin/login/feedbacks" component={Feedbacks} />
        <Route path="/admin/login/settings" component={Settings} />
      </Switch>
    </div>
  );
};

export default Outline;
