import { blockTypes } from "../types/blockTypes";
import { userService } from "../../services/userService";
import { companyService } from "../../services/companyService";
import io from "socket.io-client";

export const blockActions = {
  setBlocks,
  addBlock,
  removeBlock,
  logout,
};
const server = process.env.REACT_APP_API_URL;
let socket;
function setBlocks(token, type) {
  return (dispatch) => {
    dispatch(request());
    if (type === "User") {
      userService
        .getBlockedIds(token)
        .then((res) => {
          const blocked = res.data;
          userService
            .getBlockedByIds(token)
            .then((resp) => {
              const blockedBy = resp.data;
              dispatch(success(blocked, blockedBy));
            })
            .catch((err) => {
              const { data } = err.response;
              dispatch(failure(data.error));
            });
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .getBlockedIds(token)
        .then((res) => {
          const blocked = res.data;
          companyService
            .getBlockedByIds(token)
            .then((resp) => {
              const blockedBy = resp.data;
              dispatch(success(blocked, blockedBy));
            })
            .catch((err) => {
              const { data } = err.response;
              dispatch(failure(data.error));
            });
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: blockTypes.SET_BLOCK_REQUEST };
  }
  function success(block, blockBy) {
    return { type: blockTypes.SET_BLOCK_SUCCESS, block, blockBy };
  }
  function failure(error) {
    return { type: blockTypes.SET_BLOCK_FAILURE, error };
  }
}
function addBlock(token, type, user, room) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    if (type === "User") {
      userService
        .block(token, user)
        .then((res) => {
          socket.emit("setGlobal", room);
          dispatch(success(user.id));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .block(token, user)
        .then((res) => {
          socket.emit("setGlobal", room);
          dispatch(success(user.id));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: blockTypes.ADD_BLOCK_REQUEST };
  }
  function success(data) {
    return { type: blockTypes.ADD_BLOCK_SUCCESS, data };
  }
  function failure(error) {
    return { type: blockTypes.ADD_BLOCK_FAILURE, error };
  }
}
function removeBlock(token, type, user) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    if (type === "User") {
      userService
        .unblock(token, user)
        .then((res) => {
          socket.emit("setGlobal");
          dispatch(success(user.id));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .unblock(token, user)
        .then((res) => {
          socket.emit("setGlobal");
          dispatch(success(user.id));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: blockTypes.REMOVE_BLOCK_REQUEST };
  }
  function success(data) {
    return { type: blockTypes.REMOVE_BLOCK_SUCCESS, data };
  }
  function failure(error) {
    return { type: blockTypes.REMOVE_BLOCK_FAILURE, error };
  }
}
function logout() {
  return { type: blockTypes.LOGOUT };
}
