import React, { Component } from "react";
import FeedbackCard from "../cards/feedback";
import { feedbackService } from "../../../services/feedbackService";
import { Loader } from "../../common/loader";
import EmptyLabel from "../../common/ant-design/empty";
import lang from "../../../assets/lang";

class Feedbacks extends Component {
  state = {
    feedbacks: [],
    feedbacksCount: null,
    feedbacksCurrPage: 1,
    loading: true,
    search: "",
    pageSize: 6,
  };
  componentDidMount() {
    this.getData(1);
  }
  getData = (page) => {
    const { pageSize } = this.state;
    this.setState({ loading: true, feedbacksCurrPage: page });
    feedbackService.getFeedCards(page, pageSize).then((res) => {
      this.setState({ feedbacks: res.data, loading: false });
    });
    feedbackService.getCount().then((res) => {
      this.setState({ feedbacksCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ feedbacksCurrPage: page });
    this.getData(page);
  };
  render() {
    const { feedbacks, feedbacksCount, loading } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {feedbacksCount === 0 ? (
              <EmptyLabel label={lang.noFeedbacks[lang.ls()]} />
            ) : (
              <React.Fragment>
                {!loading && (
                  <p className="info-parag">
                    {feedbacksCount} {lang.feedbacks[lang.ls()]}
                  </p>
                )}
                <div className="triple-grid">
                  {feedbacks.map((feed) => {
                    const { _id, host, rate, feedback } = feed;
                    return (
                      <FeedbackCard
                        key={_id}
                        id={_id}
                        name={host.id.name}
                        userid={host.id._id}
                        username={host.id.username}
                        image={host.id.image}
                        rate={rate}
                        feed={feedback}
                        foll={host.id.followers.length}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Feedbacks;
