import React from "react";
import lang from "../../../assets/lang";

const ReportCard = ({ count }) => {
  return (
    <div style={{ textDecoration: "none", direction: "ltr" }}>
      <div className="alert-card">
        <i className="far fa-file-alt"></i>
        <div className="info">
          <p>{lang.reported[lang.ls()]}</p>
          <p className="count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
