import React, { useState, useEffect } from "react";
import "./about.scss";
import lang from "../../../assets/lang";
import { Input, Rate, Modal } from "antd";
import { connect } from "react-redux";
import { feedbackService } from "../../../services/feedbackService";
import { Loader } from "../../common/loader";
import { success } from "../../../utils/helperMethods";
const { TextArea } = Input;

const AboutUs = ({ token, type }) => {
  const [rate, setRate] = useState(0);
  const [feed, setFeed] = useState("");
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    setLoading(true);
    feedbackService.getFeedback(token).then((res) => {
      if (res.data) {
        setRate(res.data.rate);
        setFeed(res.data.feedback);
      }
      setLoading(false);
    });
  }, [token]);
  const giveFeed = (e) => {
    e.preventDefault();
    setSubmit(true);
    feedbackService
      .createFeedback(token, type, { rate, feedback: feed })
      .then((res) => {
        setRate(res.data.rate);
        setFeed(res.data.feedback);
        setSubmit(false);
        success(lang.feedSubm[lang.ls()]);
      });
  };
  const rating = (value) => {
    setRate(value);
  };
  const feeding = (e) => {
    setFeed(e.target.value);
  };
  return (
    <div className="content about">
      <img
        style={{ width: "200px" }}
        src={
          "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
        }
        alt="logo"
      />
      <div className="head-main">{lang.slogan[lang.ls()]}</div>
      <div className="about-feed">
        <div className="about">{lang.aboutTalk[lang.ls()]}</div>
        <div className="feedback">
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="head">{lang.giveFeed[lang.ls()]}</div>
              <form>
                <Rate className="rate" onChange={rating} value={rate} />
                <TextArea
                  placeholder={lang.enterFeed[lang.ls()]}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  value={feed}
                  onChange={feeding}
                />
                <button onClick={giveFeed} className="btn-ok">
                  {submit ? <Loader tiny /> : lang.submit[lang.ls()]}
                </button>
              </form>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(AboutUs);
