import React from "react";
import Header from "./header";
import Personal from "./personal";
import Friends from "./friends";
import EventProfile from "./eventprofile";
import lang from "../../../assets/lang";
import { Loader } from "../loader";

const MainProfile = ({ user, own, friends, wishlist, events, loading }) => {
  return (
    <React.Fragment>
      <Header user={user} own={own} />
      <Personal info={user} />
      {loading && !own ? (
        <Loader />
      ) : (
        <React.Fragment>
          {friends.length !== 0 && !own && (
            <Friends
              friends={friends}
              count={user.friends.length}
              id={user._id}
            />
          )}
          {events.length !== 0 && !own && (
            <EventProfile
              events={events}
              count={user.myEvents.length}
              name={lang.events[lang.ls()]}
              id={user._id}
              type={user.username ? "User" : "Company"}
              target="events"
            />
          )}
          {wishlist.length !== 0 && !own && (
            <EventProfile
              events={wishlist}
              count={user.wishlist.length}
              name={lang.wishlist[lang.ls()]}
              id={user._id}
              type={user.username ? "User" : "Company"}
              target="wishlist"
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MainProfile;
