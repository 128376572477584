import React from "react";
import "./select.scss";
import { Input, Select, Icon } from "antd";
import lang from "../../../assets/lang";
const { Option } = Select;
const InputGroup = Input.Group;

const BasicSelect = ({
  placeholder,
  icon,
  options,
  disabled,
  name,
  value,
  onChange,
}) => {
  const classes = disabled ? "dis-group" : null;
  const val = value ? value : undefined;
  if (icon) {
    return (
      <React.Fragment>
        <InputGroup className={classes} compact>
          <Icon type={icon} className="select-icon" />
          <Select
            placeholder={placeholder}
            defaultValue={val}
            disabled={disabled}
            onChange={onChange}
            name={name}
          >
            {options.map((option) => {
              return (
                <Option key={option._id} value={option._id}>
                  {option.name ? option.name[lang.ls()] : option}
                </Option>
              );
            })}
          </Select>
        </InputGroup>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Select
          placeholder={placeholder}
          defaultValue={val}
          disabled={disabled}
          name={name}
          onChange={onChange}
        >
          {options.map((option) => {
            return (
              <Option key={option._id} value={option._id}>
                {option.name ? option.name[lang.ls()] : option}
              </Option>
            );
          })}
        </Select>
      </React.Fragment>
    );
  }
};

export default BasicSelect;
