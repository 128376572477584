export const followTypes = {
  SET_FOLLOW_REQUEST: "SET_FOLLOW_REQUEST",
  SET_FOLLOW_SUCCESS: "SET_FOLLOW_SUCCESS",
  SET_FOLLOW_FAILURE: "SET_FOLLOW_FAILURE",

  ADD_FOLLOW_REQUEST: "ADD_FOLLOW_REQUEST",
  ADD_FOLLOW_SUCCESS: "ADD_FOLLOW_SUCCESS",
  ADD_FOLLOW_FAILURE: "ADD_FOLLOW_FAILURE",

  REMOVE_FOLLOW_REQUEST: "REMOVE_FOLLOW_REQUEST",
  REMOVE_FOLLOW_SUCCESS: "REMOVE_FOLLOW_SUCCESS",
  REMOVE_FOLLOW_FAILURE: "REMOVE_FOLLOW_FAILURE",

  LOGOUT: "LOGOUT"
};
