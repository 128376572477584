import React from "react";
import { getImage } from "../../../utils/helperMethods";
import { Rate } from "antd";
import lang from "../../../assets/lang";

const ReviewWide = ({ rev }) => {
  const { host, rate, review } = rev;
  const { name, username, image, _id } = host.id;
  const img = getImage(username);
  const to = username
    ? `/login/users/${_id}/User`
    : `/login/users/${_id}/Company`;
  return (
    <div className="review-wide">
      <div className="ident">
        <div
          className="img"
          style={{
            background: `url(${
              image ? image.url : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        <div className="info">
          <a href={to} className="name">
            {name}
          </a>
          {username && <p className="username">@{username}</p>}
        </div>
      </div>
      <Rate
        className={lang.rtl() ? "rate-rev" : "rate"}
        disabled
        value={rate}
      />
      <p className="feed">{review}</p>
    </div>
  );
};

export default ReviewWide;
