import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/reports";

export const reportService = {
  createReport,
  checkReport,
  getReports,
  removeReport,
  checkIsReported,
  getAdminReports,
};
function createReport(token, type, report) {
  return axios.post(endPoint + "/", report, {
    params: {
      token,
      type,
    },
  });
}
function checkReport(id, token) {
  return axios.post(endPoint + "/check", null, {
    params: {
      token,
      id,
    },
  });
}
function getReports(token, type) {
  return axios.get(endPoint + "/", {
    params: {
      token,
      type,
    },
  });
}
function removeReport(id) {
  return axios.put(endPoint + "/remove", null, {
    params: {
      id,
    },
  });
}
function checkIsReported(type, id) {
  return axios.put(endPoint + "/isReported", null, {
    params: {
      type,
      id,
    },
  });
}
function getAdminReports(id) {
  return axios.get(endPoint + "/admin-reps", {
    params: {
      id,
    },
  });
}
