import React from "react";
import { getImage } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import "./cards.scss";
import { Rate } from "antd";
import lang from "../../../assets/lang";

const FeedbackCard = ({ name, userid, username, image, rate, feed, foll }) => {
  let img = getImage(username);
  const to = `/admin/users/${userid}`;
  return (
    <div className="feedback-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <Link to={to} className="name">
          {name}
        </Link>
        {username ? (
          <div className="code">@{username}</div>
        ) : foll ? (
          <div className="code">
            <i className="fas fa-rss"></i> {foll} {lang.followers[lang.ls()]}
          </div>
        ) : null}
        <Rate className="rate" disabled value={rate} />
      </div>
      <p className="feed">{feed}</p>
    </div>
  );
};

export default FeedbackCard;
