import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ShareRoute = ({ loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn ? (
        <Redirect
          from={props.location}
          to={`/login/events/${props.match.params.id}`}
        />
      ) : (
        <Redirect
          from={props.location}
          to={`/moment/${props.match.params.id}`}
        />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    loggedIn: state.login.loggedIn,
  };
};

export default connect(mapStateToProps, null)(ShareRoute);
