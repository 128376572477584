import { notifTypes } from "../types/notifTypes";
import { notifService } from "../../services/notificationService";

export const notifActions = {
  setNotifs,
  setRead,
  readNotif,
};
function setNotifs(token, id) {
  return (dispatch) => {
    dispatch(request());
    let read = false;
    notifService
      .getMyNotifications(token)
      .then((res) => {
        res.data.map((notif) => {
          if (!notif.read.includes(id)) read = true;
          return null;
        });
        dispatch(success(res.data, read));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: notifTypes.SET_NOTIF_REQUEST };
  }
  function success(data, read) {
    return { type: notifTypes.SET_NOTIF_SUCCESS, data, read };
  }
  function failure(error) {
    return { type: notifTypes.SET_NOTIF_FAILURE, error };
  }
}
function readNotif(id, user) {
  return { type: notifTypes.READ_NOTIF, id, user };
}
function setRead() {
  return { type: notifTypes.SET_READ };
}
