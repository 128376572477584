import React from "react";
import { getImage, getPeriod } from "../../utils/helperMethods";
import lang from "../../assets/lang";
import { notifService } from "../../services/notificationService";
import { notifActions } from "../../redux/actions/notifActions";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { Loader } from "./loader";
const ObjectId = require("mongodb").ObjectID;

const NotifItem = ({ obj, id, readNotif, full, removeNotif }) => {
  const { _id, notif, from, read } = obj;
  let img = getImage(from.type === "User" ? true : false);
  let classes = "";
  if (full) classes = "notif-full";
  else classes = "notif-item";
  if (!read.includes(id)) classes = classes + " read";
  let admin = false;
  let nav = "",
    desc = "";
  if (notif.type === "follow") {
    nav = `/login/users/${from.id._id}/${from.type}`;
    desc = lang.followNotif[lang.ls()];
  } else if (notif.type === "invite") {
    nav = `/login/events/${notif.id}`;
    desc = lang.inviteNotif[lang.ls()];
  } else if (notif.type === "join") {
    nav = `/login/events/${notif.id}`;
    desc = lang.joinNotif[lang.ls()];
  } else if (notif.type === "join-req") {
    nav = `/login/events/${notif.id}`;
    desc = lang.joinReqNotif[lang.ls()];
  } else if (notif.type === "review") {
    nav = `/login/events/${notif.id}`;
    desc = lang.reviewNotif[lang.ls()];
  } else if (notif.type === "create") {
    nav = `/login/events/${notif.id}`;
    desc = lang.createNotif[lang.ls()];
  } else if (notif.type === "update") {
    nav = `/login/events/${notif.id}`;
    desc = lang.updateNotif[lang.ls()];
  } else if (notif.type === "admin-update") {
    img =
      "https://res.cloudinary.com/momants/image/upload/v1587288038/momants_ry4bpu.png";
    admin = true;
    nav = `/login/events/${notif.id}`;
    desc = lang.adminUpdateNotif[lang.ls()];
  } else if (notif.type === "delete") {
    img =
      "https://res.cloudinary.com/momants/image/upload/v1587288038/momants_ry4bpu.png";
    admin = true;
    nav = ``;
    desc = lang.deleteNotif[lang.ls()];
  }
  const readNot = () => {
    readNotif(_id, id);
    notifService.readNotif(_id, id);
  };
  return (
    <div className="notif-contain">
      <a href={nav} onClick={readNot} className={classes}>
        <div
          className="img"
          style={{
            background: `url(${
              from.id.image ? from.id.image.url : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        <div className="info">
          <div className="name">{admin ? "MOMANTS" : from.id.name}</div>
          <span style={{ color: "#777" }} className="desc">
            {desc} <span className="bold">{notif.text}</span>
          </span>
          <div style={{ color: "#777" }} className="time">
            {getPeriod(ObjectId(_id).getTimestamp())}
          </div>
        </div>
      </a>
      {full && (
        <Tooltip title={lang.removeNotif[lang.ls()]}>
          <i
            onClick={() => removeNotif(_id)}
            className="delete fas fa-times"
          ></i>
        </Tooltip>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  readNotif: notifActions.readNotif,
};

export default connect(null, mapDispatchToProps)(NotifItem);
