import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { eventImage } from "../../../utils/helperMethods";
import "../common.scss";
import lang from "../../../assets/lang";
import history from "../../../utils/history";
var moment = require("moment");

const EventCard = ({ req, myEvent }) => {
  const [event, setEvent] = useState(myEvent);
  const {
    _id,
    name,
    host: acc,
    startTiming,
    sharesNumber,
    views,
    isFinished,
    isRunning,
    images,
    joined,
    isArchived,
    isCatched,
    isReported,
  } = event;
  const host = acc.id;
  const img = eventImage();

  useEffect(() => {
    setEvent(myEvent);
  }, [myEvent]);
  const goToUser = () => {
    history.push(`/login/users/${host._id}/${acc.type}`);
  };
  return (
    <Link
      to={`/login/events/${_id}`}
      className="eventcard"
      style={{
        background: `url(${
          images.length > 0 ? images[0].url : img
        }) no-repeat center center/cover`,
      }}
    >
      {isFinished && !isArchived ? (
        <div className="finished">{lang.closed[lang.ls()]}</div>
      ) : isRunning && !isArchived ? (
        <div className="running">{lang.running[lang.ls()]}</div>
      ) : isArchived ? (
        <div className="archived">{lang.archived[lang.ls()]}</div>
      ) : null}
      {isCatched ? (
        <div className="catched">{lang.catched[lang.ls()]}</div>
      ) : isReported ? (
        <div className="reported">{lang.reported[lang.ls()]}</div>
      ) : null}
      <div className="info">
        <p className="name">{name}</p>
        {host ? (
          <React.Fragment>
            <p className="by">{lang.hostedby[lang.ls()]}</p>
            <span onClick={() => goToUser()} className="host">
              {host.name}
            </span>
          </React.Fragment>
        ) : null}
        {!req ? (
          <div className="timing">
            <p className="date">
              <i className="fa fa-calendar-alt"></i>{" "}
              {moment(startTiming).format("Do MMM")}
            </p>
            <p className="time">
              <i className="far fa-clock"></i>{" "}
              {moment(startTiming).format("hh:mm A")}
            </p>
            <p className="share">
              <i className="fas fa-user-friends"></i> {joined.length}
            </p>
            <p className="view">
              <i className="far fa-eye"></i> {views}
            </p>
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export default EventCard;
