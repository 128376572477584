import React from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./forgotpass";
import ResetPassword from "./resetpass";
import "./issues.scss";
import FrogotSent from "./forgotsent";
import ConfirmationSent from "./confsent";
import WelcomeEvenzt from "./welcome";
import BlockedInfo from "./blocked";
import PasswordResetted from "./passresetted";
import LandingNavbar from "../common/landingNav";
import InitLang from "../common/initLang";

const Issue = () => {
  return (
    <React.Fragment>
      <LandingNavbar />
      <Switch>
        <Route path="/issue/forgot-password" exact component={ForgotPassword} />
        <Route path="/issue/welcome" exact component={WelcomeEvenzt} />
        <Route path="/issue/blocked" exact component={BlockedInfo} />
        <Route
          path="/issue/password-changed"
          exact
          component={PasswordResetted}
        />
        <Route path="/issue/forgot-sent" exact component={FrogotSent} />
        <Route
          path="/issue/confirmation-sent"
          exact
          component={ConfirmationSent}
        />
        <Route
          path="/issue/reset-password/:id/:token"
          exact
          component={ResetPassword}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Issue;
