import React from "react";
import { Loader } from "../loader";
import Friends from "./friends";
import EventProfile from "./eventprofile";
import lang from "../../../assets/lang";
import Personal from "./personal";
import { connect } from "react-redux";

const OverallProfile = ({
  user,
  own,
  friends,
  events,
  loading,
  mutual,
  fris,
}) => {
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Personal info={user} />
          {friends.length !== 0 && fris.includes(user._id) && (
            <Friends
              friends={friends}
              count={user.friends.length}
              own={own}
              mutual={mutual}
              id={user._id}
            />
          )}
          {events.length !== 0 && (
            <EventProfile
              events={events}
              count={user.myEvents.length}
              name={lang.events[lang.ls()]}
              own={own}
              id={user._id}
              type={user.username ? "User" : "Company"}
              target="events"
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    fris: state.friend.friends,
  };
};

export default connect(mapStateToProps)(OverallProfile);
