import React, { useState } from "react";
import lang from "../../assets/lang";
import { Modal } from "antd";
import terms from "../../assets/terms";
import { getMapCateg } from "../../utils/helperMethods";
import hel from "../../assets/help";

const PrimaryFooter = () => {
  const [use, setUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [help, setHelp] = useState(false);
  const openModal = (name) => {
    if (name === "use") setUse(true);
    if (name === "help") setHelp(true);
    else setPrivacy(true);
  };
  const closeModal = (name) => {
    if (name === "use") setUse(false);
    if (name === "help") setHelp(false);
    else setPrivacy(false);
  };
  return (
    <div className="foot-cont">
      <div className="primary-footer">
        <div className="elements">
          <div
            className="img"
            style={{
              background: `url(${getMapCateg(
                "default"
              )}) no-repeat center center/contain`,
            }}
          ></div>
          <a href="/login/about-us" className="link">
            {lang.aboutComp[lang.ls()]}
          </a>
          <div onClick={() => openModal("use")} className="link">
            {lang.use[lang.ls()]}
          </div>
          <div onClick={() => openModal("privacy")} className="link">
            {lang.privacyPol[lang.ls()]}
          </div>
          <div onClick={() => openModal("help")} className="link">
            {lang.help[lang.ls()]}
          </div>
          <a href="/login/contact-us" className="link">
            {lang.contact[lang.ls()]}
          </a>
        </div>
        <div className="social">
          <div>{lang.followus[lang.ls()]}</div>
          <i className="face fab fa-facebook-square"></i>
          <i className="insta fab fa-instagram"></i>
          <i className="twitt fab fa-twitter"></i>
        </div>
        <Modal
          title={lang.use[lang.ls()]}
          visible={use}
          onCancel={() => closeModal("use")}
          footer={null}
          bodyStyle={{ overflow: "auto", height: "60vh", textAlign: "justify" }}
        >
          <div className="terModal">{terms.use[terms.ls()]}</div>
        </Modal>
        <Modal
          title={lang.privCond[lang.ls()]}
          visible={privacy}
          onCancel={() => closeModal("privacy")}
          footer={null}
          bodyStyle={{ overflow: "auto", height: "60vh", textAlign: "justify" }}
        >
          <div className="terModal">{terms.privacy[terms.ls()]}</div>
        </Modal>
        <Modal
          title={lang.help[lang.ls()]}
          visible={help}
          onCancel={() => closeModal("help")}
          footer={null}
          bodyStyle={{ overflow: "auto", height: "60vh", textAlign: "justify" }}
        >
          <div className="terModal">
            {hel.map((obj) => {
              return <div className={obj.type}>{obj[lang.ls()]}</div>;
            })}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PrimaryFooter;
