import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import EventsUser from "../login/events/eventsuser";
import EventsCompany from "../login/events/eventscompany";

const EventsRoute = ({ type, ...rest }) => (
  <Route
    {...rest}
    render={() => (type === "User" ? <EventsUser /> : <EventsCompany />)}
  />
);

const mapStateToProps = state => {
  return {
    type: state.login.type
  };
};

export default connect(mapStateToProps, null)(EventsRoute);
