import { followTypes } from "../types/followTypes";

export function followManage(state = { followings: [] }, action) {
  switch (action.type) {
    case followTypes.SET_FOLLOW_REQUEST:
      return {
        ...state,
        setting: true
      };
    case followTypes.SET_FOLLOW_SUCCESS:
      return {
        followings: action.data
      };
    case followTypes.SET_FOLLOW_FAILURE:
      return { ...state };
    case followTypes.ADD_FOLLOW_REQUEST:
      return {
        ...state,
        following: true
      };
    case followTypes.ADD_FOLLOW_SUCCESS:
      return {
        followings: [action.data, ...state.followings]
      };
    case followTypes.ADD_FOLLOW_FAILURE:
      return { ...state };
    case followTypes.REMOVE_FOLLOW_REQUEST:
      return {
        ...state,
        unfollowing: true
      };
    case followTypes.REMOVE_FOLLOW_SUCCESS:
      return {
        followings: state.followings.filter(x => x !== action.data)
      };
    case followTypes.REMOVE_FOLLOW_FAILURE:
      return { ...state };
    case followTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
