import React from "react";
import { Link } from "react-router-dom";
import lang from "../../assets/lang";

const PasswordResetted = () => {
  return (
    <div className="showcase">
      <div className="faded">
        <div className="container single-grid">
          <div className="issue">
            <div className="info">
              <div className="head head-white">
                <i className="far fa-thumbs-up"></i>{" "}
                {lang.passChanged[lang.ls()]}
              </div>
              <div className="talk">{lang.passChangedTalk[lang.ls()]}</div>
              <Link to="/login" className="btn-issue">
                {lang.logIn[lang.ls()]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetted;
