import { wishlistTypes } from "../types";
import { userService } from "../../services/userService";

export const wishlistActions = {
  setAll,
  addWishlist,
  removeWishlist,
  logout
};

function setAll(data) {
  return { type: wishlistTypes.SET_WISHLIST, data };
}
function addWishlist(token, event) {
  return dispatch => {
    dispatch(request());
    userService
      .addToWishlist(token, event)
      .then(res => {
        dispatch(success(event));
      })
      .catch(err => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: wishlistTypes.ADD_WISHLIST_REQUEST };
  }
  function success(data) {
    return { type: wishlistTypes.ADD_WISHLIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: wishlistTypes.ADD_WISHLIST_FAILURE, error };
  }
}
function removeWishlist(token, event) {
  return dispatch => {
    dispatch(request());
    userService
      .removeWishlist(token, event)
      .then(res => {
        dispatch(success(event));
      })
      .catch(err => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: wishlistTypes.REMOVE_WISHLIST_REQUEST };
  }
  function success(data) {
    return { type: wishlistTypes.REMOVE_WISHLIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: wishlistTypes.REMOVE_WISHLIST_FAILURE, error };
  }
}
function logout() {
  return { type: wishlistTypes.LOGOUT };
}
