import React from "react";
import Navbar from "../common/navbar";
import LogOutline from "./logOutline";
import PrimaryFooter from "../common/primFooter";

const Login = () => {
  return (
    <div className="main-app">
      <div className="faded">
        <Navbar url={window.location.pathname} />
        <LogOutline />
        <PrimaryFooter />
      </div>
    </div>
  );
};

export default Login;
