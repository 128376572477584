import React, { useState } from "react";
import lang from "../../../assets/lang";
import { Modal } from "antd";
import { Loader } from "../../common/loader";
import BasicInput from "../../common/ant-design/input";
import { success } from "../../../utils/helperMethods";
import { companyService } from "../../../services/companyService";

const CompSettings = ({ code, setCode, token }) => {
  const [codeMod, setCodeMod] = useState(false);
  const [codeCheck, setCodeCheck] = useState(false);
  const [codeValid, setCodeValid] = useState(null);
  const [codeText, setCodeText] = useState("");
  const [loading, setLoading] = useState(false);
  const validateCode = (e) => {
    setCodeText(e.target.value);
    if (e.target.value.length >= 4) {
      setCodeCheck(true);
      companyService.checkCode(e.target.value).then((res) => {
        setCodeValid(res.data.valid);
        setCodeCheck(false);
      });
    } else {
      setCodeValid(false);
      setCodeCheck(false);
    }
  };
  const submitCode = () => {
    setLoading(true);
    companyService.changeCode(token, codeText).then((res) => {
      setCode(codeText);
      setCodeMod(false);
      setLoading(false);
      setCodeText("");
      setCodeValid(null);
      success(lang.usernameChanged[lang.ls()]);
    });
  };
  const cancelCode = () => {
    setCodeMod(false);
  };
  const editCode = () => {
    setCodeMod(true);
  };
  return (
    <div className="comp-settings">
      <div className="sett-item">
        <div className="desc">
          <i className="fas fa-user"></i>
          <div className="info">
            <div className="head">{lang.changeUsername[lang.ls()]}</div>
            <div className="talk">{code}</div>
          </div>
        </div>
        <button onClick={editCode} className="btn-ok">
          {lang.edit[lang.ls()]}
        </button>
      </div>
      <Modal
        title={lang.changeUsername[lang.ls()]}
        visible={codeMod}
        onOk={submitCode}
        onCancel={cancelCode}
        okButtonProps={{ disabled: !codeValid }}
      >
        <div className="validate">
          <BasicInput
            placeholder={lang.username[lang.ls()]}
            tooltip={lang.usernameUnique[lang.ls()]}
            value={codeText}
            onChange={validateCode}
            classes="sufix-input"
          />
          <div className="indicator">
            {codeCheck ? (
              <Loader tinycolor />
            ) : codeValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : codeValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

export default CompSettings;
