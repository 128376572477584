export const notifTypes = {
  SET_NOTIF_REQUEST: "SET_NOTIF_REQUEST",
  SET_NOTIF_SUCCESS: "SET_NOTIF_SUCCESS",
  SET_NOTIF_FAILURE: "SET_NOTIF_FAILURE",

  SET_READ: "SET_READ",
  READ_NOTIF: "READ_NOTIF",

  LOGOUT: "LOGOUT",
};
