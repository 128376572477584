import React, { useState, useEffect } from "react";
import lang from "../../assets/lang";
import { Menu, Dropdown } from "antd";
import BasicInput from "./ant-design/input";
import EmptyLabel from "./ant-design/empty";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../redux/actions";
import { adminService } from "../../services/adminService";
import { Loader } from "./loader";

const AdminNavbar = ({ name, logout, remember }) => {
  const [users, setUsers] = useState([]);
  const [comp, setComp] = useState([]);
  const [events, setEvents] = useState([]);
  const [searchMod, setSearchMod] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [global, setGlobal] = useState("");
  const langs = localStorage.getItem("lang");
  const modalPlace = langs === "en" ? "bottomRight" : "bottomLeft";
  useEffect(() => {
    handleRemember();
  });
  const globalSearch = (e) => {
    setGlobal(e.target.value);
    setSearchLoad(true);
    setSearchMod(true);
    if (e.target.value) {
      adminService.globalSearch(e.target.value).then((res) => {
        setSearchLoad(false);
        setUsers(res.data.users);
        setComp(res.data.companies);
        setEvents(res.data.events);
      });
    } else {
      setUsers([]);
      setComp([]);
      setEvents([]);
      setSearchLoad(false);
      setSearchMod(false);
    }
  };
  const handleRemember = () => {
    if (!remember) {
      if (!sessionStorage.getItem("remember")) {
        logout();
      }
    }
  };
  const resetGlobal = () => {
    setGlobal("");
    setUsers([]);
    setComp([]);
    setEvents([]);
  };
  const handleLogout = () => {
    logout();
  };
  const searchMenu = (
    <Menu className="search-global">
      {searchLoad && <Loader />}
      {users.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.users[lang.ls()]}>
          {users.map((us) => {
            return (
              <Menu.Item>
                <a
                  href={`/login/users/${us._id}/User`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fas fa-user"></i> {us.name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      ) : null}
      {comp.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.companies[lang.ls()]}>
          {comp.map((com) => {
            return (
              <Menu.Item>
                <a
                  href={`/login/users/${com._id}/Company`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fas fa-building"></i> {com.name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      ) : null}
      {events.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.events[lang.ls()]}>
          {events.map((event) => {
            return (
              <Menu.Item>
                <a
                  href={`/login/events/${event._id}`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fa fa-calendar-alt"></i> {event.name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      ) : null}
      {!users.length && !comp.length && !events.length && !searchLoad && (
        <EmptyLabel label="No Result !" />
      )}
    </Menu>
  );
  const sett = (
    <Menu>
      <Menu.Item>
        <Link to="/admin/login/settings" className="btn-drop">
          <i className="fas fa-cogs"></i> {lang.settings[lang.ls()]}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="" onClick={handleLogout} className="btn-drop">
          <i className="fas fa-sign-out-alt"></i> {lang.logOut[lang.ls()]}
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="navbar">
      <div className="container">
        <div className="controls">
          <BasicInput
            placeholder={lang.search[lang.ls()]}
            icon="search"
            onChange={globalSearch}
            value={global}
          />
          {searchMod && (
            <div onClick={() => setSearchMod(false)} className="global-dis">
              {searchMenu}
            </div>
          )}
        </div>
        <div className="modals">
          <Link to="/admin/login/settings" className="name">
            {name.substr(0, name.indexOf(" "))}
          </Link>
          <Dropdown overlay={sett} trigger={["click"]} placement={modalPlace}>
            <i className="fas fa-chevron-down"></i>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.admin.name,
    remember: state.admin.remember,
  };
};

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
