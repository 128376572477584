import React, { Component } from "react";
import EventCard from "../../common/cards/eventcard";
import "./other.scss";
import lang from "../../../assets/lang";
import { eventService } from "../../../services/eventService";
import { Loader } from "../../common/loader";

class OtherEvents extends Component {
  state = {
    events: [],
    loading: true
  };
  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    eventService.getOtherEvents(id, "created_at").then(res => {
      this.setState({ events: res.data, loading: false });
    });
  }

  render() {
    const { events, loading } = this.state;
    return (
      <div className="other">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="head">{lang.events[lang.ls()]}</div>
            <div className="line"></div>
            <div className="items">
              {events.map(ev => {
                return <EventCard myEvent={ev} />;
              })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default OtherEvents;
