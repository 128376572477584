export const blockTypes = {
  SET_BLOCK_REQUEST: "SET_BLOCK_REQUEST",
  SET_BLOCK_SUCCESS: "SET_BLOCK_SUCCESS",
  SET_BLOCK_FAILURE: "SET_BLOCK_FAILURE",

  ADD_BLOCK_REQUEST: "ADD_BLOCK_REQUEST",
  ADD_BLOCK_SUCCESS: "ADD_BLOCK_SUCCESS",
  ADD_BLOCK_FAILURE: "ADD_BLOCK_FAILURE",

  REMOVE_BLOCK_REQUEST: "REMOVE_BLOCK_REQUEST",
  REMOVE_BLOCK_SUCCESS: "REMOVE_BLOCK_SUCCESS",
  REMOVE_BLOCK_FAILURE: "REMOVE_BLOCK_FAILURE",

  LOGOUT: "LOGOUT"
};
