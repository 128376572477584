import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import "./form.scss";
import lang from "../../../assets/lang";
import { selectService } from "../../../services/selectService";
import { eventService } from "../../../services/eventService";
import { connect } from "react-redux";
import history from "../../../utils/history";
import { Loader } from "../../common/loader";
import io from "socket.io-client";
import { notifService } from "../../../services/notificationService";
import { Link } from "react-router-dom";
import { Switch } from "antd";
const server = process.env.REACT_APP_API_URL;
let socket;

class EventForm extends Form {
  state = {
    data: {
      name: "",
      category: "",
      isPublic: true,
      country: "",
      city: "",
      startTiming: null,
      endTiming: null,
      limit: 1,
      isLimited: false,
      address: "",
      details: "",
      keywords: [],
      lat: null,
      lng: null,
      images: [],
      chat: false,
    },
    event: "",
    categories: [],
    countries: [],
    cities: [],
    isPublic: [
      {
        _id: true,
        name: {
          en: "Public",
          ar: "عام",
        },
      },
      {
        _id: false,
        name: {
          en: "Private",
          ar: "خاص",
        },
      },
    ],
    loading: false,
    mainLoad: false,
    error: "",
    removing: false,
    removeItem: "",
  };

  schema = {
    name: Joi.string().min(1).required().label("Event Name"),
    limit: Joi.number().min(1).optional().label("Limit"),
    isLimited: Joi.boolean().required().label("Is Limited"),
    chat: Joi.boolean().required().label("Chat"),
    category: Joi.string().required().label("Category"),
    isPublic: Joi.boolean().required().label("Is Public"),
    country: Joi.string().required().label("Country"),
    city: Joi.string().required().label("City"),
    startTiming: Joi.date().required().label("Start Date & Time"),
    endTiming: Joi.date().required().label("End Date & Time"),
    address: Joi.string().min(1).required().label("Address"),
    details: Joi.string().min(1).required().label("Details"),
    lat: Joi.number().required().label("Lat"),
    lng: Joi.number().required().label("Lng"),
    keywords: Joi.array().min(1).required().label("Keywords"),
    images: Joi.array().optional().empty(null),
  };
  componentDidMount() {
    sessionStorage.removeItem("create");
    if (this.props.match.params.id !== "new") {
      this.setState({ mainLoad: true });
      eventService.getEventForm(this.props.match.params.id).then((res) => {
        this.setState({ event: res.data._id });
        delete res.data._id;
        this.setState({ data: res.data, mainLoad: false });
      });
    }
    selectService.getCountries().then((res) => {
      const countries = res.data;
      this.setState({ countries });
    });
    selectService.getCities().then((res) => {
      const cities = res.data;
      this.setState({ cities });
    });
    selectService.getCategories().then((res) => {
      const categories = res.data;
      this.setState({ categories });
    });
  }
  changeChatting = (checked) => {
    const data = { ...this.state.data };
    data["chat"] = checked;
    this.setState({ data });
  };
  removeImage = (publicId) => {
    this.setState({ removing: true, removeItem: publicId });
    eventService.removeInstant(publicId, this.state.event).then((res) => {
      const data = { ...this.state.data };
      data["images"] = data["images"].filter((obj) => {
        return obj.id !== publicId;
      });
      this.setState({ data, removing: false, removeItem: "" });
    });
  };
  doSubmit = () => {
    this.setState({ loading: true });
    socket = io(server);
    const { adminLog, token, adminToken, type, friends } = this.props;
    if (this.props.match.params.id === "new") {
      eventService
        .createEvent(this.props.token, this.props.type, this.state.data)
        .then((res) => {
          notifService
            .sendMultipleNotif(token, type, "follow", false, {
              type: "create",
              id: res.data.id,
              text: res.data.name,
              pub: res.data.public,
              fr: friends,
            })
            .then((res) => {
              socket.emit("setNotif");
            });
          history.replace(
            adminLog
              ? `/admin/login/event/${res.data.id}`
              : `/login/events/${res.data.id}`
          );
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      eventService
        .updateEvent(this.props.match.params.id, this.state.data)
        .then((res) => {
          if (!adminLog) {
            notifService
              .sendMultipleNotif(token, type, "join", false, {
                type: "update",
                id: this.props.match.params.id,
                text: this.state.data.name,
              })
              .then((res) => {
                socket.emit("setNotif");
              });
          } else {
            notifService
              .sendMultipleNotif(adminToken, "Admin", "join", true, {
                type: "admin-update",
                id: this.props.match.params.id,
                text: this.state.data.name,
              })
              .then((res) => {
                socket.emit("setNotif");
              });
          }
          history.replace(
            adminLog
              ? `/admin/login/event/${this.props.match.params.id}`
              : `/login/events/${this.props.match.params.id}`
          );
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const {
      categories,
      countries,
      cities,
      isPublic,
      data,
      error,
      loading,
      mainLoad,
      removing,
      removeItem,
    } = this.state;
    const { type, adminLog } = this.props;
    return (
      <div className="content">
        {mainLoad ? (
          <Loader />
        ) : (
          <form onSubmit={this.handleSubmit}>
            <div className="event-form">
              <div className="data">
                <div className="head">
                  {this.props.match.params.id === "new"
                    ? lang.createEvent[lang.ls()]
                    : lang.editEvent[lang.ls()]}
                </div>
                <div className="line" style={{ width: "100%" }}></div>
                {error && (
                  <div className="alert alert-danger error">{error}</div>
                )}
                <div className="inline-ant">
                  {this.renderInput({
                    placeholder: lang.eventName[lang.ls()],
                    name: "name",
                    classes: "normal-input",
                  })}
                  <div
                    className="limit"
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    {this.renderCheckbox("isLimited", lang.limited[lang.ls()])}
                    {this.renderInputNumber("limit", 1, 1, "isLimited")}
                  </div>
                </div>
                <div className="inline-ant">
                  {this.renderSelect({
                    placeholder: lang.category[lang.ls()],
                    name: "category",
                    options: categories,
                  })}
                  {type === "User" || adminLog ? (
                    <div className="privacy">
                      {this.renderSelect({
                        placeholder: lang.privacy[lang.ls()],
                        name: "isPublic",
                        options: isPublic,
                      })}
                      <div className="info">
                        {data.isPublic
                          ? lang.publicSee[lang.ls()]
                          : lang.privateSee[lang.ls()]}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="inline-ant">
                  {this.renderSelect({
                    placeholder: lang.country[lang.ls()],
                    name: "country",
                    options: countries,
                  })}
                  {this.renderSelect({
                    placeholder: lang.city[lang.ls()],
                    name: "city",
                    options: cities,
                  })}
                </div>
                {this.renderRangePicker("startTiming", "endTiming")}
                <div className="chat-ch">
                  <div className="he">{lang.chatting[lang.ls()]}</div>
                  <Switch checked={data.chat} onChange={this.changeChatting} />
                </div>
                {this.renderTextArea(
                  "address",
                  lang.detailedAddress[lang.ls()]
                )}
                {this.renderTextArea("details", lang.eventDetails[lang.ls()])}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link
                    to={
                      this.props.match.params.id === "new"
                        ? "/login/explore"
                        : `/login/events/${this.props.match.params.id}`
                    }
                    className="btn-del"
                  >
                    {lang.cancel[lang.ls()]}
                  </Link>
                  {this.renderButton({
                    label: lang.submitEvent[lang.ls()],
                    icon: loading ? "loading" : null,
                  })}
                </div>
              </div>
              <div className="other">
                <div className="images">
                  <div className="head">{lang.images[lang.ls()]}</div>
                  <div className="section">
                    <div className="temp-images">
                      {data.images.map((img) => {
                        return img._id ? (
                          <div
                            className="image"
                            key={img.id}
                            onClick={() => this.removeImage(img.id)}
                            style={{
                              background: `url(${img.url}) no-repeat center center/cover`,
                            }}
                          >
                            {removing && removeItem === img.id ? (
                              <Loader tiny />
                            ) : (
                              <i className="fas fa-trash"></i>
                            )}
                          </div>
                        ) : null;
                      })}
                    </div>
                    {this.renderImageLoader()}
                  </div>
                </div>
                <div className="keywords">{this.renderTags("keywords")}</div>
                <div className="map">{this.renderMap()}</div>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
    adminLog: state.admin.loggedIn,
    adminToken: state.admin.token,
    friends: state.friend.friends,
  };
};

export default connect(mapStateToProps, null)(EventForm);
