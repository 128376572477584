import React from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./forgotpass";
import SetPassword from "./setpass";
import "./issues.scss";
import FrogotSent from "./forgotsent";
import PasswordSetted from "./passsetted";

const AdminIssue = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/admin/issue/forgot-password"
          exact
          component={ForgotPassword}
        />
        <Route
          path="/admin/issue/password-changed"
          exact
          component={PasswordSetted}
        />
        <Route path="/admin/issue/forgot-sent" exact component={FrogotSent} />
        <Route
          path="/admin/issue/set-password/:id/:token"
          exact
          component={SetPassword}
        />
      </Switch>
    </React.Fragment>
  );
};

export default AdminIssue;
