import { blockTypes } from "../types/blockTypes";

export function blockManage(state = { blocked: [], blockedBy: [] }, action) {
  switch (action.type) {
    case blockTypes.SET_BLOCK_REQUEST:
      return {
        ...state,
        setting: true
      };
    case blockTypes.SET_BLOCK_SUCCESS:
      return {
        blocked: action.block,
        blockedBy: action.blockBy
      };
    case blockTypes.SET_BLOCK_FAILURE:
      return { ...state };
    case blockTypes.ADD_BLOCK_REQUEST:
      return {
        ...state,
        blocking: true
      };
    case blockTypes.ADD_BLOCK_SUCCESS:
      return {
        ...state,
        blocked: [action.data, ...state.blocked]
      };
    case blockTypes.ADD_BLOCK_FAILURE:
      return { ...state };
    case blockTypes.REMOVE_BLOCK_REQUEST:
      return {
        ...state,
        unblocking: true
      };
    case blockTypes.REMOVE_BLOCK_SUCCESS:
      return {
        ...state,
        blocked: state.blocked.filter(x => x !== action.data)
      };
    case blockTypes.REMOVE_BLOCK_FAILURE:
      return { ...state };
    case blockTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
