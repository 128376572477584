import React, { Component } from "react";
import Search from "../../common/search";
import { adminService } from "../../../services/adminService";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
import Paginate from "../../common/ant-design/pagination";
import EmptyLabel from "../../common/ant-design/empty";
import EventCard from "../cards/eventcard";

class Events extends Component {
  state = {
    events: [],
    eventsCount: null,
    eventsCurrPage: 1,
    loading: true,
    search: "",
    pageSize: 9,
  };
  componentDidMount() {
    this.getData("created_at", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getData = (sort, filter, page) => {
    const { pageSize } = this.state;
    filter = this.cleanFilter(filter);
    this.setState({ sort, filter, loading: true, eventsCurrPage: page });
    adminService.getEventsCards(filter, page, pageSize, sort).then((res) => {
      this.setState({ events: res.data, loading: false });
    });
    adminService.getEventsCount(filter).then((res) => {
      this.setState({ eventsCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ eventsCurrPage: page });
    this.getData(this.state.sort, this.state.filter, page);
  };
  render() {
    const {
      events,
      search,
      pageSize,
      eventsCurrPage,
      loading,
      eventsCount,
    } = this.state;
    return (
      <div className="content">
        <Search
          value={search}
          onChange={this.getData}
          type="Event"
          placeholder={lang.search[lang.ls()]}
          admin="Event"
        />
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {eventsCount === 0 ? (
              <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
            ) : (
              <React.Fragment>
                {!loading && (
                  <p className="info-parag">
                    {eventsCount} {lang.events[lang.ls()]}
                  </p>
                )}
                <div className="triple-grid">
                  {events.map((event) => {
                    return <EventCard key={event._id} myEvent={event} />;
                  })}
                </div>
                {eventsCount > pageSize && !loading && (
                  <Paginate
                    def={eventsCurrPage}
                    total={eventsCount}
                    size={pageSize}
                    onChange={this.changePage}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Events;
