import React, { Component } from "react";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { Modal } from "antd";
import { adminService } from "../../../services/adminService";
import history from "../../../utils/history";
import { connect } from "react-redux";
import io from "socket.io-client";
import { notifService } from "../../../services/notificationService";
import { eventImage } from "../../../utils/helperMethods";
const server = process.env.REACT_APP_API_URL;
let socket;
const { confirm } = Modal;
var moment = require("moment");

class EventShowcase extends Component {
  render() {
    const { event, roles, adminToken } = this.props;
    const {
      _id,
      city,
      name,
      startTiming,
      likesNumber,
      limit,
      rate,
      views,
      isLimited,
      joined,
      sharesNumber,
      images,
      isFinished,
      isReported,
      isArchived,
      isCatched,
    } = event;
    const sliderProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      pauseOnHover: true,
    };
    const deleteEvent = () => {
      socket = io(server);
      confirm({
        title: lang.eventDeleteModalTitle[lang.ls()],
        content: lang.eventDeleteModalDesc[lang.ls()],
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          return notifService
            .sendMultipleNotif(adminToken, "Admin", "join", true, {
              type: "delete",
              id: _id,
              text: name,
            })
            .then((res) => {
              adminService.deleteEvent(_id).then((res) => {
                history.replace("/admin/login/events");
                socket.emit("setNotif");
              });
            });
        },
        onCancel() {},
      });
    };
    const imge = eventImage();
    return (
      <React.Fragment>
        <p
          style={{ fontSize: "1.5rem", color: "#ef7a30", fontWeight: "bolder" }}
        >
          {name}
        </p>
        <div className="img">
          {images.length > 1 ? (
            <div style={{ direction: "ltr" }} className="slide-container">
              <Slide className="slider" {...sliderProperties}>
                {images.map((img) => {
                  return (
                    <div className="each-slide" key={img.url}>
                      <div
                        style={{
                          background: `url(${img.url}) no-repeat center center/cover`,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </Slide>
            </div>
          ) : (
            <div
              style={{
                background: `url(${
                  images[0] ? images[0].url : imge
                }) no-repeat center center/cover`,
              }}
              className="slide-container"
            ></div>
          )}
          <div className="status">
            {isArchived ? (
              <div className="state">{lang.archived[lang.ls()]}</div>
            ) : isFinished ? (
              <div className="state">{lang.closed[lang.ls()]}</div>
            ) : null}
            {isReported && (
              <div className="state">{lang.reported[lang.ls()]}</div>
            )}
            {isCatched && (
              <div className="state">{lang.catched[lang.ls()]}</div>
            )}
          </div>
          <p className="city">
            <i className="fas fa-map-marker-alt"></i> {city.name[lang.ls()]}
          </p>
          {rate && rate !== 0 ? (
            <p className="rate">
              <i className="fas fa-star"></i> {rate}
            </p>
          ) : null}
        </div>
        <div className="meters">
          <div className="controls">
            {roles.editEvent && (
              <Link to={`/admin/login/event/form/${_id}`} className="btn-gr">
                {lang.edit[lang.ls()]}
              </Link>
            )}
            {roles.deleteEvent && (
              <Link onClick={deleteEvent} className="btn-del">
                {lang.delete[lang.ls()]}
              </Link>
            )}
            {isLimited ? (
              <p style={{ cursor: "default" }} className="point">
                <i className="fas fa-user-friends"></i> {joined.length} /{" "}
                {limit}
              </p>
            ) : (
              <p style={{ cursor: "default" }} className="point">
                <i className="fas fa-user-friends"></i> {joined.length}
              </p>
            )}
            <p style={{ cursor: "default" }} className="point">
              <i className="fas fa-eye"></i> {views}
            </p>
            <p style={{ cursor: "default" }} className="point">
              <i className="fas fa-thumbs-up"></i> {likesNumber}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.admin.roles,
    adminToken: state.admin.token,
  };
};

export default connect(mapStateToProps, null)(EventShowcase);
