import React, { useState, useEffect } from "react";
import lang from "../../../assets/lang";
import VisibilitySendor from "react-visibility-sensor";
import NotifItem from "../../common/notifItem";
import { Loader } from "../../common/loader";
import "./notif.scss";
import { connect } from "react-redux";
import { notifService } from "../../../services/notificationService";
import { notifActions } from "../../../redux/actions/notifActions";

const NotificationPage = ({ token, user, setNotifMod }) => {
  const [notifs, setNotifs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    notifService.getAllNotif(token, 0, 15).then((res) => {
      setNotifs(res.data);
      setLoading(false);
    });
  }, [token]);
  const loadMore = (isVisible) => {
    if (isVisible) {
      setLoading(true);
      notifService.getAllNotif(token, notifs.length, 15).then((res) => {
        const obj = [...notifs, ...res.data];
        setNotifs(obj);
        setLoading(false);
      });
    }
  };
  const removeNotif = (id) => {
    let data = [...notifs];
    data = data.filter((obj) => {
      return obj._id !== id;
    });
    setNotifs(data);
    notifService.removeNotif(token, id).then((res) => {
      setNotifMod(token, user._id);
    });
  };
  return (
    <div className="content notifications">
      <div className="head-main">
        <i className="far fa-bell"></i> {lang.notifications[lang.ls()]}
      </div>
      {notifs.length > 0 && (
        <div className="notif-container">
          {notifs.map((notItem) => {
            return (
              <NotifItem
                key={notItem._id}
                obj={notItem}
                id={user._id}
                full
                removeNotif={removeNotif}
              />
            );
          })}
        </div>
      )}
      <VisibilitySendor onChange={loadMore}>
        <div style={{ color: "#eee" }}>.{loading && <Loader />}</div>
      </VisibilitySendor>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    user: state.login.user,
  };
};

const mapDispatchToProps = {
  setNotifMod: notifActions.setNotifs,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
