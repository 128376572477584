export const wishlistTypes = {
  SET_WISHLIST: "SET_WISHLIST",

  ADD_WISHLIST_REQUEST: "ADD_WISHLIST_REQUEST",
  ADD_WISHLIST_SUCCESS: "ADD_WISHLIST_SUCCESS",
  ADD_WISHLIST_FAILURE: "ADD_WISHLIST_FAILURE",

  REMOVE_WISHLIST_REQUEST: "REMOVE_WISHLIST_REQUEST",
  REMOVE_WISHLIST_SUCCESS: "REMOVE_WISHLIST_SUCCESS",
  REMOVE_WISHLIST_FAILURE: "REMOVE_WISHLIST_FAILURE",

  LOGOUT: "LOGOUT"
};
