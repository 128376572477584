import { chatTypes } from "../types/chatTypes";
import { chattingService } from "../../services/chattingService";

export const chatActions = {
  getChattings,
  selectChat,
  sendMessage,
  linkChat,
  loadMore,
  seeAllBtn,
  deleteChat,
};
function getChattings(token) {
  return (dispatch) => {
    dispatch(request());
    chattingService
      .getChatting(token)
      .then((res) => {
        dispatch(success(res.data.chatting, res.data.notif));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: chatTypes.GET_CHATTING_REQUEST };
  }
  function success(data, notif) {
    return { type: chatTypes.GET_CHATTING_SUCCESS, data, notif };
  }
  function failure(error) {
    return { type: chatTypes.GET_CHATTING_FAILURE, error };
  }
}
function selectChat(data) {
  return { type: chatTypes.SELECT_CHAT, data };
}
function sendMessage(data) {
  return { type: chatTypes.SEND_MESSAGE, data };
}
function linkChat(data) {
  return { type: chatTypes.LINK_CHAT, data };
}
function seeAllBtn() {
  return { type: chatTypes.SEE_ALL };
}
function deleteChat(room) {
  return { type: chatTypes.DELETE_CHAT, room };
}
function loadMore(id, loadNo) {
  return (dispatch) => {
    dispatch(request());
    chattingService
      .loadMore(id, loadNo)
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: chatTypes.LOAD_MORE_REQUEST };
  }
  function success(data) {
    return { type: chatTypes.LOAD_MORE_SUCCESS, data };
  }
  function failure(error) {
    return { type: chatTypes.LOAD_MORE_FAILURE, error };
  }
}
