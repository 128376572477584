import React from "react";
import { Route, Switch } from "react-router-dom";
import LogMenu from "./logMenu";
import Explore from "./Explore";
import Profile from "./profiles";
import UserProfile from "./profiles/userprofile";
import EventProfile from "./profiles/eventprofile";
import EventForm from "./create";
import EventsRoute from "../routes/EventsRoute";
import ConnectionsRoute from "../routes/ConnectionsRoute";
import EditProfileRoute from "../routes/EditProfileRoute";
import Chatting from "./chat";
import Reviews from "./reviews";
import Reports from "./reports";
import AboutUs from "./about";
import ContactUs from "./contact";
import LoginSettings from "./settings";
import NotificationPage from "./notif";

const LogOutline = () => {
  return (
    <div className="container">
      <LogMenu />
      <Switch>
        <Route path="/login/explore" exact component={Explore} />
        <Route path="/login/notifications" exact component={NotificationPage} />
        <ConnectionsRoute path="/login/connections" exact />
        <EventsRoute path="/login/events" exact />
        <Route path="/login/chat" exact component={Chatting} />
        <Route path="/login/profile" exact component={Profile} />
        <EditProfileRoute path="/login/profile/edit" exact />
        <Route path="/login/users/:id/:type" component={UserProfile} />
        <Route path="/login/events/:id" exact component={EventProfile} />
        <Route path="/login/event/form/:id" exact component={EventForm} />
        <Route path="/login/reviews" exact component={Reviews} />
        <Route path="/login/reports" exact component={Reports} />
        <Route path="/login/about-us" exact component={AboutUs} />
        <Route path="/login/contact-us" exact component={ContactUs} />
        <Route path="/login/settings" exact component={LoginSettings} />
      </Switch>
    </div>
  );
};

export default LogOutline;
