import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MessageItem from "./messItem";
import BasicInput from "../../common/ant-design/input";
import ScrollToBottom from "react-scroll-to-bottom";
import { Loader } from "../../common/loader";
import { getImage, modalConfirm } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import lang from "../../../assets/lang";
import { chattingService } from "../../../services/chattingService";
import { chatActions } from "../../../redux/actions/chatActions";
import io from "socket.io-client";
const ObjectId = require("mongodb").ObjectID;
const server = process.env.REACT_APP_API_URL;
let socket;

const ChatWindow = ({
  activeChat,
  joining,
  message,
  handleChatChange,
  submitChat,
  deleteChat,
  user,
  loadMoreMess,
  loadMess,
  token,
  type,
}) => {
  const [isMyDel, setIsMyDel] = useState(false);
  const [lastDel, setLastDel] = useState(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (activeChat.remObj) {
      activeChat.remObj.map((obj) => {
        if (obj.user == user._id) {
          setIsMyDel(true);
          setLastDel(ObjectId(obj.id).getTimestamp());
        }
      });
    }
    let num = 0;
    activeChat.chat.map((ch) => {
      if (isMyDel && ObjectId(ch._id).getTimestamp() < lastDel) {
        return null;
      } else {
        return (num = num + 1);
      }
    });
    setCount(num);
  }, [activeChat]);
  const receiver =
    activeChat && activeChat.user1.id._id === user._id
      ? activeChat.user2
      : activeChat.user1;
  const img = getImage(receiver.type === "User" ? true : false);
  const to =
    receiver.type === "User"
      ? `/login/users/${receiver.id._id}/User`
      : `/login/users/${receiver.id._id}/Company`;
  const deleteChatting = () => {
    socket = io(server);
    modalConfirm(
      lang.chatDeleteModal[lang.ls()],
      "",
      () => {
        return chattingService
          .deleteChat(activeChat.room, token)
          .then((res) => {
            socket.emit("setChat", activeChat.room);
            deleteChat(activeChat.room);
          });
      },
      () => {}
    );
  };
  return (
    <React.Fragment>
      {joining ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="chat-head">
            <div
              className="img"
              style={{
                background: `url(${
                  receiver.id.image ? receiver.id.image.url : img
                }) no-repeat center center/cover`,
              }}
            ></div>
            <div className="info">
              <Link to={to} className="name">
                {receiver.id.name}
              </Link>
            </div>
            <i onClick={deleteChatting} className="delete fas fa-trash"></i>
          </div>
          <ScrollToBottom className="chat-body">
            {count % 10 === 0 && (
              <div className="load" onClick={loadMoreMess}>
                {loadMess ? <Loader tinycolor /> : lang.loadMore[lang.ls()]}
              </div>
            )}
            {activeChat.chat.map((ch) => {
              if (isMyDel && ObjectId(ch._id).getTimestamp() < lastDel) {
                return null;
              } else {
                return <MessageItem chat={ch} />;
              }
            })}
          </ScrollToBottom>
          <div className="chat-text">
            <form onSubmit={(e) => submitChat(e, activeChat._id)}>
              <BasicInput
                placeholder={lang.enterText[lang.ls()]}
                value={message}
                onChange={handleChatChange}
              />
              <button className="send fas fa-paper-plane"></button>
            </form>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    token: state.login.token,
    type: state.login.type,
    loadMess: state.chat.loadMess,
  };
};

const mapDispatchToProps = {
  deleteChat: chatActions.deleteChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
