export const friendsTypes = {
  SET_FRIEND_REQUEST: "SET_FRIEND_REQUEST",
  SET_FRIEND_SUCCESS: "SET_FRIEND_SUCCESS",
  SET_FRIEND_FAILURE: "SET_FRIEND_FAILURE",

  ADD_FRIEND_REQUEST: "ADD_FRIEND_REQUEST",
  ADD_FRIEND_SUCCESS: "ADD_FRIEND_SUCCESS",
  ADD_FRIEND_FAILURE: "ADD_FRIEND_FAILURE",

  REMOVE_FRIEND_REQUEST: "REMOVE_FRIEND_REQUEST",
  REMOVE_FRIEND_SUCCESS: "REMOVE_FRIEND_SUCCESS",
  REMOVE_FRIEND_FAILURE: "REMOVE_FRIEND_FAILURE",

  LOGOUT: "LOGOUT"
};
