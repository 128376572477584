import React, { useState } from "react";
import { getImage } from "../../../utils/helperMethods";
import "../common.scss";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { blockActions } from "../../../redux/actions/blockActions";

const BlockedCard = ({ user, removeBlock, token, type }) => {
  const [status, setStatus] = useState("");
  const { _id, name, image, username } = user;
  const img = getImage(username);
  function unblock() {
    setStatus("unBlocked");
    removeBlock(token, type, { id: _id, type: username ? "User" : "Company" });
  }
  return (
    <div className="blocked-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <p className="name">{name}</p>
        {status ? (
          <div className="status">{status}</div>
        ) : (
          <button onClick={unblock} className="btn-ok">
            {lang.unblock[lang.ls()]}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

const mapDispatchToProps = {
  removeBlock: blockActions.removeBlock,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedCard);
