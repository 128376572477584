import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/events";

export const eventService = {
  createEvent,
  getEvent,
  getEventForm,
  checkJoin,
  like,
  removeLike,
  checkLike,
  view,
  getMapCards,
  getCards,
  getCount,
  getMyEvents,
  getOtherProfEvents,
  getOtherEvents,
  getMyEventsCount,
  registerJoin,
  acceptRequest,
  rejectRequest,
  cancelJoinRequest,
  cancelJoin,
  getJoined,
  getJoinRequests,
  updateEvent,
  archiveEvent,
  removeImage,
  removeInstant,
  getEventsMapLand,
  getTopMoments,
};
function getEventsMapLand() {
  return axios.get(endPoint + "/event-map-land");
}
function getTopMoments() {
  return axios.get(endPoint + "/top-moments");
}
function createEvent(token, type, event) {
  return axios.post(endPoint + "/", event, {
    params: {
      token,
      type,
    },
  });
}
function getEvent(id) {
  return axios.get(endPoint + "/event", {
    params: {
      id,
    },
  });
}
function getEventForm(event) {
  return axios.get(endPoint + "/event-form", {
    params: {
      event,
    },
  });
}
function checkJoin(id, token) {
  return axios.post(endPoint + "/check-join", null, {
    params: {
      id,
      token,
    },
  });
}
function like(id, token) {
  return axios.post(endPoint + "/like", null, {
    params: {
      id,
      token,
    },
  });
}
function removeLike(id, token) {
  return axios.post(endPoint + "/remove-like", null, {
    params: {
      id,
      token,
    },
  });
}
function checkLike(id, token) {
  return axios.post(endPoint + "/check-like", null, {
    params: {
      id,
      token,
    },
  });
}
function view(id) {
  return axios.post(endPoint + "/view", null, {
    params: {
      id,
    },
  });
}
function getMapCards(token, type, filter) {
  return axios.get(endPoint + "/event-map", {
    params: {
      token,
      type,
      ...filter,
    },
  });
}
function getCards(token, type, sort, filter, page, limit) {
  return axios.get(endPoint + "/", {
    params: {
      token,
      type,
      sort,
      ...filter,
      limit,
      page,
    },
  });
}
function getCount(token, type, filter) {
  return axios.get(endPoint + "/count", {
    params: {
      token,
      type,
      ...filter,
    },
  });
}
function getMyEvents(token, sort, filter, page, limit) {
  return axios.get(endPoint + "/myevents", {
    params: {
      token,
      sort,
      ...filter,
      limit,
      page,
    },
  });
}
function getOtherProfEvents(id, sort) {
  return axios.get(endPoint + "/other-prof-events", {
    params: {
      id,
      sort,
    },
  });
}
function getOtherEvents(id, sort) {
  return axios.get(endPoint + "/other-events", {
    params: {
      id,
      sort,
    },
  });
}
function getMyEventsCount(token, filter) {
  return axios.get(endPoint + "/myevents-count", {
    params: {
      token,
      ...filter,
    },
  });
}
function registerJoin(token, event) {
  return axios.put(
    endPoint + "/registerJoin",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function acceptRequest(event, user) {
  return axios.put(endPoint + "/accept-request", null, {
    params: {
      event,
      user,
    },
  });
}
function rejectRequest(event, user) {
  return axios.put(endPoint + "/reject-request", null, {
    params: {
      event,
      user,
    },
  });
}
function cancelJoinRequest(token, event) {
  return axios.put(
    endPoint + "/cancelJoinRequest",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function cancelJoin(token, event) {
  return axios.put(
    endPoint + "/cancelJoin",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function getJoined(token, type, event) {
  return axios.get(endPoint + "/joined", {
    params: {
      event,
      token,
      type,
    },
  });
}
function getJoinRequests(token, type, event) {
  return axios.get(endPoint + "/join-requests", {
    params: {
      event,
      token,
      type,
    },
  });
}
function updateEvent(event, eventData) {
  return axios.put(endPoint + "/", eventData, {
    params: {
      event,
    },
  });
}
function archiveEvent(token, event) {
  return axios.put(endPoint + "/archive", null, {
    params: {
      token,
      event,
    },
  });
}
function removeImage(id) {
  return axios.post(endPoint + "/remove-image", null, {
    params: {
      id,
    },
  });
}
function removeInstant(id, event) {
  return axios.post(endPoint + "/instant-image", null, {
    params: {
      id,
      event,
    },
  });
}
