import React, { Component } from "react";
import { Link } from "react-router-dom";
import Input from "../common/inputs/input";
import CheckBox from "../common/ant-design/checkbox";
import lang from "../../assets/lang";
import { userActions, alertActions } from "../../redux/actions";
import { connect } from "react-redux";
import { Loader } from "../common/loader";

class SignInForm extends Component {
  state = {
    data: {
      email: "",
      password: "",
      rememberMe: false,
    },
  };
  handleRemember = () => {
    const data = { ...this.state.data };
    data.rememberMe = data.rememberMe ? false : true;
    this.setState({ data });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = () => {
    this.props.login(this.state.data);
  };
  render() {
    const { data } = this.state;
    const { alert, loggingIn } = this.props;
    return (
      <React.Fragment>
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <form>
          <Input
            icon="fa fa-user"
            name="email"
            type="text"
            placeholder={lang.emailPlace[lang.ls()]}
            onChange={this.handleChange}
          />
          <Input
            icon="fa fa-lock"
            name="password"
            type="password"
            placeholder={lang.passPlace[lang.ls()]}
            onChange={this.handleChange}
          />
          <CheckBox
            onChange={this.handleRemember}
            label={lang.rememberMe[lang.ls()]}
            checked={data.rememberMe}
          />
          <button
            onClick={this.handleSubmit}
            disabled={loggingIn}
            className="btn-ok"
          >
            {loggingIn ? <Loader tiny margin /> : null}
            {lang.logIn[lang.ls()]}
          </button>
        </form>
        <Link to="/issue/forgot-password" className="link">
          {lang.forgotPass[lang.ls()]}
        </Link>
        <p className="link-desc" style={{ color: "white" }}>
          {lang.dontAcc[lang.ls()]}{" "}
          <Link to="/sign-up" className="link">
            {lang.signUp[lang.ls()]}
          </Link>
        </p>
        <Link to="/" className="link">
          {lang.returnHome[lang.ls()]}
        </Link>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggingIn: state.login.loggingIn,
    alert: state.alert,
  };
};

const mapDispatchToProps = {
  login: userActions.login,
  clearAlerts: alertActions.clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
