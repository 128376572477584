import React, { Component } from "react";
import { Tag, Input, Icon } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import "./tags.scss";
import lang from "../../../assets/lang";

class AntTags extends Component {
  // state = {
  //   tags: ["keywords"],
  //   inputVisible: false,
  //   inputValue: ""
  // };

  // handleClose = removedTag => {
  //   const tags = this.state.tags.filter(tag => tag !== removedTag);
  //   this.setState({ tags });
  // };

  // showInput = () => {
  //   this.setState({ inputVisible: true }, () => this.input.focus());
  // };

  // handleInputChange = e => {
  //   this.setState({ inputValue: e.target.value });
  // };

  // handleInputConfirm = () => {
  //   const { inputValue } = this.state;
  //   let { tags } = this.state;
  //   if (inputValue && tags.indexOf(inputValue) === -1) {
  //     tags = [...tags, inputValue];
  //   }
  //   this.setState({
  //     tags,
  //     inputVisible: false,
  //     inputValue: ""
  //   });
  // };

  // saveInputRef = input => (this.input = input);

  forMap = tag => {
    const { name, handleClose } = this.props;
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(name, tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const {
      name,
      tags,
      inputVisible,
      inputValue,
      showInput,
      handleInputChange,
      saveInputRef,
      handleInputConfirm
    } = this.props;
    const tagChild = tags.map(this.forMap);
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={saveInputRef}
            type="text"
            size="small"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={() => handleInputConfirm(name)}
            onPressEnter={() => handleInputConfirm(name)}
          />
        )}
        {!inputVisible && (
          <Tag
            onClick={showInput}
            style={{
              color: "black",
              background: "#ededed",
              border: "none"
            }}
          >
            <Icon type="plus" /> {lang.newKeyword[lang.ls()]}
          </Tag>
        )}
      </div>
    );
  }
}

export default AntTags;
