var terms = {
  ls: function() {
    return localStorage.getItem("lang");
  },
  privacy: {
    en:
      "1.	GENERAL " +
      ' a.	This electronic record is generated by a computer system and does not require any physical or digital signatures. The word “Platform” in is this documents refers to the domain name www.momants.com  ("Website"),  IOS and Android applications that are owned and operated by Momants, having its registered office, where such expression shall, unless repugnant to the context thereof, be deemed to include its respective representatives, administrators, employees, directors, officers, agents and their successors and assigns.' +
      "b.	For the purpose of this Privacy Policy (“Policy”), wherever the context so requires," +
      "i)	The term ‘You’ &‘User’ shall mean any legal person or entity accessing or using the services provided on this Website, who is competent to enter into binding contracts;" +
      "ii)	The terms ‘We’, ‘Us’& ‘Our’ shall mean the Website and/or the Mention Form of Business, as the context so requires." +
      "iii)	The terms ‘Party’ & ‘Parties’ shall respectively be used to refer to the User and the Mention Form of Business individually and collectively, as the context so requires. " +
      "c.	The headings of each section in this Policy are only for the purpose of organizing the various provisions under this Policy in an orderly manner, and shall not be used by either Party to interpret the provisions contained herein in any manner. Further, it is specifically agreed to by the Parties that the headings shall have no legal or contractual value." +
      "d.	The use of the Website/applications by the User is solely governed by this Policy as well as the Terms of Use of the Website (“Terms”, available on the Website), and any modifications or amendments made thereto by the Momants from time to time, at its sole discretion. Visiting the home page of the Website and/or using any of the services provided on the platform shall be deemed to signify the User’s unequivocal acceptance of this Policy and the aforementioned Terms, and the User expressly agrees to be bound by the same. The User expressly agrees and acknowledges that the Terms and Policy are co-terminus, and that expiry / termination of either one will lead to the termination of the other." +
      "e.	The User unequivocally agrees that this Policy and the aforementioned Terms constitute a legally binding agreement between the User and the Momants and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the Website/apps, and that the same shall be deemed to be incorporated into the Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User, and that the User’s act of visiting any part of the Website/apps constitutes the User’s full and final acceptance of the Policy and the aforementioned Terms." +
      "f.	The Parties expressly agree that the Momants retains the sole and exclusive right to amend or modify the Policy and the aforementioned Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. The User has a duty to periodically check the Policy and Terms, and stay updated on their provisions and requirements.  If the User continues to use the Website/apps following such a change, the User will be deemed to have consented to any and all amendments / modifications made to the Policy and Terms. In so far as the User complies with the Policy and Terms, he/she is granted a personal, non-exclusive, non-transferable, revocable, limited privilege to enter, access and use the Website/apps." +
      "2.	COLLECTION OF PERSONAL AND OTHER INFORMATION" +
      "a.	The User expressly agrees and acknowledges that the Momants and stores the User’s personal information, which is provided by the User from time to time on the Website/apps, including but not limited to the User’s user name, passwords, email address, name, address, age, date of birth, sex, nationality, preferences, browsing history, business details, personal portfolio, etc., as well as any images or other information uploaded/published by the User on the platform. The User is aware that this information will be used by the Momants to provide services and features targeted at the User, that are most likely to meet the User’s needs, and also to customize and improve the platform to make its users’ experiences safer and easier. " +
      "b.	The User is aware that the Momants may automatically track certain information about the User based upon the User’s IP address and the User’s behaviour on the platform and the User expressly consents to the same. The User is aware that this information is used to do internal research on user demographics, interests, and behaviour, to enable the Momants to better understand, and cater to the interests of its users. The User is expressly made aware that such information may include the URL that the User visited prior to accessing the platform, the URL which the User subsequently visits (whether or not these URLs form a part of the platform), the User’s computer & web browser information, the User’s IP address, etc." +
      "c.	If the User chooses to purchase / acquire products / services from the platform, the User consents to allowing the Momants/Website to collect information about the User’s buying behaviour and trends." +
      "d.	If the User chooses to post messages / reviews / feedback anywhere on the Website/apps, including but not limited to message boards, chat rooms, other message areas, etc., the User is aware that any and all such information provided / uploaded will be collected and stored by the Momants indefinitely, and that such retained information may be used to resolve disputes, provide customer support, troubleshoot problems, etc." +
      "e.	The User is aware that any and all information pertaining to the User collected by the Momants, whether or not directly provided by the User to the Momants/platform, including but not limited to personal correspondence such as emails or letters, feedback from other users or third parties regarding the User’s activities or postings on the Website, etc., may be collected and complied by the Momants /platform into a file/folder specifically created for / allotted to the User, and the User hereby expressly consents to the same." +
      "f.	The User is aware that while he/she can browse some sections of the platform without being a registered user, certain activities (such as placing an order) require the User to provide valid personal information to the Momants /platform for the purpose of registration. The User is aware that the contact information provided to the Momants/platform may be used to send the User offers and promotions, whether or not based on the User’s previous orders and interests, and the User hereby expressly consents to receiving the same." +
      "g.	The User is aware that the Momants/platform may occasionally request the User to complete optional online surveys. These surveys may require the User to provide contact information and demographic information (like zip code, age, income bracket, sex, etc.). The User is aware that this data to is used to customise the platform for the benefit of the User, and providing all users of the platform with products/services/content that the Mention Form of Business/platform believes they might be interested in availing of, and also to display content according to the User’s preferences." +
      "h.	The User is further aware that the Momants/platform may occasionally request the User to write reviews for products/services purchased/availed of by the User from the Website/apps, and also reviews for the various sellers listing their products/services on the platform. The User is aware that such reviews will help other users of the platform make prudent and correct purchases, and also help the Momants/platform remove sellers whose products are unsatisfactory in any way, and the User hereby expressly authorises the Momants/platform to publish any and all reviews written by the User on the platform, along with the User’s name and certain contact details, for the benefit and use of other Users of the platform. " +
      "i.	Nothing contained herein shall be deemed to compel the platform/ Momants to store, upload, publish, or display in any manner content/reviews/surveys/feedback submitted by the User, and the User hereby expressly authorises the platform/ Momants to remove from the platform any such content, review, survey, or feedback submitted by the User, without cause or being required to notify the User of the same." +
      "3.	COOKIES" +
      "a.	The User is aware that a ‘Cookie’ is a small piece of information stored by a web server on a web browser so it can later be traced back from that particular browser, and that cookies are useful for enabling the browser to remember information specific to a given user, including but not limited to a User’s login identification, password, etc. The User is aware that the platform places both permanent and temporary cookies in the User’s computer's hard drive and web browser, and does hereby expressly consent to the same. " +
      "b.	The User is further aware that the platform uses data collection devices such as cookies on certain pages of the platform to help analyse web page flow, measure promotional effectiveness, and promote trust and safety, and that certain features of the Website are only available through the use of such cookies. While the User is free to decline the Website/apps’s cookies if the User’s browser permits, the User may consequently be unable to use certain features on the platform." +
      "c.	Additionally, the User is aware that he/she might encounter ‘cookies’ or other similar devices on certain pages of the Website/apps that are placed by third parties or affiliates of the Momants /platform. The User expressly agrees and acknowledges that the Momants /platform does not control the use of such cookies/other devices by third parties, that the Momants/platform is in no way responsible for the same, and that the User assumes any and all risks in this regard." +
      "4.	DIVULGING/SHARING OF PERSONAL INFORMATION" +
      "a.	The User is aware that the Momants may share the User’s personal information with other corporate entities and affiliates to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of the platform’s services; and to facilitate joint or co-branded services, where such services are provided by more than one corporate entity. " +
      "b.	The User is aware that the platform/ Momants may disclose personal information if required to do so by law or if the platform/ Momants in good faith believes that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal processes. The platform/ Momants may also disclose the User’s personal information to law enforcement offices, third party rights owners, or other third parties if it believes that such disclosure is reasonably necessary to enforce the Terms or Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of its users, or the general public." +
      "c.	The User is further aware that the platform/ Momants its affiliates may share / sell some or all of the User’s personal information with other business entities should the Momants /platform (or its assets) plan to merge with, or be acquired by such business entity, or in the event of re-organization, amalgamation, or restructuring of the Momants’s business. Such business entity or new entity will continue to be bound be the Terms and Policy, as may be amended from time to time." +
      "5.	THIRD PARTY ADVERTISEMENTS / PROMOTIONS" +
      "The User is aware that the Momants /platform uses third-party advertising companies to serve ads to the users of the Website. The User is aware that these companies may use information relating to the User’s visits to the Website and other websites in order to provide customised advertisements to the User. Furthermore, the Website may contain links to other websites that may collect personally identifiable information about the User. Momants /platform is not responsible for the privacy practices or the content of any of the aforementioned linked websites, and the User expressly acknowledges the same and agrees that any and all risks associated will be borne entirely by the User." +
      "6.	USER’S CONSENT" +
      "By using the platform and/ or by providing information to the Momants through the Website/apps, the User consents to the collection and use of the information disclosed by the User on the Website in accordance with this Policy, including but not limited to the User’s consent the Momants /platform sharing/divulging the User’s information, as per the terms contained hereinabove in Section 4 of the Policy." +
      "7.	DISPUTE RESOLUTION AND JURISDICTION" +
      "It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of this Policy and any disputes arising therefrom will be resolved through a two-step Alternate Dispute Resolution (“ADR”) mechanism. It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Policy and/or Terms. " +
      "a.	Mediation: In case of any dispute between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed herein below" +
      "b.	Arbitration. In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Momants, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be Momants HQ city. " +
      "The Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of Momants HQ country, and that the Courts at Momants HQ city shall have exclusive jurisdiction over any disputes arising between the Parties.",
    ar:
      ". عام" +
      ' أ.	تم إنشاء هذا السجل الإلكتروني بواسطة نظام كمبيوتر ولا يتطلب أي توقيعات مادية أو رقمية. تشير كلمة "المنصة" في هذه المستندات إلى اسم النطاق www.momants.com موقع الويب، وتطبيق نظام IOS و Android التي تمتلكها وتشغلها Momants ، ولديها مكتب مسجل .' +
      "‌ب.	عناوين كل قسم في هذه السياسة هي فقط لغرض تنظيم الأحكام المختلفة بموجب هذه السياسة بطريقة منظمة ، ولا يجوز استخدامها من قبل أي من الطرفين لتفسير الأحكام الواردة هنا بأي طريقة. علاوة على ذلك ، اتفق الطرفان على وجه التحديد على أن العناوين لن يكون لها قيمة قانونية أو تعاقدية." +
      '‌ج.	يخضع استخدام الموقع / التطبيقات من قبل المستخدم لهذه السياسة بالإضافة إلى شروط استخدام الموقع ("الشروط" ، المتوفرة على الموقع) ، وكذلك أي تعديلات يتم إجراؤها عليها من قبل Momants من وقت لآخر الوقت ، حسب تقديرها الخاص. تعتبر زيارة الصفحة الرئيسية لموقع الويب و / أو استخدام أي من الخدمات المقدمة على النظام الأساسي بمثابة قبول المستخدم القاطع لهذه السياسة والشروط المذكورة أعلاه ، ويوافق المستخدم صراحة على الالتزام بها. يوافق المستخدم ويقر صراحةً بأن الشروط والسياسات هي نهاية مشتركة ، وأن انتهاء / إنهاء أي منهما سيؤدي إلى إنهاء الآخر.' +
      "‌د.	يوافق المستخدم بشكل لا لبس فيه على أن هذه السياسة والشروط المذكورة أعلاه تشكل اتفاقية ملزمة قانونًا بين المستخدم و Momants وأن يخضع المستخدم للقواعد والمبادئ التوجيهية والسياسات والبنود والشروط المطبقة على أي خدمة يتم توفيرها بواسطة موقع الويب / التطبيقات ، والتي تعتبر نفسها مدرجة في الشروط ، وسيتم التعامل معها كجزء لا يتجزأ منها. يقر المستخدم ويوافق على أنه لا يلزم توقيع أو إجراء صريح لجعل هذه الشروط والسياسة ملزمة للمستخدم ، وأن فعل المستخدم بزيارة أي جزء من موقع الويب / التطبيقات يشكل قبول المستخدم الكامل والنهائي للسياسة و الشروط المذكورة أعلاه." +
      'ه.	يوافق الطرفان صراحة على أن " Momants" تحتفظ بالحق الوحيد والحصري لتعديل السياسة والشروط المذكورة أعلاه دون أي إذن أو تنبيه مسبق للمستخدم ، ويوافق المستخدم صراحة على أن أي تعديلات من هذا القبيل ستدخل حيز التنفيذ على الفور. على المستخدم واجب التحقق من السياسة والبنود بشكل دوري ، والبقاء على اطلاع على أحكامهم ومتطلباتهم. إذا استمر المستخدم في استخدام موقع الويب / التطبيقات بعد هذا التغيير ، فسيعتبر المستخدم قد وافق على أي وجميع التعديلات التي أدخلت على السياسة والبنود. وبقدر ما يلتزم المستخدم بالسياسة والبنود ، فإنه يمنح امتيازًا شخصيًا غير حصري وغير قابل للتحويل وقابل للإلغاء ومحدود للدخول إلى موقع الويب / التطبيقات والوصول إليه واستخدامه.' +
      "2. جمع المعلومات الشخصية وغيرها" +
      'أ.	يوافق المستخدم ويقر صراحةً بأن "Momants" لها أحقية تخزين معلومات المستخدم الشخصية ، والتي يقدمها المستخدم من وقت لآخر على موقع الويب / التطبيقات ، بما في ذلك على سبيل المثال لا الحصر ، اسم المستخدم وكلمات المرور وعنوان البريد الإلكتروني والاسم والعنوان والعمر وتاريخ الميلاد والجنس والجنسية والتفضيلات وسجل التصفح وتفاصيل العمل والحافظة الشخصية وما إلى ذلك ، بالإضافة إلى أي صور أو معلومات أخرى يتم تحميلها / نشرها من قبل المستخدم على المنصة. يدرك المستخدم أن Momants ستستخدم هذه المعلومات لتقديم الخدمات والمميزات التي تستهدف المستخدم ، والتي من المرجح أن تلبي احتياجات المستخدم ، وكذلك لتخصيص وتحسين النظام الأساسي لجعل تجارب مستخدميه أكثر أمانًا وأسهل .' +
      "ب.	إذا اختار المستخدم نشر الرسائل / التعليقات / التعليقات في أي مكان على موقع الويب / التطبيقات ، بما في ذلك على سبيل المثال لا الحصر ، لوحات الرسائل وغرف الدردشة ومناطق الرسائل الأخرى ، وما إلى ذلك ، فإن المستخدم على علم بأن أي وجميع هذه المعلومات المقدمة / التي تم تحميلها سوف يتم جمعها وتخزينها من قبل Momants إلى أجل غير مسمى ، ويمكن استخدام هذه المعلومات المحتفظ بها لحل النزاعات ، وتوفير دعم العملاء ، واستكشاف المشكلات وإصلاحها ، وما إلى ذلك. " +
      "يمكن الإطلاع على باقي كامل بنود سياسة الخصوصية في النسخة الإنجليزية الموجودة على الموقع" +
      "www.momants.com"
  },
  use: {
    en:
      "1.	GENERAL\n" +
      'This electronic record is generated by a computer system and does not require any physical or digital signatures. The word “Platform” in is this documents refers to the domain name www.momants.com ("Website"),  IOS and Android applications including its online services, such as use of software to offer social application, where anyone can register, join, share and explore their events hosted by likeminded people. Momants, provide an E Platform, where one can showcase and list their activities on the application, which would be passion, professional, business & non-profit in nature. Events and information, text, graphics, images and information obtained from service providers, any other website linked to this website, and any other material contained on the Website ("Materials") provided by Website is owned and operated by Momants and its representative..' +
      "a)	For the purpose of these Terms of Use (“Terms”), wherever the context so requires:" +
      "i.	The term ‘You’ & ‘User’ shall mean any legal person or entity accessing or using the services provided on this Website/Applications, who is competent to enter into binding contracts;" +
      "ii.	The terms ‘We’, ‘Us’ & ‘Our’ shall mean the Website/Applications and/or the Momants, as the context so requires." +
      "iii.	The terms ‘Party’& ‘Parties’ shall respectively be used to refer to the User and the Momants individually and collectively, as the context so requires. " +
      "iv.	“Effective date” means the date of acceptance of this agreement." +
      "v.	“Agreement” is defined as the agreement between the user and the Momants governed by the Terms and Conditions of service." +
      "vi.	“Services” means online services provided or performed by the Momants for the User through its Website/Applications. " +
      "vii.	“Indemnified party” means Momants and its subsidiaries, affiliates, employees, members, representative and agents." +
      "viii.	“Confidential Information” means information (in any form) which is confidential either to User or to the Momants and which either User discloses to the Momants or the Momants disclose to the User in connection with the Services. " +
      "ix.	Users include both the registered/Advertiser(s) and Browser/Visitor(s)." +
      "x.	Service' or 'Services' would mean to include the interactive online information Service offered by the Momants on the internet through which the user may access information carried by Momants in the database maintained by it. The terms would include to mean the search tools through which the user can search through the hosted databases and information using a number of search tools that are present with a selection matching their search criteria. Users then select one or more of the items presented to view the full document/ record. The term Service does not extend to Momants acting as an agent either express or implied on behalf of any User/Customer and is merely acting as a medium of information exchange/facilitator." +
      "b)	The headings of each section in these Terms are only for the purpose of organizing the various provisions under these Terms in an orderly manner, and shall not be used by either Party to interpret the provisions contained herein in any manner. Further, it is specifically agreed to by the Parties that the headings shall have no legal or contractual value." +
      "c)	The use of the website/applications by the User is solely governed by these Terms as well as the Privacy Policy (“Policy”), available on Website and any modifications or amendments made thereto by the Momants from time to time, at its sole discretion. Visiting the home page of the Website and/or using any of the services provided on the website/applications shall be deemed to signify the User’s unequivocal acceptance of these Terms and the aforementioned Policy, and the User expressly agrees to be bound by the same. The User expressly agrees and acknowledges that the Terms and Policy are co-terminus, and that expiry / termination of either one will lead to the termination of the other, save as provided in Section 4 hereunder." +
      "d)	The User unequivocally agrees that these Terms and the aforementioned Policy constitute a legally binding agreement between the User and the Momants, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the website/applications, and that the same shall be deemed to be incorporated into these Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User, and that the User’s act of visiting the any part of the Website constitutes the User’s full and final acceptance of these Terms and the aforementioned Policy. If you do not agree with any of these terms and conditions, then you should not access or use the website/app." +
      "e)	The Momants reserves the sole and exclusive right to amend or modify these Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. The User has a duty to periodically check the terms and stay updated on its requirements.  If the User continues to use the Website following such a change, the User will be deemed to have consented to any and all amendments / modifications made to the Terms. In so far as the User complies with these Terms, he/she is granted a personal, non-exclusive, non-transferable, revocable, limited privilege to enter and use the Website." +
      "f)	These terms and conditions also apply on all registered Users as well as unregistered Users in respect of all new services or features introduced on website in future as well." +
      "g)	The user name and password are either allotted by Momants or chosen by the Subscriber or agreed upon by the Momants. It is made abundantly clear that only the authorized User has the right to access the Services so offered by the Momants." +
      "h)	The Momants, reserves its right to withdraw wholly or in partly, without assigning any reason, any of website/app services or properties with or without any notice." +
      "2.	OUR SERVICES" +
      "The Momants is a technical facilitator that aims to provide a platform for accessing services and information related to social application, where anyone can register, join, share, explore their events hosted by likeminded people. Also, user can showcase and list their activities on the application, which would be passion, professional, business, non-profit events." +
      "User can post and publish events & its notifications on the website/applications, which may be public or private in nature. Momants, also provides facilities where one user can add other user as friend by sending friend request & other user may accept or reject. This platform also provide facility to its user to block other user (who are in their friends list), once an individual user is blocked, he/she will not be able to see the activity of other users. User can report spam to any suspicious event / activity or user. Also, if user is interested on any event, he/she can request to join that event. Like other social media platform, user can chat with other user friends and like & share their posts. Our management concept is founded on professionalism, integrity, accountability, and quality service that guarantee the maximum return from your investment, while maintaining your property at the highest standards to attract the best tenants possible. The Services may sometimes be located on third-party websites or applications, either as links from an add-on service to, or otherwise in connection with, websites or applications that those third parties control. Users are solely responsible for property they listed. The Momants accepts no responsibility for any legal or financial consequences or implications arising out of use of the Website/app, including any Online Services, or Properties. " +
      'The Momants disclaims any warranties express or implied connected with use of the Website/apps including its quality, availability or accuracy, which are provided on an "AS IS" basis.' +
      "3.	PURPOSE OF MOMANTS’S PLATFORM" +
      "The content of the pages of this website / application is for user’s general information and use only. It is subject to change without notice. Momants do not provide legal, professional or any other advice and user should always seek independent advice before acting on any information on the website." +
      "a)	User’s use of any information or materials on this website / application is entirely at their own risk, for which Momants shall not be liable. It shall be user’s own responsibility to ensure that any services or information available through this website / application meet their specific requirements." +
      "b)	Momants take all care in ensuring that all information, provided on website / application is accurate but Momants not responsible for any loss suffered by user as a consequence of any that action user take or omit to take on the basis of any of the information provided by Momants." +
      "c)	Momants is not be liable for any loss suffered by user through the use of the website / application howsoever caused, including loss caused through but not limited to Momants negligence, breach of contract, copyright infringement, breach of other intellectual property rights or defamation." +
      "d)	Momants is not liable for content published on the website / application by users of the website / application. If any such content appears to be inappropriate to Momants, Momants reserve the right to take down such content." +
      "e)	User shall not publish or cause to be published any unlawful, defamatory, obscene, offensive, harmful or otherwise objectionable content on the website / application." +
      "f)	Momants shall not sell, market, trade, or transfer to outside parties, user’s personally identifiable information. Momants may release user’s information when the release is required to comply with the law, enforce Momants policies, protect its or others’ rights, property, or safety." +
      "g)	This website / application contains material which is owned by Momants. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions." +
      "h)	User must refrain from using the website/apps in any way that causes damage to the website / application or in any unlawful, harmful or fraudulent manner. Any such use of this website / application may give rise to a claim for damages and/or be a criminal offence." +
      "i)	User must respect the use of the website / apps by other users and not interfere with their legitimate use of the website / application and Momants services." +
      "j)	User hereby agree not to post material including spamming which is deliberately intended to upset other users." +
      "k)	From time to time this website / application may also include links to other websites / application. These links are provided for user’s convenience to provide further information. They do not signify that Momants endorse the website(s). These third party sites have separate and independent privacy policies and, therefore, Momants owe no responsibility for the content of the linked website(s)." +
      "l)	When registering on Momants site / application, user may be asked to enter your name, e-mail address, location. Momants may also collect information about user’s computer, IP address and use of this website / application. Any of such information which Momants collect from user may be used to improve our website or to administer a contest, promotion, survey or other site feature." +
      "m)	Momants will take all reasonable and necessary care to maintain the safety of user’s personal information when you enter, submit, or access our website / application." +
      "n)	Momants does not subscribe to the views of authors and does not in any way confirm or deny the opinion expressed therein, and shall not be treated as the opinion of Momants." +
      "o)	When user join Momants, the details enter on the registration form will be used by us to provide user with Momants premium services. While registering, user agree to provide real first name, last name, e-mail address and truthful personal information." +
      "p)	User agree to keep username and password safe from use by third parties and not share their registration details with anyone else. User will be liable to indemnify Momants against any unauthorised use of username and password, howsoever caused." +
      "q)	Momants reserve the right to delete, disable and/or restrict user’s account." +
      "r)	Although Momants will do try its best to provide constant, uninterrupted access to the website / application, we do not guarantee this nor do we accept any responsibility or liability for any interruption or delay." +
      "4.	SERVICE CONTRACT" +
      "The Users expressly acknowledges, agrees and understand that:" +
      "a)	The Momants is merely a platform where users may come, find opportunities and explore themselves." +
      "b)	User acknowledges that she/he is not an employee of the Momants and further they are not directed, supervised, or controlled by the Momants." +
      "c)	Momants shall not hold any liability arises from service contract or any act or omissions of users." +
      "d)	The Momants makes no representation as to the reliability, capability or qualifications of any user or the quality, security or legality of any services." +
      "e)	The Momants does not ensure the accuracy of, endorse or recommend any content and the User uses such Content at his/her own risk. A User may access the listings/advertisements in the Service solely to obtain initial information from which further evaluation and investigation may commence. " +
      "f)	User shall limit access to and use of listings, deals to personal and internal use, and shall not use listings obtained from the Service for further distribution, publication, public display, or preparation of derivative works or facilitate any of these activities in any way." +
      "g)	User shall not use or reproduce any Content that is obtained from the Service, or that is otherwise made available to User in the Service, for or in connection with any other listing/advertising Service or device. " +
      "h)	User further shall not use the Service provided by the Momants in any other manner for or in connection with any other listing Service or device. " +
      "i)	Users violating these specific terms, specifically those Users searching the Service in an abusive or excessive manner, by automated or manual means, shall be subject to immediate termination of their membership without notice." +
      "5.	ELIGIBILITY" +
      "The User represents and warrants that he/she is competent and eligible to enter into legally binding agreements and that he/she has the requisite authority to bind himself/herself to these Terms. The User may not use this Website if he/she is not competent to contract, or is disqualified from doing so by any other applicable law, rule or regulation currently in force." +
      "6.	TERM" +
      "These Terms shall continue to form a valid and binding contract between the Parties, and shall continue to be in full force and effect until:" +
      "a)	The User continues to access and use the Website/apps; or" +
      "b)	The Transaction between the Parties, if any, concludes to the satisfaction of both Parties;" +
      "Whichever is longer, The Parties agree that certain portions of these Terms (“Sections”), such as Sections 13, 14, 15 & 16, shall continue to remain in full force and effect indefinitely, even after the expiry or termination of these Terms as contemplated herein." +
      "7.	TERMINATION" +
      "a)	The Momants reserves the right, in its sole discretion, to remove user to access the website / application and also has a right to terminate the access of the services offered on the Website/App, or any portion thereof, at any time, without notice or cause." +
      "b)	User may make a request of termination but they are not entitled to any refund of payment." +
      "c)	The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of the same, as described in Section 4 herein above. " +
      "8.	SERVICES TO BE RENDERED BY THE WEBSITE " +
      "a)	The Momants will Advertise, list, market & promote the events in its web portal / application, social media accounts and also it holds, Operates and Control over there;" +
      "b)	The Momants provides Events and opportunity oriented informative services to the Users who wish to receive information and promotional messages on and through Emails hereinafter." +
      'c)	To publish on the Website/App contact information about the registered service User in its online portal, its locations, User profile, advertise their services, posting the request of the User, upload photos, events, seminar information, and fee schedule and consultation by experts like brokers or investors and a complete "Profile page".' +
      "d)	To being the transaction promoted, the Momants reserves the right to share or circulate user’s information on its website/app/Social Media Accounts/Promotional activities etc." +
      "e)	To provide a log-in-id and password to enable registered User to create, update, and make any changes in its Profile Page as required." +
      "f)	To facilitate e-mail communication between Website's/apps registered users and the registered) third Party, to enable Users to make and receive queries and/or book or confirm appointments for various event related queries." +
      "g)	By using this platform, and providing his/her contact information to the Momants through the Website/apps, the User hereby agrees and consents to receiving e-mails and SMSs from the Momants and/or any of its affiliates or partners at any time, subject to the Policy. In the event that the User wishes to stop receiving any such marketing or email messages / text messages, the User may send an e-mail to the effect to [info@momants.com] with the subject [stop receiving]. The User agrees and acknowledges that it may take up to seven (7) business days for the Momants to give effect to such a request by the User." +
      "h)	The User expressly agrees that notwithstanding anything contained hereinabove, he/she may be contacted by the Momants or any of its affiliates / partners relating to any service availed of by the User/member on the Website or anything pursuant thereto." +
      "i)	It is expressly agreed to by the Parties that any information shared by the User with the Momants shall be governed by the Policy." +
      "j)	The Momants may provide links to other third-party World Wide Web sites or resources; however, Momants makes no representations whatsoever about any other Web site user may access through the Momants’s website/app." +
      "k)	The Momants may collect Personal Information about your on-line activities over time and across third party websites or on-line services when you use our Website or the Services.  Other parties may also be able to collect Personal Information about your on-line activities over time and across third party websites or on-line services when you use our Website or the Services." +
      "l)	The Momants reserves the right to disclose your Personal Information to Agents to enable them to provide Services.  The Momants also reserves the right to disclose your Personal Information for any other purpose disclosed when the information is provided, and for any other purpose with your consent." +
      "9.	RESPONSIBILITIES OF REGISTERED USER" +
      "a)	The Users shall agree that he will not claim or submit listings that do not belong to him/her. " +
      "b)	The User agrees to keep the information confidential provided by the Momants Further agrees to never use or transfer such information for any illegal or harmful purpose." +
      "c)	The User agrees to comply with all notices or instructions given by the Momants from time to time to enable the use of the Service." +
      "d)	The User understands and agrees that User is responsible for all applicable taxes and for all costs that are incurred in using the websites/apps services." +
      "e)	The User shall promptly make the payment to the Momants towards the Services availed as and when it becomes payable." +
      "f)	The User agrees to indemnify the Momants for any action or claim made by any third party resulting from any information posted on the site by the User or/and anybody else on his behalf." +
      "g)	The User expressly agrees that notwithstanding anything contained hereinabove, he/she may be contacted by the Momants or any of its affiliates / partners relating to any service availed of by the User on the Website/apps or anything pursuant thereto. It is expressly agreed to by the Parties that any information shared by the User with the Momants shall be governed by the Policy. " +
      "10.	IDENTITY AND SECURITY" +
      "a)	The Momants reserves the right to validate user information at any time but not limited to third party database or verification of one or more official legal documents of government." +
      "b)	The Momants is authorised to make an enquiries regarding the validation of identity and to confirm the ownership of user’s email-address. Failure to provide information on user’s part will violate or vitiate the agreement." +
      "c)	The user is solely responsible for maintaining the secrecy and security of his/her account password." +
      "d)	The user is obliged not to disclose his/her password with anyone. If user finds that their password is being hacked than they shall immediately report to the Momants." +
      "e)	Momants trying its best to make all information in the platform secure and protected." +
      "11.	PROHIBITED ACTIONS" +
      "The User agrees and acknowledges that he/she is a restricted user of this platform, and that he/she: " +
      "a)	is bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or software obtained from the platform. Any such use / limited use of the platform will only be allowed with the prior express written permission of the Momants. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information contained on the platform is expressly prohibited." +
      "b)	agrees not to access (or attempt to access) the platform and/or the materials or services by any means other than through the interface provided by the Website/apps. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website/apps or its content, or in any way reproduce or circumvent the navigational structure or presentation of the platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the platform will lead to suspension or termination of the User’s access to the platform, as detailed in Section 12 herein below. The User acknowledges and agrees that by accessing or using the Website/apps or any of the services provided therein, he/she may be exposed to content that he/she may consider offensive, indecent or otherwise objectionable. The Momants disclaims any and all liabilities arising in relation to such offensive content on the Website / application. The User expressly agrees and acknowledges that the products / services displayed on the platform are not owned by the Momants/Website /application, and that the same are the exclusive property of certain third parties who have chosen to market their products through the Momants’s platform, and that the Momants/platform is in no way responsible for the content of the same. The User may however report any such offensive or objectionable content, which the Momants may then remove from the Website/apps, at its sole discretion." +
      "c)	In places where Website/apps permits the User to post or upload data/information, the User undertakes to ensure that such material is not offensive or objectionable, and is in accordance with applicable laws. The User expressly agrees that any such material that is deemed to be objectionable /offensive may be removed from the platform immediately and without notice, and further that the User’s access to the Website/apps may also be permanently revoked, at the sole discretion of the Momants." +
      "d)	Further User undertakes not to: " +
      "i.	Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of any other person or entity;" +
      "ii.	Engage in any activity that interferes with or disrupts access to the Website or the services provided therein (or the servers and networks which are connected to the Website);" +
      "iii.	Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;" +
      'iv.	Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another\'s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever under any law, rule or regulation currently in force; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;' +
      "v.	Post any image/file/data that infringes the copyright, patent or trademark of another person or legal entity;" +
      "vi.	Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website;" +
      "vii.	Download any file posted/uploaded by another user of the Website that the User is aware, or should reasonably be aware, cannot be legally distributed in such a manner;" +
      "viii.	Probe, scan or test the vulnerability of the Website/apps or any network connected to the Website/apps, nor breach the security or authentication measures on the platform or any network connected to the Website/apps. The User may not reverse look-up, trace or seek to trace any information relating to any other user of, or visitor to, the platform, or any other customer of the platform, including any user account maintained on the platform not operated/managed by the User, or exploit the Website or information made available or offered by or through the Website/apps, in any manner;" +
      "ix.	Disrupt or interfere with the security of, or otherwise cause harm to, the platform, systems resources, accounts, passwords, servers or networks connected to or accessible through the Websites or any affiliated or linked websites;" +
      "x.	Collect or store data about other users of the Website/apps." +
      "xi.	Use the platform or any material or content therein for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of this platformor any other third party(ies);" +
      "xii.	Violate any code of conduct or guideline which may be applicable for or to any particular product or service offered on the Website / application;" +
      "xiii.	Violate any applicable laws, rules or regulations currently in force within or outside Momants HQ country;" +
      "xiv.	Violate any portion of these Terms or the Policy, including but not limited to any applicable additional terms of the platform contained herein or elsewhere, whether made by amendment, modification, or otherwise;" +
      "xv.	Publish, post, or disseminate information that is false, inaccurate or misleading; " +
      "xvi.	Directly or indirectly offer, attempt to offer, trade, or attempt to trade, any item the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force." +
      'xvii.	Commit any act that causes the Momants to lose (in whole or in part) the services of its internet service provider ("ISP") or in any manner disrupts the services of any other supplier/service provider of the Momants/platform;' +
      "xviii.	Engage in advertising to, or solicitation of, other users of the platform to buy or sell any products or services not currently displayed on the platform. The User may not transmit any chain letters or unsolicited commercial or junk email/messages to other users via the Website/apps. It shall be a violation of these Terms to use any information obtained from the platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another user of the platform without the express prior written consent of the Momants. " +
      "e)	The User hereby expressly authorises the Momants/platform to disclose any and all information relating to the User in the possession of the Momants/platform to law enforcement or other government officials, as the Momants may in its sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft / infringement of intellectual property. The User further understands that the Momants/platform might be directed to disclose any information (including the identity of persons providing information or materials on the platform) as necessary to satisfy any judicial order, law, regulation or valid governmental request. " +
      "f)	The User expressly agrees and acknowledges that the Momants/platform has no obligation to monitor the materials posted on the Website/apps, but that it has the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms. Notwithstanding this right, the User remains solely responsible for the content of the materials posted on the platform by him/her. In no event shall the Momants/platform assume or be deemed to have any responsibility or liability for any content posted, or for any claims, damages or losses resulting from use of any such content and/or the appearance of any content on the platform. The User hereby represents and warrants that he/she has all necessary rights in and to all content provided as well as all information contained therein, and that such content does not infringe any proprietary or other rights of any third party (ies), nor does it contain any libellous, tortuous, or otherwise unlawful or offensive material, and the User hereby accepts full responsibility for any consequences that may arise due to the publishing of any such material on the platform." +
      "12.	SUSPENSION OF USER ACCESS AND ACTIVITY " +
      "Notwithstanding other legal remedies that may be available to it, the Momants may in its sole discretion limit the User’s access and/ or activity by immediately removing the User’s access credentials either temporarily or indefinitely, or suspend / terminate the User’s membership, and/or refuse to provide User with access to the Website/apps, without being required to provide the User with notice or cause:" +
      "a)	If the User is in breach any of these Terms or the Policy;" +
      "b)	If the User has provided wrong, inaccurate, incomplete or incorrect information;" +
      "c)	If Momants is unable to verify or authenticate the user’s information;" +
      "d)	If the User’s actions may cause any harm, damage or loss to the other users or to the platform/Momants, at the sole discretion of the Momants;" +
      "e)	If User’ copies or duplicate the content or other information available from the Website/apps." +
      "If once the user gets suspended or the agreement is terminated than the user cannot use Momants’s platform under a different account and/or under new account. If the Momants finds so, then Momants has a right to apply the above actions." +
      "13.	INDEMNITY AND LIMITATIONS" +
      "The User hereby expressly agrees to defend, indemnify and hold harmless the platform and the Momants, its directors, affiliate vendors, employees, agents and their successors and assigns and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon the User's actions or inactions, including but not limited to any warranties, representations or undertakings, or in relation to the non-fulfilment of any of the User’s obligations under this Agreement, or arising out of the User's infringement of any applicable laws, rules and regulations, including but not limited to infringement of intellectual property rights, payment of statutory dues and taxes, claims of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers, or the infringement of any other rights of a third party. " +
      "In no event shall the Momant be liable to compensate the User or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not the Momants had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the User’s use of or access to the platform and/or the products, services or materials contained therein." +
      "The limitations and exclusions in this section apply to the maximum extent permitted by applicable law, and the Parties expressly agree that in the event of any statute, rule, regulation or amendment coming into force that would result in the Momants incurring any form of liability whatsoever, these Terms and the Policy will stand terminated one (1) day before the coming into effect of such statute, rule, regulation or amendment. It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy." +
      "14.	INTELLECTUAL PROPERTY RIGHTS " +
      "Unless expressly agreed to in writing, nothing contained herein shall give the User a right to use any of the platform’s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports, images, and other distinctive brand features, save according to the provisions of these Terms. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by the Website / application and other distinctive brand features of the platform are the property of the Momants. Furthermore, with respect to the Website/apps created by the Momants, the Momants shall be the exclusive owner of all the designs, graphics and the like, related to the Website." +
      "The User may not use any of the intellectual property displayed on the platform in any manner that is likely to cause confusion among existing or prospective users of the platform, or that in any manner disparages or discredits the Momants, to be determined in the sole discretion of the Momants." +
      "The User is aware that the products displayed on the platform are the artistic creations of their respective owners, and that all intellectual property, including but not limited to copyrights, relating to said products resides with the said owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators to the platform/Momants, or to the User. The User is aware that the Momants merely provides a platform through which the aforementioned services or information provided to users of the Website/apps, and the neither the Momants nor the Website/apps owns any of the intellectual property relating to the information displayed on the Website / application." +
      "The User is further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by the User will result in legal action being initiated against the User by the respective owners of the intellectual property so reproduced / infringed upon. It is agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy." +
      "15.	    DISLAIMER OF WARRANTIES AND LIABILITIES" +
      'a)	Except as otherwise expressly stated on the platform, all information/services offered on the Website / application are offered on an "as is" basis without any warranty whatsoever, either express or implied.' +
      "b)	The Momant makes no representations, express or implied, including without limitation implied warranties of merchantability and fitness of a products or services for a particular purpose." +
      "c)	The Momants shall not be responsible for the advice, views and suggestions provided by the expert users. " +
      "d)	The User agrees and undertakes that he/she is accessing the Website / application and transacting at his/her sole risk and are that he/she is using his/her best and prudent judgment before purchasing any product/service listed on the platform, or accessing/using any information displayed thereon." +
      "e)	Momants accepts no liability for any errors or omissions, whether on behalf of itself or third parties, or for any damage caused to the User, the User’s belongings, or any third party, resulting from the use or misuse of any availed information or service by the User from the Website / application." +
      "f)	The Momants does not guarantee that the functions and services contained in the platform will be uninterrupted or error-free, or that the platform or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks involved with the User’s use of the platform." +
      "g)	It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy. If you delete your User Contributions from the Website/apps, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other platform users." +
      "16.	SUBMISSIONS" +
      "Any comments, ideas, suggestions, information, initiation, or any other content contributed by the User to the Momants or this Website/apps will be deemed to include a royalty-free, perpetual, irrevocable, nonexclusive right and license for the Momants to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create derivative works, display worldwide, or act on such content, without additional approval or consideration, in any media, or technology now known or later developed, for the full term of any rights that may exist in such content, and the User hereby waives any claim to the contrary. The User hereby represents and warrants that he/she owns or otherwise controls all of the rights to the content contributed to the Website/apps, and that use of such content by the Momants/Website / application does not infringe upon or violate the rights of any third party. In the event of any action initiated against the Momants/Website / application by any such affected third party, the User hereby expressly agrees to indemnify and hold harmless the Momants, for its use of any such information provided to it by the User. The Momants reserves its right to defend itself in any such legal disputes that may arise, and recover the costs incurred in such proceedings from the User." +
      "17.	DISPUTE RESOLUTION AND JURISDICTION" +
      "It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of these Terms and any disputes/claim/refund/compensation arising here from will be resolved through a two-step Alternate Dispute Resolution (“ADR”) mechanism. It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy. " +
      "a)	Mediation: In case of any dispute/claim/refund/compensation between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed herein below; " +
      "b)	Arbitration. In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Momants, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. " +
      "The Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of Moments HQ country , and that the Courts at this city shall have exclusive jurisdiction over any disputes arising between the Parties. " +
      "18.	NOTICES" +
      "Any or all kind of communication relating to any dispute or grievance experienced by the User may be communicated to the Momants by the User reducing the same to writing, and sending the same to the registered office of the Momants by Registered Post Acknowledgement Due / Speed Post Acknowledgement Due (RPAD / SPAD)." +
      "19.	MISCELLANEOUS PROVISIONS" +
      "a)	Entire Agreement: These Terms, read with the Policy form the complete and final contract between the User and the Momants with respect to the subject matter hereof and supersedes all other communications, representations and agreements (whether oral, written or otherwise) relating thereto; " +
      "b)	Waiver: The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party's right at a later time to enforce the same. No waiver by either Party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms. " +
      "c)	Severability: If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision/clause of these Terms shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.",
    ar:
      ". عام" +
      'أ.	تم إنشاء هذا السجل الإلكتروني بواسطة نظام كمبيوتر ولا يتطلب أي توقيعات مادية أو رقمية. تشير كلمة "المنصة" في هذه المستندات إلى اسم النطاق www.momants.com ("موقع الويب") ، وتطبيقات IOS و Android بما في ذلك خدماتها عبر الإنترنت ، مثل استخدام البرامج لتقديم التطبيقات الاجتماعية ، حيث يمكن لأي شخص التسجيل والانضمام ومشاركة واستكشاف الأحداث الخاصة بهم التي يستضيفها أشخاص مثلهم. Momants ، توفر منصة إلكترونية ، حيث يمكن للمرء عرض وقائمة أنشطتهم في التطبيق ، سواء كانت شغفًا ، أو مهنيًا أو أعمالًا ، وغير ربحية في طبيعتها. الأحداث والمعلومات والنصوص والرسومات والصور والمعلومات التي تم الحصول عليها من مزودي الخدمة وأي موقع آخر مرتبط بهذا الموقع وأي مواد أخرى موجودة على الموقع ("المواد") المقدمة من موقع الويب مملوكة ومدارة من قبل Momants وممثلوها.' +
      "‌ب.	عناوين كل قسم في هذه الشروط هي فقط لغرض تنظيم الأحكام المختلفة بموجب هذه الشروط بطريقة منظمة ، ولا يجوز استخدامها من قبل أي من الطرفين لتفسير الأحكام الواردة هنا بأي طريقة. علاوة على ذلك ، اتفق الطرفان على وجه التحديد على أن العناوين لن يكون لها قيمة قانونية أو تعاقدية." +
      'ج.	يخضع استخدام الموقع الإلكتروني / التطبيقات من قبل المستخدم فقط لهذه الشروط بالإضافة إلى سياسة الخصوصية ("السياسة") ، المتاحة على موقع الويب وأي تعديلات أو تعديلات يتم إجراؤها عليها من قبل Momants من وقت لآخر ، في السلطة التقديرية وحدها. تعتبر زيارة الصفحة الرئيسية لموقع الويب و / أو استخدام أي من الخدمات المقدمة على موقع الويب / التطبيقات بمثابة دلالة على قبول المستخدم القاطع لهذه الشروط والسياسة المذكورة أعلاه ، ويوافق المستخدم صراحة على الالتزام بها. يوافق المستخدم ويقر صراحةً بأن الشروط والسياسات هي نهاية مشتركة ، وأن انتهاء / إنهاء أحدهما سيؤدي إلى إنهاء الآخر.' +
      "‌د.	يوافق المستخدم بشكل قاطع على أن هذه الشروط والسياسة المذكورة أعلاه تشكل اتفاقية ملزمة قانونًا بين المستخدم Momants ، وأن المستخدم يخضع للقواعد والمبادئ التوجيهية والسياسات والشروط والأحكام المطبقة على أي خدمة التي يوفرها الموقع الإلكتروني / التطبيقات ، والتي تعتبر نفسها مدرجة في هذه الشروط ، وتعامل كجزء لا يتجزأ منها. يقر المستخدم ويوافق على أنه لا يلزم توقيع أو إجراء صريح لجعل هذه الشروط والسياسة ملزمة للمستخدم ، وأن فعل المستخدم بزيارة أي جزء من الموقع يشكل موافقة المستخدم الكاملة والنهائية على هذه الشروط و السياسة المذكورة. إذا كنت لا توافق على أي من هذه الشروط والأحكام ، فلا يجب عليك الوصول إلى موقع الويب / التطبيق أو استخدامه." +
      'ه.	تحتفظ " Momants" بالحق الوحيد والحصري في تعديل أو تعديل هذه الشروط دون أي إذن أو تنبيه مسبق للمستخدم ، ويوافق المستخدم صراحةً على أن أي تعديلات من هذا القبيل ستدخل حيز التنفيذ على الفور. على المستخدم التحقق من الشروط بشكل دوري والبقاء على اطلاع على التغييرات. إذا استمر المستخدم في استخدام موقع الويب بعد هذا التغيير ، فسيعتبر المستخدم قد وافق على أي وجميع التعديلات التي أدخلت على الشروط. وبقدر امتثال المستخدم لهذه الشروط ، فإنه يمنح / لها امتيازًا شخصيًا غير حصري وغير قابل للتحويل وقابل للإلغاء ومحدود لدخول واستخدام الموقع.' +
      "و.	 تنطبق هذه الشروط والأحكام أيضًا على جميع المستخدمين المسجلين وكذلك المستخدمين غير المسجلين فيما يتعلق بجميع الخدمات أو الميزات الجديدة المقدمة على موقع الويب في المستقبل أيضًا." +
      "ز.	إما أن يتم تخصيص اسم المستخدم وكلمة المرور من قبل Momants أو اختيار المشترك من قبل المشترك أو من قبل Momants. أن المستخدم المصرح له فقط هو الذي يملك الحق في الوصول إلى الخدمات التي تقدمها Momants." +
      'ح.	تحتفظ " Momants" بحقها في الانسحاب كليًا أو جزئيًا ، دون تحديد أي سبب ، لأي من خدمات الموقع أو التطبيق أو الخصائص مع أو بدون أي إشعار.' +
      "3. الغرض من منصة Momants" +
      "إن محتوى صفحات هذا الموقع / التطبيق مخصص لمعلومات المستخدم العامة واستخدامها فقط. لا تقدم Momants نصيحة قانونية أو مهنية أو أي نصيحة أخرى ويجب على المستخدم دائمًا السعي للحصول على مشورة مستقلة قبل التصرف بناءً على أي معلومات على موقع الويب." +
      "أ.	يكون استخدام المستخدم لأية معلومات أو مواد على موقع الويب / التطبيق هذا على مسؤوليته الخاصة تمامًا ، والتي لن تكون Momants مسؤولة عنها. يتحمل المستخدم مسؤولية التأكد من أن أي خدمات أو معلومات متاحة من خلال هذا الموقع / التطبيق تلبي متطلباتها الخاصة." +
      "ب.	تلتزم Momants بكل الحرص في ضمان دقة جميع المعلومات المقدمة على موقع الويب / التطبيق ولكن Momants ليست مسؤولة عن أي خسارة يعاني منها المستخدم نتيجة أي إجراء يتخذه المستخدم أو يتجاهله على أساس أي من المعلومات التي تقدمها Momants." +
      "‌ج.	لا تكون Momants مسؤولة عن أي خسارة يتكبدها المستخدم من خلال استخدام موقع الويب / التطبيق مهما كان السبب ، بما في ذلك الخسارة التي تسببت في عدم إهمال Momants على سبيل المثال لا الحصر أو خرق العقد أو انتهاك حقوق النشر أو انتهاك حقوق الملكية الفكرية الأخرى أو التشهير." +
      "د.	Momants ليست مسؤولة عن المحتوى المنشور على الموقع / التطبيق من قبل مستخدمي الموقع / التطبيق. إذا كان أي من هذه المحتويات يبدو غير ملائم لـ Momants ، فإن Momants تحتفظ بالحق في إزالة مثل هذا المحتوى." +
      "ه.	لا يجوز للمستخدم أو نشر أي محتوى غير قانوني أو تشهيري أو فاحش أو مسيء أو ضار أو غير مرغوب فيه على الموقع / التطبيق." +
      "و.	لا يجوز لMomants بيع معلومات التعريف الشخصية للمستخدم أو تسويقها أو تداولها أو نقلها إلى أطراف خارجية. يجوز لـ Momants نشر معلومات المستخدم عندما يكون الإصدار مطلوبًا لامتثال القانون ، وفرض سياسات Momants ، وحماية حقوقه أو ممتلكاته أو سلامته أو حقوق الآخرين." +
      "ز.	يحتوي هذا الموقع / التطبيق على مواد مملوكة لشركة Momants. تتضمن هذه المواد ، على سبيل المثال لا الحصر ، التصميم والتخطيط والشكل والمظهر والرسومات. يحظر الاستنساخ إلا وفقًا لإشعار حقوق الطبع والنشر ، الذي يشكل جزءًا من هذه الشروط والأحكام." +
      "ح.	يجب على المستخدم الامتناع عن استخدام موقع الويب / التطبيقات بأي طريقة تتسبب في تلف موقع الويب / التطبيق أو بأي طريقة غير قانونية أو ضارة أو احتيالية. قد يؤدي أي استخدام من هذا الموقع / التطبيق إلى المطالبة بتعويضات و / أو يعتبر جريمة جنائية." +
      "‌ط.	يجب على المستخدم احترام استخدام موقع الويب / التطبيقات من قبل مستخدمين آخرين وعدم التدخل في استخدامهم المشروع لموقع الويب / التطبيق وخدمات Momants." +
      "ي.	يوافق المستخدم بموجب هذا على عدم نشر المواد بما في ذلك البريد العشوائي الذي يهدف عمداً إلى إزعاج المستخدمين الآخرين." +
      "ك.	من وقت لآخر ، قد يتضمن موقع الويب / التطبيق هذا أيضًا روابط لمواقع / تطبيقات أخرى. يتم توفير هذه الروابط لراحة المستخدم لتقديم المزيد من المعلومات. وإن ذلك لا يشير إلى أن Momants يؤيدون ما تحتويه هذه المواقع. وإن مواقع الأطراف الثالثة هذه لديها سياسات خصوصية منفصلة ومستقلة ، وبالتالي ، لا تتحمل Momants أية مسؤولية عن محتوى مواقع الويب المرتبطة." +
      'ل.	عند التسجيل في موقع / تطبيق Momants ، قد يُطلب من المستخدم إدخال اسمك وعنوان بريدك الإلكتروني وموقعك. قد تجمع Momants أيضًا معلومات حول كمبيوتر المستخدم وعنوان IP واستخدام هذا الموقع / التطبيق. يمكن استخدام أي من هذه المعلومات التي تجمعها "Momants" من المستخدم لتحسين موقعنا الإلكتروني أو لإدارة مسابقة أو ترويج أو استطلاع أو ميزة أخرى في الموقع.' +
      "م.	ستأخذ Momants كل العناية المعقولة والضرورية للحفاظ على سلامة المعلومات الشخصية للمستخدم عند إدخال أو تقديم أو الوصول إلى موقعنا / تطبيقنا." +
      "ن.	Momants لا تؤيد آراء المستخدمين ولا تؤكد أو تنكر بأي شكل الرأي المعبر عنه فيه ، ولا تعامل على أنها رأي Momants." +
      "س.	عندما ينضم المستخدم إلى Momants ، سنستخدم التفاصيل التي أدخلها في نموذج التسجيل لتزويد المستخدم بخدمات Momants المميزة. أثناء التسجيل ، يوافق المستخدم على تقديم الاسم الأول الحقيقي والاسم الأخير وعنوان البريد الإلكتروني والمعلومات الشخصية الحقيقية." +
      "ع.	يوافق المستخدم على الحفاظ على اسم المستخدم وكلمة المرور آمنين من الاستخدام من قبل أطراف ثالثة وعدم مشاركة تفاصيل التسجيل الخاصة بهم مع أي شخص آخر. سيكون المستخدم مسؤولاً عن تعويض Momants من أي استخدام غير مصرح به لاسم المستخدم وكلمة المرور ، مهما كان السبب." +
      "‌ف.	تحتفظ Momants الحق في حذف وتعطيل و / أو تقييد حساب المستخدم." +
      "‌ص.	على الرغم من أن Momants ستبذل قصارى جهدها لتوفير وصول مستمر وغير متقطع إلى الموقع / التطبيق ، فإننا لا نضمن ذلك ولا نقبل أي مسؤولية عن أي انقطاع أو تأخير." +
      "يمكن الإطلاع على باقي كامل بنود سياسة الإستخدام في النسخة الإنجليزية الموجودة على الموقع" +
      "www.momants.com"
  }
};

export default terms;
