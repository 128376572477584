import React, { Component } from "react";
import Search from "../../common/search";
import Paginate from "../../common/ant-design/pagination";
import lang from "../../../assets/lang";
import EmptyLabel from "../../common/ant-design/empty";
import Normal from "../cards/normalCard";
import Report from "../cards/reportCard";
import Block from "../cards/blockCard";
import { adminService } from "../../../services/adminService";
import { Loader } from "../../common/loader";

class Users extends Component {
  state = {
    users: [],
    usersCount: null,
    usersCurrPage: 1,
    loading: true,
    search: "",
    pageSize: 9,
  };
  componentDidMount() {
    this.getData("joined_at", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getData = (sort, filter, page) => {
    const { pageSize } = this.state;
    filter = this.cleanFilter(filter);
    this.setState({ filter: filter, loading: true, usersCurrPage: page });
    adminService.getUsersCards(filter, page, pageSize).then((res) => {
      this.setState({ users: res.data, loading: false });
    });
    adminService.getUsersCount(filter).then((res) => {
      this.setState({ usersCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ usersCurrPage: page });
    this.getData(this.state.sort, this.state.filter, page);
  };
  render() {
    const {
      users,
      search,
      pageSize,
      usersCurrPage,
      loading,
      usersCount,
    } = this.state;
    return (
      <div className="content">
        <Search
          value={search}
          onChange={this.getData}
          type="User"
          placeholder={lang.search[lang.ls()]}
          admin="User"
        />
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {usersCount === 0 ? (
              <EmptyLabel label={lang.noUsersFound[lang.ls()]} />
            ) : (
              <React.Fragment>
                {!loading && (
                  <p className="info-parag">
                    {usersCount} {lang.users[lang.ls()]}
                  </p>
                )}
                <div className="triple-grid">
                  {users.map((user) => {
                    const {
                      _id,
                      name,
                      username,
                      image,
                      isReported,
                      isBlocked,
                    } = user;
                    if (isBlocked) {
                      return (
                        <Block
                          name={name}
                          image={image}
                          username={username}
                          id={_id}
                          key={_id}
                        />
                      );
                    } else if (isReported) {
                      return (
                        <Report
                          name={name}
                          image={image}
                          username={username}
                          id={_id}
                          key={_id}
                        />
                      );
                    } else {
                      return (
                        <Normal
                          name={name}
                          image={image}
                          username={username}
                          id={_id}
                          key={_id}
                        />
                      );
                    }
                  })}
                </div>
                {usersCount > pageSize && !loading && (
                  <Paginate
                    def={usersCurrPage}
                    total={usersCount}
                    size={pageSize}
                    onChange={this.changePage}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Users;
