import React, { Component } from "react";
import Joi from "joi-browser";
import CheckBox from "./ant-design/checkbox";
import BasicInput from "./ant-design/input";
import BasicSelect from "./ant-design/select";
import MapContainer from "./maps/map";
import PicturesWall from "./ant-design/upload";
import AntTags from "./ant-design/tags";
import moment from "moment";
import { InputNumber, DatePicker, Input, Rate } from "antd";
import lang from "../../assets/lang";
import { Loader } from "./loader";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class Form extends Component {
  state = { data: {}, errors: {}, inputVisible: false, inputValue: "" };

  disabledDate = (current) => {
    return current && current < moment().subtract(1, "days").endOf("day");
  };
  handleImages = (image) => {
    const data = { ...this.state.data };
    data["images"].push(image);
    this.setState({ data });
  };
  handleRemoveImages = (id) => {
    const data = { ...this.state.data };
    data["images"] = data["images"].filter((obj) => {
      return obj.id !== id;
    });
    this.setState({ data });
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };
  handleDate = (e, name) => {
    const data = { ...this.state.data };
    data[name] = e._d.toISOString();
    this.setState({ data });
  };
  saveInputRef = (input) => (this.input = input);
  handleClose = (name, removedTag) => {
    const data = { ...this.state.data };
    data[name] = data[name].filter((obj) => obj !== removedTag);
    this.setState({ data });
  };
  handleInputConfirm = (name) => {
    const { inputValue } = this.state;
    const data = { ...this.state.data };
    if (inputValue && data[name].indexOf(inputValue) === -1) {
      data[name] = [...data[name], inputValue];
    }
    this.setState({
      data,
      inputVisible: false,
      inputValue: "",
    });
  };
  handleTerms = (name) => {
    const data = { ...this.state.data };
    data[name] = data[name] ? false : true;
    this.setState({ data });
  };
  handleMap = (e) => {
    const data = { ...this.state.data };
    data["lat"] = e.latLng.lat();
    data["lng"] = e.latLng.lng();
    this.setState({ data });
  };
  handlePlacing = (e) => {
    const data = { ...this.state.data };
    data["lat"] = e.geometry.location.lat();
    data["lng"] = e.geometry.location.lng();
    this.setState({ data });
  };
  handleSelect = (value, name) => {
    const data = { ...this.state.data };
    data[name] = value;

    const errors = { ...this.state.errors };

    const errorMsg = this.validateProperty({ name: name, value: value });

    if (errorMsg) errors[name] = errorMsg;
    else delete errors[name];

    this.setState({ data, errors });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    const errors = { ...this.state.errors };

    const errorMsg = this.validateProperty(input);

    if (errorMsg) errors[input.name] = errorMsg;
    else delete errors[input.name];

    this.setState({ data, errors });
  };
  increment = (e, name) => {
    const data = { ...this.state.data };
    data[name] = isNaN(e) ? 1 : e;
    this.setState({ data });
  };
  handleTiming = (e, start, end) => {
    const data = { ...this.state.data };
    data[start] = e[0]._d.toISOString();
    data[end] = e[1]._d.toISOString();
    this.setState({ data });
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const { error } = Joi.validate(obj, { [name]: this.schema[name] });
    return error ? error.details[0].message : null;
  };
  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }
    return errors;
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };
  renderButton({ label, icon }) {
    return (
      <Link
        to=""
        disabled={this.validate() || icon}
        onClick={this.handleSubmit}
        className="btn-ok"
      >
        {icon ? <Loader tiny margin /> : null}
        {label}
      </Link>
    );
  }
  renderInput({
    placeholder,
    classes,
    tooltip,
    icon,
    password,
    loading,
    disabled,
    name,
  }) {
    const { data } = this.state;
    return (
      <BasicInput
        placeholder={placeholder}
        classes={classes}
        tooltip={tooltip}
        icon={icon}
        password={password}
        loading={loading}
        disabled={disabled}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
      />
    );
  }
  renderSelect({ placeholder, icon, disabled, options, name }) {
    const { data } = this.state;
    return (
      <BasicSelect
        placeholder={placeholder}
        icon={icon}
        options={options}
        disabled={disabled}
        name={name}
        value={data[name]}
        onChange={(e) => this.handleSelect(e, name)}
      />
    );
  }
  renderCheckbox(name, label) {
    const { data } = this.state;
    return (
      <CheckBox
        name={name}
        onChange={() => this.handleTerms(name)}
        label={label}
        checked={data[name]}
      />
    );
  }
  renderInputNumber(name, minValue, defValue, disname) {
    const { data } = this.state;
    return (
      <InputNumber
        disabled={!data[disname]}
        name={name}
        value={data[name]}
        onChange={(e) => this.increment(e, name)}
        min={minValue}
        defaultValue={defValue}
      />
    );
  }
  renderRangePicker(start, end) {
    const { data } = this.state;
    const deftime = data[start]
      ? [moment.utc(data[start]), moment.utc(data[end])]
      : null;
    return (
      <RangePicker
        showTime={{ format: "h:mm A", use12Hours: true, minuteStep: 10 }}
        disabledDate={this.disabledDate}
        format="Do MMM YYYY - h:mm A"
        placeholder={[lang.startTiming[lang.ls()], lang.endTiming[lang.ls()]]}
        style={{ width: "100%" }}
        defaultValue={deftime}
        onChange={(e) => this.handleTiming(e, start, end)}
      />
    );
  }
  renderTextArea(name, placeholder) {
    const { data } = this.state;
    return (
      <TextArea
        placeholder={placeholder}
        name={name}
        autoSize={{ minRows: 3, maxRows: 5 }}
        value={data[name]}
        onChange={this.handleChange}
      />
    );
  }
  renderMap() {
    return (
      <MapContainer
        type="input"
        output={this.handleMap}
        placing={this.handlePlacing}
        lat={this.state.data.lat}
        lng={this.state.data.lng}
      />
    );
  }
  renderDatePicker(name, placeholder) {
    const { data } = this.state;
    const deftime = data[name] ? moment.utc(data[name]) : null;
    return (
      <DatePicker
        format="Do MMM YYYY"
        placeholder={placeholder}
        style={{ width: "100%" }}
        defaultValue={deftime}
        onChange={(e) => this.handleDate(e, name)}
        allowClear={false}
      />
    );
  }
  renderImageLoader() {
    return (
      <PicturesWall
        handleImages={this.handleImages}
        handleRemoveImages={this.handleRemoveImages}
        count={this.state.data.images.length}
      />
    );
  }
  renderTags(name) {
    return (
      <AntTags
        name={name}
        tags={this.state.data[name]}
        inputVisible={this.state.inputVisible}
        inputValue={this.state.inputValue}
        showInput={this.showInput}
        handleInputChange={this.handleInputChange}
        saveInputRef={this.saveInputRef}
        handleClose={this.handleClose}
        handleInputConfirm={this.handleInputConfirm}
      />
    );
  }
  renderRate(name) {
    return (
      <Rate
        onChange={(e) => this.handleSelect(e, name)}
        className="rate"
        name={name}
      />
    );
  }
}

export default Form;
