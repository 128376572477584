import React from "react";
import { Loader } from "./loader";
import EventCard from "./cards/eventcard";
import Paginate from "./ant-design/pagination";
import EmptyLabel from "./ant-design/empty";
import lang from "../../assets/lang";

const ListEvents = ({
  loading,
  events,
  eventCount,
  pageSize,
  page,
  changePage,
  cdis,
}) => {
  return (
    <React.Fragment>
      {!loading && !cdis && (
        <p className="info-parag">
          {eventCount} {lang.events[lang.ls()]}
        </p>
      )}
      {loading ? (
        <Loader />
      ) : eventCount === 0 ? (
        <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
      ) : (
        <div className="triple-grid">
          {events.map((event) => {
            return <EventCard key={event._id} myEvent={event} />;
          })}
        </div>
      )}
      {eventCount > pageSize && !loading && (
        <Paginate
          def={page}
          total={eventCount}
          size={pageSize}
          onChange={changePage}
        />
      )}
    </React.Fragment>
  );
};

export default ListEvents;
