import { wishlistTypes } from "../types";

export function wishlistManage(state = { wishlist: [] }, action) {
  switch (action.type) {
    case wishlistTypes.SET_WISHLIST:
      return {
        wishlist: action.data
      };
    case wishlistTypes.ADD_WISHLIST_REQUEST:
      return {
        ...state,
        adding: true
      };
    case wishlistTypes.ADD_WISHLIST_SUCCESS:
      return {
        wishlist: [action.data, ...state.wishlist]
      };
    case wishlistTypes.ADD_WISHLIST_FAILURE:
      return { ...state };
    case wishlistTypes.REMOVE_WISHLIST_REQUEST:
      return {
        ...state,
        removing: true
      };
    case wishlistTypes.REMOVE_WISHLIST_SUCCESS:
      return {
        wishlist: state.wishlist.filter(x => x !== action.data)
      };
    case wishlistTypes.REMOVE_WISHLIST_FAILURE:
      return { ...state };
    case wishlistTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
