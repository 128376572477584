import React, { Component } from "react";
import { Tabs } from "antd";
import "./conn.scss";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { userService } from "../../../services/userService";
import ListUsers from "../../common/usersList";
import { requestService } from "../../../services/requestService";
import Search from "../../common/search";
import { companyService } from "../../../services/companyService";
import { requestActions } from "../../../redux/actions/requestActions";
import { friendsActions } from "../../../redux/actions/friendsActions";
import { followActions } from "../../../redux/actions/followActions";
import { blockActions } from "../../../redux/actions/blockActions";
import _ from "lodash";
const { TabPane } = Tabs;

class ConnectionsUser extends Component {
  state = {
    friends: [],
    friendsCount: null,
    friendsLoad: true,
    followers: [],
    followersCount: null,
    followersLoad: true,
    followings: [],
    followingsCount: null,
    followingsLoad: true,
    requests: [],
    requestsCount: null,
    requestsLoad: true,
    users: [],
    usersLoad: true,
    usersCount: null,
    usersCurrPage: 1,
    companies: [],
    companiesLoad: true,
    companiesCount: null,
    companiesCurrPage: 1,
    blocked: [],
    blockedCount: null,
    blockedLoad: true,
    tab: "friends",
    pageSize: 12,
    temp: [],
    tempEn: false,
  };
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ tab: this.props.location.state.target }, () => {
        this.getUsers("joined_at", {}, 1);
      });
    } else {
      this.getUsers("joined_at", {}, 1);
    }
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getUsers = (sort, filter, page) => {
    const { tab, pageSize } = this.state;
    const { token, type } = this.props;
    filter = this.cleanFilter(filter);
    this.setState({ sort: sort, filter: filter });
    if (tab === "friends") {
      this.setState({ friendsLoad: true });
      userService.getFriends(token).then((res) => {
        this.setState({
          friends: res.data.friends,
          friendsLoad: false,
          friendsCount: res.data.friends.length,
        });
      });
    } else if (tab === "followers") {
      this.setState({ followersLoad: true });
      userService.getFollowers(token).then((res) => {
        const followers = [];
        res.data.followers.map((foll) => {
          followers.push(foll.id);
          return null;
        });
        this.setState({
          followers: followers,
          followersLoad: false,
          followersCount: res.data.followers.length,
        });
      });
    } else if (tab === "followings") {
      this.setState({ followingsLoad: true });
      userService.getFollowings(token).then((res) => {
        const followings = [];
        res.data.followings.map((foll) => {
          followings.push(foll.id);
          return null;
        });
        this.setState({
          followings: followings,
          followingsLoad: false,
          followingsCount: res.data.followings.length,
        });
      });
    } else if (tab === "requests") {
      this.setState({ requestsLoad: true });
      requestService.getFriendRequests(token).then((res) => {
        this.setState({
          requests: res.data,
          requestsLoad: false,
          requestsCount: res.data.length,
        });
      });
    } else if (tab === "blocked") {
      this.setState({ blockedLoad: true });
      userService.getBlocked(token).then((res) => {
        const blocked = [];
        res.data.blocked.map((bloc) => {
          blocked.push(bloc.id);
          return null;
        });
        this.setState({
          blocked: blocked,
          blockedLoad: false,
          blockedCount: res.data.blocked.length,
        });
      });
    } else if (tab === "users") {
      this.setState({ usersLoad: true });
      userService.getUsers(token, type, filter, page, pageSize).then((res) => {
        this.setState({ users: res.data, usersLoad: false });
      });
      userService.getUsersCount(token, type, filter).then((res) => {
        this.setState({ usersCount: res.data.count });
      });
    } else if (tab === "companies") {
      this.setState({ companiesLoad: true });
      companyService
        .getCompanies(token, type, filter, page, pageSize)
        .then((res) => {
          this.setState({ companies: res.data, companiesLoad: false });
        });
      companyService.getCompaniesCount(token, type, filter).then((res) => {
        this.setState({ companiesCount: res.data.count });
      });
    }
  };
  searchOthers = (sort, filter, page) => {
    const { tab, friends, followers, followings } = this.state;
    filter = this.cleanFilter(filter);
    let data = [];
    if (tab === "friends") {
      data = [...friends];
    } else if (tab === "followers") {
      data = [...followers];
    } else if (tab === "followings") {
      data = [...followings];
    }
    if (_.isEmpty(filter)) {
      this.setState({ tempEn: false });
    } else {
      this.setState({ tempEn: true });
      if (filter.name) {
        data = data.filter((obj) => {
          return (
            obj.name.toLowerCase().includes(filter.name) ||
            obj.auth.username.toLowerCase().includes(filter.name)
          );
        });
        delete filter.name;
      }
      this.setState({ temp: data });
    }
  };
  changePage = (page) => {
    const { tab } = this.state;
    if (tab === "users") {
      this.setState({ usersCurrPage: page });
    } else {
      this.setState({ companiesCurrPage: page });
    }
    this.getUsers(this.state.sort, this.state.filter, page);
  };
  changeTab = (e) => {
    this.setState({ tab: e, usersCurrPage: 1, companiesCurrPage: 1 }, () => {
      this.getUsers(this.state.sort, this.state.filter, 1);
    });
  };
  render() {
    const {
      friends,
      friendsCount,
      friendsLoad,
      followers,
      followersCount,
      followersLoad,
      followings,
      followingsCount,
      followingsLoad,
      requests,
      requestsCount,
      requestsLoad,
      users,
      usersLoad,
      usersCount,
      usersCurrPage,
      companies,
      companiesLoad,
      companiesCount,
      companiesCurrPage,
      blocked,
      blockedCount,
      blockedLoad,
      tab,
      pageSize,
      temp,
      tempEn,
    } = this.state;
    return (
      <div className="content inner-tabs">
        <Tabs
          activeKey={tab}
          onChange={this.changeTab}
          style={{ direction: "ltr" }}
        >
          <TabPane tab={lang.friends[lang.ls()]} key="friends">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              type="Firm"
              filterDis
              placeholder={lang.search[lang.ls()]}
            />
            <ListUsers
              loading={friendsLoad}
              users={!tempEn ? friends : temp}
              userCount={!tempEn ? friends.length : temp.length}
              empty={lang.noFriendsFound[lang.ls()]}
              count={lang.friends[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.followers[lang.ls()]} key="followers">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              type="Firm"
              filterDis
              placeholder={lang.search[lang.ls()]}
            />
            <ListUsers
              loading={followersLoad}
              users={!tempEn ? followers : temp}
              userCount={!tempEn ? followers.length : temp.length}
              empty={lang.noFollowersFound[lang.ls()]}
              count={lang.followers[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.following[lang.ls()]} key="followings">
            <Search
              onChange={this.searchOthers}
              filter={this.state.filter}
              type="Firm"
              filterDis
              placeholder={lang.search[lang.ls()]}
            />
            <ListUsers
              loading={followingsLoad}
              users={!tempEn ? followings : temp}
              userCount={!tempEn ? followings.length : temp.length}
              empty={lang.noFollowingsFound[lang.ls()]}
              count={lang.following[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.requests[lang.ls()]} key="requests">
            <ListUsers
              loading={requestsLoad}
              users={requests}
              userCount={requestsCount}
              type="request"
              empty={lang.noFriendRequestsFound[lang.ls()]}
              count={lang.requests[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.users[lang.ls()]} key="users">
            <Search
              onChange={this.getUsers}
              filter={this.state.filter}
              type="User"
              placeholder={lang.search[lang.ls()]}
            />
            <ListUsers
              loading={usersLoad}
              users={users}
              userCount={usersCount}
              pageSize={pageSize}
              changePage={this.changePage}
              page={usersCurrPage}
              empty={lang.noUsersFound[lang.ls()]}
              count={lang.users[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.companies[lang.ls()]} key="companies">
            <Search
              onChange={this.getUsers}
              filter={this.state.filter}
              placeholder={lang.search[lang.ls()]}
            />
            <ListUsers
              loading={companiesLoad}
              users={companies}
              userCount={companiesCount}
              pageSize={pageSize}
              changePage={this.changePage}
              page={companiesCurrPage}
              empty={lang.noCompaniesFound[lang.ls()]}
              count={lang.companies[lang.ls()]}
            />
          </TabPane>
          <TabPane tab={lang.blocked[lang.ls()]} key="blocked">
            <ListUsers
              loading={blockedLoad}
              users={blocked}
              userCount={blockedCount}
              type="block"
              empty={lang.noBlockedFound[lang.ls()]}
              count={lang.blocked[lang.ls()]}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

const mapDispatchToProps = {
  setRequests: requestActions.setRequests,
  setFriends: friendsActions.setFriends,
  setFollows: followActions.setFollows,
  setBlocks: blockActions.setBlocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsUser);
