import React, { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import { adminService } from "../../../services/adminService";
import { Loader } from "../../common/loader";
import lang from "../../../assets/lang";
import BasicInput from "../../common/ant-design/input";
import CheckBox from "../../common/ant-design/checkbox";
import { success } from "../../../utils/helperMethods";
import { connect } from "react-redux";
const { confirm } = Modal;
var pattern = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const AdminTable = ({ roles }) => {
  const [rolesDoc, setRolesDoc] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [loadAdmins, setLoadAdmins] = useState(true);
  const [registerMod, setRegisterMod] = useState(false);
  const [rolesMod, setRolesMod] = useState(false);
  const [rolesLoad, setRolesLoad] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [emailText, setEmailText] = useState("");
  const [nameText, setNameText] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoadAdmins(true);
    adminService.getAccounts().then((res) => {
      let i = 1;
      const accounts = res.data;
      let result = [];
      accounts.map((acc) => {
        result.push({
          key: i.toString(),
          name: acc.name,
          address: acc.email,
          isAdmin: acc.isAdmin,
          id: acc._id,
        });
        i++;
        return null;
      });
      setData(result);
      setLoadAdmins(false);
    });
  }, []);
  const deleteAdmin = (id) => {
    let obj = [...data];
    confirm({
      title: lang.deleteAdminTitle[lang.ls()],
      onOk: () => {
        return adminService.deleteAdmin(id).then((res) => {
          obj = obj.filter((item) => {
            return item.id !== id;
          });
          setData(obj);
          success(lang.adminDeleted[lang.ls()]);
        });
      },
      onCancel() {},
    });
  };
  const columns = [
    {
      title: lang.fullName[lang.ls()],
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: lang.email[lang.ls()],
      dataIndex: "address",
      key: "address",
      width: "40%",
    },
    {
      title: () => {
        return roles.signUp ? (
          <div
            style={{ margin: "0", padding: "3px 1rem", cursor: "pointer" }}
            className="btn-ok"
            onClick={editRegister}
          >
            {lang.createAccount[lang.ls()]}
          </div>
        ) : null;
      },
      key: "action",
      width: "30%",
      render: (record) => (
        <span>
          {!record.isAdmin && roles.admins && (
            <React.Fragment>
              <div
                onClick={() =>
                  editRoles(record.id, record.name, record.address)
                }
                className="edit-admin"
              >
                <i className="fas fa-user-edit"></i>
              </div>
              <div
                onClick={() => deleteAdmin(record.id)}
                className="edit-admin del"
              >
                <i className="fas fa-user-times"></i>
              </div>
            </React.Fragment>
          )}
        </span>
      ),
      align: "center",
    },
  ];
  const validateEmail = (e) => {
    setEmailText(e.target.value);
    if (e.target.value.length >= 5 && pattern.test(e.target.value)) {
      setEmailCheck(true);
      adminService.validateEmail(e.target.value).then((res) => {
        setEmailValid(res.data.valid);
        setEmailCheck(false);
      });
    } else {
      setEmailValid(false);
      setEmailCheck(false);
    }
  };
  const validateName = (e) => {
    setNameText(e.target.value);
  };
  const cancelRoles = () => {
    setEmailText("");
    setNameText("");
    setRolesDoc({});
    setError("");
    setEmailValid(null);
    setRolesMod(false);
  };
  const editRoles = (id, name, email) => {
    setRolesMod(true);
    setRolesLoad(true);
    setNameText(name);
    setEmailText(email);
    setId(id);
    adminService.getAccountRoles(id).then((res) => {
      setRolesDoc(res.data);
      setRolesLoad(false);
    });
  };
  const cancelRegister = () => {
    setRegisterMod(false);
  };
  const editRegister = () => {
    setRegisterMod(true);
  };
  const inputNewPass = (e) => {
    setNewPass(e.target.value);
  };
  const inputConfPass = (e) => {
    setConfPass(e.target.value);
  };
  const handleRoles = (name) => {
    let result = { ...rolesDoc };
    if (name === "signUp") result.signUp = !result.signUp ? true : false;
    if (name === "admins") result.admins = !result.admins ? true : false;
    if (name === "catching") result.catching = !result.catching ? true : false;
    if (name === "editEvent")
      result.editEvent = !result.editEvent ? true : false;
    if (name === "deleteEvent")
      result.deleteEvent = !result.deleteEvent ? true : false;
    if (name === "blockUsers")
      result.blockUsers = !result.blockUsers ? true : false;
    setRolesDoc(result);
  };
  const submitRegister = () => {
    setLoading(true);
    setError("");
    if (newPass.length < 8 || confPass < 8) {
      setLoading(false);
      setError(lang.passValid[lang.ls()]);
    } else if (newPass !== confPass) {
      setLoading(false);
      setError(lang.twoPassValid[lang.ls()]);
    } else {
      adminService
        .registerAdmin(
          {
            name: nameText,
            email: emailText,
            password: newPass,
            ...rolesDoc,
          },
          lang.ls()
        )
        .then((res) => {
          let result = [...data];
          result.push({
            key: (result.length + 1).toString(),
            name: nameText,
            address: emailText,
            isAdmin: false,
            id: res.data._id,
          });
          setData(result);
          setRegisterMod(false);
          setLoading(false);
          setEmailText("");
          setNameText("");
          setRolesDoc({});
          setError("");
          setEmailValid(null);
          setNewPass("");
          setConfPass("");
          success("Admin Account Registered Successfully...");
        });
    }
  };
  const submitRoles = () => {
    setLoading(true);
    setError("");
    adminService.updateRoles(id, rolesDoc).then((res) => {
      setRolesMod(false);
      setRolesDoc({});
      setEmailText("");
      setNameText("");
      setLoading(false);
      setRolesMod(false);
      setId("");
      success("Account Roles Updated Successfully...");
    });
  };
  return (
    <div className="admins">
      {loadAdmins ? (
        <Loader />
      ) : (
        <Table
          style={{ direction: "ltr" }}
          pagination={{
            pageSize: 5,
          }}
          columns={columns}
          dataSource={data}
        />
      )}
      <Modal
        title={lang.register[lang.ls()]}
        visible={registerMod}
        onOk={submitRegister}
        onCancel={cancelRegister}
        okButtonProps={{ disabled: !emailValid || !nameText }}
      >
        {error ? <div className="alert">{error}</div> : null}
        <BasicInput
          placeholder={lang.fullName[lang.ls()]}
          value={nameText}
          onChange={validateName}
        />
        <div className="validate">
          <BasicInput
            placeholder={lang.email[lang.ls()]}
            tooltip={lang.emailUnique[lang.ls()]}
            value={emailText}
            onChange={validateEmail}
            classes="sufix-input"
          />
          <div className="indicator">
            {emailCheck ? (
              <Loader tinycolor />
            ) : emailValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : emailValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        <BasicInput
          placeholder={lang.passPlace[lang.ls()]}
          value={newPass}
          onChange={inputNewPass}
          password={true}
          classes="sufix-input"
        />
        <BasicInput
          placeholder={lang.rePass[lang.ls()]}
          value={confPass}
          onChange={inputConfPass}
          password={true}
          classes="sufix-input"
        />
        <div className="check-boxs">
          <div className="head">{lang.roles[lang.ls()]}</div>
          <CheckBox
            onChange={() => handleRoles("signUp")}
            label={lang.signUpRole[lang.ls()]}
            checked={rolesDoc["signUp"]}
          />
          <CheckBox
            onChange={() => handleRoles("admins")}
            label={lang.adminsRole[lang.ls()]}
            checked={rolesDoc["admins"]}
          />
          <CheckBox
            onChange={() => handleRoles("catching")}
            label={lang.catchingRole[lang.ls()]}
            checked={rolesDoc["catching"]}
          />
          <CheckBox
            onChange={() => handleRoles("editEvent")}
            label={lang.editEventRole[lang.ls()]}
            checked={rolesDoc["editEvent"]}
          />
          <CheckBox
            onChange={() => handleRoles("deleteEvent")}
            label={lang.deleteEventRole[lang.ls()]}
            checked={rolesDoc["deleteEvent"]}
          />
          <CheckBox
            onChange={() => handleRoles("blockUsers")}
            label={lang.blockUsersRole[lang.ls()]}
            checked={rolesDoc["blockUsers"]}
          />
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
      <Modal
        title={lang.register[lang.ls()]}
        visible={rolesMod}
        onOk={submitRoles}
        onCancel={cancelRoles}
      >
        <div className="head">{nameText}</div>
        <div className="head">{emailText}</div>
        <div className="check-boxs">
          <div className="head">{lang.roles[lang.ls()]}</div>
          {rolesLoad ? (
            <Loader />
          ) : (
            <React.Fragment>
              <CheckBox
                onChange={() => handleRoles("signUp")}
                label={lang.signUpRole[lang.ls()]}
                checked={rolesDoc["signUp"]}
              />
              <CheckBox
                onChange={() => handleRoles("admins")}
                label={lang.adminsRole[lang.ls()]}
                checked={rolesDoc["admins"]}
              />
              <CheckBox
                onChange={() => handleRoles("catching")}
                label={lang.catchingRole[lang.ls()]}
                checked={rolesDoc["catching"]}
              />
              <CheckBox
                onChange={() => handleRoles("editEvent")}
                label={lang.editEventRole[lang.ls()]}
                checked={rolesDoc["editEvent"]}
              />
              <CheckBox
                onChange={() => handleRoles("deleteEvent")}
                label={lang.deleteEventRole[lang.ls()]}
                checked={rolesDoc["deleteEvent"]}
              />
              <CheckBox
                onChange={() => handleRoles("blockUsers")}
                label={lang.blockUsersRole[lang.ls()]}
                checked={rolesDoc["blockUsers"]}
              />
            </React.Fragment>
          )}
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.admin.roles,
  };
};

export default connect(mapStateToProps, null)(AdminTable);
