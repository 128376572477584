import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/companies";

export const companyService = {
  changeCode,
  getFollowings,
  getFollowers,
  getOtherFollowings,
  getOtherFollowers,
  getCompanies,
  getCompaniesCount,
  getBlocked,
  followAccount,
  unfollowAccount,
  getFollowIds,
  block,
  unblock,
  getBlockedIds,
  getBlockedByIds,
  getCompany,
  updateCompany,
  checkCode,
};
function changeCode(token, code) {
  return axios.put(endPoint + "/change-code", null, {
    params: {
      token,
      code,
    },
  });
}
function getFollowings(token) {
  return axios.get(endPoint + "/followings", {
    params: {
      token,
    },
  });
}
function getFollowers(token) {
  return axios.get(endPoint + "/followers", {
    params: {
      token,
    },
  });
}
function getOtherFollowings(token, type, id) {
  return axios.get(endPoint + "/other-followings", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getOtherFollowers(token, type, id) {
  return axios.get(endPoint + "/other-followers", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getCompanies(token, type, filter, page, limit) {
  return axios.get(endPoint + "/", {
    params: {
      ...filter,
      limit,
      page,
      token,
      type,
    },
  });
}
function getCompaniesCount(token, type, filter) {
  return axios.get(endPoint + "/companies-count", {
    params: {
      ...filter,
      token,
      type,
    },
  });
}
function getBlocked(token) {
  return axios.get(endPoint + "/blocked", {
    params: {
      token,
    },
  });
}
function followAccount(token, type, target) {
  return axios.put(endPoint + "/follow", null, {
    params: {
      token,
      type,
      target,
    },
  });
}
function unfollowAccount(token, type, target) {
  return axios.put(endPoint + "/unfollow", null, {
    params: {
      token,
      type,
      target,
    },
  });
}
function getFollowIds(token) {
  return axios.get(endPoint + "/followings-ids", {
    params: {
      token,
    },
  });
}
function block(token, user) {
  return axios.put(
    endPoint + "/block",
    { user },
    {
      params: {
        token,
      },
    }
  );
}
function unblock(token, user) {
  return axios.put(
    endPoint + "/unblock",
    { user },
    {
      params: {
        token,
      },
    }
  );
}
function getBlockedIds(token) {
  return axios.get(endPoint + "/blocked-ids", {
    params: {
      token,
    },
  });
}
function getBlockedByIds(token) {
  return axios.get(endPoint + "/blockedBy-ids", {
    params: {
      token,
    },
  });
}
function getCompany(token) {
  return axios.get(endPoint + "/getCompany", {
    params: {
      token,
    },
  });
}
function updateCompany(token, company) {
  return axios.put(endPoint + "/", company, {
    params: {
      token,
    },
  });
}
function checkCode(code) {
  return axios.post(endPoint + "/check-code", null, {
    params: {
      code,
    },
  });
}
