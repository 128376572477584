import React, { Component } from "react";
import "./events.scss";
import lang from "../../../assets/lang";
import { eventService } from "../../../services/eventService";
import { connect } from "react-redux";
import ListEvents from "../../common/eventslist";
import Search from "../../common/search";

class EventsCompany extends Component {
  state = {
    myEvents: [],
    myeventsLoad: true,
    myEventsCount: null,
    myEventsCurrPage: 1,
    pageSize: 12,
  };
  componentDidMount() {
    this.getEvents("created_at", {}, 1);
  }
  cleanFilter = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };
  getEvents = (sort, filter, page) => {
    const { pageSize } = this.state;
    const { token } = this.props;
    filter = this.cleanFilter(filter);
    this.setState({ sort: sort, filter: filter });
    this.setState({ myeventsLoad: true });
    eventService
      .getMyEvents(token, sort, filter, page, pageSize)
      .then((res) => {
        this.setState({ myEvents: res.data, myeventsLoad: false });
      });
    eventService.getMyEventsCount(token, filter).then((res) => {
      this.setState({ myEventsCount: res.data.count });
    });
  };
  changePage = (page) => {
    this.setState({ myEventsCurrPage: page });
    this.getEvents(this.state.sort, this.state.filter, page);
  };
  render() {
    const {
      myEvents,
      myeventsLoad,
      myEventsCount,
      myEventsCurrPage,
      pageSize,
    } = this.state;
    return (
      <div className="content inner-tabs">
        <Search
          onChange={this.getEvents}
          filter={this.state.filter}
          sort={this.state.sort}
          type="Event"
          placeholder={lang.searchEvents[lang.ls()]}
        />
        <ListEvents
          loading={myeventsLoad}
          events={myEvents}
          eventCount={myEventsCount}
          pageSize={pageSize}
          changePage={this.changePage}
          page={myEventsCurrPage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

export default connect(mapStateToProps, null)(EventsCompany);
