import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authentication } from "./userReducer";
import { alert } from "./alertReducer";
import { registration } from "./registerReducer";
import { wishlistManage } from "./wishlistReducer";
import { friendsManage } from "./friendsReducer";
import { requestManage } from "./requestReducer";
import { followManage } from "./followingReducer";
import { blockManage } from "./blockReducer";
import { chatManage } from "./chatReducer";
import { adminManage } from "./adminReducer";
import { notifManage } from "./notifReducer";

const persistConfig = {
  key: "evenzt",
  storage,
  whitelist: [
    "login",
    "notif",
    "admin",
    "wishlist",
    "friend",
    "request",
    "follow",
    "block",
    "chat",
  ],
};

const rootReducer = combineReducers({
  login: authentication,
  notif: notifManage,
  admin: adminManage,
  register: registration,
  follow: followManage,
  block: blockManage,
  wishlist: wishlistManage,
  friend: friendsManage,
  request: requestManage,
  chat: chatManage,
  alert,
});

export default persistReducer(persistConfig, rootReducer);
