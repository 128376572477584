import React from "react";
import { connect } from "react-redux";
import moment from "moment";

const MessageItem = ({ chat, user }) => {
  const classes = user._id === chat.sender.id ? "own-mess" : "other-mess";
  const classesT = user._id === chat.sender.id ? "own-time" : "other-time";
  return (
    <React.Fragment>
      <div className={classes}>
        {chat.message}
        <div className={classesT}>
          {moment(chat.createdAt).format("Do MMM hh:mm a")}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

export default connect(mapStateToProps, null)(MessageItem);
