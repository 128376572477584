import React from "react";
import { Upload, Icon, Modal } from "antd";
import lang from "../../../assets/lang";
import { eventService } from "../../../services/eventService";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    fileList: [],
    stated: false,
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = (info) => {
    this.setState({ stated: true });
    if (info.file.status === "done") {
      this.props.handleImages(info.file.response);
      this.setState({ stated: false });
    }
    this.setState({ fileList: info.fileList });
  };

  handleRemove = (info) => {
    this.setState({ stated: false });
    if (info.response.id) {
      eventService.removeImage(info.response.id).then((res) => {
        this.setState({ stated: false });
        this.props.handleRemoveImages(info.response.id);
      });
    } else {
      setTimeout(() => {
        this.setState({ stated: false });
      }, 2000);
    }
  };

  render() {
    const { previewVisible, previewImage, fileList, stated } = this.state;
    const { count } = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{lang.upload[lang.ls()]}</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          name="image"
          accept=".jpg, .png, .jpeg"
          action={`${process.env.REACT_APP_API_URL}/events/add-image`}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
        >
          {fileList.length >= 3 - count + fileList.length || stated
            ? null
            : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PicturesWall;
