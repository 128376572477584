import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import EditUserProfile from "../login/profiles/editUserProfile";
import EditCompanyProfile from "../login/profiles/editCompanyProfile";

const EditProfileRoute = ({ type, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      type === "User" ? <EditUserProfile /> : <EditCompanyProfile />
    }
  />
);

const mapStateToProps = state => {
  return {
    type: state.login.type
  };
};

export default connect(mapStateToProps, null)(EditProfileRoute);
