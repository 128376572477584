import React, { Component } from "react";
import LabelSelect from "./ant-design/labelselect";
import { selectService } from "../../services/selectService";
import lang from "../../assets/lang";
import Sorting from "./ant-design/sorting";
import { Loader } from "./loader";
import { Select } from "antd";
const { Option } = Select;

class Search extends Component {
  state = {
    classes: "filter",
    categories: [],
    cities: [],
    privacy: [
      {
        _id: true,
        name: {
          en: "Public",
          ar: "عام",
        },
      },
      {
        _id: false,
        name: {
          en: "Private",
          ar: "خاص",
        },
      },
    ],
    gender: [
      {
        _id: "Male",
        name: {
          en: "Male",
          ar: "ذكر",
        },
      },
      {
        _id: "Female",
        name: {
          en: "Female",
          ar: "انثى",
        },
      },
    ],
    usersSelect: [
      {
        _id: "Normal",
        name: {
          en: "Normal Users",
          ar: "المستخدمين العاديين",
        },
      },
      {
        _id: "Reported",
        name: {
          en: "Reported Users",
          ar: "تم تقريرهم",
        },
      },
      {
        _id: "Blocked",
        name: {
          en: "Blocked Users",
          ar: "المحظورين",
        },
      },
    ],
    companiesSelect: [
      {
        _id: "Normal",
        name: {
          en: "Normal Companies",
          ar: "الشركات العادية",
        },
      },
      {
        _id: "Reported",
        name: {
          en: "Reported Companies",
          ar: "تم تقريرهم",
        },
      },
      {
        _id: "Blocked",
        name: {
          en: "Blocked Companies",
          ar: "المحظورين",
        },
      },
    ],
    eventsSelect: [
      {
        _id: "NormalE",
        name: {
          en: "Normal Events",
          ar: "الفعاليات العادية",
        },
      },
      {
        _id: "ReportedE",
        name: {
          en: "Reported Events",
          ar: "تم تقريرهم",
        },
      },
      {
        _id: "Catched",
        name: {
          en: "Catched Events",
          ar: "الفعاليات الملتقطة",
        },
      },
      {
        _id: "Finished",
        name: {
          en: "Finished Events",
          ar: "الفعاليات المنتهية",
        },
      },
      {
        _id: "Archived",
        name: {
          en: "Archived Events",
          ar: "الفعاليات المؤرشفة",
        },
      },
    ],
    filter: { name: "" },
    select: {},
    sort: "created_at",
    filterLoad: true,
  };
  componentDidMount() {
    selectService.getCities().then((res) => {
      const cities = res.data;
      this.setState({ cities });
      selectService.getCategories().then((res) => {
        const categories = res.data;
        this.setState({ categories });
        this.setState({ filterLoad: false });
      });
    });
  }
  handleChange = (value, name) => {
    const filter = { ...this.state.filter };
    filter[name] = value;
    this.setState({ filter });
    this.props.onChange(
      this.state.sort,
      { ...filter, ...this.state.select },
      1
    );
  };
  handleSort = (value) => {
    this.setState({ sort: value });
    this.props.onChange(
      value,
      { ...this.state.filter, ...this.state.select },
      1
    );
  };
  handleFilter = () => {
    let { classes } = this.state;
    classes = classes === "filter" ? "filter-active" : "filter";
    this.setState({ classes });
  };
  handleSelect = (value) => {
    const select = {};
    if (value === "Normal") {
      select["isReported"] = false;
      select["isBlocked"] = false;
    } else if (value === "Reported") {
      select["isReported"] = true;
      select["isBlocked"] = false;
    } else if (value === "NormalE") {
      select["isReported"] = false;
      select["isCatched"] = false;
      select["isFinished"] = false;
      select["isArchived"] = false;
    } else if (value === "ReportedE") {
      select["isReported"] = true;
      select["isCatched"] = false;
    } else if (value === "Blocked") {
      select["isBlocked"] = true;
    } else if (value === "Catched") {
      select["isCatched"] = true;
    } else if (value === "Finished") {
      select["isFinished"] = true;
    } else if (value === "Archived") {
      select["isArchived"] = true;
    }
    this.setState({ select });
    this.props.onChange(
      this.state.sort,
      { ...this.state.filter, ...select },
      1
    );
  };
  render() {
    const {
      classes,
      categories,
      cities,
      privacy,
      gender,
      filter,
      filterLoad,
      usersSelect,
      eventsSelect,
      companiesSelect,
    } = this.state;
    const { type, placeholder, filterDis, admin } = this.props;
    return (
      <React.Fragment>
        <div className="search">
          {/* <i className="fa fa-search"></i> */}
          {filterLoad && !filterDis ? (
            <Loader tinycolor />
          ) : !filterDis ? (
            <i onClick={this.handleFilter} className="fa fa-filter"></i>
          ) : null}
          <input
            type="text"
            name="search"
            className="search-bar"
            placeholder={placeholder}
            value={filter.name}
            onChange={(e) => this.handleChange(e.currentTarget.value, "name")}
          />
          {admin === "User" ? (
            <Select defaultValue="" onChange={this.handleSelect}>
              <Option value="">{lang.all[lang.ls()]}</Option>
              {usersSelect.map((option) => {
                return (
                  <Option key={option._id} value={option._id}>
                    {option.name ? option.name[lang.ls()] : option}
                  </Option>
                );
              })}
            </Select>
          ) : admin === "Event" ? (
            <Select defaultValue="" onChange={this.handleSelect}>
              <Option value="">{lang.all[lang.ls()]}</Option>
              {eventsSelect.map((option) => {
                return (
                  <Option key={option._id} value={option._id}>
                    {option.name ? option.name[lang.ls()] : option}
                  </Option>
                );
              })}
            </Select>
          ) : admin === "Company" ? (
            <Select defaultValue="" onChange={this.handleSelect}>
              <Option value="">{lang.all[lang.ls()]}</Option>
              {companiesSelect.map((option) => {
                return (
                  <Option key={option._id} value={option._id}>
                    {option.name ? option.name[lang.ls()] : option}
                  </Option>
                );
              })}
            </Select>
          ) : null}
        </div>
        <div className={classes}>
          {type === "Event" ? (
            <div className="filter-elem">
              <LabelSelect
                label={lang.category[lang.ls()]}
                name="category"
                options={categories}
                onChange={this.handleChange}
              />
              <LabelSelect
                label={lang.city[lang.ls()]}
                name="city"
                options={cities}
                onChange={this.handleChange}
              />
              <LabelSelect
                label={lang.privacy[lang.ls()]}
                name="isPublic"
                options={privacy}
                onChange={this.handleChange}
              />
              <Sorting onChange={this.handleSort} />
            </div>
          ) : (
            <div className="filter-elem">
              <LabelSelect
                label={lang.city[lang.ls()]}
                name="city"
                options={cities}
                onChange={this.handleChange}
              />
              {type === "User" ? (
                <React.Fragment>
                  <LabelSelect
                    label={lang.gender[lang.ls()]}
                    name="gender"
                    options={gender}
                    onChange={this.handleChange}
                  />
                </React.Fragment>
              ) : null}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Search;
