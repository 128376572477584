import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/auth";

export const authService = {
  login,
  register,
  getProfile,
  getOtherProfile,
  searchChats,
  validateEmail,
  validateUsername,
  getSettings,
  changeNotif,
  changeEmail,
  changePassword,
  forgotPassword,
  verTempToken,
  resetPassword,
  globalSearch,
  checkBlock,
};
function globalSearch(token, type, name) {
  return axios.get(endPoint + "/global-search", {
    params: {
      name,
      token,
      type,
    },
  });
}
function login(email, password) {
  return axios.post(endPoint + "/login", {
    email: email,
    password: password,
  });
}
function register(user, profile, lang) {
  return axios.post(
    endPoint + "/register",
    {
      user,
      profile,
    },
    {
      params: {
        lang,
      },
    }
  );
}
function getProfile(token, type) {
  return axios.get(endPoint + "/profile", {
    params: {
      token,
      type,
    },
  });
}
function getOtherProfile(id, type) {
  return axios.get(endPoint + "/other-profile", {
    params: {
      id,
      type,
    },
  });
}
function searchChats(token, type, name) {
  return axios.get(endPoint + "/chat-search", {
    params: {
      name,
      token,
      type,
    },
  });
}
function validateEmail(email) {
  return axios.post(endPoint + "/check-email", null, {
    params: {
      email,
    },
  });
}
function validateUsername(username) {
  return axios.post(endPoint + "/check-username", null, {
    params: {
      username,
    },
  });
}
function getSettings(token, type) {
  return axios.get(endPoint + "/settings", {
    params: {
      type,
      token,
    },
  });
}
function changeNotif(id, notif) {
  return axios.put(endPoint + "/notif", null, {
    params: {
      id,
      notif,
    },
  });
}
function changeEmail(id, email, name, lang) {
  return axios.put(endPoint + "/email", null, {
    params: {
      id,
      email,
      name,
      lang,
    },
  });
}
function changePassword(auth, oldPass, password) {
  return axios.put(endPoint + "/change-password", null, {
    params: {
      auth,
      oldPass,
      password,
    },
  });
}
function forgotPassword(email, lang) {
  return axios.put(endPoint + "/forgot-pass", null, {
    params: {
      email,
      lang,
    },
  });
}
function verTempToken(token) {
  return axios.put(endPoint + `/ver-token/${token}`);
}
function resetPassword(id, password) {
  return axios.put(endPoint + "/reset-pass", null, {
    params: {
      id,
      password,
    },
  });
}
function checkBlock(token, type) {
  return axios.get(endPoint + "/check-block", {
    params: {
      token,
      type,
    },
  });
}
