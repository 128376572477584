import React, { useState } from "react";
import { getImage } from "../../../utils/helperMethods";
import { eventService } from "../../../services/eventService";
import lang from "../../../assets/lang";

const JoinRequestCard = ({ event, user, token }) => {
  const [status, setStatus] = useState("");
  const { _id, name, username, image } = user;
  const img = getImage(username);
  const to = `/login/users/${_id}`;
  function reject() {
    eventService.rejectRequest(event, _id);
    setStatus("Request Rejected");
  }
  function accept() {
    eventService.acceptRequest(event, _id);
    setStatus("Request Accepted");
  }
  return (
    <div className="request-card">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <a href={to} className="name">
          {name}
        </a>
        {username ? <p className="username">@{username}</p> : null}
        <div className="decision">
          {status ? (
            <div className="status">{status}</div>
          ) : (
            <React.Fragment>
              <button onClick={accept} className="btn-ok">
                {lang.accept[lang.ls()]}
              </button>
              <button onClick={reject} className="btn-del">
                {lang.reject[lang.ls()]}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default JoinRequestCard;
