import React from "react";

const CounterCard = ({ icon, label, count }) => {
  return (
    <div style={{ textDecoration: "none", direction: "ltr" }}>
      <div className="counter-card">
        <i className={icon}></i>
        <div className="info">
          <p>{label}</p>
          <p className="count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default CounterCard;
