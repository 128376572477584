import React from "react";
import lang from "../../../assets/lang";
var moment = require("moment");

const EventData = ({ event }) => {
  const {
    created_at,
    isPublic,
    category,
    country,
    address,
    startTiming,
    endTiming,
    details,
    keywords
  } = event;
  return (
    <React.Fragment>
      <p className="item">
        <span className="bold">{lang.created[lang.ls()]}</span>{" "}
        {moment(created_at).format("Do MMM YY")}
      </p>
      {isPublic ? (
        <p className="item">
          <i className="fas fa-users"></i> {lang.public[lang.ls()]}
        </p>
      ) : (
        <p className="item">
          <i className="fas fa-user-lock"></i> {lang.private[lang.ls()]}
        </p>
      )}
      <p className="item">
        <span className="bold">{lang.category[lang.ls()]}</span>{" "}
        {category.name[lang.ls()]}
      </p>
      <p className="item">
        <span className="bold">{lang.country[lang.ls()]}</span>{" "}
        {country.name[lang.ls()]}
      </p>
      <p className="item allcol">
        <span className="bold">{lang.address[lang.ls()]}</span> {address}
      </p>
      <p className="item allcol">
        <span className="bold">{lang.date[lang.ls()]}</span>{" "}
        <span className="grey">
          {moment(startTiming).format("Do MMM YYYY")}
        </span>{" "}
        {lang.to[lang.ls()]}{" "}
        <span className="grey">{moment(endTiming).format("Do MMM YYYY")}</span>
      </p>
      <p className="item allcol">
        <span className="bold">{lang.time[lang.ls()]}</span>{" "}
        <span className="grey">{moment(startTiming).format("hh:mm A")}</span>{" "}
        {lang.to[lang.ls()]}{" "}
        <span className="grey">{moment(endTiming).format("hh:mm A")}</span>
      </p>
      <p className="item allcol">
        <span className="bold det">{lang.details[lang.ls()]}</span>{" "}
        <span className="grey det">{details}</span>
      </p>
      <div className="item allcol key-show">
        <span className="bold">{lang.keywords[lang.ls()]}</span>
        <div className="keywords">
          {keywords.map(keyword => {
            return (
              <p key={keyword} className="keyword">
                {keyword}
              </p>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventData;
