import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import BasicInput from "./../common/ant-design/input";
import lang from "../../assets/lang";
import terms from "../../assets/terms";
import { connect } from "react-redux";
import { userActions } from "../../redux/actions";
import { Loader } from "../common/loader";
import { authService } from "../../services/authService";
import { Checkbox, Modal } from "antd";
import Recaptcha from "react-google-invisible-recaptcha";
import { Link } from "react-router-dom";
import BasicSelect from "../common/ant-design/select";
var pattern = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

class UserForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      companyName: "d",
      username: "",
      email: "",
      password: "",
      rePassword: "",
      terms: false,
      emailValid: null,
      usernameValid: null,
    },
    type: "User",
    emailCheck: false,
    usernameCheck: false,
    error: "",
    use: false,
    privacy: false,
    loading: false,
  };

  schema = {
    firstName: Joi.string().min(1).required().label("First Name"),
    lastName: Joi.string().min(1).required().label("Last Name"),
    companyName: Joi.string().min(1).max(20).required().label("Company Name"),
    username: Joi.string().min(4).max(20).required().label("Username"),
    email: Joi.string().min(5).required().label("E-mail"),
    password: Joi.string().required().label("Password"),
    rePassword: Joi.string().required().label("Password"),
    terms: Joi.boolean().required().valid(true),
    emailValid: Joi.boolean().required().valid(true),
    usernameValid: Joi.boolean().required().valid(true),
  };
  tryResolve = () => {
    this.setState({ loading: true });
    this.recaptcha.execute();
  };
  openModal = (name) => {
    if (name === "use") this.setState({ use: true });
    else this.setState({ privacy: true });
  };
  closeModal = (name) => {
    if (name === "use") this.setState({ use: false });
    else this.setState({ privacy: false });
  };
  doSubmit = () => {
    const data = { ...this.state.data };
    if (data.password === data.rePassword) {
      if (data.password.length < 8) {
        this.setState({
          error: lang.passValid[lang.ls()],
          loading: false,
        });
      } else {
        this.setState({ error: "" });
        const { data } = this.state;
        const user = {
          email: data.email,
          password: data.password,
          type: this.state.type === "Firm" ? "Company" : "User",
          username: data.username,
        };
        let profile = {};
        if (this.state.type === "Firm") {
          profile = {
            name: data.companyName,
            code: data.username,
          };
        } else {
          profile = {
            name: data.firstName + " " + data.lastName,
            username: data.username,
          };
        }
        this.props.register(user, profile, lang.ls());
      }
    } else {
      this.setState({ error: lang.twoPassValid[lang.ls()], loading: false });
    }
  };
  validateUsername = (e) => {
    const data = { ...this.state.data };
    data.username = e.target.value;
    if (e.target.value.length >= 4) {
      this.setState({ usernameCheck: true, data });
      authService.validateUsername(e.target.value).then((res) => {
        if (this.state.data.username === res.data.username) {
          data.usernameValid = res.data.valid;
          this.setState({ data, usernameCheck: false });
        }
      });
    } else {
      data.usernameValid = false;
      this.setState({ data, usernameCheck: false });
    }
  };
  validateEmail = (e) => {
    const data = { ...this.state.data };
    data.email = e.target.value;
    if (e.target.value.length >= 5 && pattern.test(e.target.value)) {
      this.setState({ emailCheck: true, data });
      authService.validateEmail(e.target.value).then((res) => {
        if (this.state.data.email === res.data.email) {
          data.emailValid = res.data.valid;
          this.setState({ data, emailCheck: false });
        }
      });
    } else {
      data.emailValid = false;
      this.setState({ data, emailCheck: false });
    }
  };
  changeType = (value) => {
    const data = { ...this.state.data };
    if (value === "Firm") {
      data.firstName = "d";
      data.lastName = "d";
      data.companyName = "";
    } else {
      data.companyName = "d";
      data.firstName = "";
      data.lastName = "";
    }
    this.setState({ data, type: value });
  };
  render() {
    const {
      error,
      emailCheck,
      usernameCheck,
      data,
      loading,
      type,
    } = this.state;
    const { alert } = this.props;
    const types = [
      {
        _id: "Firm",
        name: {
          en: lang.company[lang.ls()],
          ar: lang.company[lang.ls()],
        },
      },
      {
        _id: "User",
        name: {
          en: lang.user[lang.ls()],
          ar: lang.user[lang.ls()],
        },
      },
    ];
    return (
      <form onSubmit={this.handleSubmit}>
        {error && <div className="alert alert-danger error">{error}</div>}
        {/* {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )} */}
        <BasicSelect options={types} value={type} onChange={this.changeType} />
        {type === "Firm" ? (
          this.renderInput({
            placeholder: lang.compName[lang.ls()],
            tooltip: lang.required[lang.ls()],
            classes: "normal-input",
            name: "companyName",
          })
        ) : (
          <div className="inline-ant">
            {this.renderInput({
              placeholder: lang.firstName[lang.ls()],
              name: "firstName",
            })}
            {this.renderInput({
              placeholder: lang.lastName[lang.ls()],
              name: "lastName",
            })}
          </div>
        )}
        <div className="validate">
          <BasicInput
            placeholder={lang.username[lang.ls()]}
            tooltip={lang.usernameUnique[lang.ls()]}
            icon="user"
            name="username"
            value={data["username"]}
            onChange={this.validateUsername}
          />
          <div className="indicator">
            {usernameCheck ? (
              <Loader tiny />
            ) : data.usernameValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : data.usernameValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        <div className="validate">
          <BasicInput
            placeholder={lang.email[lang.ls()]}
            tooltip={lang.emailUnique[lang.ls()]}
            icon="mail"
            name="email"
            value={data["email"]}
            onChange={this.validateEmail}
          />
          <div className="indicator">
            {emailCheck ? (
              <Loader tiny />
            ) : data.emailValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : data.emailValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {this.renderInput({
          placeholder: lang.passPlace[lang.ls()],
          password: true,
          icon: "lock",
          name: "password",
        })}
        {this.renderInput({
          placeholder: lang.rePass[lang.ls()],
          password: true,
          icon: "lock",
          name: "rePassword",
        })}
        <Checkbox
          checked={data["terms"]}
          onChange={() => this.handleTerms("terms")}
          style={{
            maxWidth: "80%",
            marginTop: "10px",
            textAlign: "center",
            color: "#de7e4c",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <p className="terms link-desc" style={{ color: "white" }}>
            {lang.agreeTo[lang.ls()]}{" "}
            <span onClick={() => this.openModal("use")} className="link">
              {lang.use[lang.ls()]}
            </span>{" "}
            {lang.and[lang.ls()]}{" "}
            <span onClick={() => this.openModal("privacy")} className="link">
              {lang.privCond[lang.ls()]}
            </span>{" "}
            {lang.bySign[lang.ls()]}
          </p>
        </Checkbox>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          onResolved={this.doSubmit}
        />
        <Link
          disabled={this.validate() || loading}
          onClick={this.tryResolve}
          className="btn-ok"
        >
          {loading ? <Loader tiny margin /> : null}
          {lang.register[lang.ls()]}
        </Link>
        <Modal
          title={lang.use[lang.ls()]}
          visible={this.state.use}
          onCancel={() => this.closeModal("use")}
          footer={null}
          bodyStyle={{ overflow: "auto", height: "60vh", textAlign: "justify" }}
        >
          <div className="terModal">{terms.use[terms.ls()]}</div>
        </Modal>
        <Modal
          title={lang.privCond[lang.ls()]}
          visible={this.state.privacy}
          onCancel={() => this.closeModal("privacy")}
          footer={null}
          bodyStyle={{ overflow: "auto", height: "60vh", textAlign: "justify" }}
        >
          <div className="terModal">{terms.privacy[terms.ls()]}</div>
        </Modal>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};

const mapDispatchToProps = {
  register: userActions.register,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
