import React from "react";
import { getImage } from "../../../utils/helperMethods";

const UserTag = ({ friend }) => {
  const { _id, name, username, image } = friend;
  const img = getImage(username);
  const to = username
    ? `/login/users/${_id}/User`
    : `/login/users/${_id}/Company`;
  return (
    <div className="user-tag">
      <div
        className="img"
        style={{
          background: `url(${
            image ? image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <a href={to} className="name">
          {name}
        </a>
        {username && <p className="username">@{username}</p>}
      </div>
    </div>
  );
};

export default UserTag;
