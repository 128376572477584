import React from "react";
import { getImage } from "../../../utils/helperMethods";

const ChatItem = ({ chat, selected, selectChat }) => {
  const img = getImage(chat.type === "User" ? true : false);
  const classes = selected ? "chat-item-active" : "chat-item";
  return (
    <div
      onClick={() =>
        selectChat({
          _id: chat._id,
          id: chat.id,
          type: chat.type,
        })
      }
      className={classes}
    >
      <div
        className="img"
        style={{
          background: `url(${
            chat.image.url ? chat.image.url : img
          }) no-repeat center center/cover`,
        }}
      ></div>
      <div className="info">
        <div className="name">{chat.name}</div>
        <div className="activity">
          <div className="latest">{chat.latest}</div>
          {chat.seen ? (
            <div className="seen">
              <div>{chat.seen}</div>
            </div>
          ) : null}
        </div>

        <div className="line"></div>
      </div>
    </div>
  );
};

export default ChatItem;
