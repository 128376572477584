import React, { useState, useEffect } from "react";
import { eventService } from "../../services/eventService";
import Slider from "react-slick";
import EventMapCard from "../common/cards/eventmapcard";
import lang from "../../assets/lang";

const TopMoments = () => {
  const [moments, setMoments] = useState([]);
  const [settings, setSettings] = useState({
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow:
      window.innerWidth > 1160
        ? 4
        : window.innerWidth > 900
        ? 3
        : window.innerWidth > 600
        ? 2
        : 1,
    speed: 500,
  });
  useEffect(() => {
    eventService.getTopMoments().then((res) => {
      setMoments(res.data);
      let sett = { ...settings };
      if (res.data.length < sett.slidesToShow) {
        sett.slidesToShow = res.data.length;
      }
      setSettings(sett);
    });
  }, []);
  return (
    moments.length !== 0 && (
      <div className="top-mom">
        <div className="head-main">{lang.topMoments[lang.ls()]}</div>
        <div className="line"></div>
        <Slider {...settings}>
          {moments.map((mom) => {
            return <EventMapCard event={mom} />;
          })}
        </Slider>
      </div>
    )
  );
};

export default TopMoments;
