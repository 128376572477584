import React from "react";
import { Tabs } from "antd";
import lang from "../../../assets/lang";
import Personal from "../../common/profiles/personal";
import EventCard from "../cards/eventcard";
import Normal from "../cards/normalCard";
import Report from "../cards/reportCard";
import Block from "../cards/blockCard";
import ReportCard from "../cards/report";
import EmptyLabel from "../../common/ant-design/empty";
const { TabPane } = Tabs;

const ProfileContents = ({ data, reports }) => {
  const {
    auth,
    myEvents,
    wishlist,
    sharedEvents,
    joined,
    friends,
    followers,
    followings,
    blocked,
    blockedBy,
  } = data;
  const loadUsers = (users, obj) => {
    return (
      <React.Fragment>
        {users.length === 0 ? (
          <EmptyLabel label={lang.noUsersFound[lang.ls()]} />
        ) : (
          <div className="triple-grid">
            {users.map((user) => {
              const { _id, name, username, image, isReported, isBlocked } = obj
                ? user.id
                : user;
              if (isBlocked) {
                return (
                  <Block
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              } else if (isReported) {
                return (
                  <Report
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              } else {
                return (
                  <Normal
                    name={name}
                    image={image}
                    username={username}
                    id={_id}
                    key={_id}
                  />
                );
              }
            })}
          </div>
        )}
      </React.Fragment>
    );
  };
  const tabHeader = (name, count) => {
    return (
      <div className="tab-head">
        <div className="name">{name}</div>
        <div className="count">{count}</div>
      </div>
    );
  };
  return (
    <Tabs
      style={{
        direction: "ltr",
        marginTop: "2rem",
        padding: "0 0.5rem 0.5rem 0.5rem",
      }}
    >
      <TabPane tab={lang.about[lang.ls()]} key="about">
        <Personal info={data} />
      </TabPane>
      <TabPane
        tab={tabHeader(lang.events[lang.ls()], myEvents.length)}
        key="events"
      >
        {myEvents.length === 0 ? (
          <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
        ) : (
          <div className="triple-grid">
            {myEvents.map((event) => {
              return <EventCard key={event._id} myEvent={event} />;
            })}
          </div>
        )}
      </TabPane>
      {auth.type === "User" && (
        <TabPane
          tab={tabHeader(lang.wishlist[lang.ls()], wishlist.length)}
          key="wishlist"
        >
          {wishlist.length === 0 ? (
            <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
          ) : (
            <div className="triple-grid">
              {wishlist.map((event) => {
                return <EventCard key={event._id} myEvent={event} />;
              })}
            </div>
          )}
        </TabPane>
      )}
      {auth.type === "User" && (
        <TabPane
          tab={tabHeader(lang.shared[lang.ls()], sharedEvents.length)}
          key="shared"
        >
          {sharedEvents.length === 0 ? (
            <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
          ) : (
            <div className="triple-grid">
              {sharedEvents.map((event) => {
                return <EventCard key={event._id} myEvent={event} />;
              })}
            </div>
          )}
        </TabPane>
      )}
      {auth.type === "User" && (
        <TabPane
          tab={tabHeader(lang.joined[lang.ls()], joined.length)}
          key="joined"
        >
          {joined.length === 0 ? (
            <EmptyLabel label={lang.noEventsFound[lang.ls()]} />
          ) : (
            <div className="triple-grid">
              {joined.map((event) => {
                return <EventCard key={event._id} myEvent={event} />;
              })}
            </div>
          )}
        </TabPane>
      )}
      {auth.type === "User" && (
        <TabPane
          tab={tabHeader(lang.friends[lang.ls()], friends.length)}
          key="friends"
        >
          {loadUsers(friends, false)}
        </TabPane>
      )}
      <TabPane
        tab={tabHeader(lang.followers[lang.ls()], followers.length)}
        key="followers"
      >
        {loadUsers(followers, true)}
      </TabPane>
      <TabPane
        tab={tabHeader(lang.following[lang.ls()], followings.length)}
        key="followings"
      >
        {loadUsers(followings, true)}
      </TabPane>
      <TabPane
        tab={tabHeader(lang.blocked[lang.ls()], blocked.length)}
        key="blocked"
      >
        {loadUsers(blocked, true)}
      </TabPane>
      <TabPane
        tab={tabHeader(lang.blockedBy[lang.ls()], blockedBy.length)}
        key="blockedBy"
      >
        {loadUsers(blockedBy, true)}
      </TabPane>
      <TabPane
        tab={tabHeader(lang.reports[lang.ls()], reports.length)}
        key="reports"
      >
        {reports.length === 0 ? (
          <EmptyLabel label={lang.noReports[lang.ls()]} />
        ) : (
          <div className="repos">
            {reports.map((rep) => {
              const { _id, host, report, created_at } = rep;
              return (
                <ReportCard
                  key={_id}
                  user={host.id}
                  report={report}
                  created={created_at}
                />
              );
            })}
          </div>
        )}
      </TabPane>
    </Tabs>
  );
};

export default ProfileContents;
