import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/admins";

export const adminService = {
  login,
  getAccounts,
  getAccountRoles,
  getUsersStatus,
  getCompaniesStatus,
  getEventsStatus,
  getFeedbacks,
  getContactsCount,
  getUsersCards,
  getUsersCount,
  getCompaniesCards,
  getCompaniesCount,
  getEventsCards,
  getEventsCount,
  getUser,
  getCompany,
  blockAccount,
  unblockAccount,
  getEvent,
  deleteEvent,
  changeEmail,
  changePassword,
  validateEmail,
  changeName,
  registerAdmin,
  deleteAdmin,
  globalSearch,
  updateRoles,
  forgotPassword,
  setPassword,
  verTempToken,
};
function login(email, password) {
  return axios.post(endPoint + "/login", {
    email: email,
    password: password,
  });
}
function getAccounts() {
  return axios.get(endPoint + "/admins");
}
function getAccountRoles(id) {
  return axios.get(endPoint + "/admin-roles", {
    params: {
      id,
    },
  });
}
async function getUsersStatus() {
  let normal = 0,
    reported = 0,
    blocked = 0;
  const { data: users } = await axios.get(endPoint + "/users-status");
  users.map((user) => {
    if (!user.isReported && !user.isBlocked) normal = normal + 1;
    else if (user.isReported) reported = reported + 1;
    if (user.isBlocked) blocked = blocked + 1;
    return null;
  });
  return {
    normal: normal,
    reported: reported,
    blocked: blocked,
  };
}
async function getCompaniesStatus() {
  let normal = 0,
    reported = 0,
    blocked = 0;
  const { data: companies } = await axios.get(endPoint + "/companies-status");
  companies.map((company) => {
    if (!company.isReported && !company.isBlocked) normal = normal + 1;
    if (company.isReported) reported = reported + 1;
    if (company.isBlocked) blocked = blocked + 1;
    return null;
  });
  return {
    normal: normal,
    reported: reported,
    blocked: blocked,
  };
}
async function getEventsStatus() {
  let normal = 0,
    reported = 0,
    catched = 0;
  const { data: events } = await axios.get(endPoint + "/events-status");
  events.map((event) => {
    if (!event.isReported && !event.isCatched) normal = normal + 1;
    if (event.isCatched) catched = catched + 1;
    if (event.isReported) reported = reported + 1;
    return null;
  });
  return {
    normal: normal,
    catched: catched,
    reported: reported,
  };
}
async function getFeedbacks() {
  const { data: feedbacks } = await axios.get(endPoint + "/feedbacks-status");

  const sum = feedbacks.reduce((a, b) => a + (b["rate"] || 0), 0);
  const rate = sum / feedbacks.length;

  return {
    count: feedbacks.length,
    rate: rate,
  };
}
async function getContactsCount() {
  const { data: contacts } = await axios.get(endPoint + "/contacts-count");
  return contacts;
}
function getUsersCards(filter, page, limit) {
  return axios.get(endPoint + "/users-cards", {
    params: {
      page,
      limit,
      ...filter,
    },
  });
}
function getUsersCount(filter) {
  return axios.get(endPoint + "/users-count", {
    params: {
      ...filter,
    },
  });
}
function getCompaniesCards(filter, page, limit) {
  return axios.get(endPoint + "/companies-cards", {
    params: {
      page,
      limit,
      ...filter,
    },
  });
}
function getCompaniesCount(filter) {
  return axios.get(endPoint + "/companies-count", {
    params: {
      ...filter,
    },
  });
}
function verTempToken(token) {
  return axios.put(endPoint + `/ver-token/${token}`);
}
function getEventsCards(filter, page, limit, sort) {
  return axios.get(endPoint + "/events-cards", {
    params: {
      page,
      limit,
      sort,
      ...filter,
    },
  });
}
function getEventsCount(filter) {
  return axios.get(endPoint + "/events-count", {
    params: {
      ...filter,
    },
  });
}
function getUser(id) {
  return axios.get(endPoint + `/user/${id}`);
}
function getCompany(id) {
  return axios.get(endPoint + `/company/${id}`);
}
function blockAccount(id, type) {
  return axios.put(endPoint + "/block", null, {
    params: {
      id,
      type,
    },
  });
}
function unblockAccount(id, type) {
  return axios.put(endPoint + "/unblock", null, {
    params: {
      id,
      type,
    },
  });
}
function getEvent(id) {
  return axios.get(endPoint + `/event/${id}`);
}
function deleteEvent(id) {
  return axios.put(endPoint + "/delete-event", null, {
    params: {
      id,
    },
  });
}
function changeEmail(token, email) {
  return axios.put(endPoint + "/email", null, {
    params: {
      token,
      email,
    },
  });
}
function changePassword(token, oldPass, password) {
  return axios.put(endPoint + "/reset-password", null, {
    params: {
      token,
      oldPass,
      password,
    },
  });
}
function validateEmail(email) {
  return axios.post(endPoint + "/check-email", null, {
    params: {
      email,
    },
  });
}
function changeName(token, name) {
  return axios.put(endPoint + "/name", null, {
    params: {
      token,
      name,
    },
  });
}
function registerAdmin(data, lang) {
  return axios.post(endPoint + "/", data, {
    params: {
      lang,
    },
  });
}
function deleteAdmin(id) {
  return axios.put(endPoint + "/delete", null, {
    params: {
      id,
    },
  });
}
function updateRoles(id, roles) {
  return axios.put(endPoint + "/roles-update", null, {
    params: {
      id,
      roles,
    },
  });
}
function globalSearch(name) {
  return axios.get(endPoint + "/global-search", {
    params: {
      name,
    },
  });
}
function forgotPassword(email, lang) {
  return axios.put(endPoint + "/forgot-pass", null, {
    params: {
      email,
      lang,
    },
  });
}
function setPassword(id, password) {
  return axios.put(endPoint + "/set-pass", null, {
    params: {
      id,
      password,
    },
  });
}
