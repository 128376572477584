import React from "react";
import MenuLink from "./../common/menuLink";

const Menu = ({ sections }) => {
  return (
    <React.Fragment>
      {sections.map(({ icon, label, to }) => {
        return <MenuLink key={to} icon={icon} label={label} to={to} />;
      })}
    </React.Fragment>
  );
};

export default Menu;
