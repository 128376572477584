import React from "react";
import { getImage } from "../../../utils/helperMethods";
import lang from "../../../assets/lang";
import Tooltip from "@material-ui/core/Tooltip";
import { chatActions } from "../../../redux/actions/chatActions";
import history from "../../../utils/history";
import { connect } from "react-redux";

const HostLocation = ({ event, user, linkChat, seeAllBtn }) => {
  const { host: acc } = event;
  const { id: host } = acc;
  const img = getImage(host.username);
  const to = `/login/users/${host._id}/${acc.type}`;
  function dispatchChat(recv) {
    seeAllBtn();
    linkChat(recv);
    history.push("/login/chat");
  }
  return (
    <React.Fragment>
      <div className="host">
        <div
          className="img"
          style={{
            background: `url(${
              host.image ? host.image.url : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        <div className="info">
          <p className="by">{lang.hostedby[lang.ls()]}</p>
          <a href={to} className="name">
            {host.name}
          </a>
        </div>
        {event.chat && host._id !== user._id && (
          <Tooltip title={lang.chat[lang.ls()]} placement="top">
            <div
              onClick={() =>
                dispatchChat({
                  id: host._id,
                  type: host.username ? "User" : "Company",
                })
              }
              className="ch-btn"
            >
              <i className="far fa-comments"></i>
            </div>
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
};
const mapDispatchToProps = {
  linkChat: chatActions.linkChat,
  seeAllBtn: chatActions.seeAllBtn,
};

export default connect(null, mapDispatchToProps)(HostLocation);
