import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MainSettings from "./mainSettings";
import UserSettings from "./userSettings";
import { Loader } from "../../common/loader";
import "./settings.scss";
import { authService } from "../../../services/authService";
import lang from "../../../assets/lang";
import { userService } from "../../../services/userService";
import InitLang from "../../common/initLang";
import { userActions } from "../../../redux/actions";
import CompSettings from "./compSettings";

const LoginSettings = ({ type, token, auth, changeNot }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [notif, setNotif] = useState(null);
  const [chnotif, setChNotif] = useState(false);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [friend, setFriend] = useState(null);
  const [chFriend, setChFriend] = useState(false);
  useEffect(() => {
    authService.getSettings(token, type).then((res) => {
      setLoading(false);
      setEmail(res.data.auth.email);
      setNotif(res.data.auth.notifications);
      setUsername(res.data.username);
      setCode(res.data.code);
      setFriend(res.data.friendRequests);
    });
  }, [token, type]);
  const changeNotif = (checked) => {
    setChNotif(true);
    authService.changeNotif(auth._id, checked).then((res) => {
      changeNot(checked);
      setChNotif(false);
      setNotif(checked);
    });
  };
  const changeFriend = (checked) => {
    setChFriend(true);
    userService.changeFriend(token, checked).then((res) => {
      setChFriend(false);
      setFriend(checked);
    });
  };
  return (
    <div className="content settings">
      <div className="head-main">
        <i className="fas fa-cogs"></i> {lang.settings[lang.ls()]}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <MainSettings
            notif={notif}
            email={email}
            chnotif={chnotif}
            setEmail={setEmail}
            changeNotif={changeNotif}
          />
          {type === "User" && (
            <UserSettings
              username={username}
              friend={friend}
              chFriend={chFriend}
              setUsername={setUsername}
              changeFriend={changeFriend}
            />
          )}
          {type === "Company" && (
            <CompSettings code={code} setCode={setCode} token={token} />
          )}
          <div style={{ width: "fit-content", margin: "1rem auto" }}>
            <InitLang normal />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.login.type,
    token: state.login.token,
    auth: state.login.auth,
  };
};

const mapDispatchToProps = {
  changeNot: userActions.changeNotif,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSettings);
