export const chatTypes = {
  GET_CHATTING_REQUEST: "GET_CHATTING_REQUEST",
  GET_CHATTING_SUCCESS: "GET_CHATTING_SUCCESS",
  GET_CHATTING_FAILURE: "GET_CHATTING_FAILURE",

  SELECT_CHAT: "SELECT_CHAT",
  LINK_CHAT: "LINK_CHAT",
  SEND_MESSAGE: "SEND_MESSAGE",
  SEE_ALL: "SEE_ALL",
  DELETE_CHAT: "DELETE_CHAT",

  LOAD_MORE_REQUEST: "LOAD_MORE_REQUEST",
  LOAD_MORE_SUCCESS: "LOAD_MORE_SUCCESS",
  LOAD_MORE_FAILURE: "LOAD_MORE_FAILURE",

  LOGOUT: "LOGOUT",
};
