import React from "react";
import { Link } from "react-router-dom";
import lang from "../../assets/lang";

const BlockedInfo = () => {
  return (
    <div className="showcase">
      <div className="faded">
        <div className="container single-grid">
          <div className="issue">
            <div className="info">
              <div className="head head-white">
                <i className="fas fa-ban"></i> {lang.blockedInfo[lang.ls()]}
              </div>
              <div className="talk">{lang.blockedTalk[lang.ls()]}</div>
              <Link to="/" className="btn-issue">
                {lang.returnHome[lang.ls()]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedInfo;
