import { chatTypes } from "../types/chatTypes";

export function chatManage(state = {}, action) {
  switch (action.type) {
    case chatTypes.GET_CHATTING_REQUEST:
      return { ...state, loadList: true };
    case chatTypes.GET_CHATTING_SUCCESS:
      return {
        ...state,
        loadList: false,
        notif: action.notif,
        chatting: action.data,
      };
    case chatTypes.GET_CHATTING_FAILURE:
      return { ...state, loadList: false };
    case chatTypes.SEND_MESSAGE:
      state.activeChat.chat.push(action.data);
      console.log(action.data);
      return {
        ...state,
        activeChat: state.activeChat,
      };
    case chatTypes.DELETE_CHAT:
      const result = state.chatting.filter((obj) => {
        return obj.room !== action.room;
      });
      return {
        ...state,
        chatting: result,
        activeChat: null,
      };
    case chatTypes.SELECT_CHAT:
      return {
        ...state,
        activeChat: action.data,
      };
    case chatTypes.LINK_CHAT:
      return {
        ...state,
        linkedChat: action.data,
      };
    case chatTypes.LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMess: true,
      };
    case chatTypes.SEE_ALL:
      return {
        ...state,
        linkedChat: null,
        activeChat: null,
      };
    case chatTypes.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMess: false,
        activeChat: {
          ...state.activeChat,
          chat: [...action.data, ...state.activeChat.chat],
        },
      };
    case chatTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
