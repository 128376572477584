import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { getImage } from "../../utils/helperMethods";
import lang from "../../assets/lang";
import { chatActions } from "../../redux/actions/chatActions";
import { Badge } from "antd";
import ChatItem from "../login/chat/chatItem";
import io from "socket.io-client";
import history from "../../utils/history";
import BasicInput from "./ant-design/input";
import EmptyLabel from "./ant-design/empty";
import { authService } from "../../services/authService";
import { userActions } from "../../redux/actions";
import { requestService } from "../../services/requestService";
import RequestCard from "./cards/requestcard";
import { requestActions } from "../../redux/actions/requestActions";
import { friendsActions } from "../../redux/actions/friendsActions";
import { followActions } from "../../redux/actions/followActions";
import { blockActions } from "../../redux/actions/blockActions";
import NotifItem from "./notifItem";
import { notifActions } from "../../redux/actions/notifActions";
import { Loader } from "./loader";

let socket;

const Navbar = ({
  type,
  user,
  token,
  chatting,
  notif,
  notifs,
  read,
  setNotifs,
  getChattings,
  linkChat,
  deleteChat,
  url,
  activeChat,
  logout,
  setRequests,
  setFriends,
  setFollows,
  setBlocks,
  seeAllBtn,
  remember,
  setRead,
  isNotif,
}) => {
  const [users, setUsers] = useState([]);
  const [comp, setComp] = useState([]);
  const [events, setEvents] = useState([]);
  const [requests, setRequestModal] = useState([]);
  const [global, setGlobal] = useState("");
  const [searchMod, setSearchMod] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const langs = localStorage.getItem("lang");
  const modalPlace = langs === "en" ? "bottomRight" : "bottomLeft";
  const img = getImage(type === "User" ? true : false);
  chatting = chatting ? chatting.slice(0, 5) : null;
  const server = process.env.REACT_APP_API_URL;
  useEffect(() => {
    socket = io(server);
    handleRemember();
    requestService.getFriendRequests(token).then((res) => {
      setRequestModal(res.data);
    });
    socket.on("notif", () => {
      setNotifs(token, user._id);
    });
    socket.on("chat", (value) => {
      if (activeChat && activeChat.room === value) {
        deleteChat(value);
      }
      getChattings(token);
    });
    socket.on("global", () => {
      setRequests(token);
      setFriends(token);
      setFollows(token, type);
      setBlocks(token, type);
      requestService.getFriendRequests(token).then((res) => {
        setRequestModal(res.data);
      });
    });
    return () => {
      socket.emit("disconnect");
      socket.off();
    };
  }, [token]);
  useEffect(() => {
    authService.checkBlock(token, type).then((res) => {
      if (res.data.valid) {
        logout();
        history.push("/issue/blocked");
      }
    });
  }, [url]);
  const handleRemember = () => {
    if (!remember && user) {
      if (!sessionStorage.getItem("remember")) {
        logout();
      }
    }
  };
  const handleLogout = () => {
    logout();
  };
  const changeRequests = () => {
    requestService.getFriendRequests(token).then((res) => {
      setRequestModal(res.data);
    });
  };
  function dispatchChat(recv) {
    seeAllBtn();
    linkChat(recv);
    history.push("/login/chat");
  }
  function seeAllChat() {
    seeAllBtn();
  }
  const globalSearch = (e) => {
    setGlobal(e.target.value);
    setSearchLoad(true);
    setSearchMod(true);
    if (e.target.value) {
      authService.globalSearch(token, type, e.target.value).then((res) => {
        setSearchLoad(false);
        setUsers(res.data.users);
        setComp(res.data.companies);
        setEvents(res.data.events);
      });
    } else {
      setSearchLoad(false);
      setUsers([]);
      setComp([]);
      setEvents([]);
      setSearchMod(false);
    }
  };
  const resetGlobal = () => {
    setGlobal("");
    setUsers([]);
    setComp([]);
    setEvents([]);
  };
  const notifMenu = (
    <Menu>
      {!isNotif ? (
        <div className="notif-mute">{lang.notifDis[lang.ls()]}</div>
      ) : notifs && notifs.length ? (
        notifs.map((notItem) => {
          return (
            <Menu.Item key={notItem._id}>
              <NotifItem obj={notItem} id={user._id} />
            </Menu.Item>
          );
        })
      ) : (
        <EmptyLabel label={lang.noNotifications[lang.ls()]} />
      )}
      {notifs && notifs.length ? (
        <Menu.Item>
          <Link
            to="/login/notifications"
            className="btn-drop"
            style={{ textAlign: "center" }}
          >
            {lang.seeall[lang.ls()]}
          </Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );
  const chatMenu = (
    <Menu>
      {chatting &&
        chatting.map((ch) => {
          if (!ch.removed) {
            return (
              <Menu.Item key={ch._id}>
                <ChatItem chat={ch} id={user._id} selectChat={dispatchChat} />
              </Menu.Item>
            );
          } else {
            return null;
          }
        })}
      <Menu.Item>
        <Link
          to="/login/chat"
          onClick={seeAllChat}
          className="btn-drop"
          style={{ textAlign: "center" }}
        >
          {lang.seeall[lang.ls()]}
        </Link>
      </Menu.Item>
    </Menu>
  );
  const requestMenu = (
    <Menu>
      {requests.length ? (
        requests.map((ch) => {
          return (
            <Menu.Item>
              <RequestCard
                changeRequests={changeRequests}
                request={ch}
                key={ch._id}
              />
            </Menu.Item>
          );
        })
      ) : (
        <EmptyLabel label={lang.noFriendRequestsFound[lang.ls()]} />
      )}
      {requests.length ? (
        <Menu.Item>
          <Link
            to="/login/connections"
            className="btn-drop"
            style={{ textAlign: "center" }}
          >
            {lang.seeall[lang.ls()]}
          </Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );
  const searchMenu = (
    <Menu className="search-global">
      {searchLoad && <Loader />}
      {users.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.users[lang.ls()]}>
          {users.map((us) => {
            return us._id !== user._id ? (
              <Menu.Item key={us._id}>
                <a
                  href={`/login/users/${us._id}/User`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fas fa-user"></i> {us.name}
                </a>
              </Menu.Item>
            ) : null;
          })}
        </Menu.ItemGroup>
      ) : null}
      {comp.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.companies[lang.ls()]}>
          {comp.map((com) => {
            return com._id !== user._id ? (
              <Menu.Item key={com._id}>
                <a
                  href={`/login/users/${com._id}/Company`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fas fa-building"></i> {com.name}
                </a>
              </Menu.Item>
            ) : null;
          })}
        </Menu.ItemGroup>
      ) : null}
      {events.length && !searchLoad ? (
        <Menu.ItemGroup title={lang.events[lang.ls()]}>
          {events.map((event) => {
            return (
              <Menu.Item key={event._id}>
                <a
                  href={`/login/events/${event._id}`}
                  className="btn-drop"
                  onClick={resetGlobal}
                >
                  <i className="fa fa-calendar-alt"></i> {event.name}
                </a>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      ) : null}
      {!users.length && !comp.length && !events.length && !searchLoad && (
        <EmptyLabel label="No Result !" />
      )}
    </Menu>
  );
  const sett = (
    <Menu>
      <Menu.Item>
        <Link to="/" className="btn-drop">
          <i className="fas fa-home"></i> {lang.home[lang.ls()]}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/login/settings" className="btn-drop">
          <i className="fas fa-cogs"></i> {lang.settings[lang.ls()]}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="" onClick={handleLogout} className="btn-drop">
          <i className="fas fa-sign-out-alt"></i> {lang.logOut[lang.ls()]}
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="navbar">
      <div className="container">
        <div className="controls">
          <BasicInput
            placeholder={lang.search[lang.ls()]}
            icon="search"
            onChange={globalSearch}
            value={global}
          />
          {searchMod && (
            <div onClick={() => setSearchMod(false)} className="global-dis">
              {searchMenu}
            </div>
          )}
          <a href="/login/event/form/new" className="btn-ok">
            {lang.createEvent[lang.ls()]}
          </a>
        </div>
        <div className="modals">
          {type === "User" && (
            <Badge dot={requests.length > 0}>
              <Dropdown
                overlay={requestMenu}
                trigger={["click"]}
                placement={modalPlace}
              >
                <i className="fas fa-user-plus"></i>
              </Dropdown>
            </Badge>
          )}
          {url !== "/login/chat" ? (
            <Badge dot={notif}>
              <Dropdown
                overlay={chatMenu}
                trigger={["click"]}
                placement={modalPlace}
              >
                <i className="fas fa-comments"></i>
              </Dropdown>
            </Badge>
          ) : null}
          <Badge dot={read && isNotif}>
            <Dropdown
              overlay={notifMenu}
              trigger={["click"]}
              placement={modalPlace}
              onClick={setRead}
            >
              <i className="fas fa-bell"></i>
            </Dropdown>
          </Badge>
          <Link to="/login/profile">
            <div
              className="img"
              style={{
                background: `url(${
                  user.image ? user.image.url : img
                }) no-repeat center center/cover`,
                margin: "0 1rem",
              }}
            ></div>
          </Link>
          <Dropdown overlay={sett} trigger={["click"]} placement={modalPlace}>
            <i className="fas fa-chevron-down"></i>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    type: state.login.type,
    token: state.login.token,
    chatting: state.chat.chatting,
    notif: state.chat.notif,
    activeChat: state.chat.activeChat,
    remember: state.login.remember,
    notifs: state.notif.notifs,
    read: state.notif.read,
    isNotif: state.login.notif,
  };
};

const mapDispatchToProps = {
  linkChat: chatActions.linkChat,
  getChattings: chatActions.getChattings,
  logout: userActions.logout,
  setRequests: requestActions.setRequests,
  setFriends: friendsActions.setFriends,
  setFollows: followActions.setFollows,
  setBlocks: blockActions.setBlocks,
  seeAllBtn: chatActions.seeAllBtn,
  setNotifs: notifActions.setNotifs,
  setRead: notifActions.setRead,
  deleteChat: chatActions.deleteChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
