import React from "react";
import lang from "../../assets/lang";

const ReadMore = () => {
  return (
    <div
      style={{
        background: `url(https://res.cloudinary.com/momants/image/upload/v1588176101/color_bflx5t.png) no-repeat center center/cover`,
      }}
      className="read-more"
    >
      <div className="container" id="read-more">
        <div className="section">
          <div className="talk">
            <div className="slogan">{lang.slogan[lang.ls()]}</div>
            <div className="line"></div>
            <div className="about">{lang.aboutTalk[lang.ls()]}</div>
          </div>
          <img
            style={{ width: "170px" }}
            src={
              "https://res.cloudinary.com/momants/image/upload/v1588176334/phone_xo4gav.png"
            }
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default ReadMore;
