import React, { Component } from "react";
import UserCard from "../../common/cards/usercard";
import "./other.scss";
import lang from "../../../assets/lang";
import { userService } from "../../../services/userService";
import { Loader } from "../../common/loader";
import { connect } from "react-redux";

class OtherFriends extends Component {
  state = {
    friends: [],
    loading: true,
  };
  componentDidMount() {
    const { match, token, type } = this.props;
    const { id } = match.params;
    userService.getOtherFriends(token, type, id).then((res) => {
      this.setState({ friends: res.data.friends, loading: false });
    });
  }

  render() {
    const { friends, loading } = this.state;
    return (
      <div className="other">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="head">{lang.friends[lang.ls()]}</div>
            <div className="line"></div>
            <div className="items">
              {friends.map((user) => {
                return <UserCard user={user} />;
              })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(OtherFriends);
