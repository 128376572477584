import React from "react";
import UserTag from "../cards/usertag";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";

const Friends = ({ friends, count, id, mutual }) => {
  return (
    <div className="profile-item">
      <div className="indicator">
        <div className="head">{lang.friends[lang.ls()]}</div>
        <div className="count">{count}</div>
        {mutual > 0 && (
          <div className="mutual">
            {lang.mutualFriends[lang.ls()]} {mutual}
          </div>
        )}
      </div>
      <div className="show">
        {friends.map((fr) => {
          return <UserTag key={fr._id} friend={fr} />;
        })}
      </div>
      {friends.length === 4 && (
        <Link to={`/login/users/${id}/User/friends`} className="btn-ok">
          {lang.seeall[lang.ls()]}
        </Link>
      )}
    </div>
  );
};

export default Friends;
