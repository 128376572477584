import React, { Component } from "react";
import LabelInput from "../../common/inputs/labelinput";
import InitLang from "../../common/initLang";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
import history from "../../../utils/history";
import { Link } from "react-router-dom";
import { adminService } from "../../../services/adminService";

class ForgotPassword extends Component {
  state = { email: "", loading: false, error: "" };

  insertEmail = ({ currentTarget: input }) => {
    let email = { ...this.state.email };
    email = input.value;
    this.setState({ email });
  };

  handleReset = () => {
    this.setState({ loading: true });
    this.setState({ error: "" });
    adminService
      .forgotPassword(this.state.email, lang.ls())
      .then((res) => {
        sessionStorage.setItem("authId", res.data);
        sessionStorage.setItem("email", this.state.email);
        this.setState({ loading: false });
        history.replace("/issue/forgot-sent");
      })
      .catch((err) => {
        const { data } = err.response;
        this.setState({ loading: false });
        this.setState({ error: data.message });
      });
  };

  render() {
    const { error, loading } = this.state;
    return (
      <div className="showcase">
        <div className="faded">
          <InitLang />
          <div className="container single-grid">
            <div className="issue">
              <h1 className="head-white issue-head">
                {lang.forgotPass[lang.ls()]}
              </h1>
              <p className="issue-body">{lang.forgotPassDesc[lang.ls()]}</p>
              {error && <div className="alert">{error}</div>}
              <LabelInput
                type="text"
                label={lang.emailLbl[lang.ls()]}
                name="email"
                onChange={this.insertEmail}
              />
              <Link onClick={this.handleReset} className="btn-issue">
                {loading ? <Loader tiny margin /> : lang.resetPass[lang.ls()]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
