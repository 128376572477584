import { requestTypes } from "../types/requestTypes";
import { requestService } from "../../services/requestService";
import io from "socket.io-client";

export const requestActions = {
  setRequests,
  addRequest,
  removeRequest,
  logout,
};
const server = process.env.REACT_APP_API_URL;
let socket;
function setRequests(token) {
  return (dispatch) => {
    dispatch(request());
    requestService
      .getRequested(token)
      .then((res) => {
        const reqs = [];
        res.data.map((req) => {
          reqs.push(req.to);
          return null;
        });
        dispatch(success(reqs));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: requestTypes.SET_REQUEST_REQUEST };
  }
  function success(data) {
    return { type: requestTypes.SET_REQUEST_SUCCESS, data };
  }
  function failure(error) {
    return { type: requestTypes.SET_REQUEST_FAILURE, error };
  }
}
function addRequest(token, to) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    requestService
      .sendRequest(token, { to: to })
      .then((res) => {
        socket.emit("setGlobal");
        dispatch(success(to));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: requestTypes.ADD_REQUEST_REQUEST };
  }
  function success(data) {
    return { type: requestTypes.ADD_REQUEST_SUCCESS, data };
  }
  function failure(error) {
    return { type: requestTypes.ADD_REQUEST_FAILURE, error };
  }
}
function removeRequest(req) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    requestService
      .removeRequest(req)
      .then((res) => {
        socket.emit("setGlobal");
        dispatch(success(res.data.to));
      })
      .catch((err) => {
        const { data } = err.response;
        dispatch(failure(data.error));
      });
  };
  function request() {
    return { type: requestTypes.REMOVE_REQUEST_REQUEST };
  }
  function success(data) {
    return { type: requestTypes.REMOVE_REQUEST_SUCCESS, data };
  }
  function failure(error) {
    return { type: requestTypes.REMOVE_REQUEST_FAILURE, error };
  }
}
function logout() {
  return { type: requestTypes.LOGOUT };
}
