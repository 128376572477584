import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/users";

export const userService = {
  addToWishlist,
  removeWishlist,
  getWishlistCards,
  getProfWishlist,
  getOtherProfWishlist,
  getOtherWishlist,
  shareEvent,
  unShareEvent,
  getSharedEvents,
  getProfShared,
  getOtherProfShared,
  getOtherShared,
  getJoinedEvents,
  getJoinRequests,
  getFriends,
  getProfFriends,
  getOtherProfFriends,
  getOtherFriends,
  getFollowings,
  getFollowers,
  getOtherFollowings,
  getOtherFollowers,
  getUsers,
  getUsersCount,
  getBlocked,
  unFriend,
  getFriendsIds,
  followAccount,
  unfollowAccount,
  getFollowIds,
  block,
  unblock,
  getBlockedIds,
  getBlockedByIds,
  getUser,
  getOneUser,
  getUsersFromArray,
  updateUser,
  validateUsername,
  changeUsername,
  changeFriend,
  mutualFriends,
};
function changeUsername(token, username) {
  return axios.put(endPoint + "/change-username", null, {
    params: {
      token,
      username,
    },
  });
}
function changeFriend(token, friend) {
  return axios.put(endPoint + "/change-friend", null, {
    params: {
      token,
      friend,
    },
  });
}
function addToWishlist(token, event) {
  return axios.put(
    endPoint + "/addWishlist",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function removeWishlist(token, event) {
  return axios.put(
    endPoint + "/removeFromWishlist",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function getWishlistCards(token, type) {
  return axios.get(endPoint + "/getWishlist", {
    params: {
      token,
      type,
    },
  });
}
function getProfWishlist(token, type) {
  return axios.get(endPoint + "/prof-getWishlist", {
    params: {
      token,
      type,
    },
  });
}
function getOtherProfWishlist(token, type, id) {
  return axios.get(endPoint + "/other-prof-wishlist", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getOtherWishlist(token, type, id) {
  return axios.get(endPoint + "/other-wishlist", {
    params: {
      id,
      token,
      type,
    },
  });
}
function shareEvent(token, event) {
  return axios.put(
    endPoint + "/share",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function unShareEvent(token, event) {
  return axios.put(
    endPoint + "/unshare",
    { event },
    {
      params: {
        token,
      },
    }
  );
}
function getSharedEvents(token, type) {
  return axios.get(endPoint + "/getShared", {
    params: {
      token,
      type,
    },
  });
}
function getProfShared(token, type) {
  return axios.get(endPoint + "/prof-getShared", {
    params: {
      token,
      type,
    },
  });
}
function getOtherProfShared(token, type, id) {
  return axios.get(endPoint + "/other-prof-shared", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getOtherShared(token, type, id) {
  return axios.get(endPoint + "/other-shared", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getJoinedEvents(token, type) {
  return axios.get(endPoint + "/joined", {
    params: {
      token,
      type,
    },
  });
}
function getJoinRequests(token, type) {
  return axios.get(endPoint + "/join-requests", {
    params: {
      token,
      type,
    },
  });
}
function getFriends(token) {
  return axios.get(endPoint + "/userFriends", {
    params: {
      token,
    },
  });
}
function getProfFriends(token) {
  return axios.get(endPoint + "/profFriends", {
    params: {
      token,
    },
  });
}
function getOtherFriends(token, type, id) {
  return axios.get(endPoint + "/other-friends", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getOtherProfFriends(token, type, id) {
  return axios.get(endPoint + "/other-prof-friends", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getFollowings(token) {
  return axios.get(endPoint + "/followings", {
    params: {
      token,
    },
  });
}
function getFollowers(token) {
  return axios.get(endPoint + "/followers", {
    params: {
      token,
    },
  });
}
function getOtherFollowings(token, type, id) {
  return axios.get(endPoint + "/other-followings", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getOtherFollowers(token, type, id) {
  return axios.get(endPoint + "/other-followers", {
    params: {
      id,
      token,
      type,
    },
  });
}
function getUsers(token, type, filter, page, limit) {
  return axios.get(endPoint + "/", {
    params: {
      ...filter,
      limit,
      page,
      token,
      type,
    },
  });
}
function getUsersCount(token, type, filter) {
  return axios.get(endPoint + "/users-count", {
    params: {
      ...filter,
      token,
      type,
    },
  });
}
function getBlocked(token) {
  return axios.get(endPoint + "/blocked", {
    params: {
      token,
    },
  });
}
function unFriend(token, target) {
  return axios.put(endPoint + "/unfriend", null, {
    params: {
      token,
      target,
    },
  });
}
function getFriendsIds(token) {
  return axios.get(endPoint + "/friends-id", {
    params: {
      token,
    },
  });
}
function followAccount(token, type, target) {
  return axios.put(endPoint + "/follow", null, {
    params: {
      token,
      type,
      target,
    },
  });
}
function unfollowAccount(token, type, target) {
  return axios.put(endPoint + "/unfollow", null, {
    params: {
      token,
      type,
      target,
    },
  });
}
function getFollowIds(token) {
  return axios.get(endPoint + "/followings-ids", {
    params: {
      token,
    },
  });
}
function block(token, user) {
  return axios.put(
    endPoint + "/block",
    { user },
    {
      params: {
        token,
      },
    }
  );
}
function unblock(token, user) {
  return axios.put(
    endPoint + "/unblock",
    { user },
    {
      params: {
        token,
      },
    }
  );
}
function getBlockedIds(token) {
  return axios.get(endPoint + "/blocked-ids", {
    params: {
      token,
    },
  });
}
function getBlockedByIds(token) {
  return axios.get(endPoint + "/blockedBy-ids", {
    params: {
      token,
    },
  });
}
function getUser(token) {
  return axios.get(endPoint + "/getUser", {
    params: {
      token,
    },
  });
}
function getOneUser(id) {
  return axios.get(endPoint + "/getUser-id", {
    params: {
      id,
    },
  });
}
function getUsersFromArray(array) {
  return axios.get(endPoint + "/users-array", {
    params: {
      array,
    },
  });
}
function updateUser(token, user) {
  return axios.put(endPoint + "/", user, {
    params: {
      token,
    },
  });
}
function validateUsername(username) {
  return axios.post(endPoint + "/check-username", null, {
    params: {
      username,
    },
  });
}
function mutualFriends(token, user) {
  return axios.get(endPoint + "/mutualFriends", {
    params: {
      token,
      user,
    },
  });
}
