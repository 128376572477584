import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/reviews";

export const reviewService = {
  createReview,
  updateRate,
  checkReview,
  getEventReviews,
  getUserReviews,
  removeReview,
};
function createReview(token, type, review) {
  return axios.post(endPoint + "/", review, {
    params: {
      token,
      type,
    },
  });
}
function updateRate(id) {
  return axios.post(endPoint + "/update", null, {
    params: {
      id,
    },
  });
}
function checkReview(id, token) {
  return axios.post(endPoint + "/check", null, {
    params: {
      token,
      id,
    },
  });
}
function getEventReviews(event) {
  return axios.get(endPoint + "/event-rev", {
    params: {
      event,
    },
  });
}
function getUserReviews(token, type) {
  return axios.get(endPoint + "/", {
    params: {
      token,
      type,
    },
  });
}
function removeReview(id) {
  return axios.put(endPoint + "/remove", null, {
    params: {
      id,
    },
  });
}
