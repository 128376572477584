import React, { Component } from "react";
import MainCounters from "./maincounters";
import StatusCounters from "./statuscounters";
import EventCounters from "./eventcounters";
import { adminService } from "./../../../services/adminService";
import { Loader } from "../../common/loader";
import lang from "../../../assets/lang";

class Home extends Component {
  state = {
    counters: {},
    users: {},
    companies: {},
    events: {},
    loading: true,
  };
  async componentDidMount() {
    const users = await adminService.getUsersStatus();
    const companies = await adminService.getCompaniesStatus();
    const events = await adminService.getEventsStatus();
    const feedbacks = await adminService.getFeedbacks();
    const contacts = await adminService.getContactsCount();
    const counters = {
      users: users.normal + users.reported + users.blocked,
      companies: companies.normal + companies.reported + companies.blocked,
      events: events.normal + events.reported + events.catched,
      contacts: contacts.length,
      feedbacks: feedbacks.count,
      rate: feedbacks.rate.toFixed(2),
    };
    this.setState({ counters, users, companies, events, loading: false });
  }
  render() {
    const { counters, users, companies, events, loading } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <MainCounters counters={counters} />
            <StatusCounters
              label={lang.users[lang.ls()]}
              icon="fas fa-users"
              counters={users}
              to="/admin/users"
            />
            <StatusCounters
              label={lang.companies[lang.ls()]}
              icon="far fa-building"
              counters={companies}
              to="/admin/companies"
            />
            <EventCounters
              icon="fa fa-calendar-alt"
              label={lang.events[lang.ls()]}
              counters={events}
              to="/admin/events"
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Home;
