import React from "react";
import Showcase from "./showcase";
import "./LandingPage.scss";
import ReadMore from "./readMore";
import AtService from "./service";
import Categories from "./categories";
import LandingContact from "./contact";
import MainFooter from "../common/footer";
import LandingNavbar from "../common/landingNav";
import GetApp from "./getApp";
import TopMoments from "./topMoments";
import InitLang from "../common/initLang";

const HomePage = () => {
  return (
    <div className="home-page">
      <LandingNavbar />
      <Showcase />
      <ReadMore />
      <AtService />
      <Categories />
      <TopMoments />
      <GetApp />
      <LandingContact />
      <MainFooter />
    </div>
  );
};

export default HomePage;
