import React from "react";
import { getAge } from "../../../utils/helperMethods";
import lang from "../../../assets/lang";
var moment = require("moment");

const Personal = ({ info }) => {
  return (
    <div className="personal">
      {info.joined_at && (
        <div className="item">
          <div className="head">{lang.joined[lang.ls()]}</div>
          <div className="info">
            {moment(info.joined_at).format("Do MMMM YYYY")}
          </div>
        </div>
      )}
      {info.country && (
        <div className="item">
          <div className="head">{lang.country[lang.ls()]}</div>
          <div className="info">{info.country.name[lang.ls()]}</div>
        </div>
      )}
      {info.city && (
        <div className="item">
          <div className="head">{lang.city[lang.ls()]}</div>
          <div className="info">{info.city.name[lang.ls()]}</div>
        </div>
      )}
      {info.gender && (
        <div className="item">
          <div className="head">{lang.gender[lang.ls()]}</div>
          <div className="info">{info.gender}</div>
        </div>
      )}
      {info.dateOfBirth && (
        <React.Fragment>
          <div className="item">
            <div className="head">{lang.dateOfBirth[lang.ls()]}</div>
            <div className="info">
              {moment(info.dateOfBirth).format("Do MMMM YYYY")}
            </div>
          </div>
          <div className="item">
            <div className="head">{lang.age[lang.ls()]}</div>
            <div className="info">
              {getAge(new Date(info.dateOfBirth))} Yrs old
            </div>
          </div>
        </React.Fragment>
      )}
      {info.education && (
        <div className="item">
          <div className="head">{lang.education[lang.ls()]}</div>
          <div className="info">{info.education}</div>
        </div>
      )}
      {info.about && (
        <div className="item-h">
          <div className="head">{lang.about[lang.ls()]}</div>
          <div className="info">{info.about}</div>
        </div>
      )}
    </div>
  );
};

export default Personal;
