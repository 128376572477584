import React from "react";
import "../common.scss";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";

const Metrics = ({ events, followers, following, id, own, type }) => {
  const toMom = own ? "/login/events" : `/login/users/${id}/${type}/events`;
  const toFoll = own
    ? "/login/connections"
    : `/login/users/${id}/${type}/followers`;
  const toFoling = own
    ? "/login/connections"
    : `/login/users/${id}/${type}/followings`;
  return (
    <div className="metrics">
      <div className="sector">
        <Link to={toMom} className="head">
          {lang.events[lang.ls()]}
        </Link>
        <p className="meter">{events}</p>
      </div>
      <div className="vert-line"></div>
      <div className="sector">
        <Link
          to={{ pathname: toFoll, state: { target: "followers" } }}
          className="head"
        >
          {lang.followers[lang.ls()]}
        </Link>
        <p className="meter">{followers}</p>
      </div>
      <div className="vert-line"></div>
      <div className="sector">
        <Link
          to={{ pathname: toFoling, state: { target: "followings" } }}
          className="head"
        >
          {lang.following[lang.ls()]}
        </Link>
        <p className="meter">{following}</p>
      </div>
    </div>
  );
};

export default Metrics;
