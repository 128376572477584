import React from "react";
import "./settings.scss";
import AccountInformation from "./accInfo";
import Catching from "./catching";
import AdminTable from "./admins";

const Settings = ({ match }) => {
  return (
    <div className="content">
      <AccountInformation />
      <Catching />
      <AdminTable />
    </div>
  );
};

export default Settings;
