import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { selectService } from "../../../services/selectService";
import { companyService } from "../../../services/companyService";
import { getImage } from "../../../utils/helperMethods";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { Loader } from "../../common/loader";
import history from "../../../utils/history";
import { userActions } from "../../../redux/actions/userActions";

class EditCompanyProfile extends Form {
  state = {
    data: {
      name: "",
      country: "",
      city: "",
      about: "",
    },
    countries: [],
    cities: [],
    image: {},
    loading: true,
    saving: false,
  };
  schema = {
    name: Joi.string().min(1).required(),
    country: Joi.string().optional().empty(""),
    city: Joi.string().optional().empty(""),
    about: Joi.string().optional().empty(""),
  };
  componentDidMount() {
    selectService.getCountries().then((res) => {
      const countries = res.data;
      this.setState({ countries });
    });
    selectService.getCities().then((res) => {
      const cities = res.data;
      this.setState({ cities });
    });
    companyService.getCompany(this.props.token).then((res) => {
      const data = { ...this.state.data };
      const company = res.data.company;
      data.name = company.name;
      data.country = company.country;
      data.city = company.city;
      data.about = company.about;
      this.setState({ data, loading: false, image: company.image });
    });
  }
  doSubmit = () => {
    this.setState({ saving: true });
    const { token } = this.props;
    companyService.updateCompany(token, this.state.data).then((res) => {
      this.props.changeData(this.state.data.name, this.state.data.city);
      history.push("/login/profile");
    });
  };
  render() {
    const img = getImage(false);
    const { countries, cities, loading, saving, image } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <div
            style={{ width: "60%", margin: "auto" }}
            className="pic-section picname"
          >
            <div className="pic">
              <div
                className="img"
                style={{
                  background: `url(${
                    image ? image.url : img
                  }) no-repeat center center/cover`,
                }}
              ></div>
            </div>
            {this.renderInput({
              placeholder: lang.compName[lang.ls()],
              name: "name",
              clear: true,
            })}
            {this.renderSelect({
              placeholder: lang.country[lang.ls()],
              name: "country",
              options: countries,
            })}
            {this.renderSelect({
              placeholder: lang.city[lang.ls()],
              name: "city",
              options: cities,
            })}
            {this.renderTextArea("about", lang.about[lang.ls()])}
            {this.renderButton({
              label: lang.submit[lang.ls()],
              icon: saving ? "loading" : null,
            })}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};
const mapDispatchToProps = {
  changeData: userActions.changeData,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyProfile);
