import React from "react";
import Form from "../../common/form";
import Joi from "joi-browser";
import { getImage } from "../../../utils/helperMethods";
import { selectService } from "../../../services/selectService";
import lang from "../../../assets/lang";
import { userService } from "../../../services/userService";
import { connect } from "react-redux";
import { Loader } from "../../common/loader";
import history from "../../../utils/history";
import { userActions } from "../../../redux/actions/userActions";
import get from "lodash/get";

class EditUserProfile extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      country: "",
      city: "",
      gender: "",
      dateOfBirth: null,
      education: "",
      about: "",
    },
    countries: [],
    cities: [],
    gender: [
      {
        _id: "Male",
        name: {
          en: "Male",
          ar: "ذكر",
        },
      },
      {
        _id: "Female",
        name: {
          en: "Female",
          ar: "انثى",
        },
      },
    ],
    loading: true,
    image: {},
    saving: false,
  };
  schema = {
    firstName: Joi.string().min(1).required(),
    lastName: Joi.string().min(1).required(),
    country: Joi.string().optional().empty(""),
    city: Joi.string().optional().empty(""),
    gender: Joi.string().optional().empty(""),
    dateOfBirth: Joi.date().optional().empty(null),
    education: Joi.string().optional().empty(""),
    about: Joi.string().optional().empty(""),
  };
  componentDidMount() {
    selectService.getCountries().then((res) => {
      const countries = res.data;
      this.setState({ countries });
    });
    selectService.getCities().then((res) => {
      const cities = res.data;
      this.setState({ cities });
    });
    userService.getUser(this.props.token).then((res) => {
      const data = { ...this.state.data };
      const user = res.data.user;
      data.firstName = user.name.split(" ")[0];
      data.lastName = user.name.split(" ")[1];
      data.country = get(user, "country._id");
      data.city = get(user, "city._id");
      data.gender = user.gender;
      data.dateOfBirth = user.dateOfBirth;
      data.education = user.education;
      data.about = user.about;
      this.setState({ data, loading: false, image: user.image });
    });
  }
  doSubmit = () => {
    this.setState({ saving: true });
    const { token } = this.props;
    const data = { ...this.state.data };
    const final = {
      name: data.firstName + " " + data.lastName,
      ...data,
    };
    delete final.firstName;
    delete final.lastName;
    userService.updateUser(token, final).then((res) => {
      this.props.changeData(
        `${this.state.data.firstName} ${this.state.data.lastName}`,
        this.state.data.city
      );
      history.push("/login/profile");
    });
  };
  render() {
    const img = getImage(true);
    const { countries, cities, gender, loading, saving, image } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <div className="edit-profile double-grid">
            <div className="pic-section picname">
              <div className="pic">
                <div
                  className="img"
                  style={{
                    background: `url(${
                      image ? image.url : img
                    }) no-repeat center center/cover`,
                  }}
                ></div>
              </div>
              {this.renderInput({
                placeholder: lang.firstName[lang.ls()],
                name: "firstName",
                clear: true,
              })}
              {this.renderInput({
                placeholder: lang.lastName[lang.ls()],
                name: "lastName",
              })}
            </div>
            <div className="info-section">
              <div className="inline-ant">
                {this.renderSelect({
                  placeholder: lang.country[lang.ls()],
                  name: "country",
                  options: countries,
                })}
                {this.renderSelect({
                  placeholder: lang.city[lang.ls()],
                  name: "city",
                  options: cities,
                })}
              </div>
              <div className="inline-ant">
                {this.renderSelect({
                  placeholder: lang.gender[lang.ls()],
                  name: "gender",
                  options: gender,
                })}
                {this.renderDatePicker(
                  "dateOfBirth",
                  lang.dateOfBirth[lang.ls()]
                )}
              </div>
              {this.renderTextArea("education", lang.education[lang.ls()])}
              {this.renderTextArea("about", lang.about[lang.ls()])}
            </div>
            {this.renderButton({
              label: lang.submit[lang.ls()],
              icon: saving ? "loading" : null,
            })}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};
const mapDispatchToProps = {
  changeData: userActions.changeData,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfile);
