import React from "react";
import lang from "../../assets/lang";

const GetApp = () => {
  return (
    <div
      style={{
        background: `url(https://res.cloudinary.com/momants/image/upload/v1588176101/color_bflx5t.png) no-repeat center center/cover`,
      }}
      className="get-app"
    >
      <div className="label">{lang.getApp[lang.ls()]}</div>
      <div className="imgs">
        <img
          // style={{
          //   background: `url(https://res.cloudinary.com/momants/image/upload/v1594166052/AppStore_xeb2j0.png) no-repeat center center/contain`,
          // }}
          src="https://res.cloudinary.com/momants/image/upload/v1594166052/AppStore_xeb2j0.png"
          className="img"
        ></img>
        <img
          // style={{
          //   background: `url(https://res.cloudinary.com/momants/image/upload/v1594166052/GooglePlay_kkp7q7.png) no-repeat center center/contain`,
          // }}
          src="https://res.cloudinary.com/momants/image/upload/v1594166052/GooglePlay_kkp7q7.png"
          className="img"
        ></img>
      </div>
    </div>
  );
};

export default GetApp;
