import React, { Component } from "react";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { Loader } from "../loader";
import { eventService } from "../../../services/eventService";
import { Menu, Dropdown, Modal } from "antd";
import { Link } from "react-router-dom";
import { wishlistActions } from "../../../redux/actions/wishlistActions";
import { Slide } from "react-slideshow-image";
import io from "socket.io-client";
import { notifService } from "../../../services/notificationService";
import { eventImage } from "../../../utils/helperMethods";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";
var moment = require("moment");
const server = process.env.REACT_APP_API_URL;
let socket;

class EventShowcase extends Component {
  state = {
    join: true,
    joinStatus: "",
    likeLoad: true,
    like: false,
    imagesMod: false,
    shareMod: false,
    copied: false,
  };
  componentDidMount() {
    socket = io(server);
    eventService
      .checkJoin(this.props.event._id, this.props.token)
      .then((res) => {
        this.setState({ join: false, joinStatus: res.data });
      });
    eventService
      .checkLike(this.props.event._id, this.props.token)
      .then((res) => {
        if (res.data) this.setState({ likeLoad: false, like: true });
        else this.setState({ likeLoad: false, like: false });
      });
  }
  addToWishlist = () => {
    this.props.addWishlist(this.props.token, this.props.event._id);
  };
  removeWishlist = () => {
    this.props.removeWishlist(this.props.token, this.props.event._id);
  };
  likeEvent = () => {
    this.setState({ likeLoad: true });
    eventService
      .like(this.props.event._id, this.props.token)
      .then((res) => {
        this.props.event.likesNumber = this.props.event.likesNumber + 1;
        this.setState({ likeLoad: false, like: true });
      })
      .catch((err) => {
        this.setState({ likeLoad: false, like: false });
      });
  };
  removeLike = () => {
    this.setState({ likeLoad: true });
    eventService
      .removeLike(this.props.event._id, this.props.token)
      .then((res) => {
        this.props.event.likesNumber = this.props.event.likesNumber - 1;
        this.setState({ likeLoad: false, like: false });
      })
      .catch((err) => {
        this.setState({ likeLoad: false, like: true });
      });
  };
  joinEvent = () => {
    this.setState({ join: true });
    const { event, token, type, user } = this.props;
    eventService.registerJoin(token, event._id).then((res) => {
      eventService.checkJoin(event._id, token).then((res) => {
        notifService
          .sendSingleNotif(
            token,
            type,
            event.host.id._id,
            event.host.id.fcmToken,
            user.name,
            {
              type: res.data === "joined" ? "join" : "join-req",
              id: event._id,
              text: event.name,
            }
          )
          .then((res) => {
            socket.emit("setNotif");
          });
        this.setState({ join: false, joinStatus: res.data });
      });
    });
  };
  cancelJoinRequest = () => {
    this.setState({ join: true });
    eventService
      .cancelJoinRequest(this.props.token, this.props.event._id)
      .then((res) => {
        eventService
          .checkJoin(this.props.event._id, this.props.token)
          .then((res) => {
            this.setState({ join: false, joinStatus: res.data });
          });
      });
  };
  cancelJoin = () => {
    this.setState({ join: true });
    eventService
      .cancelJoin(this.props.token, this.props.event._id)
      .then((res) => {
        eventService
          .checkJoin(this.props.event._id, this.props.token)
          .then((res) => {
            this.setState({ join: false, joinStatus: res.data });
          });
      });
  };
  closeImages = () => {
    this.setState({ imagesMod: false });
  };
  closeShare = () => {
    this.setState({ shareMod: false });
  };
  joinStatus = () => {
    const { join, joinStatus } = this.state;
    if (join) return <Loader tiny />;
    else {
      if (joinStatus === "joined") {
        return (
          <button onClick={this.cancelJoin} className="btn-gr">
            {lang.joined[lang.ls()]}
          </button>
        );
      } else if (joinStatus === "request") {
        return (
          <button onClick={this.cancelJoinRequest} className="btn-white">
            {lang.pending[lang.ls()]}
          </button>
        );
      } else {
        return (
          <button onClick={this.joinEvent} className="btn-ok">
            {lang.join[lang.ls()]}
          </button>
        );
      }
    }
  };
  render() {
    const { like, likeLoad, imagesMod, shareMod, copied } = this.state;
    const {
      event,
      user,
      type,
      wishlist,
      adding,
      removing,
      removeEvent,
      openJoined,
      openRequest,
    } = this.props;
    const {
      _id,
      city,
      name,
      startTiming,
      likesNumber,
      limit,
      rate,
      views,
      isLimited,
      joined,
      images,
    } = event;
    const sliderProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      pauseOnHover: true,
    };
    const imge = eventImage();
    return (
      <React.Fragment>
        <p
          style={{ fontSize: "1.5rem", color: "#ef7a30", fontWeight: "bolder" }}
        >
          {name}
        </p>
        <div className="img">
          {images.length > 1 ? (
            <div style={{ direction: "ltr" }} className="slide-container">
              <Slide className="slider" {...sliderProperties}>
                {images.map((img) => {
                  return (
                    <div className="each-slide" key={img.url}>
                      <div
                        style={{
                          background: `url(${img.url}) no-repeat center center/cover`,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </Slide>
            </div>
          ) : (
            <div
              style={{
                background: `url(${
                  images[0] ? images[0].url : imge
                }) no-repeat center center/cover`,
              }}
              className="slide-container"
            ></div>
          )}
          <p className="city">
            <i className="fas fa-map-marker-alt"></i> {city.name[lang.ls()]}
          </p>
          {rate && rate !== 0 ? (
            <p className="rate">
              <i className="fas fa-star"></i> {rate}
            </p>
          ) : null}
          <p className="views">
            <i className="fas fa-eye"></i> {views}
          </p>
          {images[0] ? (
            <p
              onClick={() => this.setState({ imagesMod: true })}
              className="show"
            >
              <i className="far fa-images"></i>
            </p>
          ) : null}
        </div>
        <div className="meters">
          <div className="controls">
            {event.host.id._id === user._id ? (
              <Link to={`/login/event/form/${_id}`} className="btn-gr">
                {lang.edit[lang.ls()]}
              </Link>
            ) : event.isRunning ? (
              <div className="running">{lang.running[lang.ls()]}</div>
            ) : event.isFinished ||
              (event.isLimited && event.joined.length >= event.limit) ? (
              <div className="finished">{lang.closed[lang.ls()]}</div>
            ) : type === "User" ? (
              this.joinStatus()
            ) : null}
            {isLimited ? (
              <Tooltip title={lang.usersJoined[lang.ls()]}>
                <p
                  onClick={event.host.id._id === user._id && openJoined}
                  className={event.host.id._id === user._id && "point"}
                >
                  <i className="fas fa-user-friends"></i> {joined.length} /{" "}
                  {limit}
                </p>
              </Tooltip>
            ) : (
              <Tooltip title={lang.usersJoined[lang.ls()]}>
                <p
                  onClick={event.host.id._id === user._id ? openJoined : null}
                  className={event.host.id._id === user._id && "point"}
                >
                  <i className="fas fa-user-friends"></i> {joined.length}
                </p>
              </Tooltip>
            )}
            {((event.host.id._id === user._id && !event.isPublic) ||
              event.isPublic) && (
              <Tooltip title={lang.copyLink[lang.ls()]}>
                <p
                  className="point"
                  onClick={() =>
                    this.setState({ shareMod: true, copied: false })
                  }
                >
                  <i className="fas fa-share-alt"></i>
                </p>
              </Tooltip>
            )}
            {event.host.id._id === user._id && isLimited && (
              <Tooltip title={lang.joinRequests[lang.ls()]}>
                <p className="point" onClick={openRequest}>
                  <i className="fas fa-scroll"></i>
                </p>
              </Tooltip>
            )}
            {this.props.reviewed &&
              event.host.id._id !== user._id &&
              event.joined.includes(user._id) && (
                <Tooltip title={lang.rateAndReview[lang.ls()]}>
                  <p
                    className="point"
                    onClick={() => this.props.open("review")}
                  >
                    <i className="far fa-comment-alt"></i>
                  </p>
                </Tooltip>
              )}
            {this.props.reported && event.host.id._id !== user._id && (
              <Tooltip title={lang.report[lang.ls()]}>
                <p className="point" onClick={() => this.props.open("report")}>
                  <i className="far fa-file-alt"></i>
                </p>
              </Tooltip>
            )}
            {event.host.id._id === user._id && (
              <Tooltip title={lang.remove[lang.ls()]}>
                <p className="point" onClick={() => removeEvent()}>
                  <i className="fas fa-trash"></i>
                </p>
              </Tooltip>
            )}
            {event.host.id._id !== user._id ? (
              likeLoad ? (
                <div className="likes">
                  <Loader tiny />
                </div>
              ) : like ? (
                <Tooltip title={lang.unlike[lang.ls()]}>
                  <p
                    className="likes"
                    onClick={() => this.removeLike()}
                    style={{ color: "#ef7a30" }}
                  >
                    <i className="fas fa-thumbs-up"></i> {likesNumber}
                  </p>
                </Tooltip>
              ) : (
                <Tooltip title={lang.like[lang.ls()]}>
                  <p className="likes" onClick={() => this.likeEvent()}>
                    <i className="fas fa-thumbs-up"></i> {likesNumber}
                  </p>
                </Tooltip>
              )
            ) : null}

            {type === "User" &&
              (wishlist.includes(_id) ? (
                <Tooltip title={lang.removeWish[lang.ls()]}>
                  <div className="wishlist-active">
                    {removing ? (
                      <Loader tiny />
                    ) : (
                      <i
                        onClick={this.removeWishlist}
                        className="fas fa-heart"
                      ></i>
                    )}
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={lang.addToWish[lang.ls()]}>
                  <div className="wishlist">
                    {adding ? (
                      <Loader tiny />
                    ) : (
                      <i
                        onClick={this.addToWishlist}
                        className="fas fa-heart"
                      ></i>
                    )}
                  </div>
                </Tooltip>
              ))}
          </div>
          <div className="sep"></div>
          {((event.host.id._id === user._id && !event.isPublic) ||
            event.isPublic) && (
            <div className="share">
              <div className="head">{lang.share[lang.ls()]}:</div>
              <Tooltip title="Facebook">
                <FacebookShareButton
                  url={`https://www.momants.com/share/${this.props.event._id}`}
                  title={`Momants - ${this.props.event.name}`}
                  className="sh-icon"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Tooltip>
              <Tooltip title="Twitter">
                <TwitterShareButton
                  url={`https://www.momants.com/share/${this.props.event._id}`}
                  className="sh-icon"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Tooltip>
              <Tooltip title="WhatsApp">
                <WhatsappShareButton
                  url={`https://www.momants.com/share/${this.props.event._id}`}
                  separator=":: "
                  className="sh-icon"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Tooltip>
              <Tooltip title="Email">
                <EmailShareButton
                  url={`https://www.momants.com/share/${this.props.event._id}`}
                  subject={`Momants - ${this.props.event.name}`}
                  className="sh-icon"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </Tooltip>
            </div>
          )}
        </div>
        <Modal
          title={lang.images[lang.ls()]}
          visible={imagesMod}
          onCancel={this.closeImages}
          footer={null}
          width={1200}
          bodyStyle={{
            height: "400px",
            padding: "0",
          }}
        >
          <div style={{ direction: "ltr" }} className="slide-container">
            <Slide className="slider" {...sliderProperties}>
              {images.map((img) => {
                return (
                  <div className="each-slide" key={img.url}>
                    <div
                      style={{
                        background: `url(${img.url}) no-repeat center center/contain`,
                      }}
                    ></div>
                  </div>
                );
              })}
            </Slide>
          </div>
        </Modal>
        <Modal
          title={lang.share[lang.ls()]}
          visible={shareMod}
          onCancel={this.closeShare}
          footer={null}
          bodyStyle={{
            padding: "0 0 1rem 0",
          }}
        >
          <div
            style={{
              margin: "auto",
              fontSize: "1.1rem",
              padding: "0.5rem",
              textDecoration: "underline",
            }}
            className="head-main"
          >
            {`https://www.momants.com/share/${this.props.event._id}`}
          </div>
          {copied && (
            <div
              style={{
                margin: "auto",
                fontSize: "1.1rem",
                padding: "0.5rem",
                color: "#a83039",
              }}
              className="head-main"
            >
              {lang.copied[lang.ls()]}
            </div>
          )}
          <CopyToClipboard
            text={`https://www.momants.com/share/${this.props.event._id}`}
            onCopy={() => this.setState({ copied: true })}
          >
            <div
              style={{ margin: "1rem auto", padding: "0.5rem 1rem" }}
              className="btn-ok"
            >
              <i className="far fa-copy"></i> {lang.copy[lang.ls()]}
            </div>
          </CopyToClipboard>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    type: state.login.type,
    token: state.login.token,
    wishlist: state.wishlist.wishlist,
    adding: state.wishlist.adding,
    removing: state.wishlist.removing,
  };
};

const mapDispatchToProps = {
  addWishlist: wishlistActions.addWishlist,
  removeWishlist: wishlistActions.removeWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventShowcase);
