import React, { Component } from "react";
import Menu from "../common/menu";
import lang from "../../assets/lang";
import history from "../../utils/history";
class LogMenu extends Component {
  state = {
    sections: [
      {
        icon: "far fa-compass",
        label: lang.explore[lang.ls()],
        to: "/login/explore",
      },
      {
        icon: "fa fa-calendar-alt",
        label: lang.events[lang.ls()],
        to: "/login/events",
      },
      {
        icon: "fas fa-network-wired",
        label: lang.connections[lang.ls()],
        to: "/login/connections",
      },
      {
        icon: "fas fa-user",
        label: lang.profile[lang.ls()],
        to: "/login/profile",
      },
      {
        icon: "far fa-comment-alt",
        label: lang.reviews[lang.ls()],
        to: "/login/reviews",
      },
      {
        icon: "far fa-file-alt",
        label: lang.reports[lang.ls()],
        to: "/login/reports",
      },
    ],
  };
  render() {
    return (
      <div className="menu">
        <img
          style={{ width: "110px", cursor: "pointer" }}
          src={
            "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
          }
          onClick={() => history.push("/login/explore")}
          alt="logo"
        />
        <div className="line"></div>
        <Menu sections={this.state.sections} />
        <div
          style={{ fontSize: "0.7rem", margin: "0.5rem 0", color: "#de7e4c" }}
          className="copy"
        >
          © 2020 Momants
        </div>
      </div>
    );
  }
}

export default LogMenu;
