import React, { Component } from "react";
import MainProfile from "../../common/profiles/mainprofile";
import { authService } from "../../../services/authService";
import { connect } from "react-redux";
import { Loader } from "../../common/loader";
import { eventService } from "../../../services/eventService";
import { userService } from "../../../services/userService";
class Profile extends Component {
  state = {
    user: {},
    friends: [],
    events: [],
    wishlist: [],
    loading: true,
    attLoad: true,
  };
  componentDidMount() {
    const { token, type } = this.props;
    authService.getProfile(token, type).then((res) => {
      this.setState({ loading: false, user: res.data });
    });
    eventService.getMyEvents(token, "created_at", {}, 1, 3).then((res) => {
      this.setState({ events: res.data });
      if (type === "User") {
        userService.getProfFriends(token).then((res) => {
          this.setState({ friends: res.data.friends });
          userService.getProfWishlist(token, type).then((res) => {
            this.setState({ wishlist: res.data.wishlist, attLoad: false });
          });
        });
      } else {
        this.setState({ attLoad: false });
      }
    });
  }

  render() {
    const { user, friends, wishlist, events, loading, attLoad } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <MainProfile
            user={user}
            friends={friends}
            wishlist={wishlist}
            events={events}
            loading={attLoad}
            own={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(Profile);
