import React, { useState } from "react";
import { Modal, Input } from "antd";
import { contactService } from "../../../services/contactService";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
const { TextArea } = Input;

const NotRepliedContact = ({
  id,
  name,
  email,
  message,
  replied,
  replyState,
  updateState,
}) => {
  const [replying, setReplying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState("");
  const [closing, setClosing] = useState(false);
  const replyContact = () => {
    setLoading(true);
    contactService.reply(id, reply, lang.ls()).then((res) => {
      replyState(id);
      setLoading(false);
      setReplying(false);
    });
  };
  const handleChange = (e) => {
    setReply(e.target.value);
  };
  const closeModal = () => {
    setReplying(false);
  };
  const removing = () => {
    setClosing(true);
    contactService.removeContact(id).then((res) => {
      updateState(id);
    });
  };
  function handleReply(reply) {
    if (reply) {
      return (
        <p className="replied">
          <i className="fas fa-check"></i> {lang.replied[lang.ls()]}
        </p>
      );
    } else {
      return (
        <div onClick={() => setReplying(true)} className="btn-ok">
          <i className="fa fa-send"></i>
          {lang.reply[lang.ls()]}
        </div>
      );
    }
  }
  return (
    <div style={{ direction: "ltr" }} className="contact-card">
      <p className="name">{name}</p>
      <p className="email">{email}</p>
      <div className="message">{message}</div>
      {handleReply(replied)}
      <div className="remove">
        {closing ? null : <i onClick={removing} className="fas fa-times"></i>}
      </div>
      <Modal onCancel={closeModal} footer={null} visible={replying}>
        <div
          style={{
            width: "100%",
            background: "transparent",
            boxShadow: "none",
          }}
          className="contact-card"
        >
          <p className="name">{name}</p>
          <p className="email">{email}</p>
          <div className="message">{message}</div>
        </div>
        <TextArea
          placeholder={lang.reply[lang.ls()]}
          autoSize={{ minRows: 3, maxRows: 5 }}
          value={reply}
          onChange={handleChange}
        />
        <div
          onClick={() => replyContact()}
          disabled={loading}
          style={{
            margin: "1rem auto 0 auto",
            padding: "3px 1rem",
            width: "fit-content",
          }}
          className="btn-ok"
        >
          {loading ? <Loader tiny /> : lang.submit[lang.ls()]}
        </div>
      </Modal>
    </div>
  );
};

export default NotRepliedContact;
