export const requestTypes = {
  SET_REQUEST_REQUEST: "SET_REQUEST_REQUEST",
  SET_REQUEST_SUCCESS: "SET_REQUEST_SUCCESS",
  SET_REQUEST_FAILURE: "SET_REQUEST_FAILURE",

  ADD_REQUEST_REQUEST: "ADD_REQUEST_REQUEST",
  ADD_REQUEST_SUCCESS: "ADD_REQUEST_SUCCESS",
  ADD_REQUEST_FAILURE: "ADD_REQUEST_FAILURE",

  REMOVE_REQUEST_REQUEST: "REMOVE_REQUEST_REQUEST",
  REMOVE_REQUEST_SUCCESS: "REMOVE_REQUEST_SUCCESS",
  REMOVE_REQUEST_FAILURE: "REMOVE_REQUEST_FAILURE",

  LOGOUT: "LOGOUT"
};
