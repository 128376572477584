import React, { Component } from "react";
import InitLang from "../common/initLang";
import { Loader } from "../common/loader";
import lang from "../../assets/lang";
import { Link } from "react-router-dom";
import Input from "../common/inputs/input";
import CheckBox from "../common/ant-design/checkbox";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../redux/actions";

class AdminLogin extends Component {
  state = {
    data: {
      email: "",
      password: "",
      rememberMe: false,
    },
  };
  handleRemember = () => {
    const data = { ...this.state.data };
    data.rememberMe = data.rememberMe ? false : true;
    this.setState({ data });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = () => {
    this.props.adminLogin(this.state.data);
  };
  render() {
    const { alert, loggingIn } = this.props;
    const { data } = this.state;
    return (
      <div className="showcase-admin">
        <InitLang />
        <div className="container single-grid">
          <div className="gradient-card admin-sign-in">
            <h2 className="head-white">
              <i className="fas fa-user-shield"></i>
              {lang.adminDash[lang.ls()]}
            </h2>
            <h2 className="head-main">{lang.signIn[lang.ls()]}</h2>
            {alert.message && (
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            )}
            <form>
              <Input
                icon="fa fa-user"
                name="email"
                type="text"
                placeholder={lang.email[lang.ls()]}
                onChange={this.handleChange}
              />
              <Input
                icon="fa fa-lock"
                name="password"
                type="password"
                placeholder={lang.passPlace[lang.ls()]}
                onChange={this.handleChange}
              />
              <CheckBox
                onChange={this.handleRemember}
                label={lang.rememberMe[lang.ls()]}
                checked={data.rememberMe}
              />
              <button
                onClick={this.handleSubmit}
                disabled={loggingIn}
                className="btn-ok"
              >
                {loggingIn ? <Loader tiny margin /> : null}
                {lang.logIn[lang.ls()]}
              </button>
            </form>
            <Link to="/admin/issue/forgot-password" className="link">
              {lang.forgotPass[lang.ls()]}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggingIn: state.admin.loggingIn,
    alert: state.alert,
  };
};

const mapDispatchToProps = {
  adminLogin: userActions.adminLogin,
  clearAlerts: alertActions.clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
