import React, { Component } from "react";
import EventShowcase from "../../common/cards/eventshowcase";
import EventData from "../../common/cards/eventdata";
import HostLocation from "../../common/cards/hostloc";
import EventFriends from "../../common/cards/eventfriends";
import { connect } from "react-redux";
import EventNotJoined from "../../common/cards/eventinvite";
import lang from "../../../assets/lang";
import { eventService } from "../../../services/eventService";
import MapContainer from "../../common/maps/map";
import { Loader } from "../../common/loader";
import ReviewModal from "../../common/modals/reviewmodal";
import ReportModal from "../../common/modals/reportmodal";
import { reviewService } from "../../../services/reviewService";
import { reportService } from "../../../services/reportService";
import { userService } from "../../../services/userService";
import history from "../../../utils/history";
import { Modal } from "antd";
import EmptyLabel from "../../common/ant-design/empty";
import UserCard from "../../common/cards/usercard";
import JoinRequestCard from "../../common/cards/joinRequest";
import { inviteService } from "../../../services/inviteService";
import { notifService } from "../../../services/notificationService";
import io from "socket.io-client";
import ReviewWide from "../../common/cards/reviewWide";
const server = process.env.REACT_APP_API_URL;
let socket;
class EventProfile extends Component {
  state = {
    event: {},
    friends: [],
    notJoined: [],
    reviews: [],
    loading: true,
    review: false,
    report: false,
    reviewed: false,
    reported: false,
    joinedShow: false,
    joinedLoad: true,
    joined: [],
    inviting: false,
    invitObj: "",
    requestShow: false,
    requestLoad: true,
    request: [],
    error: "",
  };
  componentDidMount() {
    const { match, type, friends, user } = this.props;
    const { id } = match.params;
    sessionStorage.removeItem("acting");
    eventService.view(id).then((res) => {
      eventService
        .getEvent(id)
        .then((res) => {
          const event = res.data;
          this.setState({ event, loading: false });
          reviewService.checkReview(event._id, this.props.token).then((res) => {
            this.setState({ reviewed: res.data });
          });
          reportService.checkReport(event._id, this.props.token).then((res) => {
            this.setState({ reported: res.data });
          });
          if (type === "User") {
            inviteService
              .getInvitations({ from: user._id, event: event._id })
              .then((res) => {
                const obj = [];
                let friendsJoined = [];
                let friendsNotJoined = [];
                res.data.map((inv) => {
                  return obj.push(inv.to);
                });
                friends.map((friend) => {
                  if (
                    event.joined.includes(friend) &&
                    friendsJoined.length < 3
                  ) {
                    friendsJoined.push(friend);
                  } else if (
                    friendsNotJoined.length < 3 &&
                    !event.joined.includes(friend) &&
                    event.host.id._id !== friend &&
                    !obj.includes(friend)
                  ) {
                    friendsNotJoined.push(friend);
                  }
                  return null;
                });
                userService.getUsersFromArray(friendsJoined).then((res) => {
                  this.setState({ friends: res.data });
                });
                userService.getUsersFromArray(friendsNotJoined).then((res) => {
                  this.setState({ notJoined: res.data });
                });
              });
          }
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.error === "not-found") {
            this.setState({ error: lang.notFound[lang.ls()], loading: false });
          } else {
            this.setState({
              error: lang.networkError[lang.ls()],
              loading: false,
            });
          }
        });
    });
    reviewService.getEventReviews(id).then((res) => {
      this.setState({ reviews: res.data });
    });
  }
  inviteFriend = (id, fcm) => {
    const { token, type, friends, user } = this.props;
    const { event } = this.state;
    socket = io(server);
    this.setState({ inviting: true, invitObj: id });
    inviteService
      .createInvite(token, { to: id, event: event._id })
      .then((res) => {
        notifService
          .sendSingleNotif(token, type, id, fcm, user.name, {
            type: "invite",
            id: event._id,
            text: event.name,
          })
          .then((res) => {
            socket.emit("setNotif");
          });
        if (type === "User") {
          const friendsNotJoined = [];
          inviteService
            .getInvitations({ from: user._id, event: event._id })
            .then((res) => {
              const obj = [];
              res.data.map((inv) => {
                return obj.push(inv.to);
              });
              friends.map((friend) => {
                if (
                  !event.joined.includes(friend) &&
                  friendsNotJoined.length < 3 &&
                  event.host.id._id !== friend &&
                  !obj.includes(friend)
                ) {
                  friendsNotJoined.push(friend);
                }
                return null;
              });
              userService.getUsersFromArray(friendsNotJoined).then((res) => {
                this.setState({ notJoined: res.data });
                this.setState({ inviting: false, invitObj: "" });
              });
            });
        }
      });
  };
  removeEvent = () => {
    const { token } = this.props;
    const { event } = this.state;
    this.setState({ loading: true });
    eventService.archiveEvent(token, event._id).then((res) => {
      this.setState({ loading: false });
      history.replace("/login/events");
    });
  };
  closeModal = (target) => {
    this.setState({ [target]: false });
  };
  openModal = (target) => {
    this.setState({ [target]: true });
  };
  updateRate = (rate) => {
    const event = { ...this.state.event };
    event.rate = rate;
    this.setState({ event });
    reviewService
      .checkReview(this.state.event._id, this.props.token)
      .then((res) => {
        this.setState({ reviewed: res.data });
      });
  };
  updateReport = () => {
    reportService
      .checkReport(this.state.event._id, this.props.token)
      .then((res) => {
        this.setState({ reported: res.data });
      });
  };
  closeJoined = () => {
    this.setState({ joinedShow: false });
  };
  openJoined = () => {
    this.setState({ joinedShow: true, joinedLoad: true });
    const { token, type } = this.props;
    eventService.getJoined(token, type, this.state.event._id).then((res) => {
      this.setState({ joined: res.data.joined }, () => {
        this.setState({ joinedLoad: false });
      });
    });
  };
  closeRequest = () => {
    this.setState({ requestShow: false });
  };
  openRequest = () => {
    this.setState({ requestShow: true, requestLoad: true });
    const { token, type } = this.props;
    eventService
      .getJoinRequests(token, type, this.state.event._id)
      .then((res) => {
        console.log(res.data);
        this.setState({ request: res.data.joinRequests }, () => {
          this.setState({ requestLoad: false });
        });
      });
  };
  render() {
    const {
      event,
      friends,
      notJoined,
      reviews,
      loading,
      review,
      report,
      reviewed,
      reported,
      joined,
      joinedLoad,
      joinedShow,
      requestShow,
      requestLoad,
      request,
      error,
      inviting,
      invitObj,
    } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          {loading || this.props.settFr ? (
            <Loader />
          ) : error ? (
            <EmptyLabel label={error} />
          ) : (
            <div className="profile-event">
              <div className="main">
                <div className="event-showcase">
                  <EventShowcase
                    reviewed={reviewed}
                    reported={reported}
                    open={this.openModal}
                    event={event}
                    removeEvent={this.removeEvent}
                    openJoined={this.openJoined}
                    openRequest={this.openRequest}
                  />
                </div>
                <div className="data">
                  <EventData event={event} />
                </div>
                <div className="network">
                  <HostLocation event={event} user={this.props.user} />
                  {friends.length ? (
                    <EventFriends event={event} friends={friends} />
                  ) : null}
                  {notJoined.length ? (
                    <EventNotJoined
                      event={event}
                      inviteFriend={this.inviteFriend}
                      notJoined={notJoined}
                      inviting={inviting}
                      invitObj={invitObj}
                    />
                  ) : null}
                </div>
                <div
                  className={`location ${
                    friends.length === 0 && notJoined.length === 0 && "gr-loc"
                  }`}
                >
                  <div className="label">{lang.location[lang.ls()]}</div>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {lang.doubleClick[lang.ls()]}
                  </div>
                  <div className="mapping" style={{ direction: "ltr" }}>
                    <MapContainer markers={[event]} />
                  </div>
                </div>
              </div>
              {reviews.length ? (
                <div className="reviews">
                  <div className="label">{lang.reviews[lang.ls()]}</div>
                  <div className="line"></div>
                  {reviews.map((rev) => {
                    return <ReviewWide rev={rev} />;
                  })}
                </div>
              ) : null}
            </div>
          )}
        </div>
        {review ? (
          <ReviewModal
            updateRate={this.updateRate}
            close={this.closeModal}
            event={event._id}
            name={event.name}
            host={event.host.id}
          />
        ) : null}
        {report ? (
          <ReportModal
            updateReport={this.updateReport}
            close={this.closeModal}
            id={event._id}
            rtype="Event"
          />
        ) : null}
        <Modal
          title={lang.usersJoined[lang.ls()]}
          visible={joinedShow}
          onCancel={this.closeJoined}
          footer={null}
          width={400}
          bodyStyle={{
            height: "400px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          {joinedLoad ? (
            <Loader />
          ) : joined && joined.length ? (
            joined.map((user) => {
              return <UserCard key={user._id} user={user} />;
            })
          ) : (
            <EmptyLabel label={lang.noUsersFound[lang.ls()]} />
          )}
        </Modal>
        <Modal
          title={lang.joinRequests[lang.ls()]}
          visible={requestShow}
          onCancel={this.closeRequest}
          footer={null}
          width={400}
          bodyStyle={{
            height: "400px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          {requestLoad ? (
            <Loader />
          ) : request && request.length ? (
            request.map((user) => {
              return (
                <JoinRequestCard key={user._id} event={event._id} user={user} />
              );
            })
          ) : (
            <EmptyLabel label={lang.noUsersFound[lang.ls()]} />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    type: state.login.type,
    token: state.login.token,
    friends: state.friend.friends,
    settFr: state.friend.setting,
  };
};

export default connect(mapStateToProps, null)(EventProfile);
