import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/invites";

export const inviteService = {
  createInvite,
  getInvitations,
};
function createInvite(token, body) {
  return axios.post(endPoint + "/", body, {
    params: {
      token,
    },
  });
}
function getInvitations(query) {
  return axios.get(endPoint + "/", {
    params: {
      ...query,
    },
  });
}
