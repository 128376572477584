import { requestTypes } from "../types/requestTypes";

export function requestManage(state = { requests: [] }, action) {
  switch (action.type) {
    case requestTypes.SET_REQUEST_REQUEST:
      return {
        ...state,
        setting: true
      };
    case requestTypes.SET_REQUEST_SUCCESS:
      return {
        requests: action.data
      };
    case requestTypes.SET_REQUEST_FAILURE:
      return { ...state };
    case requestTypes.ADD_REQUEST_REQUEST:
      return {
        ...state,
        sending: true
      };
    case requestTypes.ADD_REQUEST_SUCCESS:
      return {
        requests: [action.data, ...state.requests]
      };
    case requestTypes.ADD_REQUEST_FAILURE:
      return { ...state };
    case requestTypes.REMOVE_REQUEST_REQUEST:
      return {
        ...state,
        canceling: true
      };
    case requestTypes.REMOVE_REQUEST_SUCCESS:
      return {
        requests: state.requests.filter(x => x !== action.data)
      };
    case requestTypes.REMOVE_REQUEST_FAILURE:
      return { ...state };
    case requestTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
