import React from "react";
import "./select.scss";
import { Select } from "antd";
import lang from "../../../assets/lang";
const { Option } = Select;

const Sorting = ({ onChange }) => {
  return (
    <div className="sorting">
      <div className="label">{lang.sortBy[lang.ls()]}</div>
      <Select defaultValue="created_at" onChange={onChange}>
        <Option value="created_at">{lang.latest[lang.ls()]}</Option>
        <Option value="views">{lang.views[lang.ls()]}</Option>
        <Option value="likesNumber">{lang.likes[lang.ls()]}</Option>
        <Option value="rate">{lang.rate[lang.ls()]}</Option>
      </Select>
    </div>
  );
};

export default Sorting;
