import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/requests";

export const requestService = {
  sendRequest,
  removeRequest,
  acceptRequest,
  getFriendRequests,
  getRequested,
  getProfRequest
};
function sendRequest(token, request) {
  return axios.post(endPoint + "/", request, {
    params: {
      token
    }
  });
}
function removeRequest(request) {
  return axios.put(endPoint + "/", null, {
    params: {
      request
    }
  });
}
function acceptRequest(request) {
  return axios.put(endPoint + "/accept", null, {
    params: {
      request
    }
  });
}
function getFriendRequests(token) {
  return axios.get(endPoint + "/", {
    params: {
      token
    }
  });
}
function getRequested(token) {
  return axios.get(endPoint + "/requested", {
    params: {
      token
    }
  });
}
function getProfRequest(id) {
  return axios.get(endPoint + "/prof-request", {
    params: {
      id
    }
  });
}
