import { Modal } from "antd";
const categ = require.context("../assets/categ", true);
const { confirm } = Modal;

export function getImage(isUser) {
  if (isUser)
    return "https://res.cloudinary.com/momants/image/upload/v1586633800/WhatsApp_Image_2020-04-11_at_7.08.04_PM_nttcya.jpg";
  else
    return "https://res.cloudinary.com/momants/image/upload/v1586633806/WhatsApp_Image_2020-04-11_at_7.08.03_PM_tyvrxq.jpg";
}

export function getMapCateg(name) {
  return categ(`./${name}.png`);
}

export function eventImage() {
  return "https://res.cloudinary.com/momants/image/upload/v1588175087/1-4_xfar5h.jpg";
}

export function commaSep(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function modalConfirm(title, content, onOk, onCancel) {
  confirm({
    title: title,
    content: content,
    onOk: onOk,
    onCancel: onCancel,
  });
}

export function success(content) {
  Modal.success({ content: content });
}

export function getAge(birth) {
  const today = new Date();
  let age_now = today.getFullYear() - birth.getFullYear();
  let m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age_now--;
  }
  return age_now;
}

export function getPeriod(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + " yrs ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " mons ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hrs ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " mins ago";
  }
  return Math.floor(seconds) + " sec ago";
}
