import React, { Component } from "react";
import Menu from "../common/menu";
import lang from "../../assets/lang";
import history from "../../utils/history";

class AdminMenu extends Component {
  state = {
    sections: [
      {
        icon: "fa fa-home",
        label: lang.home[lang.ls()],
        to: "/admin/login/home",
      },
      {
        icon: "fas fa-users",
        label: lang.users[lang.ls()],
        to: "/admin/login/users",
      },
      {
        icon: "far fa-building",
        label: lang.companies[lang.ls()],
        to: "/admin/login/companies",
      },
      {
        icon: "fa fa-calendar-alt",
        label: lang.events[lang.ls()],
        to: "/admin/login/events",
      },
      {
        icon: "far fa-address-book",
        label: lang.contacts[lang.ls()],
        to: "/admin/login/contacts",
      },
      {
        icon: "far fa-comment-alt",
        label: lang.feedbacks[lang.ls()],
        to: "/admin/login/feedbacks",
      },
    ],
  };
  render() {
    return (
      <div className="menu">
        <img
          style={{ width: "110px", marginBottom: "0", cursor: "pointer" }}
          src={
            "https://res.cloudinary.com/momants/image/upload/v1588176833/mainLogo_w2yo4w.png"
          }
          onClick={() => history.push("/admin/login/home")}
          alt="logo"
        />
        <div className="line"></div>
        <Menu sections={this.state.sections} />
        <div
          style={{ fontSize: "0.7rem", margin: "0.5rem 0", color: "#de7e4c" }}
          className="copy"
        >
          © 2020 Momants
        </div>
      </div>
    );
  }
}

export default AdminMenu;
