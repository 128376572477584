import React from "react";
import { Pagination } from "antd";

const Paginate = ({ def, total, size, onChange }) => {
  return (
    <div style={{ direction: "ltr" }} className="paginate">
      <Pagination
        defaultCurrent={def}
        total={total}
        pageSize={size}
        onChange={onChange}
      />
    </div>
  );
};

export default Paginate;
