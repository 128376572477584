import React from "react";
import Joi from "joi-browser";
import Form from "../form";
import lang from "../../../assets/lang";
import { connect } from "react-redux";
import { reviewService } from "../../../services/reviewService";
import io from "socket.io-client";
import { notifService } from "../../../services/notificationService";
const server = process.env.REACT_APP_API_URL;
let socket;

class ReviewModal extends Form {
  state = {
    data: {
      rate: 0,
      review: "",
      event: "",
    },
    loading: false,
  };

  schema = {
    review: Joi.string().min(1).required().label("Review"),
    rate: Joi.number().required().label("Rate"),
    event: Joi.string().required(),
  };

  componentDidMount() {
    const data = { ...this.state.data };
    data.event = this.props.event;
    this.setState({ data });
  }

  doSubmit = () => {
    const { token, type, event, host, name, ownInfo } = this.props;
    socket = io(server);
    this.setState({ loading: true });
    reviewService.createReview(token, type, this.state.data).then((res) => {
      reviewService
        .updateRate(this.state.data.event)
        .then((res) => {
          this.props.updateRate(res.data);
          this.setState({ loading: false });
          this.props.close("review");
          notifService
            .sendSingleNotif(
              token,
              type,
              host._id,
              host.fcmToken,
              ownInfo.name,
              {
                type: "review",
                id: event,
                text: name,
              }
            )
            .then((res) => {
              socket.emit("setNotif");
            });
        })
        .catch((err) => console.log(err.res));
    });
  };
  render() {
    const { loading } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="div-modal">
          <div className="modal-items">
            <div className="close" onClick={() => this.props.close("review")}>
              <i className="fas fa-times"></i>
            </div>
            <div className="head">
              <i className="far fa-comment-alt"></i>{" "}
              {lang.rateAndReview[lang.ls()]}
            </div>
            {this.renderRate("rate")}
            {this.renderTextArea("review", lang.reviewPlace[lang.ls()])}
            {this.renderButton({
              label: lang.submit[lang.ls()],
              icon: loading ? "loading" : null,
            })}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
    ownInfo: state.login.user,
  };
};

export default connect(mapStateToProps, null)(ReviewModal);
