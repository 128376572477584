import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/notif";

export const notifService = {
  sendSingleNotif,
  getMyNotifications,
  sendMultipleNotif,
  readNotif,
  getAllNotif,
  removeNotif,
};
function removeNotif(token, id) {
  return axios.put(endPoint + "/remove", null, {
    params: {
      token,
      id,
    },
  });
}
function sendSingleNotif(token, type, to, fcm, name, notif) {
  return axios.post(
    endPoint + "/single",
    { notif },
    {
      params: {
        token,
        type,
        to,
        fcm,
        name,
      },
    }
  );
}
function getMyNotifications(token) {
  return axios.get(endPoint + "/", {
    params: {
      token,
    },
  });
}
function sendMultipleNotif(token, type, target, host, notif) {
  return axios.post(
    endPoint + "/multiple",
    { notif },
    {
      params: {
        token,
        type,
        target,
        host,
      },
    }
  );
}
function readNotif(notif, user) {
  return axios.put(endPoint + "/read", null, {
    params: {
      user,
      notif,
    },
  });
}
function getAllNotif(token, skip, limit) {
  return axios.get(endPoint + "/all", {
    params: {
      token,
      limit,
      skip,
    },
  });
}
