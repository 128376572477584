import React from "react";
import ChatItem from "./chatItem";
import { connect } from "react-redux";

const ChatsMenu = ({ chatting, activeChat, selectChatRoom, user }) => {
  return (
    <React.Fragment>
      {chatting.map((ch) => {
        if (!ch.removed) {
          return (
            <ChatItem
              key={ch._id}
              chat={ch}
              selected={
                activeChat &&
                (activeChat.user1.id._id === user._id ||
                  activeChat.user2.id._id === user._id) &&
                (activeChat.user1.id._id === ch.id ||
                  activeChat.user2.id._id === ch.id)
                  ? true
                  : false
              }
              selectChat={selectChatRoom}
            />
          );
        } else {
          return null;
        }
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
  };
};

export default connect(mapStateToProps, null)(ChatsMenu);
