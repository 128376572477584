import React, { useState } from "react";
import lang from "../../../assets/lang";
import { Switch, Modal } from "antd";
import { Loader } from "../../common/loader";
import { userService } from "../../../services/userService";
import { connect } from "react-redux";
import BasicInput from "../../common/ant-design/input";
import { success } from "../../../utils/helperMethods";

const UserSettings = ({
  username,
  friend,
  chFriend,
  setUsername,
  changeFriend,
  token,
}) => {
  const [usernameMod, setUsernameMod] = useState(false);
  const [usernameCheck, setUsernameCheck] = useState(false);
  const [usernameValid, setUsernameValid] = useState(null);
  const [usernameText, setUsernameText] = useState("");
  const [loading, setLoading] = useState(false);
  const validateUsername = (e) => {
    setUsernameText(e.target.value);
    if (e.target.value.length >= 4) {
      setUsernameCheck(true);
      userService.validateUsername(e.target.value).then((res) => {
        setUsernameValid(res.data.valid);
        setUsernameCheck(false);
      });
    } else {
      setUsernameValid(false);
      setUsernameCheck(false);
    }
  };
  const submitUsername = () => {
    setLoading(true);
    userService.changeUsername(token, usernameText).then((res) => {
      setUsername(usernameText);
      setUsernameMod(false);
      setLoading(false);
      setUsernameText("");
      setUsernameValid(null);
      success(lang.usernameChanged[lang.ls()]);
    });
  };
  const cancelUsername = () => {
    setUsernameMod(false);
  };
  const editUsername = () => {
    setUsernameMod(true);
  };
  return (
    <div className="user-settings">
      <div className="sett-item">
        <div className="desc">
          <i className="fas fa-user"></i>
          <div className="info">
            <div className="head">{lang.changeUsername[lang.ls()]}</div>
            <div className="talk">{username}</div>
          </div>
        </div>
        <button onClick={editUsername} className="btn-ok">
          {lang.edit[lang.ls()]}
        </button>
      </div>
      <div className="sep"></div>
      <div className="sett-item">
        <div className="head">{lang.requests[lang.ls()]}</div>
        <Switch disabled={chFriend} checked={friend} onChange={changeFriend} />
        {chFriend && <Loader tinycolor />}
      </div>
      <Modal
        title={lang.changeUsername[lang.ls()]}
        visible={usernameMod}
        onOk={submitUsername}
        onCancel={cancelUsername}
        okButtonProps={{ disabled: !usernameValid }}
      >
        <div className="validate">
          <BasicInput
            placeholder={lang.username[lang.ls()]}
            tooltip={lang.usernameUnique[lang.ls()]}
            value={usernameText}
            onChange={validateUsername}
            classes="sufix-input"
          />
          <div className="indicator">
            {usernameCheck ? (
              <Loader tinycolor />
            ) : usernameValid ? (
              <i className="valid fas fa-check-square"></i>
            ) : usernameValid === false ? (
              <i className="invalid fas fa-times-circle"></i>
            ) : null}
          </div>
        </div>
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

export default connect(mapStateToProps, null)(UserSettings);
