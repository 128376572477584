import React from "react";
import lang from "../../assets/lang";

const Categories = () => {
  return (
    <div className="categories">
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1589918471/Category_Sports_pzltsf.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.sports[lang.ls()]}</div>
      </div>
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1589918474/Category_Learning_mdgzrz.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.learing[lang.ls()]}</div>
      </div>
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1589918471/Category_Exhibitions_y2zzsa.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.exhib[lang.ls()]}</div>
      </div>
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1591013443/fest_wdgxry.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.festival[lang.ls()]}</div>
      </div>
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1589918491/Category_Social_fuxn0v.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.social[lang.ls()]}</div>
      </div>
      <div
        className="item"
        style={{
          background: `url(https://res.cloudinary.com/momants/image/upload/v1589918505/Category_Adventures_lydrkz.jpg) no-repeat center center/cover`,
        }}
      >
        <div className="head">{lang.category[lang.ls()]}</div>
        <div className="type">{lang.adventure[lang.ls()]}</div>
      </div>
    </div>
  );
};

export default Categories;
