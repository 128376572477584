import React from "react";
import lang from "../../assets/lang";

const AtService = () => {
  return (
    <div className="container service">
      <div className="head-main">{lang.atService[lang.ls()]}</div>
      <div className="line"></div>
      <div className="section">
        <div className="item">
          <i className="fas fa-search-location"></i>

          <div className="head">{lang.exploreEvents[lang.ls()]}</div>
          <div className="talk">{lang.exploreTalk[lang.ls()]}</div>
        </div>
        <div className="item">
          <i className="fas fa-users"></i>
          <div className="head">{lang.connectFriends[lang.ls()]}</div>
          <div className="talk">{lang.connectTalk[lang.ls()]}</div>
        </div>
        <div className="item">
          <i className="fas fa-lock"></i>
          <div className="head">{lang.secure[lang.ls()]}</div>
          <div className="talk">{lang.secureTalk[lang.ls()]}</div>
        </div>
        <div className="item">
          <i className="fas fa-comments"></i>
          <div className="head">{lang.chatting[lang.ls()]}</div>
          <div className="talk">{lang.chattingTalk[lang.ls()]}</div>
        </div>
      </div>
    </div>
  );
};

export default AtService;
