import React from "react";
import { Checkbox } from "antd";

const CheckBox = ({ label, onChange, name, checked }) => {
  return (
    <Checkbox
      checked={checked}
      name={name}
      onChange={onChange}
      style={{
        maxWidth: "80%",
        marginTop: "10px",
        marginLeft: "10px",
        textAlign: "center",
        color: "#de7e4c",
      }}
    >
      {" "}
      {label}
    </Checkbox>
  );
};

export default CheckBox;
