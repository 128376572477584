import React from "react";
import "./common.scss";
import Form from "./form";
import Joi from "joi-browser";
import lang from "../../assets/lang";
import { contactService } from "../../services/contactService";
import { success } from "../../utils/helperMethods";

class ContactUs extends Form {
  state = {
    data: {
      name: "",
      email: "",
      message: "",
    },
    loading: false,
  };
  schema = {
    name: Joi.string().min(1).required().label("Full Name"),
    email: Joi.string().min(5).required().label("Email"),
    message: Joi.string().min(1).required().label("Message"),
  };
  doSubmit = () => {
    this.setState({ loading: true });
    contactService.createContact(this.state.data).then((res) => {
      this.setState({
        loading: false,
        data: {
          name: "",
          email: "",
          message: "",
        },
      });
      success(lang.contactSent[lang.ls()]);
    });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="contact">
        <div className="head-main">{lang.getinTouch[lang.ls()]}</div>
        <div className="email">
          <i className="far fa-envelope"></i>
          <div className="info">info@momants.com</div>
        </div>
        <div className="talk">{lang.contactTalk[lang.ls()]}</div>
        {this.renderTextArea("message", lang.enterMess[lang.ls()])}
        <div className="lower">
          {this.renderInput({
            placeholder: lang.fullName[lang.ls()],
            name: "name",
            classes: "normal-input",
          })}
          {this.renderInput({
            placeholder: lang.email[lang.ls()],
            name: "email",
            classes: "normal-input",
          })}
          {this.renderButton({
            label: lang.send[lang.ls()],
            icon: loading ? "loading" : null,
          })}
        </div>
      </div>
    );
  }
}

export default ContactUs;
