import { followTypes } from "../types/followTypes";
import { userService } from "../../services/userService";
import { companyService } from "../../services/companyService";
import { notifService } from "../../services/notificationService";
import io from "socket.io-client";

export const followActions = {
  setFollows,
  addFollow,
  removeFollow,
  logout,
};
const server = process.env.REACT_APP_API_URL;
let socket;
function setFollows(token, type) {
  return (dispatch) => {
    dispatch(request());
    if (type === "User") {
      userService
        .getFollowIds(token)
        .then((res) => {
          dispatch(success(res.data));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .getFollowIds(token)
        .then((res) => {
          dispatch(success(res.data));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: followTypes.SET_FOLLOW_REQUEST };
  }
  function success(data) {
    return { type: followTypes.SET_FOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: followTypes.SET_FOLLOW_FAILURE, error };
  }
}
function addFollow(token, type, target, targetType, fcm, name) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    if (type === "User") {
      userService
        .followAccount(token, targetType, target)
        .then((res) => {
          socket.emit("setGlobal");
          notifService
            .sendSingleNotif(token, type, target, fcm, name, { type: "follow" })
            .then((res) => {
              socket.emit("setNotif");
            });
          dispatch(success(target));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .followAccount(token, targetType, target)
        .then((res) => {
          socket.emit("setGlobal");
          notifService
            .sendSingleNotif(token, type, target, fcm, name, { type: "follow" })
            .then((res) => {
              socket.emit("setNotif");
            });
          dispatch(success(target));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: followTypes.ADD_FOLLOW_REQUEST };
  }
  function success(data) {
    return { type: followTypes.ADD_FOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: followTypes.ADD_FOLLOW_FAILURE, error };
  }
}
function removeFollow(token, type, target, targetType) {
  return (dispatch) => {
    socket = io(server);
    dispatch(request());
    if (type === "User") {
      userService
        .unfollowAccount(token, targetType, target)
        .then((res) => {
          socket.emit("setGlobal");
          dispatch(success(target));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    } else {
      companyService
        .unfollowAccount(token, targetType, target)
        .then((res) => {
          socket.emit("setGlobal");
          dispatch(success(target));
        })
        .catch((err) => {
          const { data } = err.response;
          dispatch(failure(data.error));
        });
    }
  };
  function request() {
    return { type: followTypes.REMOVE_FOLLOW_REQUEST };
  }
  function success(data) {
    return { type: followTypes.REMOVE_FOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: followTypes.REMOVE_FOLLOW_FAILURE, error };
  }
}
function logout() {
  return { type: followTypes.LOGOUT };
}
