import { userTypes } from "../types";

export function authentication(state = {}, action) {
  switch (action.type) {
    case userTypes.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        token: action.data.token,
        remember: action.remember,
        auth: {
          _id: action.data.response.auth._id,
        },
        user: {
          _id: action.data.response.user._id,
          name: action.data.response.user.name,
          image: action.data.response.user.image,
          city: action.data.response.user.city,
        },
        type: action.data.response.auth.type,
        notif: action.data.response.auth.notifications,
      };
    case userTypes.CHANGE_IMAGE:
      return {
        ...state,
        user: {
          _id: state.user._id,
          name: state.user.name,
          image: action.image,
          city: state.user.city,
        },
      };
    case userTypes.CHANGE_DATA:
      return {
        ...state,
        user: {
          _id: state.user._id,
          name: action.name,
          image: state.user.image,
          city: action.city,
        },
      };
    case userTypes.CHANGE_NOTIF:
      return {
        ...state,
        notif: action.value,
      };
    case userTypes.LOGIN_FAILURE:
      return {};
    case userTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
