import React, { useState, useEffect } from "react";
import { getImage } from "../../../utils/helperMethods";
import { Link } from "react-router-dom";
import lang from "../../../assets/lang";
import history from "../../../utils/history";
import { connect } from "react-redux";
import { chatActions } from "../../../redux/actions/chatActions";
import { Modal } from "antd";
import { userService } from "../../../services/userService";

const EventFriends = ({ event, friends, linkChat, seeAllBtn, type, frds }) => {
  const [allNot, setAllNot] = useState([]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (type === "User") {
      let friendsJoined = [];
      frds.map((friend) => {
        if (event.joined.includes(friend)) {
          friendsJoined.push(friend);
        }
        return null;
      });
      userService.getUsersFromArray(friendsJoined).then((res) => {
        setAllNot(res.data);
      });
    }
  }, [event, type, frds]);
  const closeModal = () => {
    setModal(false);
  };
  function dispatchChat(recv) {
    seeAllBtn();
    linkChat(recv);
    history.push("/login/chat");
  }
  return (
    <div className="friends">
      <div className="link-head">
        <div className="head">{lang.gofriends[lang.ls()]}</div>
        <div onClick={() => setModal(true)} className="btn-ok">
          {lang.seeall[lang.ls()]}
        </div>
      </div>
      <div className="items">
        {friends.map((fr) => {
          return (
            <div key={fr._id} className="info">
              <div
                className="img"
                style={{
                  background: `url(${
                    fr.image ? fr.image.url : getImage(fr.username)
                  }) no-repeat center center/cover`,
                }}
              ></div>
              <Link to={`/login/users/${fr._id}/User`} className="name">
                {fr.name}
              </Link>
              <button
                onClick={() =>
                  dispatchChat({
                    id: fr._id,
                    type: fr.username ? "User" : "Company",
                  })
                }
                className="btn-mess"
              >
                {lang.message[lang.ls()]}
              </button>
            </div>
          );
        })}
      </div>
      <Modal
        title={lang.invitefriends[lang.ls()]}
        visible={modal}
        onCancel={closeModal}
        footer={null}
        bodyStyle={{
          height: "400px",
          overflow: "auto",
        }}
      >
        <div className="friends">
          <div className="items">
            {allNot.map((fr) => {
              return (
                <div key={fr._id} className="info">
                  <div
                    className="img"
                    style={{
                      background: `url(${
                        fr.image ? fr.image.url : getImage(fr.username)
                      }) no-repeat center center/cover`,
                    }}
                  ></div>
                  <Link to={`/login/users/${fr._id}/User`} className="name">
                    {fr.name}
                  </Link>
                  <button
                    onClick={() =>
                      dispatchChat({
                        id: fr._id,
                        type: fr.username ? "User" : "Company",
                      })
                    }
                    className="btn-mess"
                  >
                    {lang.message[lang.ls()]}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.login.type,
    frds: state.friend.friends,
  };
};

const mapDispatchToProps = {
  linkChat: chatActions.linkChat,
  seeAllBtn: chatActions.seeAllBtn,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventFriends);
