import React from "react";
import { Link } from "react-scroll";
import lang from "../../assets/lang";
import MapContainer from "../common/maps/map";

const Intro = ({ moments }) => {
  return (
    <div className="intro">
      <div className="upper">
        <h1 className="head-white" style={{ textAlign: "center" }}>
          {lang.slogan[lang.ls()]}
        </h1>
        <div className="line"></div>
        <p className="parag parag-center-white">{lang.intro[lang.ls()]}</p>
        <Link
          activeClass="active"
          to="read-more"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          className="btn-ok"
        >
          {lang.readMore[lang.ls()]}
        </Link>
      </div>
      <div className="map">
        <MapContainer land show markers={moments} />
      </div>
    </div>
  );
};

export default Intro;
