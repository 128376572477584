import React, { useState, useEffect } from "react";
import { eventService } from "../../services/eventService";
import { Loader } from "../common/loader";
import EventData from "../common/cards/eventdata";
import lang from "../../assets/lang";
import MapContainer from "../common/maps/map";
import history from "../../utils/history";
import { Slide } from "react-slideshow-image";
import { eventImage } from "../../utils/helperMethods";

const TempMomentProf = ({ match }) => {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const { id } = match.params;
    eventService.getEvent(id).then((res) => {
      setEvent(res.data);
      setLoading(false);
    });
  }, [match]);
  const sliderProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
  };
  const imge = eventImage();
  return (
    <div className="showcase">
      <div className="faded single-grid">
        <div className="white-card">
          {loading ? (
            <Loader />
          ) : (
            <div className="profile-event">
              <div className="main">
                <div className="event-showcase">
                  <p
                    style={{
                      fontSize: "1.5rem",
                      color: "#ef7a30",
                      fontWeight: "bolder",
                    }}
                  >
                    {event.name}
                  </p>
                  <div className="img">
                    {event.images.length > 1 ? (
                      <div
                        style={{ direction: "ltr" }}
                        className="slide-container"
                      >
                        <Slide className="slider" {...sliderProperties}>
                          {event.images.map((img) => {
                            return (
                              <div className="each-slide" key={img.url}>
                                <div
                                  style={{
                                    background: `url(${img.url}) no-repeat center center/cover`,
                                  }}
                                ></div>
                              </div>
                            );
                          })}
                        </Slide>
                      </div>
                    ) : (
                      <div
                        style={{
                          background: `url(${
                            event.images[0] ? event.images[0].url : imge
                          }) no-repeat center center/cover`,
                        }}
                        className="slide-container"
                      ></div>
                    )}
                    <p className="city">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {event.city.name[lang.ls()]}
                    </p>
                    {event.rate && event.rate !== 0 ? (
                      <p className="rate">
                        <i className="fas fa-star"></i> {event.rate}
                      </p>
                    ) : null}
                  </div>
                  <button
                    style={{ margin: "1rem auto", padding: "0.5rem 3rem" }}
                    className="btn-ok"
                    onClick={() => {
                      history.push(`/login/events/${event._id}`);
                      sessionStorage.setItem("acting", event._id);
                    }}
                  >
                    {lang.join[lang.ls()]}
                  </button>
                </div>
                <div className="data">
                  <EventData event={event} show />
                </div>
                <div className="location gr-loc">
                  <div className="label">{lang.location[lang.ls()]}</div>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "0.5rem",
                      color: "white",
                    }}
                  >
                    {lang.doubleClick[lang.ls()]}
                  </div>
                  <div className="mapping" style={{ direction: "ltr" }}>
                    <MapContainer markers={[event]} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TempMomentProf;
