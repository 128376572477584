import React, { useState } from "react";
import lang from "../../../assets/lang";
import { Link } from "react-router-dom";
import { Rate } from "antd";
import { reviewService } from "../../../services/reviewService";

const UserReviewCard = ({ review, updateState }) => {
  const [closing, setClosing] = useState(false);
  const { name, _id, username } = review.event.host.id;
  const removing = () => {
    setClosing(true);
    reviewService.removeReview(review._id).then(res => {
      updateState(review._id);
      reviewService.updateRate(review.event._id);
    });
  };
  return (
    <div className="user-review-card">
      <Link to={`/login/events/${review.event._id}`} className="name">
        {review.event.name}
      </Link>
      <p className="by">{lang.hostedby[lang.ls()]}</p>
      <div className="info">
        <Link
          to={`/login/users/${_id}/${review.event.host.type}`}
          className="host-name"
        >
          {name}
        </Link>
        {username && <p className="username">@{username}</p>}
        <Rate className="rate" disabled value={review.rate} />
      </div>
      <div className="feed">{review.review}</div>
      <div className="remove">
        {closing ? null : <i onClick={removing} className="fas fa-times"></i>}
      </div>
    </div>
  );
};

export default UserReviewCard;
