import React, { useState, useEffect } from "react";
import "./profile.scss";
import ProfileHeader from "./profileheader";
import ProfileContents from "./profilecontent";
import { adminService } from "../../../services/adminService";
import { reportService } from "../../../services/reportService";
import { Loader } from "../../common/loader";
import { Modal } from "antd";
import lang from "../../../assets/lang";
const { confirm } = Modal;

const Profile = ({ match }) => {
  const [data, setData] = useState({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const { id, type } = match.params;
    setLoading(true);
    reportService.getAdminReports(id).then((res) => {
      setReports(res.data);
      if (type === "User") {
        adminService.getUser(id).then((res) => {
          setData(res.data);
          setLoading(false);
        });
      } else {
        adminService.getCompany(id).then((res) => {
          setData(res.data);
          setLoading(false);
        });
      }
    });
  }, [match]);
  const blockAccount = () => {
    const res = { ...data };
    confirm({
      title: lang.blockModalTitle[lang.ls()],
      content: lang.blockModalDesc[lang.ls()],
      onOk: () => {
        return adminService
          .blockAccount(res._id, res.auth.type)
          .then((result) => {
            res.isBlocked = true;
            setData(res);
          });
      },
      onCancel() {},
    });
  };
  const unblockAccount = () => {
    const res = { ...data };
    confirm({
      title: lang.unBlockModalTitle[lang.ls()],
      content: lang.unBlockModalDesc[lang.ls()],
      onOk: () => {
        return adminService
          .unblockAccount(res._id, res.auth.type)
          .then((result) => {
            res.isBlocked = false;
            setData(res);
          });
      },
      onCancel() {},
    });
  };
  return (
    <div className="content">
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <ProfileHeader
            blockAccount={blockAccount}
            unblockAccount={unblockAccount}
            data={data}
            count={reports.length}
          />
          <ProfileContents data={data} reports={reports} />
        </React.Fragment>
      )}
    </div>
  );
};

export default Profile;
