import React, { Component } from "react";
import "./App.scss";
import Home from "./components";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { store } from "./redux/store";
import { Loader } from "./components/common/loader";
import { isAndroid, isIOS, isBrowser } from "react-device-detect";

class App extends Component {
  state = {
    hydrate: false,
  };
  componentDidMount() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
    var x = document.getElementsByTagName("BODY")[0];
    localStorage.getItem("lang") === "ar"
      ? (x.style.direction = "rtl")
      : (x.style.direction = "ltr");
    persistStore(store, {}, () => {
      this.setState({ hydrate: true });
    });
    // if (isAndroid) {
    //   window.location = "momants://";
    //   setTimeout(() => {
    //     window.location.href = "https://play.google.com/";
    //   }, 500);
    // }
    // if (isIOS) {
    //   window.location = "momants://";
    //   setTimeout(() => {
    //     window.location.href = "https://www.apple.com/ios/app-store/";
    //   }, 500);
    // }
  }
  render() {
    const { hydrate } = this.state;
    return !hydrate ? (
      <Loader />
    ) : (
      <Provider store={store}>
        <Home />
      </Provider>
    );
  }
}

export default App;
