import React from "react";

const Head = ({ label, mid = false }) => {
  return (
    <div
      style={{
        padding: mid ? "2rem 0" : "0",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <h1 className="head-line">{label}</h1>
    </div>
  );
};

export default Head;
