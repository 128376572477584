import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const endPoint = "/contacts";

export const contactService = {
  createContact,
  getContacts,
  getCount,
  reply,
  removeContact,
};
function createContact(contact) {
  return axios.post(endPoint + "/", contact);
}
function getContacts(filter, page, limit) {
  return axios.get(endPoint + "/", {
    params: {
      page,
      limit,
      ...filter,
    },
  });
}
function getCount(filter) {
  return axios.get(endPoint + "/count", {
    params: {
      ...filter,
    },
  });
}
function reply(id, reply, lang) {
  return axios.post(endPoint + "/reply", null, {
    params: {
      id,
      reply,
      lang,
    },
  });
}
function removeContact(id) {
  return axios.put(endPoint + "/remove", null, {
    params: {
      id,
    },
  });
}
