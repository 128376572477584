import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import lang from "../../assets/lang";
import history from "../../utils/history";
import { Loader } from "../common/loader";
import { authService } from "../../services/authService";
import { success } from "../../utils/helperMethods";

const FrogotSent = () => {
  const authId = sessionStorage.getItem("authId");
  const email = sessionStorage.getItem("email");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!email && !authId) {
      history.replace("/");
    }
    return () => {
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("authId");
    };
  }, [email, authId]);
  const resendEmail = () => {
    setLoading(true);
    authService.forgotPassword(email, lang.ls()).then((res) => {
      setLoading(false);
      success("Re-Sending Email done Successfully !!");
    });
  };
  return (
    <div className="showcase">
      <div className="faded">
        <div className="container single-grid">
          <div className="issue">
            <h1 className="head-white issue-head">
              {lang.oneLastStep[lang.ls()]}
            </h1>
            <p className="issue-body">{lang.forgotSentDesc[lang.ls()]}</p>
            <Link to="/" className="btn-issue">
              {lang.returnHome[lang.ls()]}
            </Link>
            <div className="issue-action">
              <Link to="/issue/forgot-password" className="head">
                {email}
              </Link>
              <p className="desc">{lang.wrongEmail[lang.ls()]}</p>
            </div>
            <div className="issue-action">
              <Link onClick={resendEmail} className="head">
                {loading ? <Loader tiny margin /> : lang.resendReset[lang.ls()]}
              </Link>
              <p className="desc">{lang.didnotEmail[lang.ls()]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrogotSent;
