import React, { useState } from "react";
import "../common.scss";
import { Link } from "react-router-dom";
import { getImage } from "../../../utils/helperMethods";
import { Menu, Dropdown, Upload, Modal } from "antd";
import { connect } from "react-redux";
import history from "../../../utils/history";
import { blockActions } from "../../../redux/actions/blockActions";
import { Loader } from "../loader";
import { userActions } from "../../../redux/actions";
import lang from "../../../assets/lang";
const { confirm } = Modal;

const PicName = ({
  id,
  username,
  code,
  image,
  name,
  own,
  token,
  type,
  chatting,
  addBlock,
  reported,
  open,
  changeImage,
}) => {
  const [currImg, setCurrImg] = useState(image ? image.url : "");
  const [loading, setLoading] = useState(false);
  const menu = (
    <Menu className="drop-colored">
      <Menu.Item>
        <div className="btn-drop" onClick={() => block()}>
          <i className="fas fa-ban"></i> {lang.block[lang.ls()]}
        </div>
      </Menu.Item>
      {reported && (
        <Menu.Item>
          <div onClick={open} className="btn-drop">
            <i className="far fa-file-alt"></i> {lang.report[lang.ls()]}
          </div>
        </Menu.Item>
      )}
    </Menu>
  );
  const props = {
    name: "image",
    data: {
      token,
    },
    action:
      type === "User"
        ? `${process.env.REACT_APP_API_URL}/users/set-image`
        : `${process.env.REACT_APP_API_URL}/companies/set-image`,
    onChange(info) {
      setLoading(true);
      if (info.file.status === "done") {
        changeImage(info.file.response);
        setCurrImg(info.file.response.url);
        setLoading(false);
      }
    },
  };
  const img = getImage(username);
  const to = username
    ? `/login/users/${id}/User`
    : `/login/users/${id}/Company`;
  const block = () => {
    confirm({
      title: "Do you really want to Block this account?",
      content:
        "When you click OK, this account will be blocked and can not connect with it until you unblock it.",
      onOk: () => {
        history.push("/login/connections");
        let room = "";
        chatting.map((ch) => {
          if (ch.id === id) room = ch.room;
          return null;
        });
        return addBlock(
          token,
          type,
          {
            id: id,
            type: username ? "User" : "Company",
          },
          room
        );
      },
      onCancel() {},
    });
  };
  return (
    <div className="picname">
      <div className="pic">
        <div
          className="img"
          style={{
            background: `url(${
              currImg ? currImg : img
            }) no-repeat center center/cover`,
          }}
        ></div>
        {own && (
          <Upload
            accept=".jpg, .png, .jpeg"
            showUploadList={false}
            className="upload"
            {...props}
          >
            {loading ? <Loader tinycolor /> : <i className="fas fa-camera"></i>}
          </Upload>
        )}
        {!own && (
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
            <i className="fas fa-ellipsis-h"></i>
          </Dropdown>
        )}
      </div>
      <div className="info">
        <div className="edit">
          <Link to={to} className="name">
            {name}
          </Link>
          {username && <div className="username">@{username}</div>}
          {code && <div className="username">@{code}</div>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
    chatting: state.chat.chatting,
  };
};

const mapDispatchToProps = {
  addBlock: blockActions.addBlock,
  changeImage: userActions.changeImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PicName);
