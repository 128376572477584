import React from "react";
import "./contact.scss";
import ContactUs from "../../common/contact";

const LoginContact = () => {
  return (
    <div className="content login-contact">
      <ContactUs />
    </div>
  );
};

export default LoginContact;
