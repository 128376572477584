import { notifTypes } from "../types/notifTypes";

export function notifManage(state = { notifs: [] }, action) {
  switch (action.type) {
    case notifTypes.SET_NOTIF_REQUEST:
      return {
        ...state,
        setting: true,
      };
    case notifTypes.SET_NOTIF_SUCCESS:
      return {
        read: action.read,
        notifs: action.data,
      };
    case notifTypes.SET_NOTIF_FAILURE:
      return { ...state };
    case notifTypes.SET_READ:
      return { ...state, read: false };
    case notifTypes.READ_NOTIF:
      let newObj = state.notifs;
      newObj.map((notif) => {
        if (notif._id === action.id) notif.read.push(action.user);
        return null;
      });
      return { ...state, notifs: newObj };
    case notifTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
