import React from "react";
import Head from "../../common/head";
import CounterCard from "../../common/cards/countcard";
import ReportCard from "./../../common/cards/reportcard";
import BlockCard from "../../common/cards/blockcard";
import lang from "../../../assets/lang";

const StatusCounters = ({ icon, label, counters, to }) => {
  return (
    <React.Fragment>
      <Head label={label} mid={true} />
      <div className="triple-grid">
        <CounterCard
          icon={icon}
          label={lang.normal[lang.ls()]}
          count={counters.normal}
        />
        <ReportCard count={counters.reported} />
        <BlockCard count={counters.blocked} />
      </div>
    </React.Fragment>
  );
};

export default StatusCounters;
