import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import HomePage from "./landingPage";
import SignUp from "./signup";
import Issue from "./issues/index";
import Login from "./login";
import history from "../utils/history";
import PrivateRoute from "./routes/PrivateRoute";
import Admin from "./admin";
import SignIn from "./landingPage/sign-in";
import TempMomentProf from "./landingPage/tempMoment";
import ShareRoute from "./routes/ShareRoute";

const Home = () => {
  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <ShareRoute path="/share/:id" exact />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route path="/admin" component={Admin} />
          <Route path="/issue" component={Issue} />
          <Route path="/moment/:id" component={TempMomentProf} />
          <PrivateRoute path="/login" component={Login} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Home;
