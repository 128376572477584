import React, { useState, useEffect } from "react";
import AntTags from "../../common/ant-design/tags";
import { Modal } from "antd";
import lang from "../../../assets/lang";
import { Loader } from "../../common/loader";
import { catchService } from "../../../services/catchService";
import { success } from "../../../utils/helperMethods";
import { connect } from "react-redux";

const Catching = ({ roles }) => {
  let input;
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  let [catching, setCatching] = useState([]);
  const [catchMod, setCatchMod] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    catchService.getCatch().then((res) => {
      setCatching(res.data.catch);
    });
  }, []);
  const submitCatches = () => {
    setLoading(true);
    catchService.setCatch(catching).then((res) => {
      setCatchMod(false);
      setLoading(false);
      success("Catching Words Edited Successfully...");
    });
  };
  const cancelCatch = () => {
    setCatchMod(false);
  };
  const editCatch = () => {
    setCatchMod(true);
  };
  const showInput = () => {
    new Promise(() => {
      setInputVisible(true);
    }).then(() => {
      input.focus();
    });
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const saveInputRef = (inp) => (input = inp);
  const handleClose = (name, removedTag) => {
    catching = catching.filter((obj) => obj !== removedTag);
    setCatching(catching);
  };
  const handleInputConfirm = (name) => {
    if (inputValue && catching.indexOf(inputValue) === -1) {
      catching = [...catching, inputValue];
    }
    setCatching(catching);
    setInputValue("");
    setInputVisible(false);
  };
  return (
    <div className="catching">
      <div className="head">{lang.eventChachingWords[lang.ls()]}</div>
      {roles.catching && (
        <div onClick={editCatch} className="btn-ok">
          {lang.edit[lang.ls()]}
        </div>
      )}
      <div className="catches">
        {catching.map((ch) => {
          return <div className="catch">{ch}</div>;
        })}
      </div>
      <Modal
        title={lang.editCatches[lang.ls()]}
        visible={catchMod}
        onOk={submitCatches}
        onCancel={cancelCatch}
        okButtonProps={{ disabled: !catching }}
      >
        <AntTags
          tags={catching}
          inputVisible={inputVisible}
          inputValue={inputValue}
          showInput={showInput}
          handleInputChange={handleInputChange}
          saveInputRef={saveInputRef}
          handleClose={handleClose}
          handleInputConfirm={handleInputConfirm}
        />
        {loading && <Loader tinycolor />}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.admin.roles,
  };
};

export default connect(mapStateToProps, null)(Catching);
