import React, { Component } from "react";
import { Link } from "react-router-dom";

class MenuLink extends Component {
  render() {
    const { icon, label, to } = this.props;
    const str = window.location.pathname;
    const isActive = str.includes(to);
    const classes = isActive && to !== "/" ? "btn-menu active" : "btn-menu";
    return (
      <Link style={{ textAlign: "center" }} className={classes} to={to}>
        <i className={icon}></i> {label}
      </Link>
    );
  }
}

export default MenuLink;
