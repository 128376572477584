import React from "react";
import { Link } from "react-router-dom";
import lang from "../../assets/lang";

const WelcomeEvenzt = () => {
  return (
    <div className="showcase">
      <div className="faded">
        <div className="container single-grid">
          <div className="issue">
            <div className="info">
              <div className="head head-white">
                <i className="far fa-smile-beam"></i> {lang.welcome[lang.ls()]}
              </div>
              <div className="talk">{lang.welcomeTalk[lang.ls()]}</div>
              <Link to="/login" className="btn-issue">
                {lang.logIn[lang.ls()]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeEvenzt;
