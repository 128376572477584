import React, { Component } from "react";
import "./reports.scss";
import { Tabs } from "antd";
import { reportService } from "../../../services/reportService";
import { connect } from "react-redux";
import lang from "../../../assets/lang";
import EmptyLabel from "../../common/ant-design/empty";
import OwnReportCard from "./reportCard";
import { Loader } from "../../common/loader";
const { TabPane } = Tabs;

class Reports extends Component {
  state = {
    eventReports: [],
    eventLoad: true,
    userReports: [],
    userLoad: true,
    companyReports: [],
    companyLoad: true,
    tab: "events",
  };
  componentDidMount() {
    const { token } = this.props;
    reportService.getReports(token, "Event").then((res) => {
      this.setState({ eventReports: res.data, eventLoad: false });
    });
    reportService.getReports(token, "User").then((res) => {
      this.setState({ userReports: res.data, userLoad: false });
    });
    reportService.getReports(token, "Company").then((res) => {
      this.setState({ companyReports: res.data, companyLoad: false });
    });
  }
  updateState = (type, id) => {
    let { eventReports, userReports, companyReports } = this.state;
    if (type === "Event") {
      eventReports = eventReports.filter((obj) => {
        return obj._id !== id;
      });
      this.setState({ eventReports });
    } else if (type === "User") {
      userReports = userReports.filter((obj) => {
        return obj._id !== id;
      });
      this.setState({ userReports });
    } else {
      companyReports = companyReports.filter((obj) => {
        return obj._id !== id;
      });
      this.setState({ companyReports });
    }
  };
  changeTab = (e) => {
    this.setState({ tab: e });
  };
  render() {
    const {
      eventReports,
      eventLoad,
      userReports,
      userLoad,
      companyReports,
      companyLoad,
      tab,
    } = this.state;
    return (
      <div className="content inner-tabs reports">
        <div className="header">
          <div className="head">{lang.reports[lang.ls()]}</div>
        </div>
        <Tabs
          defaultActiveKey={tab}
          onChange={this.changeTab}
          style={{ direction: "ltr" }}
        >
          <TabPane tab={lang.events[lang.ls()]} key="events">
            {eventLoad ? (
              <Loader />
            ) : eventReports.length ? (
              <div className="triple-grid">
                {eventReports.map((rep) => {
                  return (
                    <OwnReportCard
                      updateState={this.updateState}
                      report={rep}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyLabel label={lang.noReports[lang.ls()]} />
            )}
          </TabPane>
          <TabPane tab={lang.users[lang.ls()]} key="users">
            {userLoad ? (
              <Loader />
            ) : userReports.length ? (
              <div className="triple-grid">
                {userReports.map((rep) => {
                  return (
                    <OwnReportCard
                      updateState={this.updateState}
                      report={rep}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyLabel label={lang.noReports[lang.ls()]} />
            )}
          </TabPane>
          <TabPane tab={lang.companies[lang.ls()]} key="companies">
            {companyLoad ? (
              <Loader />
            ) : companyReports.length ? (
              <div className="triple-grid">
                {companyReports.map((rep) => {
                  return (
                    <OwnReportCard
                      updateState={this.updateState}
                      report={rep}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyLabel label={lang.noReports[lang.ls()]} />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

export default connect(mapStateToProps, null)(Reports);
