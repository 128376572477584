import React, { Component } from "react";
import "./reviews.scss";
import { connect } from "react-redux";
import { reviewService } from "../../../services/reviewService";
import { Loader } from "../../common/loader";
import UserReviewCard from "./reviewCard";
import EmptyLabel from "../../common/ant-design/empty";
import lang from "../../../assets/lang";

class Reviews extends Component {
  state = {
    reviews: [],
    loading: true,
  };
  componentDidMount() {
    const { token, type } = this.props;
    reviewService.getUserReviews(token, type).then((res) => {
      this.setState({ reviews: res.data, loading: false });
    });
  }
  updateState = (id) => {
    let { reviews } = this.state;
    reviews = reviews.filter((obj) => {
      return obj._id !== id;
    });
    this.setState({ reviews });
  };
  render() {
    const { reviews, loading } = this.state;
    return (
      <div className="content">
        {loading ? (
          <Loader />
        ) : (
          <div className="reviews">
            <div className="header">
              <div className="head">{lang.reviews[lang.ls()]}</div>
              <div className="count">{reviews.length}</div>
            </div>
            {reviews.length ? (
              <div className="triple-grid">
                {reviews.map((rev) => {
                  return (
                    <UserReviewCard
                      review={rev}
                      updateState={this.updateState}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyLabel label={lang.noReviews[lang.ls()]} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    type: state.login.type,
  };
};

export default connect(mapStateToProps, null)(Reviews);
